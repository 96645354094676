import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

class ManualList extends Component {
  constructor(props) {
    super(props);
    this.state = { items: [], loading: true, error: false };

    this.loadItems = this.loadItems.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    document.title = `Operational Manual | Flamingo Admin`;
    this.loadItems();
  }

  /* NETWORKING */

  loadItems() {
    this.setState({ loading: true });
    api.get(`/operation/manual`)
      .then((res) => this.setState({ items: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderItem(item, i) {
    return (
      <Link className="fm-manual-list-item" key={i} to={{ pathname: `/manual/${item.id}`, state: { item: item } }}>
        <p className="fm-manual-list-item-title">{ item.title }</p>
      </Link>
    );
  }

  render() {
    const { items, loading, error } = this.state;

    return (
      <div className="fm-manual-list">
        <NavigationBar title="Operational Manual" loading={loading} />
        <div className="fm-manual-list-items">
          { items.map(this.renderItem) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ManualList;
