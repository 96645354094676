import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import api from 'helpers/api';
import colors from 'helpers/colors';

const descriptionFor = (task) => {
  switch (task.status) {
    case 'available':
      return task.expiresAt ? `Expires ${ moment(task.expiresAt).fromNow() }.` : `Available.`;
    case 'reserved':
      return `Reserved by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }. Expires ${ moment(task.expiresAt).fromNow() }.`;
    case 'collected':
      return `Collected by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }.${ task.completeBy ? ` Due ${moment(task.completeBy).fromNow() }.` : '' }`;
    case 'completed':
      return `Completed by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName } at ${ moment(task.completedAt).format('h:mma D/M/YY') }.`;
    case 'expired':
      return `Task expired. ${ task.notes ? task.notes : '' }`;
    case 'cancelled':
      return `Task cancelled.`;
    default:
      return ``;
  }
};

class TaskView extends Component {
  constructor() {
    super();
    this.state = { task: {}, loading: true };

    this.loadTask = this.loadTask.bind(this);
    this.handleExpire = this.handleExpire.bind(this);
  }

  componentDidMount() {
    document.title = 'Task Details | Flamingo Admin';
    this.loadTask();
  }

  loadTask() {
    return api.get('/task/' + this.props.match.params.taskId)
      .then((res) => this.setState({ task: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleExpire() {
    this.setState({ loading: true });
    return api.post(`/task/${this.props.match.params.taskId}/expire`)
      .then(() => history.goBack())
      .catch(() => this.setState({ loading: false }, () => alert('Failed.')));
  }

  render() {
    const { loading, task } = this.state;
    const expireTaskAction = { onClick: this.handleExpire, icon: 'trash' };
    const showExpire = !loading && ['available','reserved','collected'].includes(task.status);
    const assignAction = { to: task ? `/task/${task.id}/assign` : '#', icon: 'user-plus' };

    return (
      <div className="fm-task">
        <NavigationBar title="Task Details" loading={loading} showBack={true} rightActions={showExpire ? [expireTaskAction, assignAction] : [assignAction]} />
        {
          !loading &&
          <>
            <div className="fm-task-view-content">
              <div className="fm-task-view-header">
                <Link className="fm-task-view-rego" to={`/vehicle/${task.vehicle.id}`}>{ task.vehicle.registration }</Link>
                <p className="fm-task-view-type" style={{ backgroundColor: colors.task[task.type] }}>{ task.type.toUpperCase() }</p>
                <p className="fm-task-view-created">{ moment(task.createdAt).fromNow() }</p>
                <div className="fm-task-view-status" style={{ backgroundColor: colors.taskStatus[task.status] }}></div>
              </div>
              <p className="fm-task-view-notes">{ descriptionFor(task) }</p>
              { task.notes && <p className="fm-task-view-notes">{ task.notes }</p> }
              { task.createdBy && <p className="fm-task-view-notes">Created by { task.createdBy.firstName } { task.createdBy.lastName }.</p> }
              <div className="fm-task-view-details">
                {
                  (task.collectedBatteryPercent && task.collectedAt) ? (
                    <a className="fm-task-view-details-link" target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${task.collectedLatitude},${task.collectedLongitude}`}>
                      <span className="fm-task-view-details-link-left">Collected:</span>
                      <span className="fm-task-view-details-link-right">{ moment(task.collectedAt).format('h:mma D/M/YY') } - { parseInt(task.collectedBatteryPercent * 100) }%</span>
                    </a>
                  ) : null
                }
                {
                  (task.completedBatteryPercent && task.completedAt) ? (
                    <a className="fm-task-view-details-link" target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${task.completedLatitude},${task.completedLongitude}`}>
                      <span className="fm-task-view-details-link-left">Completed:</span>
                      <span className="fm-task-view-details-link-right">{ moment(task.completedAt).format('h:mma D/M/YY') } - { parseInt(task.completedBatteryPercent * 100) }%</span>
                    </a>
                  ) : null
                }
              </div>
            </div>
            { task.photoUrl && <img className="fm-task-view-photo" src={task.photoUrl} alt="Completed Task" /> }
          </>
        }
      </div>
    );
  }
}

export default TaskView;
