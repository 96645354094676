import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const firmwareStatus = {
  success: '#2ecc71',
  upgrading: '#00a8ff',
  failed: '#e74c3c',
};

class FirmwareView extends Component {
  constructor() {
    super();
    this.state = { firmware: {}, history: [], loading: true };

    this.loadFirmware = this.loadFirmware.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderHistory = this.renderHistory.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Firmware | Flamingo Admin';
    this.loadFirmware();
  }

  loadFirmware() {
    this.setState({ loading: true });
    return api.get(`/vehicle/${this.props.match.params.vehicleId}/firmware`)
      .then((res) => this.setState({ firmware: res.data.data, loading: false }))
      .then(() => api.get(`/vehicle/${this.props.match.params.vehicleId}/firmware-history`))
      .then((res) => this.setState({ history: res.data.data }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  renderHistory(item, i) {
    return (
      <div className="fm-firmware-view-history-item" key={i}>
        <div className="fm-firmware-view-history-left">
          <p className="fm-firmware-view-history-name">{ item.name }</p>
          <p className="fm-firmware-view-history-time">{ moment(item.createdAt).fromNow() }</p>
        </div>
        <p className="fm-firmware-view-history-status" style={{ backgroundColor: firmwareStatus[item.status] }}>{ item.status.toUpperCase() }</p>
      </div>
    );
  }

  render() {
    const { loading, firmware, history } = this.state;
    const upgradeAction = { to: `/vehicle/${this.props.match.params.vehicleId}/firmware/upgrade`, icon: 'arrow-alt-circle-up' };
    const refreshAction = { onClick: this.loadFirmware, icon: 'sync-alt' };

    return (
      <div className="fm-firmware-view">
        <NavigationBar title="Current Firmware" loading={loading} showBack={true} rightActions={[refreshAction, upgradeAction]} />
        {
          !loading &&
          <div className="fm-firmware-view-list">
            <div className="fm-firmware-view-row">
              <p className="fm-firmware-view-label">IoT Firmware:</p>
              <p className="fm-firmware-view-value">{ firmware.iot || 'Unknown' }</p>
            </div>
            <div className="fm-firmware-view-row">
              <p className="fm-firmware-view-label">Controller Firmware:</p>
              <p className="fm-firmware-view-value">{ firmware.controller || 'Unknown' }</p>
            </div>
            <div className="fm-firmware-view-row">
              <p className="fm-firmware-view-label">Dashboard Firmware:</p>
              <p className="fm-firmware-view-value">{ firmware.dashboard || 'Unknown' }</p>
            </div>
            <div className="fm-firmware-view-row">
              <p className="fm-firmware-view-label">Battery Firmware:</p>
              <p className="fm-firmware-view-value">{ firmware.battery || 'Unknown' }</p>
            </div>
            {
              firmware.externalBattery &&
              <div className="fm-firmware-view-row">
                <p className="fm-firmware-view-label">External Battery Firmware:</p>
                <p className="fm-firmware-view-value">{ firmware.externalBattery || 'Unknown' }</p>
              </div>
            }
          </div>
        }
        {
          history.length > 0 &&
          <div className="fm-firmware-view-section-header">
            <p className="fm-firmware-view-section-title">Upgrade History</p>
          </div>
        }
        <div className="fm-firmware-view-history">
          { history.map(this.renderHistory) }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default FirmwareView;
