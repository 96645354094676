import React, { Component } from 'react';
import { QrReader } from '@flamingo-scooters/react-qr-reader';
import NavigationBar from '../../../common/navigation-bar';
import api from '../../../../helpers/api';

const emojis = ['😎','🤡','💩','🤖','👾','👽','🦸‍','🧚','🦄','🦩','🧙','‍🧟','😀'];
const comments = [
  'Awesome work!',
  'Goodbye Rona!',
  'Squeaky clean!',
  'Spray and walk away!',
  'Thank you!',
  'Stay hydrated!',
  'Atleast the weathers nice!',
  'Perfect!',
  'Keep them clean, keep them keen.',
  'Another one - DJ Khaled',
  'Stay safe!',
  'Hello World',
  'Tap for a surprise.',
  'Nice work!',
  'All clean!',
  'On to the next!',
  'Hungry? Order some Flamingo Food',
  'Nice!',
  'Great work!',
  'New High Score',
  'Making the world a safer place',
  'You could eat off this',
  'You\'re the 1 millionth scanner!',
  '99.99% clean',
  'Stay away from the dirty limes',
  'Beam that rona away',
  'Delta who?',
  'Skrt skrt',
  'Voice commands activated. Say "Scan" to continue.',
  'Less reading, more cleaning.',
  'no touchy touchy',
  'Are you wearing your vest?',
  'Make sure that scooter is parked safely!',
  'Nice to see you again!',
  'Look behind you!',
  'Imagine you are scanning the winning Lotto ticket',
];

class SanitisationScanner extends Component {
  constructor() {
    super();
    this.state = { lastScan: '', status: 'Ready to scan', loading: false };

    this.handleScan = this.handleScan.bind(this);
    this.findResponse = this.findResponse.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleScannerError = this.handleScannerError.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Sanitisation Scanner | Flamingo Admin';
    localStorage.setItem('fm-sanitisation', 'scanner');
  }

  /* ------ Networking ------ */

  handleScan(result) {
    if (result) {
      const qrCode = result.text.slice(-6);
      if (!isNaN(qrCode) && this.state.lastScan !== qrCode) {
        this.setState({ status: `Scanned ${qrCode}...`, lastScan: qrCode, loading: true });
        return api.post('/vehicle/search', { query: qrCode, limit: 1 })
          .then(this.findResponse)
          .catch(this.handleError);
      }
    }
  }

  findResponse(res) {
    if (res.data.data[0]) {
      const vehicle = res.data.data[0];
      this.setState({ status: `Sanitising ${vehicle.registration}...` });
      return api.post(`/sanitisation/${vehicle.id}`)
        .then(this.handleSuccess)
        .catch(this.handleError);
    } else {
      this.setState({ status: 'Could not find vehicle', loading: false });
    }
  }

  handleSuccess() {
    const status = `${emojis[Math.floor(Math.random() * emojis.length)]} : ${comments[Math.floor(Math.random() * comments.length)]}`;
    this.setState({ loading: false, status });
    setTimeout(() => {
      if (this.state.status === status && !this.state.loading) {
        this.setState({ status: 'Ready to scan' });
      }
    }, 2500);
  }

  handleScannerError() {
    this.setState({ status: 'Scanner failed', loading: false });
  }

  handleError(e) {
    const status = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ status, loading: false });
  }

  /* ------ Renders ------ */

  render() {
    const { loading, status } = this.state;
    const actions = [
      { to: '/sanitisation/map', icon: 'map-marked' },
      { to: '/sanitisation/schedule', icon: 'list' },
    ];

    return (
      <div className="fm-sanitisation-scanner">
        <NavigationBar title="Sanitisation Scanner" loading={loading} rightActions={actions} />
        <div className="fm-sanitisation-scanner-content">
          <div className="fm-sanitisation-scanner-container">
            <QrReader
              scanDelay={300}
              onResult={this.handleScan}
              constraints={{ facingMode: 'environment' }}
              videoStyle={{ objectFit: 'cover' }}
              className="fm-sanitisation-scanner-reader"
            />
          </div>
          <div className="fm-sanitisation-scanner-status">{ status }</div>
        </div>
      </div>
    );
  }
}

export default SanitisationScanner;
