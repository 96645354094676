import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import moment from 'moment';
import api from 'helpers/api';

import HelmetLockTabs from './components/helmet-lock-tabs';

class HelmetLock extends Component {
  constructor(props) {
    super(props);
    const preloadedHelmetLock = props.location.state ? props.location.state.helmetLock : false;
    this.state = { helmetLock: preloadedHelmetLock || {}, loading: true };

    this.loadHelmetLock = this.loadHelmetLock.bind(this);
    this.handleError = this.handleError.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.findResponse = this.findResponse.bind(this);
  }

  componentDidMount() {
    document.title = `Helmet Lock | Flamingo Admin`;
    this.loadHelmetLock();
  }

  /* NETWORKING */

  loadHelmetLock() {
    this.setState({ loading: true });
    return api.get(`/helmet/lock/${this.props.match.params.helmetLockId}`)
      .then((res) => this.setState({ helmetLock: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  setStatus(eventType) {
    this.setState({ loading: true, error: false });
    return api.post(`/helmet/lock/${this.props.match.params.helmetLockId}/status`, { eventType })
      .then((res) => this.setState({ helmetLock: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleChange() {
    const registration = window.prompt('Enter the registration of the new vehicle to link this helmet lock to.', '');
    if (registration != null) {
      this.setState({ loading: true, error: false });
      return api.post('/vehicle/search', { query: registration, limit: 1 })
        .then(this.findResponse)
        .catch(this.handleError);
    }
  }

  findResponse(res) {
    if (res.data.data[0]) {
      const vehicleId = res.data.data[0].id;
      const identifier = this.state.helmetLock.identifier;
      return api.post(`/helmet/lock`, { vehicleId, identifier })
        .then((res) => this.setState({ helmetLock: res.data.data, loading: false }))
        .catch(this.handleError);
    } else {
      this.setState({ error: 'vehicle_not_found', loading: false });
    }
  }

  handleRemove() {
    const promptResult = window.confirm(`This action unpairs the helmet lock from the ${this.state.helmetLock.vehicle.type.toLowerCase()}. Use this when a helmet lock is being discarded. Please use the Android app if you wish to unlock the helmet. Are you sure you want to remove this helmet lock from ${this.state.helmetLock.vehicle.registration}?`);
    if (promptResult === true) {
      this.setState({ loading: true, error: false });
      return api.post(`/helmet/lock/${this.props.match.params.helmetLockId}/remove`)
        .then((res) => this.setState({ helmetLock: res.data.data, loading: false }))
        .catch(this.handleError);
    }
  }

  /* RENDERS */

  render() {
    const { helmetLock, loading, error } = this.state;

    // Navigation
    const title = `Helmet Lock ${this.props.match.params.helmetLockId}`;

    // Render
    return (
      <div className="fm-helmet-lock">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-helmet-lock-content">
          <div className="fm-helmet-lock-header">
            <p className="fm-helmet-lock-identifier">{ helmetLock.identifier || '0103XXXXXXXXXXXX' }</p>
            <p className="fm-helmet-lock-updated">{ helmetLock.lastUpdated ? moment(helmetLock.lastUpdated).fromNow() : '---' }</p>
          </div>
          <div className="fm-helmet-lock-status">
            <div className="fm-helmet-lock-status-item">
              <div className="fm-helmet-lock-status-item-icon">
                <Icon icon={ helmetLock.hasHelmet ? 'hard-hat' : 'lock-open' } />
              </div>
              <p className="fm-helmet-lock-status-item-text">{ helmetLock.hasHelmet ? 'Has Helmet' : 'No Helmet' }</p>
            </div>
            <div className="fm-helmet-lock-status-item">
              <div className="fm-helmet-lock-status-item-icon">
                <Icon icon="battery-three-quarters" />
              </div>
              <p className="fm-helmet-lock-status-item-text">{ parseInt(helmetLock.batteryPercent * 100) }%</p>
            </div>
            <Link className="fm-helmet-lock-status-item" to={{ pathname: helmetLock.vehicle ? `/vehicle/${helmetLock.vehicle.id}` : '#', state: { vehicle: helmetLock.vehicle } }}>
              <div className="fm-helmet-lock-status-item-icon">
                <Icon icon="dot-circle" />
              </div>
              <p className="fm-helmet-lock-status-item-text">{ helmetLock.vehicle ? helmetLock.vehicle.registration : 'Not Linked' }</p>
            </Link>
          </div>
          <div className="fm-helmet-lock-reminder">
            <p className="fm-helmet-lock-reminder-text">The Admin App does not have bluetooth capabilities to unlock the helmet lock. Please use the Android Admin App to unlock the helmet.</p> 
          </div>
          <div className="fm-helmet-lock-controls">
            <div className="fm-helmet-lock-controls-group">
              <button className="fm-helmet-lock-controls-button" onClick={() => this.setStatus('locked')}>Set Has Helmet</button>
              <button className="fm-helmet-lock-controls-button" onClick={() => this.setStatus('unlocked')}>Set Missing Helmet</button>
            </div>
            <div className="fm-helmet-lock-controls-group">
              <button className="fm-helmet-lock-controls-button" onClick={this.handleChange}>Change Vehicle</button>
              { helmetLock.vehicle && <button className="fm-helmet-lock-controls-button" onClick={this.handleRemove}>Unpair Helmet Lock</button> }
            </div>
          </div>
          <HelmetLockTabs helmetLockId={this.props.match.params.helmetLockId} />
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default HelmetLock;
