import React, { Component } from 'react';
import Map, { Marker, Circle } from 'components/common/map';
import NavigationBar from 'components/common/navigation-bar';
import moment from 'moment';
import api from 'helpers/api';

const weekSort = { monday: 0, tuesday: 1, wednesday: 2, thursday: 3, friday: 4, saturday: 5, sunday: 6 };

class HubView extends Component {
  constructor() {
    super();
    this.state = { hub: { targets: [] }, loading: true, targetKey: moment().add(3, 'hours').format('dddd').toLowerCase() };

    this.loadHub = this.loadHub.bind(this);
    this.handleDirections = this.handleDirections.bind(this);
    this.renderTarget = this.renderTarget.bind(this);
  }

  componentDidMount() {
    document.title = 'Hub Details | Flamingo Admin';
    this.loadHub();
  }

  loadHub() {
    return api.get('/hub/' + this.props.match.params.hubId)
      .then((res) => this.setState({ hub: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleDirections() {
    if (this.state.loading) {
      return;
    }
    const { latitude, longitude } = this.state.hub;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  renderTarget(target, i) {
    const { hub } = this.state;

    const occupancy = target.vehicleType === 'scooter' ? hub.scooterOccupancy : hub.bikeOccupancy;
    const percentage = occupancy / target.target;
    const backgroundColor = percentage > 0.8 ? '#20BF6B' : (percentage > 0.5 ? '#f39c12' : '#EB3B5A');
    const icon = `https://storage.googleapis.com/flamingo-static/images/admin/icon-${target.vehicleType}-white.svg`;

    return (
      <div className="fm-hub-view-tags" style={{ backgroundColor }} key={i}>
        <p className="fm-hub-view-capacity">{ occupancy } / { target.target }</p>
        <img className="fm-hub-view-type" src={ icon } alt={target.vehicleType} />
      </div>
    );
  }

  render() {
    const { loading, hub, targetKey } = this.state;

    const title = hub.name || 'Hub Details';
    const navigationAction = { onClick: this.handleDirections, icon: 'directions' };
    const editAction = { to: `/hub/${this.props.match.params.hubId}/edit`, icon: 'edit' };

    const position = { lat: hub.latitude, lng: hub.longitude };
    const mapOptions = { center: position, zoom: 15 };

    const todaysTargets = hub.targets.filter((target) => target.day === targetKey);
    const targets = hub.targets.sort((a,b) => weekSort[a.day] - weekSort[b.day]);

    return (
      <div className="fm-hub-view">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={[navigationAction, editAction]} />
        {
          !loading &&
          <div className="fm-hub-view-content">
            <div className="fm-hub-view-header">
              <div className="fm-hub-view-photo" style={{ backgroundImage: `url('${hub.photoUrl}')` }}>
                { todaysTargets.map(this.renderTarget) }
              </div>
              <div className="fm-hub-view-map">
                <Map options={mapOptions} google={window.google}>
                  <Marker position={position} />
                  <Circle position={position} radius={hub.radius} fillOpacity={0.25} fillColor={'#ff206e'} strokeWeight={0} />
                  <Circle position={position} radius={hub.deployRadius} fillOpacity={0.25} fillColor={'#ff206e'} strokeWeight={0} />
                </Map>
              </div>
            </div>
            <div className="fm-hub-view-container">
              <p className="fm-hub-view-address">{ hub.address }</p>
              <p className="fm-hub-view-description">{ hub.description }</p>
              <p className="fm-hub-view-targets">Targets</p>
              { targets.map((target, i) => <p className="fm-hub-view-target" key={i}>{ target.day }: { target.target } { target.vehicleType }s</p>) }
            </div>
          </div>
        }
      </div>
    );
  }
}

export default HubView;
