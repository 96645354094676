import React, { Component } from 'react';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';

const filterOptions = ['AVAILABLE', 'UNAVAILABLE', 'MAINTENANCE'];

const vehicleIcon = (status, batteryPercent = false) => {
  if (batteryPercent) {
    const batteryKey = batteryPercent > 0.51 ? 3 : (batteryPercent > 0.33 ? 2 : 1);
    return { url: `https://storage.googleapis.com/flamingo-static/images/admin/vehicle-${status.toLowerCase()}-${batteryKey}.png`, size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) };
  }
  return { url: `https://storage.googleapis.com/flamingo-static/images/admin/vehicle-${status.toLowerCase()}.png`, size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) };
};

class TaskNewMap extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], type: 'pickup', mode: 'search' };

    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    this.loadFilters();
  }

  /* ------ Filters ------ */

  loadFilters() {
    const filtersString = localStorage.getItem('vehicleFilters');
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      filters.statuses = filters.statuses.filter((status) => filterOptions.includes(status));
      this.setState({ filters });
    } else {
      const filters = { statuses: filterOptions };
      this.setState({ filters });
    }
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw, zoom) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), zoom, this.state.filters);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, zoom, filters) {
    let endpoint = `/vehicle/area?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}&zoom=${zoom}`;

    if (filters.battery !== '1.1') {
      endpoint += '&battery=' + filters.battery;
    }

    if (filters.model && filters.model !== 'ALL') {
      endpoint += '&model=' + filters.model;
    }

    if (filters.statuses.length > 0) {
      endpoint += filters.statuses.map((status) => `&status[]=${status}`).join('');
    } else {
      endpoint += filterOptions.map((status) => `&status[]=${status}`).join('');
    }

    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude, zoom };
    return api.get(endpoint)
      .then((res) => this.setState({ vehicles: res.data.data, currentBounds }))
      .catch(console.log);
  }

  handleSelect(vehicle) {
    this.props.onAdd(vehicle);
  }

  /* ------ Renders ------ */

  renderMarker(vehicle, i) {
    const position = { lat: vehicle.latitude, lng: vehicle.longitude };
    const icon = vehicleIcon(vehicle.status, vehicle.batteryPercent);
    return (
      <Marker
        key={i}
        position={position}
        title={vehicle.registration}
        icon={icon}
        onClick={() => this.handleSelect(vehicle)} />
    );
  }

  render() {
    const { selectedVehicles } = this.props;
    const vehicles = this.state.vehicles.filter((vehicle) => selectedVehicles.findIndex((v) => v.id === vehicle.id) < 0);
    return (
      <div className="fm-task-new-map">
        <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
          { vehicles.map(this.renderMarker) }
        </Map>
      </div>
    );
  }
}

export default TaskNewMap;
