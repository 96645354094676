import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SanitisationSchedule from './components/sanitisation-schedule/';
import SanitisationMap from './components/sanitisation-map/';
import SanitisationScanner from './components/sanitisation-scanner/';
import SanitisationStatistics from './components/sanitisation-statistics/';

class Sanitisation extends Component {
  render() {
    const lastRoute = `/sanitisation/${ localStorage.getItem('fm-sanitisation') || 'schedule' }`;
    return (
      <Switch>
        <Route path="/sanitisation/scanner" component={SanitisationScanner} />
        <Route path="/sanitisation/map" component={SanitisationMap} />
        <Route path="/sanitisation/statistics" component={SanitisationStatistics} />
        <Route path="/sanitisation/schedule" component={SanitisationSchedule} />
        <Redirect from='/sanitisation' to={lastRoute} />
      </Switch>
    );
  }
}

export default Sanitisation;
