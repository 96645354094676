import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsWellington extends Component {
  constructor() {
    super();
    const today = moment().format('YYYY-MM')
    this.state = { month: today, today, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    document.title = 'Wellington | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const month = this.state.month;
    return api.post('/analytics/wellington', { month })
      .then((res) => this.setState({ data: res.data.data.content, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleDateChange(e) {
    this.setState({ month: e.target.value });
  }

  renderRow(content, i) {
    if (i === 0) {
      return (
        <tr key={i}>
          { content.map((c, ii) => <th key={ii}>{c}</th>) }
        </tr>
      );
    }
    return (
      <tr key={i}>
        { content.map((c, ii) => <td key={ii}>{c}</td>) }
      </tr>
    );
  }

  renderContent(content, i) {
    if (Array.isArray(content)) {
      if (Array.isArray(content[0])) {
        return (
          <table key={i} className="fm-analytics-wellington-table">
            <tbody>
              { content.map(this.renderRow) }
            </tbody>
          </table>
        );
      }
      return (
        <ul key={i} className="fm-analytics-wellington-list">
          { content.map((c, ii) => <li key={ii}>{c}</li>) }
        </ul>
      );
    }
    return <p key={i} className="fm-analytics-wellington-text">{content}</p>;
  }

  render() {
    const { loading, data, month, today } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="Wellington" loading={loading} />
        <div className="fm-analytics-kpis">
          <div className="fm-analytics-kpis-form">
            <input value={month} className="fm-input" type="month" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-kpis-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            data ? data.map(this.renderContent) : (
              <p className="fm-analytics-kpis-empty">{ loading ? 'Please wait...' : 'Select the month above.' }</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsWellington;
