import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PieChart, Pie } from 'recharts';
import Loading from 'components/common/loading';
import PopUp from 'components/common/pop-up';
import Input from 'components/common/input';
import api from 'helpers/api';

class ParkingOverview extends Component {
  constructor() {
    super();
    this.state = { loading: true, stats: { usersReviewed:{}, ridesReviewed:{}, waitingOffences:{} }, periodPicker: false, periodString: moment().format('MMMM YYYY'), period: moment().format('YYYY-MM') };
    this.loadStats = this.loadStats.bind(this);
    this.renderReviewCounts = this.renderReviewCounts.bind(this);
    this.handlePeriodPicker = this.handlePeriodPicker.bind(this);
    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Parking Overview | Flamingo Admin';
    this.loadStats();
  }

  loadStats() {
    return api.get('/parking?period=' + this.state.period)
      .then((res) => this.setState({ stats: res.data.data, loading: false }))
      .catch(console.log);
  }

  handlePeriodPicker() {
    this.setState({ periodPicker: true });
  }

  handlePeriodChange(period) {
    const periodString = moment(`${period}-01`).format('MMMM YYYY');
    this.setState({ periodPicker: false, loading: true, periodString, period }, this.loadStats);
  }

  renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, data) {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const item = data[index];

    return (
      <text x={x} y={y} fill="#222" textAnchor={x > cx ? "start" : "end"} fontSize={12} dominantBaseline="central">
        { item.name } ({ (percent * 100).toFixed() }%)
      </text>
    );
  }

  renderReviewCounts() {
    // Country demographics
    const parkingReviewCounts = this.state.stats.parkingReviewCounts;
    const data = parkingReviewCounts.map((review) => ({ name: review.parkingReview, value: review.count }));
    return (
      <div className="fm-parking-overview-counts">
        <div className="fm-parking-overview-graph">
          <p className="fm-parking-overview-graph-title">Parking Review Chart</p>
          <PieChart width={425} height={300}>
            <Pie dataKey="value" data={data} cx={212} cy={150} innerRadius={40} outerRadius={80} label={(d) => this.renderCustomizedLabel(d, data)} fill="#FF206E" />
          </PieChart>
        </div>
        <div className="fm-parking-overview-counts-list">
          <p className="fm-parking-overview-count-title">Parking Review Counts</p>
          { parkingReviewCounts.map((review, i) => <p key={i} className="fm-parking-overview-counts-item">{review.parkingReview}<span className="fm-parking-overview-counts-item-value">{review.count}</span></p>) }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="fm-page fm-parking">
        <p className="fm-page-title">Parking Overview - { this.state.periodString }</p>
        <div className="fm-page-actions">
          <Link className="fm-page-action" to="/parking/offence">Parking Offences</Link>
          <Link className="fm-page-action" to="/parking/review">Parking Review</Link>
          <span className="fm-page-action" onClick={this.handlePeriodPicker}>Change Month</span>
        </div>
        {
          this.state.loading ? (
            <Loading />
          ) : (
            <>
              <div className="fm-parking-overview-stats">
                <div className="fm-parking-overview-stat">
                  <p className="fm-parking-overview-stat-value">{ this.state.stats.usersReviewed[0].count }</p>
                  <p className="fm-parking-overview-stat-label">Users Reviewed</p>
                </div>
                <div className="fm-parking-overview-stat">
                  <p className="fm-parking-overview-stat-value">{ this.state.stats.ridesReviewed[0].count }</p>
                  <p className="fm-parking-overview-stat-label">Rides Reviewed</p>
                </div>
                <div className="fm-parking-overview-stat">
                  <p className="fm-parking-overview-stat-value">{ this.state.stats.waitingOffences[0].count }</p>
                  <p className="fm-parking-overview-stat-label">Outstanding Offences</p>
                </div>
              </div>
              { this.renderReviewCounts() }
            </>
          )
        }
        <PopUp active={this.state.periodPicker} title="Select Parking Period" onDismiss={() => this.setState({ periodPicker: false })}>
          <Input type="month" value={this.state.period} onChange={this.handlePeriodChange} />
          <span className="fm-vehicle-popup-cancel-button" onClick={() => this.setState({ periodPicker: false })}>Close</span>
        </PopUp>
      </div>
    );
  }
}

export default ParkingOverview;
