import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import FileUploader from 'components/common/file-uploader';
import Map, { Marker } from 'components/common/map';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import colors from 'helpers/colors';
import history from 'helpers/history';
import api from 'helpers/api';

class InspectionNew extends Component {
  constructor() {
    super();
    this.state = {
      vehicle: {},
      criteria: [],
      results: {},
      uploads: {},
      notes: '',
      currentIndex: 0,
      prefill: [],
      tickets: [],
      loading: true,
    };

    this.loadVehicle = this.loadVehicle.bind(this);
    this.loadCriteria = this.loadCriteria.bind(this);
    this.loadPrefill = this.loadPrefill.bind(this);
    this.loadTickets = this.loadTickets.bind(this);
    this.parseCriteria = this.parseCriteria.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleToot = this.handleToot.bind(this);
    this.handleLight = this.handleLight.bind(this);
    this.handleResolve = this.handleResolve.bind(this);

    this.handleNext = this.handleNext.bind(this);
    this.handleFail = this.handleFail.bind(this);
    this.handlePass = this.handlePass.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handlePhoto = this.handlePhoto.bind(this);
    this.handlePhotoProgress = this.handlePhotoProgress.bind(this);

    this.handleNotes = this.handleNotes.bind(this);
    this.appendNote = this.appendNote.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.renderCriteria = this.renderCriteria.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderPrefill = this.renderPrefill.bind(this);
    this.renderTicket = this.renderTicket.bind(this);
  }

  componentDidMount() {
    document.title = 'New Inspection | Flamingo Admin';

    this.loadVehicle();
  }

  /* ----- NETWORKING ----- */

  loadVehicle() {
    return api.get(`/vehicle/${this.props.match.params.vehicleId}`)
      .then((res) => this.setState({ vehicle: res.data.data }))
      .then(this.loadCriteria)
      .catch(this.handleError);
  }

  loadCriteria() {
    return api.get(`/inspection/criteria/${this.props.match.params.vehicleId}`)
      .then(this.parseCriteria)
      .catch(this.handleError);
  }

  loadPrefill() {
    return api.get(`/inspection/prefill/${this.props.match.params.vehicleId}`)
      .then((res) => this.setState({ prefill: res.data.data }, this.loadTickets))
      .catch(this.handleError);
  }

  loadTickets() {
    return api.get(`/ticket/active?vehicleId=${this.props.match.params.vehicleId}`)
      .then((res) => this.setState({ tickets: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  parseCriteria(res) {
    const criteria = res.data.data.reduce((total, group) => {
      const items = group.items;
      group.items = undefined;
      total.push(group);
      return total.concat(items);
    }, []);
    this.setState({ criteria, loading: false, startTime: new Date() }, this.loadPrefill);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ----- ACTIONS ----- */

  handleToot() {
    this.setState({ loading: true });
    return api.post(`/vehicle/${this.props.match.params.vehicleId}/toot`)
      .then((res) => this.setState({ loading: false }))
      .catch(this.handleError);
  }

  handleLight() {
    this.setState({ loading: true });
    const headlightOn = this.state.headlightOn ? false : true;
    return api.post(`/vehicle/${this.props.match.params.vehicleId}/headlight`, { controlType: headlightOn })
      .then((res) => this.setState({ headlightOn, loading: false }))
      .catch(this.handleError);
  }

  handleResolve(ticket) {
    this.setState({ loading: true });
    api.post(`/ticket/${ticket.id}/resolve`)
      .then(this.loadTickets)
      .catch(this.handleError);
  }

  /* ----- HANDLERS ----- */

  handleNext(e, updatedResults = false, updatedUploads = false) {
    let { currentIndex, criteria } = this.state;
    const results = updatedResults || this.state.results;
    const uploads = updatedUploads || this.state.uploads;
    const currentCriteria = criteria[currentIndex];
    let error = false;
    if (currentCriteria.object === 'inspectionItem' && !results[currentCriteria.id] && !uploads[currentCriteria.id]) {
      error = 'A value is required.';
    } else {
      ++currentIndex;
    }
    this.setState({ currentIndex, results, uploads, error });
  }

  handleFail() {
    const { criteria, currentIndex, results } = this.state;
    results[criteria[currentIndex].id] = 'FAIL';
    return this.handleNext(false, results);
  }

  handlePass() {
    const { criteria, currentIndex, results } = this.state;
    results[criteria[currentIndex].id] = 'PASS';
    return this.handleNext(false, results);
  }

  handlePhoto(criteriaId, file) {
    const { results, uploads } = this.state;
    results[criteriaId] = file.url;
    uploads[criteriaId] = false;
    return this.handleNext(false, results, uploads);
  }

  handlePhotoProgress(criteriaId, progress = 0.01) {
    const { uploads } = this.state;
    uploads[criteriaId] = progress === 100 ? false : progress;
    this.setState({ uploads });
  }

  handleBack() {
    let { currentIndex } = this.state;
    if (currentIndex === 0) {
      return;
    }
    --currentIndex;
    this.setState({ currentIndex, error: false });
  }

  handleNotes(e) {
    const notes = e.target.value;
    this.setState({ notes });
  }

  appendNote(note) {
    let { loading, notes } = this.state;
    if (loading) {
      return;
    }

    if (notes.length === 0) {
      notes = note + '.';
    } else if (notes.slice(-1) === '.') {
      notes += ' ' + note + '.';
    } else {
      notes += '. ' + note + '.';
    }

    this.setState({ notes });
  }

  handleSubmit() {
    const { loading, results, uploads, startTime, notes } = this.state;
    if (loading) {
      return;
    }

    if (notes.length < 5) {
      return this.setState({ error: 'Please add a note to the inspection.', loading: false });
    }

    this.setState({ error: false, loading: true });
    const duration = moment().diff(startTime, 'seconds');

    if (Object.values(uploads).includes(true)) {
      return this.setState({ error: 'Please wait for uploads to finish.', loading: false });
    }

    return api.post(`/inspection/${this.props.match.params.vehicleId}`, { notes, duration, ...results })
      .then((res) => history.push(`/vehicle/${this.props.match.params.vehicleId}`))
      .catch(this.handleError);
  }

  /* ----- RENDERS ----- */

  renderContent(criteria) {
    const { vehicle, loading } = this.state;

    if (criteria.type === 'PHOTO') {
      return (
        <div className="fm-inspection-new-card-content">
          <FileUploader accept="images" type="inspection" resize={1800} onStart={() => this.handlePhotoProgress(criteria.id)} onProgress={(progress) => this.handlePhotoProgress(criteria.id, progress)} onFinish={(file) => this.handlePhoto(criteria.id, file)} />
        </div>
      );
    }

    if (criteria.feature === 'photo' && criteria.photoUrl) {
      return (
        <div className="fm-inspection-new-card-content">
          <div className="fm-inspection-new-card-content-photo" style={{ backgroundImage: `url('${criteria.photoUrl}')` }}></div>
        </div>
      );
    }

    if (criteria.feature === 'location') {
      return (
        <div className="fm-inspection-new-card-content">
          <div className="fm-inspection-new-card-content-map">
            <Map options={{ center: { lat: vehicle.latitude, lng: vehicle.longitude }, zoom: 15 }} google={window.google}>
              <Marker position={{ lat: vehicle.latitude, lng: vehicle.longitude }} />
            </Map>
          </div>
          <p className="fm-inspection-new-card-content-map-subtitle">Last Updated: { moment(vehicle.gpsTime).fromNow() }</p>
        </div>
      );
    }

    if (criteria.feature === 'battery') {
      return (
        <div className="fm-inspection-new-card-content">
          <div className="fm-inspection-new-card-content-battery">
            <div className="fm-inspection-new-card-content-battery-inner" style={{ width: `${vehicle.batteryPercent * 100}%` }}></div>
          </div>
          <p className="fm-inspection-new-card-content-battery-percent">{ parseInt(vehicle.batteryPercent * 100) }%</p>
          <p className="fm-inspection-new-card-content-battery-range">{ parseInt(vehicle.remainingRange / 100) } km</p>
        </div>
      );
    }

    if (criteria.feature === 'qrCode') {
      return (
        <div className="fm-inspection-new-card-content">
          <p className="fm-inspection-new-card-content-qr">{ vehicle.qrCode }</p>
          <p className="fm-inspection-new-card-content-rego">{ vehicle.registration }</p>
        </div>
      );
    }

    if (criteria.feature === 'toot') {
      return (
        <div className="fm-inspection-new-card-content">
          <button className="fm-inspection-new-card-content-button" onClick={this.handleToot}>{ loading ? 'Tooting...' : 'Tap to Toot' }</button>
        </div>
      );
    }

    if (criteria.feature === 'lights') {
      return (
        <div className="fm-inspection-new-card-content">
          <button className="fm-inspection-new-card-content-button" onClick={this.handleLight}>{ loading ? 'Toggling...' : 'Tap to Toggle Lights' }</button>
        </div>
      );
    }

    return (
      <div className="fm-inspection-new-card-content">
        <div className="fm-inspection-new-card-content-photo">
          <Icon icon="tools" />
        </div>
      </div>
    );
  }

  renderCriteria(criteria, i) {
    const { currentIndex, results } = this.state;
    const toFront = i - currentIndex;
    let className = `fm-inspection-new-card fm-inspection-new-card-pending fm-inspection-new-card-pending-${toFront}`;
    if (currentIndex > i) {
      if (results[criteria.id] === 'PASS' && criteria.object === 'inspectionItem') {
        className = 'fm-inspection-new-card fm-inspection-new-card-complete fm-inspection-new-card-complete-passed';
      } else if (results[criteria.id] === 'FAIL' && criteria.object === 'inspectionItem') {
        className = 'fm-inspection-new-card fm-inspection-new-card-complete fm-inspection-new-card-complete-failed';
      } else {
        className = 'fm-inspection-new-card fm-inspection-new-card-complete';
      }
    }

    if (criteria.object === 'inspectionItem') {
      return (
        <div className={className} key={i}>
          { this.renderContent(criteria) }
          <div className="fm-inspection-new-card-details">
            <p className="fm-inspection-new-card-name">{ criteria.name }</p>
            <p className="fm-inspection-new-card-description">{ criteria.description }</p>
            { criteria.directive && <p className="fm-inspection-new-card-directive">{ criteria.directive }</p> }
          </div>
        </div>
      );
    }

    return (
      <div className={className} key={i}>
        <div className="fm-inspection-new-card-group-title">
          <p className="fm-inspection-new-card-group-name">{ criteria.name }</p>
        </div>
        <div className="fm-inspection-new-card-details">
          <p className="fm-inspection-new-card-description">{ criteria.description }</p>
        </div>
      </div>
    );
  }

  renderPrefill(item, i) {
    return <span className="fm-inspection-new-review-notes-prefill-item" key={i} onClick={() => this.appendNote(item.value)}>{ item.value }</span>;
  }

  renderTicket(ticket, i) {
    return (
      <div className="fm-inspection-new-review-tickets-item" key={i}>
        <div className="fm-inspection-new-review-tickets-item-header">
          <p className="fm-inspection-new-review-tickets-item-type" style={{ backgroundColor: colors.vehicleTicket[ticket.type] }}>{ ticket.type.toUpperCase() }</p>
          <p className="fm-inspection-new-review-tickets-item-created">{ moment(ticket.createdAt).fromNow() }</p>
        </div>
        <div className="fm-inspection-new-review-tickets-item-content">
          <p className="fm-inspection-new-review-tickets-item-details">{ ticket.description }</p>
          <button className="fm-inspection-new-review-tickets-item-resolve" onClick={() => this.handleResolve(ticket)}>
            <Icon icon="check-square" />
          </button>
        </div>
      </div>
    );
  }

  renderUpload(upload, i) {
    if (!upload) {
      return null;
    }
    return (
      <div className="fm-inspection-new-review-upload" key={i}>
        <div className="fm-inspection-new-review-upload-progress" style={{ width: `${ upload }%` }}></div>
        <p className="fm-inspection-new-review-upload-label">Photo Upload - { parseInt(upload) }%</p>
      </div>
    );
  }

  render() {
    const { vehicle, criteria, currentIndex, results, uploads, prefill, tickets, notes, loading, error } = this.state;
    const currentCriteria = criteria[currentIndex];
    const passed = !Object.values(results).includes('FAIL');
    const title = vehicle.registration ? `${vehicle.registration} Inspection` : 'New Inspection';

    return (
      <div className="fm-inspection-new">
        <NavigationBar title={title} subtitle={vehicle.model} loading={loading} showBack={true} />
        <div className="fm-inspection-new-content">
          {
            (!currentCriteria && currentIndex > 0) ? (
              <div className="fm-inspection-new-review">
                <div className="fm-inspection-new-review-status">
                  <p className="fm-inspection-new-review-status-rego">{ vehicle.registration }</p>
                  <p className={`fm-inspection-new-review-status-result fm-inspection-new-review-status-result-${passed ? 'passed' : 'failed'}`}>{ passed ? 'PASSED' : 'FAILED' }</p>
                </div>
                {
                  tickets.length > 0 &&
                  <div className="fm-inspection-new-review-tickets">
                    <p className="fm-inspection-new-review-tickets-title">Tickets</p>
                    <div className="fm-inspection-new-review-tickets-items">
                      { tickets.map(this.renderTicket) }
                    </div>
                    <p className="fm-inspection-new-review-tickets-note">Maintenance tickets will be automatically resolved on completion, note tickets must be manually resolved if applicable.</p>
                  </div>
                }
                <div className="fm-inspection-new-review-notes">
                  <p className="fm-inspection-new-review-notes-title">Notes</p>
                  <p className="fm-inspection-new-review-notes-description">What was wrong with the vehicle? What did you repair on the vehicle?</p>
                  <textarea className="fm-inspection-new-review-notes-textarea" value={notes} onChange={this.handleNotes} disabled={loading} placeholder={'Select from the options below. Select "Passed As Is" if nothing was repaired.'} rows={5}></textarea>
                  <div className="fm-inspection-new-review-notes-prefill">
                    { prefill.map(this.renderPrefill) }
                  </div>
                </div>
                <div className="fm-inspection-new-review-uploads">
                  { Object.values(uploads).map(this.renderUpload) }
                </div>
                <div className="fm-inspection-new-review-actions">
                  <button className="fm-inspection-new-next" onClick={this.handleSubmit} disabled={loading}>{ loading ? 'SUBMITTING...' : 'SUBMIT' }</button>
                  <button className="fm-inspection-new-back" onClick={this.handleBack} disabled={loading}>BACK</button>
                </div>
              </div>
            ) : (
              <div className="fm-inspection-new-cards">
                { criteria.map(this.renderCriteria) }
              </div>
            )
          }
          {
            currentCriteria &&
            <div className="fm-inspection-new-actions">
              {
                currentCriteria.type === 'GRADE' ? (
                  <div className="fm-inspection-new-actions-grade">
                    <button className="fm-inspection-new-action fm-inspection-new-action-fail" onClick={this.handleFail}>FAIL</button>
                    <button className="fm-inspection-new-action fm-inspection-new-action-pass" onClick={this.handlePass}>PASS</button>
                  </div>
                ) : (
                  <button className="fm-inspection-new-next" onClick={this.handleNext}>NEXT</button>
                )
              }
              <button className="fm-inspection-new-back" onClick={this.handleBack}>BACK</button>
            </div>
          }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default InspectionNew;
