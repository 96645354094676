import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Input from 'components/common/input';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import colors from 'helpers/colors';
import api from 'helpers/api';

const periodOptions = [{ label: 'On day', value: 'day' }, { label: 'For week ending', value: 'week' }, { label: 'For fortnight ending', value: 'fortnight' }];
const subTypeDescription = {
  removed: 'A vehicle was collected.',
  available: 'A vehicle was made available.',
  inspection: 'An inspection was completed.',
  batteryOpen: 'A battery was swapped.',
};
const typeDescription = {
  clockIn: 'The user clocked in.',
  clockOut: 'The user clocked out.',
};

class PerformanceOutOfHours extends Component {
  constructor() {
    super();
    const maxDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.state = { users: [], date: maxDate, period: 'day', maxDate, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.renderActivity = this.renderActivity.bind(this);
  }

  componentDidMount() {
    document.title = 'Staff Timeliness | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const { date, period } = this.state;
    const data = {
      date: date,
      period: period === 'day' ? 'day' : 'week',
      units: period === 'fortnight' ? 2 : 1,
    };
    api.post('/performance/timeliness', data)
      .then(this.handleData)
      .catch(this.handleError);
  }

  handleData(res) {
    const allUsers = res.data.data.users;
    const activity = res.data.data.userActivity;
    const users = [];

    for (var i = 0; i < allUsers.length; i++) {
      const user = allUsers[i];
      if (activity[user.id] && activity[user.id].length > 0) {
        user.activity = activity[user.id];
        users.push(user);
      }
    }

    this.setState({ users, error: false, loading: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  handlePeriodChange(period) {
    this.setState({ period });
  }

  renderUser(user, i) {
    return (
      <div className="fm-performance-timeliness-row" key={i}>
        <p className="fm-performance-timeliness-row-name">{ user.firstName } { user.lastName }</p>
        <div className="fm-performance-timeliness-events">
          { user.activity.map(this.renderActivity) }
        </div>
      </div>
    );
  }

  renderActivity(activity, i) {
    let compareLabel = null;
    if (activity.compareTime) {
      const difference = activity.type === 'firstActivity' ? moment(activity.eventTime).diff(activity.compareTime, 'minutes') : moment(activity.compareTime).diff(activity.eventTime, 'minutes');
      if (difference > 20) {
        compareLabel = `${ difference } mins`;
      }
    }
    return (
      <Link className="fm-performance-timeliness-event-row" key={i} to={ activity.link || '#' }>
        <p className="fm-performance-timeliness-event-row-type" style={{ backgroundColor: colors.performanceTimeliness[activity.type] }}>{ activity.type }</p>
        <p className="fm-performance-timeliness-event-row-description">{ activity.subType ? subTypeDescription[activity.subType] : typeDescription[activity.type] }</p>
        <p className="fm-performance-timeliness-event-row-time" style={{ borderRightColor: colors.performanceTimeliness[activity.type] }}>{ compareLabel && <span className="fm-performance-timeliness-event-row-compare">{ compareLabel }</span> }{ moment(activity.eventTime).format('h:mma D/M/YY') }</p>
      </Link>
    );
  }

  render() {
    const { users, error, loading } = this.state;

    return (
      <div className="fm-performance-timeliness">
        <NavigationBar title="Staff Timeliness" loading={loading} />
        <div className="fm-performance-timeliness-options">
          <Input type="select" value={this.state.period} onChange={this.handlePeriodChange} options={periodOptions} />
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} max={this.state.maxDate} />
          <button className="fm-performance-timeliness-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-performance-timeliness-list">
          { users.map(this.renderUser) }
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default PerformanceOutOfHours;
