import React, { Component } from 'react';
import moment from 'moment';
import Map, { Polyline, Polygon } from 'components/common/map';
import Loading from 'components/common/loading';
import Toast from 'components/common/toast';
import PopUp from 'components/common/pop-up';
import api from 'helpers/api';

class ParkingOffence extends Component {
  constructor() {
    super();
    this.state = { offence: {}, loading: true, empty: false };
    this.loadOffence = this.loadOffence.bind(this);

    this.handleAPI = this.handleAPI.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleFine = this.handleFine.bind(this);
    this.handleWarning = this.handleWarning.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    document.title = 'Parking Offence | Flamingo Admin';
    this.loadOffence();
  }

  loadOffence() {
    return api.get('/parking/offence/next')
      .then((res) => this.setState({ offence: res.data.data, loading: false, updating: false }))
      .catch(this.handleError);
  }

  /* API Helpers */

  handleAPI(endpoint, data) {
    this.setState({ updating: true });
    return api.post(endpoint, data)
      .then(this.loadOffence)
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    const empty = window.access(() => err.response.status) && err.response.status === 404;
    this.setState({ error, empty, updating: false });
  }

  /* Controls */

  handleFine() {
    const promptResult = window.confirm('Are you sure you want to issue this user a $15 fine?');
    if (promptResult === true) {
      const offenceId = this.state.offence.id;
      return this.handleAPI('/parking/offence/' + offenceId + '/fine');
    }
  }

  handleWarning() {
    const offenceId = this.state.offence.id;
    return this.handleAPI('/parking/offence/' + offenceId + '/warning');
  }

  handleDismiss() {
    const offenceId = this.state.offence.id;
    return this.handleAPI('/parking/offence/' + offenceId + '/dismiss');
  }

  /* Renders */

  render() {
    if (this.state.empty) {
      return (
        <div className="fm-page fm-parking-offence">
          <p className="fm-page-title">Parking Offences</p>
          <p className="fm-parking-offence-empty">No parking offences to review.</p>
        </div>
      );
    }
    if (this.state.loading) {
      return <Loading />;
    }
    const { offence } = this.state;
    const mapOptions = { center: { lat: offence.ride.endLatitude, lng: offence.ride.endLongitude }, zoom: 17 };
    const warnings = offence.pastParkingOffences.WARNING || 0;
    return (
      <div className="fm-page fm-parking-offence">
        <p className="fm-page-title">Parking Offence #{ offence.id }</p>
        <div className="fm-parking-offence-content">
          <img className="fm-parking-offence-photo" src={ offence.ride.parkingPhotoUrl } alt="Parking" />
          <Map options={mapOptions} google={window.google}>
            <Polygon paths={offence.zone.polygon.map((point) => ({ lat: point.latitude, lng: point.longitude }))} strokeColor={'#F1C40F'} strokeWeight={2} fillColor={'#F1C40F'} fillOpacity={0.2} />
            <Polyline encodedPath={offence.ride.encodedPolyline} strokeColor={'#ff206e'} strokeWeight={'4'} />
          </Map>
        </div>
        <div className="fm-parking-offence-details">
          <p className="fm-parking-offence-detail-title">{ offence.zone.name }</p>
          <p className="fm-parking-offence-detail-label">{ offence.zone.message } Parked at { moment(offence.occurredAt).format('h:mma dddd Do of MMMM YYYY') }. Ride duration of { (offence.ride.duration / 60).toFixed() } mins.</p>
        </div>
        <div className="fm-parking-offence-action">
          <div className="fm-parking-offence-history">
            {
              warnings > 0 ? (
                <p className="fm-parking-offence-history-label" style={{ backgroundColor: '#e74c3c' }}>Warnings: { warnings } - ESCALATION REQUIRED</p>
              ) : (
                <p className="fm-parking-offence-history-label">Warnings: { warnings }</p>
              )
            }
          </div>
          <div className="fm-parking-offence-controls">
            <button className="fm-parking-offence-control fm-parking-offence-control-view" onClick={() => window.open(`/trip/${offence.ride.id}`)}>View Trip</button>
            <button className="fm-parking-offence-control fm-parking-offence-control-fine" onClick={this.handleFine}>Issue $15 Fine</button>
            <button className="fm-parking-offence-control fm-parking-offence-control-warning" onClick={this.handleWarning}>Issue Warning</button>
            <button className="fm-parking-offence-control fm-parking-offence-control-dismiss" onClick={this.handleDismiss}>Dismiss</button>
          </div>
        </div>
        { /* ------ Loading Pop Up ------ */ }
        <PopUp active={this.state.updating} title="Updating..." description="Please wait while the offence is updated." onDismiss={() => this.setState({ updating: false })}>
          <Loading />
        </PopUp>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default ParkingOffence;
