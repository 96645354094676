import React from 'react';
import DeleteMenu from './delete-menu';

class Polygon extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.renderPolygon();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.map !== prevProps.map) || (this.props.paths !== prevProps.paths)) {
      if (this.polygon) {
        this.polygon.setMap(null);
      }
      this.renderPolygon();
    }
  }

  componentWillUnmount() {
    if (this.polygon) {
      this.polygon.setMap(null);
    }
  }

  renderPolygon() {
    const { map, google, paths, strokeColor, strokeWeight, fillColor, fillOpacity, editable, clickable } = this.props;
    this.polygon = new google.maps.Polygon({ map, paths, strokeColor, strokeWeight, fillColor, fillOpacity, editable, clickable });
    this.polygon.addListener('click', this.handleClick);

    if (editable) {
      const deleteMenu = new DeleteMenu();
      google.maps.event.addListener(this.polygon, 'contextmenu', (e) => {
        if (e.vertex === undefined) {
          return;
        }
        deleteMenu.open(map, this.polygon.getPath(), e.vertex);
      });
      const handleChange = (i, o) => {
        if (this.props.onChange) {
          const vertices = this.polygon.getPath();
          const coordinates = [];
          for (let i = 0; i < vertices.getLength(); i++) {
            const v = vertices.getAt(i);
            coordinates.push({ latitude: v.lat(), longitude: v.lng() });
          }
          this.props.onChange(coordinates);
        }
      };
      google.maps.event.addListener(this.polygon.getPath(), 'set_at', handleChange);
      google.maps.event.addListener(this.polygon.getPath(), 'insert_at', handleChange);
      google.maps.event.addListener(this.polygon.getPath(), 'remove_at', handleChange);
    }
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return null;
  }
}

export default Polygon;
