import Promise from 'bluebird';
import moment from 'moment';

let locationCache = null;

const locationOptions = {
  maximumAge: 120000,
  enableHighAccuracy: true,
};

const getCurrentPosition = () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, (error) => reject(new Error(error)), locationOptions);
    });
  }
  return Promise.reject(new Error('Location services unavailable.'));
};

const refreshLocation = () => {
  return getCurrentPosition()
    .then((loc) => {
      const formattedLocation = updateCache(loc);
      return Promise.resolve(formattedLocation);
    })
    .catch(() => {});
};

const getLocation = (forceRefresh = false) => {
  if (locationCache && !forceRefresh) {
    return Promise.resolve(locationCache);
  }

  return getCurrentPosition()
    .then((loc) => {
      const formattedLocation = updateCache(loc);
      return Promise.resolve(formattedLocation);
    })
    .catch(() => {});
};

const updateCache = (loc) => {
  locationCache = {
    latitude: loc.coords.latitude,
    longitude: loc.coords.longitude,
    accuracy: loc.coords.accuracy,
    timestamp: moment(loc.timestamp).format(),
  };
  return locationCache;
};

const getLastLocation = (forceRefresh = false) => {
  return locationCache || {};
};

const location = { getLocation, getLastLocation, refreshLocation, updateCache };

export default location;
