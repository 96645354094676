import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loading from 'components/common/loading';
import Table from 'components/common/table';
import api from 'helpers/api';

class Credentials extends Component {
  constructor() {
    super();
    this.state = { loading: false, credentials: [] };

    this.loadCredentials = this.loadCredentials.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    document.title = 'Data Sharing | Flamingo Admin';
    this.loadCredentials();
  }

  loadCredentials() {
    return api.get('/mds/credentials')
      .then((res) => this.setState({ credentials: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderTable() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <Table>
        <tbody>
          { this.state.credentials.map(this.renderRow) }
        </tbody>
      </Table>
    );
  }

  renderRow(row, i) {
    return (
      <tr key={i}>
        <td>{ row.clientId }</td>
        <td className="fm-staff-row-light">{ row.user ? (row.user.firstName + ' ' + row.user.lastName + ' (' + row.region.name + ')') : row.nickname }</td>
        <td className="fm-staff-row-light fm-staff-row-no-mobile">{ moment(row.updatedAt).format('D/M/YY') }</td>
      </tr>
    );
  }

  render() {
    return (
      <div className="fm-page fm-staff">
        <p className="fm-page-title">MDS Credentials</p>
        <div className="fm-page-actions">
          <Link className="fm-page-action" to="/data-sharing/add-credential">Add Credential</Link>
        </div>
        { this.renderTable() }
      </div>
    );
  }
}

export default Credentials;
