import history from './history';

function handleRead(event) {
  for (const record of event.message.records) {
    if (record.recordType === 'text') {
      const textDecoder = new TextDecoder(record.encoding);
      const data = textDecoder.decode(record.data);
      const parts = data.split('-');
      if (data.includes('FLAMINGO') && parts.length === 3) {
        history.push({ pathname: `/vehicle/${parts[1]}` });
      }
    }
  }
}

function startScanner() {
  if ('NDEFReader' in window) {
    const ndef = new window.NDEFReader();
    ndef.scan().then(() => {
      ndef.onreadingerror = (e) => console.log(e);
      ndef.onreading = handleRead;
    }).catch(console.log);
  }
}

const nfc = { startScanner };

export default nfc;
