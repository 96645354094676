import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import colors from 'helpers/colors';
import api from 'helpers/api';

class FirmwareUpgrade extends Component {
  constructor() {
    super();
    this.state = { firmware: [], loading: true, error: false };
    this.loadFirmware = this.loadFirmware.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderFirmware = this.renderFirmware.bind(this);
  }

  componentDidMount() {
    document.title = 'Upgrade Firmware | Flamingo Admin';
    this.loadFirmware();
  }

  loadFirmware() {
    return api.get(`/vehicle/${this.props.match.params.vehicleId}/available-firmware`)
      .then((res) => this.setState({ firmware: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleInstall(firmwareId) {
    if (this.state.loading) {
      return;
    }
    if (window.confirm('Are you sure you want to update the firmware on this vehicle? The vehicle will lock before updating.') === true) {
      this.setState({ loading: true });
      return api.post(`/vehicle/${this.props.match.params.vehicleId}/firmware`, { firmwareId })
        .then(() => alert('Update sent successfully. Please wait approximately 5 minutes.'))
        .then(() => history.goBack())
        .catch(this.handleError);
    }
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  renderFirmware(firmware, i) {
    return (
      <div className="fm-firmware-upgrade-row" key={i}>
        <div className="fm-firmware-upgrade-details">
          <div className="fm-firmware-upgrade-top">
            <p className="fm-firmware-upgrade-type" style={{ backgroundColor: colors.firmware[firmware.type] }}>{ firmware.type }</p>
            <p className="fm-firmware-upgrade-name">{ firmware.name }</p>
          </div>
          <p className="fm-firmware-upgrade-description">{ firmware.description }</p>
        </div>
        { !this.state.loading && <button className="fm-firmware-upgrade-button" onClick={() => this.handleInstall(firmware.id)}>Install<Icon icon="download" /></button> }
      </div>
    );
  }

  render() {
    const { loading, firmware, error } = this.state;

    return (
      <div className="fm-firmware-upgrade">
        <NavigationBar title="Upgrade Firmware" loading={loading} showBack={true} />
        <p className="fm-firmware-upgrade-warning"><b>Scooter Order:</b> IoT, Battery, Dashboard then Controller.</p>
        <p className="fm-firmware-upgrade-warning"><b>Bike Order:</b> IoT, Battery, Dashboard, Controller then Repeater.</p>
        <div className="fm-firmware-upgrade-list">
          { firmware.map(this.renderFirmware) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default FirmwareUpgrade;
