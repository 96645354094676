import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import Icon from 'components/common/icon';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker } from 'components/common/map';
import Input from 'components/common/input';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  'AVAILABLE': { url: baseUrl + 'vehicle-available.png', size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) },
  'UNAVAILABLE': { url: baseUrl + 'vehicle-unavailable.png', size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) },
  'RESERVED': { url: baseUrl + 'vehicle-charging.png', size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) },
};

const filterOptions = ['AVAILABLE', 'UNAVAILABLE', 'RESERVED'];

class NestMap extends Component {
  constructor(props) {
    super(props);
    this.state = { searchActive: false, filtersActive: false, query: '', results: [], mapNests: [], filters: { statuses: [] } };

    this.handleMapBoundsChange = throttle(500, this.handleMapBoundsChange.bind(this));
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = throttle(500, this.search);
    this.renderResult = this.renderResult.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  componentDidMount() {
    document.title = 'Nests | Flamingo Admin';
    this.loadFilters();
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw, zoom) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), zoom, this.state.filters);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, zoom, filters) {
    let endpoint = `/nest/area?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}&zoom=${zoom}`;

    if (filters.statuses && filters.statuses.length > 0) {
      endpoint += filters.statuses.map((status) => `&status[]=${status}`).join('');
    }

    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude, zoom };
    return api.get(endpoint)
      .then((res) => this.setState({ mapNests: res.data.data, currentBounds }))
      .catch(console.log);
  }

  /* ------ API ------ */

  handleApiError(error) {

  }

  /* ------ Filters ------ */

  loadFilters() {
    const filtersString = localStorage.getItem('nestFilters');
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      this.setState({ filters });
    }
  }

  toggleFilters() {
    const filtersActive = !this.state.filtersActive;
    this.setState({ filtersActive, searchActive: false });
  }

  toggleStatus(status) {
    const filters = this.state.filters;

    const statusIndex = filters.statuses.indexOf(status);
    if (statusIndex > -1) {
      filters.statuses.splice(statusIndex, 1);
    } else {
      filters.statuses.push(status);
    }

    this.setState({ filters }, () => this.filterChange(filters));
    localStorage.setItem('nestFilters', JSON.stringify(filters));
  }

  filterChange(filters) {
    const cb = this.state.currentBounds;
    if (cb) {
      this.loadMapForBounds(cb.neLatitude, cb.neLongitude, cb.swLatitude, cb.swLongitude, cb.zoom, filters);
    }
  }

  /* ------ Search ------ */

  handleSearchFocus() {
    this.setState({ searchActive: true, filtersActive: false, scannerActive: false }, () => setTimeout(() => window.scrollTo(0, 0), 400));
  }

  handleSearchBlur() {
    this.setState({ searchActive: this.state.query.length > 0 });
  }

  handleSearchChange(query) {
    this.setState({ searching: true, searchActive: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ searching: false, results: [] });
      return;
    }
    api.post('/nest/search', { query, limit: 10 })
      .then((res) => this.setState({ results: res.data.data, searching: false }))
      .catch(this.handleApiError);
  }

  /* ------ Renders ------ */

  renderResult(row, i) {
    return (
      <Link to={ '/nest/' + row.id } key={i}>
        <div className="fm-nest-map-result">
          <p className="fm-nest-map-result-address">{ row.address }</p>
          <p className="fm-nest-map-result-status" style={{ backgroundColor: colors.status[row.status] }}>{ row.status }</p>
        </div>
      </Link>
    );
  }

  renderOption(status, i) {
    const style = { color: '#222222', background: '#F8F7F9' };

    if (this.state.filters.statuses.includes(status)) {
      style.background = colors.status[status];
      style.color = '#FFF';
    }

    return (
      <span
        className="fm-nest-map-filters-status"
        key={i}
        style={style}
        onClick={() => this.toggleStatus(status)}
      >
        { status }
      </span>
    );
  }

  render() {
    const { searchActive, filtersActive, searching, mapNests, results, query } = this.state;
    const searchClass = 'fm-nest-map-search fm-nest-map-search-' + (searchActive ? 'active' : 'inactive');
    const sourceClass = 'fm-nest-map-source fm-nest-map-source-' + (searchActive ? 'search' : 'map');

    const targetAction = { to: `/hub`, icon: 'bullseye' };
    const hotAction = { to: `/nest/top`, icon: 'fire' };
    const addAction = { to: `/nest/add`, icon: 'plus-circle' };

    return (
      <div className="fm-nest-map">
        <NavigationBar title="Nests" loading={searching} rightActions={[targetAction, hotAction, addAction]} />
        <div className={sourceClass}>
          <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { mapNests.map((nest, i) => <Marker key={i} position={{ lat: nest.latitude, lng: nest.longitude }} title={`${nest.address} - ${nest.currentOccupancy}/${nest.maxCapacity}`} icon={icons[nest.status]} onClick={() => history.push('/nest/' + nest.id)} />) }
          </Map>
        </div>
        <div className="fm-nest-map-bar">
          <Input
            placeholder="Search"
            value={this.state.query}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            onBlur={this.handleSearchBlur} />
          <div className="fm-nest-map-bar-filter" onClick={this.toggleFilters}>
            <Icon icon={ filtersActive ? 'chevron-down' : (searchActive ? 'times' : 'filter') } />
          </div>
        </div>
        {
          filtersActive && (
            <div className="fm-nest-map-filters">
              <div className="fm-nest-map-filters-statuses">
                { filterOptions.map(this.renderOption) }
              </div>
            </div>
          )
        }
        <div className={searchClass}>
          { (!searching && results.length === 0 && query.length > 0) && <p className="fm-nest-map-results-empty">No nests found</p> }
          { results.map(this.renderResult) }
        </div>
      </div>
    );
  }
}

export default NestMap;
