import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker } from 'components/common/map';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import geocoder from 'helpers/geocoder';
import api from 'helpers/api';

const types = [{ value: 'incident', label: 'Incident' }, { value: 'nearMiss', label: 'Near Miss' }, { value: 'vandalism', label: 'Vandalism' }, { value: 'theft', label: 'Theft' }];
const severities = [{ value: 'minor', label: 'Minor' }, { value: 'moderate', label: 'Moderate' }, { value: 'significant', label: 'Significant' }, { value: 'major', label: 'Major' }, { value: 'severe', label: 'Severe' }];

class IncidentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { incident: {}, incidentUpdate: {}, now: moment().format('YYYY-MM-DDTHH:mm'), loading: true, error: false };

    this.loadIncident = this.loadIncident.bind(this);
    this.parseIncident = this.parseIncident.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    document.title = 'Edit Incident | Flamingo Admin';
    this.loadIncident();
  }

  // Networking

  loadIncident() {
    this.setState({ error: false, loading: true });
    return api.get('/incident/' + this.props.match.params.id)
      .then((res) => this.parseIncident(res.data.data))
      .catch(this.handleError);
  }

  parseIncident(incident) {
    incident.startTime = moment(incident.startTime).format('YYYY-MM-DDTHH:mm');
    incident.endTime = moment(incident.endTime).format('YYYY-MM-DDTHH:mm');
    incident.regionId = incident.region ? incident.region.id : -1;
    const initialLocation = (incident.latitude && incident.longitude) ? { lat: incident.latitude, lng: incident.longitude } : undefined;
    return this.setState({ incident, initialLocation, loading: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Handlers

  onChange(e) {
    const { incident, incidentUpdate } = this.state;
    const key = e.target.getAttribute('okey');
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    incident[key] = value;
    incidentUpdate[key] = value;
    this.setState({ incident, incidentUpdate, error: false });
  }

  handleMapClick(latitude, longitude) {
    const { incident, incidentUpdate } = this.state;
    incident.latitude = latitude;
    incident.longitude = longitude;
    incidentUpdate.latitude = latitude;
    incidentUpdate.longitude = longitude;
    this.setState({ incident, incidentUpdate, error: false }, () => this.setLocation(latitude, longitude));
  }

  setLocation(latitude, longitude) {
    return geocoder(latitude, longitude)
      .then((location) => this.setState({ incident: { ...this.state.incident, location }, incidentUpdate: { ...this.state.incidentUpdate, location } }))
      .catch(this.handleError);
  }

  handleSave() {
    const { incident, incidentUpdate } = this.state;
    if (incidentUpdate.startTime) {
      incidentUpdate.startTime = moment(incidentUpdate.startTime).utc().format();
    }
    if (incidentUpdate.endTime) {
      incidentUpdate.endTime = moment(incidentUpdate.endTime).utc().format();
    }
    if (Object.keys(incidentUpdate).length === 0) {
      return history.push({ pathname: `/incident/${incident.id}`, state: { incident } });
    }
    this.setState({ error: false, loading: true });
    return api.post(`/incident/${incident.id}`, incidentUpdate)
      .then((res) => history.push({ pathname: `/incident/${incident.id}`, state: { incident: res.data.data } }))
      .catch(this.handleError);
  }

  // Renders

  render() {
    const { loading, incident, initialLocation, now, error } = this.state;
    const subtitle = this.props.match.params.type ? `From ${this.props.match.params.type}` : undefined;

    const hasLocation = incident.latitude && incident.longitude;
    const mapOptions = { center: initialLocation, zoom: 15 };

    return (
      <div className="fm-incident-new">
        <NavigationBar title="Edit Incident" subtitle={subtitle} loading={loading} showBack={true} />
        <div className="fm-incident-edit-content">
          <div className="fm-incident-edit-input-row">
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Type</p>
              <select value={incident.type} disabled={loading} onChange={this.onChange} okey="type" className="fm-input fm-input-select">
                <option disabled value="-1">Select an option</option>
                { types.map((item, i) => <option key={i} value={item.value}>{item.label}</option>) }
              </select>
            </div>
            {
              ['incident','nearMiss'].includes(incident.type) &&
              <div className="fm-incident-edit-input">
                <p className="fm-input-label">Severity</p>
                <select value={incident.severity} disabled={loading} onChange={this.onChange} okey="severity" defaultValue="-1" className="fm-input fm-input-select">
                  <option disabled value="-1">Select an option</option>
                  { severities.map((item, i) => <option key={i} value={item.value}>{item.label}</option>) }
                </select>
              </div>
            }
          </div>
          <div className="fm-incident-edit-input-row">
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Vehicle Registration</p>
              <input value={incident.vehicleRegistration || ''} onChange={this.onChange} okey="vehicleRegistration" className="fm-input" disabled={loading || incident.vehicleId} />
            </div>
          </div>
          <div className="fm-incident-edit-input">
            <p className="fm-input-label">Name</p>
            <input value={incident.name || ''} onChange={this.onChange} okey="name" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-incident-edit-input-row">
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Email</p>
              <input value={incident.email || ''} onChange={this.onChange} okey="email" className="fm-input" disabled={loading} />
            </div>
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Phone</p>
              <input value={incident.phone || ''} onChange={this.onChange} okey="phone" className="fm-input" disabled={loading} />
            </div>
          </div>
          <div className="fm-incident-edit-input-row">
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Start Time</p>
              <input type="datetime-local" value={incident.startTime || now} onChange={this.onChange} okey="startTime" className="fm-input" disabled={loading} />
            </div>
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">End Time</p>
              <input type="datetime-local" value={incident.endTime || now} onChange={this.onChange} okey="endTime" className="fm-input" disabled={loading} />
            </div>
          </div>
          <div className="fm-incident-edit-input">
            <p className="fm-input-label">Location</p>
            <input value={incident.location || ''} onChange={this.onChange} okey="location" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-incident-edit-map">
            <Map google={window.google} onClick={this.handleMapClick} options={mapOptions}>
              { hasLocation && <Marker position={{ lat: incident.latitude, lng: incident.longitude }} title="Hub Location" /> }
            </Map>
          </div>
          {
            ['incident','nearMiss'].includes(incident.type) &&
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Weather</p>
              <input value={incident.weather || ''} onChange={this.onChange} okey="weather" className="fm-input" disabled={loading} />
            </div>
          }
          <div className="fm-incident-edit-input">
            <p className="fm-input-label">{ ['incident','nearMiss'].includes(incident.type) ? 'Root Cause' : 'Outcome' }</p>
            <textarea className="fm-input fm-input-textarea fm-incident-edit-textarea" value={incident.outcome || ''} onChange={this.onChange} okey="outcome" disabled={loading}></textarea>
          </div>
          <div className="fm-incident-edit-input-row">
            <div className="fm-incident-edit-input">
              <label className="fm-input-label">Visible on Insights</label>
              <label className="fm-input-checkbox-container">
                <input className="fm-input fm-input-checkbox" disabled={loading} type="checkbox" checked={incident.insightsVisible || false} okey="insightsVisible" onChange={this.onChange} />
                <span className="fm-input-checkbox-checkmark"></span>
              </label>
            </div>
            <div className="fm-incident-edit-input">
              <label className="fm-input-label">Flagged for Maintenance</label>
              <label className="fm-input-checkbox-container">
                <input className="fm-input fm-input-checkbox" disabled={loading} type="checkbox" checked={incident.maintenanceFlagged || false} okey="maintenanceFlagged" onChange={this.onChange} />
                <span className="fm-input-checkbox-checkmark"></span>
              </label>
            </div>
          </div>
          <div className="fm-incident-edit-input-row">
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Validity</p>
              <select value={incident.validity || 'confirmed'} disabled={loading} onChange={this.onChange} okey="validity" className="fm-input fm-input-select">
                <option value="confirmed">Confirmed</option>
                <option value="falseAlarm">False Alarm</option>
              </select>
            </div>
            <div className="fm-incident-edit-input">
              <p className="fm-input-label">Status</p>
              <select value={incident.status || 'open'} disabled={loading} onChange={this.onChange} okey="status" className="fm-input fm-input-select">
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>
            </div>
          </div>
          <button className="fm-incident-edit-button" onClick={this.handleSave} disabled={loading}>{ loading ? 'Loading...' : 'Save Incident' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentEdit;
