import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class ReportTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { types: [], loading: true };

    this.loadTypes = this.loadTypes.bind(this);
    this.parseTypes = this.parseTypes.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Trip Templates | Flamingo Admin';
    this.loadTypes();
  }

  loadTypes() {
    return api.get(`/report/templates`)
      .then((res) => this.parseTypes(res.data.data))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseTypes(data) {
    const types = data.map((type) => {
      type.emailTemplate = type.emailTemplate.replace(/<br\/>/g, '\n\r').replace(/<\/p>/g, '</p>\n\r').replace(/<[^>]+>/g, '').replace(/\n\s*\n/g, '\n\n');
      return type;
    })
    this.setState({ types, loading: false });
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderType(type, i) {
    return (
      <div className="fm-report-template-card" key={i}>
        <div className="fm-report-template-card-icon">
          <Icon icon={type.icon} />
        </div>
        <div className="fm-report-template-card-text">
          <p className="fm-report-template-card-title">{ type.title }</p>
          <p className="fm-report-template-card-description">{ type.description }</p>
          <p className="fm-report-template-card-email">{ type.emailTemplate }</p>
        </div>
      </div>
    );
  }

  render() {
    const { loading, types } = this.state;
    return (
      <div className="fm-report-template">
        <NavigationBar title="Trip Report Templates" loading={loading} showBack={true} />
        <div className="fm-report-template-content">
          { types.map(this.renderType) }
        </div>
      </div>
    );
  }
}

export default ReportTemplate;
