import React from 'react';
import { Link } from 'react-router-dom';
import api from 'helpers/api';
import user from 'helpers/user';
import location from 'helpers/location';
import distance from 'helpers/distance';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      task: false,
      showBanner: false,
      showAssignee: true,
      taskDistance: false,
    };

    this.checkTasks = this.checkTasks.bind(this);
    this.retryTasks = this.retryTasks.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.parseUser = this.parseUser.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleTasks = this.handleTasks.bind(this);
  }

  componentDidMount() {
    this.loadUser();
    this.checkTasks();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  loadUser() {
    user.getCurrentUser().then(this.parseUser);
  }

  parseUser(user) {
    if (user.admin && user.admin.showBanner) {
      this.setState({ user, showBanner: true });
    }
  }

  checkTasks() {
    return api.get('/task/priority')
      .then(this.handleTasks)
      .catch(this.retryTasks);
  }

  retryTasks() {
    this.timer = setTimeout(this.checkTasks, 60000);
  }

  handleTasks(res) {
    const { showAssignee, taskDistance } = this.state;
    const task = res.data.data[0];
    const refreshRate = task ? 10000 : 60000;
    const shouldShowAssignee = (taskDistance && !task.assignedTo && task.priority === 3) ? !showAssignee : true;
    this.setState({ task, showAssignee: shouldShowAssignee });
    this.timer = setTimeout(this.checkTasks, refreshRate);
    if (task && !task.assignedTo && task.priority === 3) {
      this.getLocation();
    }
  }

  getLocation() {
    return location.getLocation()
      .then(this.handleLocation)
      .catch(console.log);
  }

  handleLocation(location) {
    const { task } = this.state;
    if (task && location && location.latitude && location.longitude) {
      const taskDistance = distance(location.latitude, location.longitude, task.vehicle.latitude, task.vehicle.longitude).toFixed(1);
      this.setState({ taskDistance });
    }
  }

  render() {
    const { task, user, showAssignee, showBanner, taskDistance } = this.state;

    if (!task || !showBanner) {
      return null;
    }

    const titlePriority = task.priority === 3 ? 'HIGH' : 'MEDIUM';
    const titleAssigned = task.assignedTo ? 'ASSIGNED TO YOU' : (user ? `CAN YOU ATTEND ${user.firstName}?` : 'CAN YOU ATTEND?');
    const title = `${ titlePriority } PRIORITY - ${ showAssignee ? titleAssigned : `${taskDistance} KM AWAY` }`;

    const bannerClass = task.priority === 3 ? `fm-banner fm-banner-high` : `fm-banner fm-banner-medium`;
    return (
      <div className={bannerClass}>
        <Link className="fm-banner-link" to="/task/priority">
          <p className="fm-banner-title">{ title }</p>
          { task.notes && <p className="fm-banner-notes">{ task.notes }</p> }
        </Link>
      </div>
    );
  }
}

export default Banner;
