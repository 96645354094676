import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Input from 'components/common/input';
import NavigationBar from 'components/common/navigation-bar';
import colors from 'helpers/colors';
import api from 'helpers/api';

class PerformanceActivity extends Component {
  constructor() {
    super();
    const maxDate = moment().format('YYYY-MM-DD');
    this.state = { activity: [], date: maxDate, maxDate, loading: false };
    this.loadData = this.loadData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    document.title = 'User Activity | Flamingo Admin';
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const period = 'DAY';
    const userId = this.props.match.params.userId;
    const { date } = this.state;
    api.post('/performance/activity', { date, userId, period })
      .then((res) => this.setState({ activity: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  renderItem(item, i) {
    const details = (item.batteryPercent ? `${parseInt(item.batteryPercent * 100)}% - ` : '') + moment(item.eventTime).format('h:mma D/M/YY');
    return (
      <div className="fm-performance-user-row" key={i}>
        <Link className="fm-performance-user-row-registration" to={`/vehicle/${item.vehicle.id}`}>{ item.vehicle.registration }</Link>
        {
          item.itemId ? (
            <Link className="fm-performance-user-row-activity-type" style={{ backgroundColor: colors.performanceActivity[item.event] }} to={ `/${item.event}/${item.itemId}` }>{ item.event.toUpperCase() }</Link>
          ) : (
            <p className="fm-performance-user-row-activity-type" style={{ backgroundColor: colors.performanceActivity[item.event] }}>{ item.event.toUpperCase() }</p>
          )
        }
        {
          item.latitude ? (
            <a className="fm-performance-user-row-activity-date" target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${item.latitude},${item.longitude}`}>{ details }</a>
          ) : (
            <p className="fm-performance-user-row-activity-date">{ details }</p>
          )
        }
      </div>
    );
  }

  render() {
    const { activity, loading } = this.state;

    return (
      <div className="fm-performance-user">
        <NavigationBar title="User Activity" showBack={true} loading={loading} />
        <div className="fm-performance-user-options">
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} max={this.state.maxDate} />
          <button className="fm-performance-user-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-performance-user-list">
          { activity.map(this.renderItem) }
        </div>
      </div>
    );
  }
}

export default PerformanceActivity;
