import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const firmwareStatus = {
  success: '#2ecc71',
  upgrading: '#00a8ff',
  failed: '#e74c3c',
};

class VehicleSounds extends Component {
  constructor() {
    super();
    this.state = { loading: true, model: false, history: [] };

    this.loadHistory = this.loadHistory.bind(this);
    this.loadVehicle = this.loadVehicle.bind(this);
    this.updateSound = this.updateSound.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderHistory = this.renderHistory.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Sounds | Flamingo Admin';
    this.loadVehicle();
    this.loadHistory();
  }

  /* NETWORKING */

  loadVehicle() {
    this.setState({ loading: true });
    return api.get(`/vehicle/${this.props.match.params.vehicleId}`)
      .then((res) => this.setState({ model: res.data.data.model, loading: false }))
      .catch(this.handleError);
  }

  loadHistory() {
    return api.get(`/vehicle/${this.props.match.params.vehicleId}/sound-update`)
      .then((res) => this.setState({ history: res.data.data }))
      .catch(this.handleError);
  }

  updateSound(type) {
    this.setState({ loading: true });
    return api.post(`/vehicle/${this.props.match.params.vehicleId}/sound-update`, { type })
      .then(() => this.setState({ loading: false }, () => { this.loadHistory(); alert('Update sent! Please wait 2 minutes.'); }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderHistory(item, i) {
    return (
      <div className="fm-firmware-view-history-item" key={i}>
        <div className="fm-firmware-view-history-left">
          <p className="fm-firmware-view-history-name">{ item.name }</p>
          <p className="fm-firmware-view-history-time">{ moment(item.createdAt).fromNow() }</p>
        </div>
        <p className="fm-firmware-view-history-status" style={{ backgroundColor: firmwareStatus[item.status] }}>{ item.status.toUpperCase() }</p>
      </div>
    );
  }

  render() {
    const { loading, error, history, model } = this.state;
    const refreshAction = { onClick: this.loadHistory, icon: 'sync-alt' };

    return (
      <div className="fm-vehicle-sounds">
        <NavigationBar title="Vehicle Sounds" loading={loading} showBack={true} rightActions={[refreshAction]} />
        <div className="fm-vehicle-sounds-content">
          {
            model === 'SEGWAY_MAX_PLUS' &&
            <>
              <button className="fm-vehicle-sounds-button" disabled={loading} onClick={() => this.updateSound('lock')}>Update Lock Sound</button>
              <button className="fm-vehicle-sounds-button" disabled={loading} onClick={() => this.updateSound('unlock')}>Update Unlock Sound</button>
              <button className="fm-vehicle-sounds-button" disabled={loading} onClick={() => this.updateSound('toot')}>Update Toot Sound</button>
            </>
          }
          {
            model === 'SEGWAY_S90L' &&
            <>
              <button className="fm-vehicle-sounds-button" disabled={loading} onClick={() => this.updateSound('all')}>Update All Sounds</button>
            </>
          }
          <div className="fm-vehicle-sounds-history">
            { history.map(this.renderHistory) }
            { (!loading && history.length === 0) && <p className="fm-vehicle-sounds-history-empty">No recent sound update history.</p> }
          </div>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default VehicleSounds;
