import React, { Component } from 'react';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class AddMember extends Component {
  constructor() {
    super();
    this.state = { nickname: '', submitting: false, regions: [], error: false, credential: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleNicknameChange = this.handleNicknameChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Add Insights User | Flamingo Admin';
    this.loadRegions();
  }

  loadRegions() {
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data, regionId: res.data.data[0].id }))
      .catch(console.log);
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const { nickname, regionId } = this.state;
    this.setState({ submitting: true });

    return api.post('/mds/credential/new', { nickname, regionId })
      .then((res) => this.setState({ credential: res.data.data, submitting: false }))
      .catch((e) => this.setState({ submitting: false, error: this.handleError(e) }));
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    return window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
  }

  handleNicknameChange(nickname) {
    this.setState({ nickname, error: false });
  }

  handleRegionChange(regionId) {
    this.setState({ regionId, error: false });
  }

  renderContent() {
    if (this.state.credential) {
      return (
        <>
          <p className="fm-text">Your credential for "{ this.state.nickname }" has been successfully created.</p>
          <Input label="Client ID" value={this.state.credential.clientId} disabled={true} />
          <Input label="Client Secret" value={this.state.credential.clientSecret} disabled={true} />
          <p className="fm-text">This secret is displayed only once, please make sure you store it in a safe place. If you lose the secret, you will be required to reset it.</p>
          <Button onClick={() => history.push('/data-sharing')}>Close</Button>
        </>
      );
    }

    // Return the form
    const regionOptions = this.state.regions.map((region) => ({ label: region.name, value: region.id }));
    return (
      <>
        <p className="fm-text">Create a nickname and select a region for the credential.</p>
        <form onSubmit={this.handleSubmit}>
          <Input label="Nickname" value={this.state.nickname} onChange={this.handleNicknameChange} placeholder="My API Project" disabled={this.state.submitting} />
          <Input label="Region" type="select" defaultValue="Select Region" value={this.state.regionId} options={regionOptions} onChange={this.handleRegionChange} disabled={this.state.submitting} />
          <Button loading={this.state.submitting}>Create</Button>
        </form>
      </>
    );
  }

  render() {
    return (
      <div className="fm-page fm-staff">
        <p className="fm-page-title">Add MDS Credential</p>
        { this.renderContent() }

        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AddMember;
