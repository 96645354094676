import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import LoadingLine from 'components/common/loading-line';
import Toast from 'components/common/toast';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';
import history from 'helpers/history';

const icon = { url: 'https://storage.googleapis.com/flamingo-static/images/admin/task-inspection.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) };

class SanitisationMap extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], loading: true };

    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Sanitisation Map | Flamingo Admin';
    localStorage.setItem('fm-sanitisation', 'map');
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), this.state.types);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, types) {
    const endpoint = `/sanitisation/area?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}`;
    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude };
    return api.get(endpoint)
      .then((res) => this.setState({ vehicles: res.data.data, currentBounds, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Renders ------ */

  render() {
    const { vehicles, loading, error } = this.state;

    const actions = [
      { to: '/sanitisation/schedule', icon: 'list' },
      { to: '/sanitisation/scanner', icon: 'qrcode' },
    ];

    return (
      <div className="fm-sanitisation-map">
        <NavigationBar title="Sanitisation Map" rightActions={actions} />
        <div className="fm-sanitisation-map-holder">
          <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { vehicles.map((vehicle, i) => <Marker key={i} position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={`${ vehicle.registration } - ${ vehicle.count } trips`} icon={icon} onClick={() => history.push({ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } })} opacity={ vehicle.count > 2 ? 1 : 0.3 } />) }
          </Map>
        </div>
        <LoadingLine hide={!loading} />
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SanitisationMap;
