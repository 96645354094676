import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';

const incidentTypes = [
  { icon: 'exclamation-triangle', title: 'Interference', description: 'Unauthorised movement or damage.', linkType: 'interference' },
  { icon: 'exclamation-circle', title: 'Incident', description: 'An accident or near miss on a scooter/bike.', linkType: 'incident' },
  { icon: 'tools', title: 'Vandalism', description: 'Scooter/bike was significantly damaged.', linkType: 'vandalism' },
  { icon: 'people-carry', title: 'Theft', description: 'Scooter/bike was stolen.', linkType: 'theft' },
];

class ReportTripType extends Component {
  constructor(props) {
    super(props);
    const preloadedTrip = props.location.state ? props.location.state.trip : false;
    this.state = { trip: preloadedTrip || {}, category: props.match.params.category, types: [], loading: true };

    this.loadTrip = this.loadTrip.bind(this);
    this.loadTypes = this.loadTypes.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Trip | Flamingo Admin';
    this.loadTrip();
  }

  loadTrip() {
    if (this.state.trip.id) {
      return this.loadTypes();
    }
    return api.get(`/trip/${this.props.match.params.tripId}`)
      .then((res) => this.setState({ trip: res.data.data }, this.loadTypes))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  loadTypes() {
    if (this.state.category === 'incident') {
      return this.setState({ loading: false, types: incidentTypes });
    }
    return api.get(`/report/types/${this.state.category}`)
      .then((res) => this.setState({ types: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderType(type, i) {
    const { trip } = this.state;
    const vehicleId = trip.vehicle ? trip.vehicle.id : trip.vehicleId;
    const pathnames = {
      theft: `/incident/new/vehicle/${vehicleId}/theft`,
      vandalism: `/incident/new/vehicle/${vehicleId}/vandalism`,
      incident: `/incident/new/trip/${trip.id}/incident`,
      interference: `/report/interference/vehicle/${vehicleId}`,
    };
    const pathname = pathnames[type.linkType] || `/report/trip/${this.props.match.params.tripId}/${type.category}/${type.id}`;
    return (
      <Link className="fm-report-trip-type-card" key={i} to={{ pathname, state: { type } }}>
        <div className="fm-report-trip-type-card-icon">
          <Icon icon={type.icon} />
        </div>
        <div className="fm-report-trip-type-card-text">
          <p className="fm-report-trip-type-card-title">{ type.title }</p>
          <p className="fm-report-trip-type-card-description">{ type.description }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, types, category } = this.state;
    return (
      <div className="fm-report-trip-type">
        <NavigationBar title="Trip Report" subtitle={category.toUpperCase()} loading={loading} showBack={true} />
        <div className="fm-report-trip-type-content">
          { types.map(this.renderType) }
        </div>
      </div>
    );
  }
}

export default ReportTripType;
