import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import VoucherList from './components/voucher-list/';
import VoucherView from './components/voucher-view/';
import VoucherNew from './components/voucher-new/';

class Voucher extends Component {
  render() {
    return (
      <Switch>
        <Route path="/voucher/new" component={VoucherNew} />
        <Route path="/voucher/:voucherId" component={VoucherView} />
        <Route path="/voucher" component={VoucherList} />
      </Switch>
    );
  }
}

export default Voucher;
