import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AllMembers from './components/all-members';
import AddMember from './components/add-member';
import Member from './components/member';

class Insights extends Component {
  render() {
    return (
      <Switch>
        <Route path="/insights/add" component={AddMember} />
        <Route path="/insights/:id" component={Member} />
        <Route path="/insights" component={AllMembers} />
      </Switch>
    );
  }
}

export default Insights;
