import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Input from 'components/common/input';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class TicketNew extends Component {
  constructor() {
    super();
    this.state = { loading: false, error: false, type: 'maintenance', description: '', pickup: false };

    this.descriptionChange = this.descriptionChange.bind(this);
    this.handleMaintenance = this.handleMaintenance.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handlePickup = this.handlePickup.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.createTask = this.createTask.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'New Ticket | Flamingo Admin';
  }

  descriptionChange(description) {
    this.setState({ description });
  }

  handleMaintenance() {
    this.setState({ type: 'maintenance' });
  }

  handleNote() {
    this.setState({ type: 'note' });
  }

  handlePickup() {
    this.setState({ pickup: !this.state.pickup });
  }

  handleCreate() {
    this.setState({ loading: true, error: false });
    const { description, type, pickup } = this.state;
    const vehicleId = this.props.match.params.vehicleId;
    return api.post(`/ticket`, { vehicleId, description, type })
      .then(() => pickup ? this.createTask(vehicleId, description) : Promise.resolve())
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  createTask(vehicleId, description) {
    const data = {
      vehicleIds: [vehicleId],
      type: 'pickup',
      priority: 1,
      notes: description,
    };
    return api.post('/task', data);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  render() {
    const { loading, type, description, pickup, error } = this.state;
    const sliderClass = `fm-ticket-new-slider ${ type === 'maintenance' ? 'fm-ticket-new-slider-maintenance' : 'fm-ticket-new-slider-note' }`;

    return (
      <div className="fm-ticket-new">
        <NavigationBar title="New Ticket" loading={loading} showBack={true} />
        <div className="fm-ticket-new-content">
          <div className={sliderClass}>
            <div className="fm-ticket-new-slider-background"></div>
            <div className="fm-ticket-new-slider-options">
              <button className="fm-ticket-new-slider-option fm-ticket-new-slider-option-maintenance" onClick={this.handleMaintenance} disabled={loading}>MAINTENANCE</button>
              <button className="fm-ticket-new-slider-option fm-ticket-new-slider-option-note" onClick={this.handleNote} disabled={loading}>NOTE</button>
            </div>
          </div>
          <div className="fm-ticket-new-input">
            <Input type="textarea" value={description} onChange={this.descriptionChange} autoFocus={true} />
          </div>
          <div className="fm-ticket-new-input fm-ticket-new-input-checkbox">
            <label className="fm-input-label" for="fm-pickup-checkbox">Create Pick Up Task</label>
            <label className="fm-input-checkbox-container">
              <input className="fm-input fm-input-checkbox" id="fm-pickup-checkbox" disabled={loading} type="checkbox" checked={pickup} onChange={this.handlePickup} />
              <span className="fm-input-checkbox-checkmark"></span>
            </label>
          </div>
          <button className="fm-ticket-new-button" onClick={this.handleCreate} disabled={loading}>{ loading ? 'Loading...' : 'Create Ticket' }</button>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default TicketNew;
