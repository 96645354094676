import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../icon';
import LoadingLine from '../loading-line';
import history from 'helpers/history';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleTitleClick = this.handleTitleClick.bind(this);
  }

  handleTitleClick() {
    if (this.props.copyUrl) {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = window.location.href;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
  }

  render() {
    const { title, subtitle, showBack, rightActions, loading } = this.props;
    return (
      <div className="fm-navigation">
        <div className="fm-navigation-bar">
          <div className="fm-navigation-content">
            <div className="fm-navigation-left">
              {
                showBack && (
                  <button className="fm-navigation-back" onClick={() => history.goBack()}>
                    <Icon icon="chevron-left" />
                  </button>
                )
              }
              { title && <p className={ subtitle ? 'fm-navigation-title fm-navigation-title-small' : 'fm-navigation-title' } onClick={this.handleTitleClick}>{ title }</p> }
              { subtitle && <p className="fm-navigation-subtitle">{ subtitle }</p> }
            </div>
            <div className="fm-navigation-right">
              {
                rightActions && (
                  <div className="fm-navigation-actions">
                    {
                      rightActions.map((action, i) => {
                        if (action.onClick) {
                          return <button key={i} className="fm-navigation-action" title={action.title} onClick={action.onClick}><Icon icon={action.icon} /></button>;
                        }
                        return <Link key={i} className="fm-navigation-action" target={action.target} title={action.title} to={action.to}><Icon icon={action.icon} /></Link>;
                      })
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {
          loading && (
            <div className="fm-navigation-loading">
              <LoadingLine />
            </div>
          )
        }
      </div>
    );
  }
}

export default NavigationBar;
