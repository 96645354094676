import React, { Component } from 'react';
import Loading from 'components/common/loading';
import Input from 'components/common/input';
import Map, { Polylines, Dots } from 'components/common/map';
import api from 'helpers/api';

class AnalyticsEvents extends Component {
  constructor() {
    super();
    this.state = { data: [], type: 'ROUTES', period: 'DAY', date: new Date(), loading: false, firstLoad: true };
    this.loadData = this.loadData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Analytics | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const { period, date } = this.state;
    // Load routes
    if (this.state.type === 'ROUTES') {
      api.post('/analytics/routes', { period, date })
        .then((res) => this.setState({ data: res.data.data, loading: false, firstLoad: false }))
        .catch(console.log);
    }
    // Load start locations
    if (this.state.type === 'START') {
      api.post('/analytics/start-locations', { period, date })
        .then((res) => this.setState({ data: res.data.data, loading: false, firstLoad: false }))
        .catch(console.log);
    }
    // Load end locations
    if (this.state.type === 'END') {
      api.post('/analytics/end-locations', { period, date })
        .then((res) => this.setState({ data: res.data.data, loading: false, firstLoad: false }))
        .catch(console.log);
    }
    // Load deployment locations
    if (this.state.type === 'DEPLOYMENT') {
      api.post('/analytics/deployment-locations', { period, date })
        .then((res) => this.setState({ data: res.data.data, loading: false, firstLoad: false }))
        .catch(console.log);
    }
    // Load deployment locations
    if (this.state.type === 'RIDER') {
      api.post('/analytics/rider-locations', { period, date })
        .then((res) => this.setState({ data: res.data.data, loading: false, firstLoad: false }))
        .catch(console.log);
    }
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  handlePeriodChange(period) {
    this.setState({ period });
  }

  handleTypeChange(type) {
    this.setState({ type });
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.firstLoad) {
      return <p className="fm-analytics-first">Select a category, period and date, then press go.</p>;
    }

    const mapOptions = { center: { lat: -41.288170, lng: 174.778952 }, zoom: 16 };
    return (
      <Map options={mapOptions} google={window.google} isGlobal hideLocation>
        {
          this.state.type === 'ROUTES' ? (
            <Polylines encodedPaths={this.state.data} strokeOpacity={0.05} strokeColor={'#ff206e'} />
          ) : (
            <Dots dots={this.state.data} />
          )
        }
      </Map>
    );
  }

  render() {
    const typeOptions = [{ label: 'Ride start locations', value: 'START' }, { label: 'Ride routes', value: 'ROUTES' }, { label: 'Ride end locations', value: 'END' }, { label: 'Deployment locations', value: 'DEPLOYMENT' }, { label: 'Rider locations', value: 'RIDER' }];
    const periodOptions = [{ label: 'on day', value: 'DAY' }, { label: 'for week starting', value: 'WEEK' }];
    return (
      <div className="fm-page fm-analytics">
        <p className="fm-page-title">Analytics</p>
        <div className="fm-analytics-options">
          <Input type="select" value={this.state.type} onChange={this.handleTypeChange} options={typeOptions} />
          <Input type="select" value={this.state.period} onChange={this.handlePeriodChange} options={periodOptions} />
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} />
          <button className="fm-analytics-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-analytics-map">
          { this.renderContent() }
        </div>
      </div>
    );
  }
}

export default AnalyticsEvents;
