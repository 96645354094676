import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import history from 'helpers/history';

class ReportTripType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.match.params.category,
      reportId: props.match.params.reportId,
      types: [],
      loading: true,
    };

    this.loadTypes = this.loadTypes.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  componentDidMount() {
    document.title = 'Change Report | Flamingo Admin';
    this.loadTypes();
  }

  loadTypes() {
    return api.get(`/report/types/${this.state.category}`)
      .then((res) => this.setState({ types: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  handleChange(tripReportTypeId) {
    this.setState({ loading: true });
    return api.post(`/report/${this.state.reportId}/type`, { tripReportTypeId })
      .then(() => history.goBack())
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderType(type, i) {
    return (
      <div className="fm-report-change-type-card" key={i} onClick={() => this.handleChange(type.id)}>
        <div className="fm-report-change-type-card-icon">
          <Icon icon={type.icon} />
        </div>
        <div className="fm-report-change-type-card-text">
          <p className="fm-report-change-type-card-title">{ type.title }</p>
          <p className="fm-report-change-type-card-description">{ type.description }</p>
        </div>
      </div>
    );
  }

  render() {
    const { loading, types, category } = this.state;
    return (
      <div className="fm-report-change-type">
        <NavigationBar title="Change Report Type" subtitle={category.toUpperCase()} loading={loading} showBack={true} />
        <div className="fm-report-change-type-content">
          <p className="fm-report-change-type-info"><b>Safety issue reports are more important than photo reports.</b> For example, please do not change "Blocking Pathway" reports to "Not in Photo" if the vehicle is clearly blocking the pathway.</p>
          { types.map(this.renderType) }
        </div>
      </div>
    );
  }
}

export default ReportTripType;
