import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingLine from 'components/common/loading-line';
import api from 'helpers/api';
import colors from 'helpers/colors';

const tabs = [
  { label: 'Photo', key: 'photo' },
  { label: 'Waypoints', key: 'waypoints' },
  { label: 'Status Changes', key: 'statusChanges' },
  { label: 'Reports', key: 'reports' },
  { label: 'Cost', key: 'cost' },
  { label: 'Parking Warnings', key: 'parkingWarnings' },
  { label: 'Support Tickets', key: 'supportTickets' },
  { label: 'Helmets', key: 'helmets' },
];

class TripTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tab: 'photo',
      trip: props.trip,
      photo: [0],
      waypoints: [],
      statusChanges: [],
      reports: [],
      cost: [],
      parkingWarnings: [],
      supportTickets: [],
      helmets: [],
    };

    this.setTab = this.setTab.bind(this);
    this.loadTab = this.loadTab.bind(this);

    this.renderPhoto = this.renderPhoto.bind(this);
    this.renderWaypoint = this.renderWaypoint.bind(this);
    this.renderStatusChange = this.renderStatusChange.bind(this);
    this.renderReport = this.renderReport.bind(this);
    this.renderCost = this.renderCost.bind(this);
    this.renderParkingWarning = this.renderParkingWarning.bind(this);
    this.renderSupportTicket = this.renderSupportTicket.bind(this);
    this.renderHelmetHire = this.renderHelmetHire.bind(this);
  }

  setTab(tab) {
    this.setState({ tab, loading: this.state[tab].length === 0 }, this.loadTab);
  }

  loadTab() {
    const tripId = this.props.trip.id;
    switch (this.state.tab) {
      case 'waypoints':
        return api.get(`/trip/${tripId}/waypoints`)
          .then((res) => this.setState({ loading: this.state.tab !== 'waypoints', waypoints: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'parkingWarnings':
        return api.get(`/trip/${tripId}/parking-warnings`)
          .then((res) => this.setState({ loading: this.state.tab !== 'parkingWarnings', parkingWarnings: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'reports':
        return api.get(`/trip/${tripId}/reports`)
          .then((res) => this.setState({ loading: this.state.tab !== 'reports', reports: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'supportTickets':
        return api.get(`/trip/${tripId}/support-tickets`)
          .then((res) => this.setState({ loading: this.state.tab !== 'supportTickets', supportTickets: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'statusChanges':
        return api.get(`/trip/${tripId}/status-changes`)
          .then((res) => this.setState({ loading: this.state.tab !== 'statusChanges', statusChanges: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'helmets':
        return api.get(`/trip/${tripId}/helmets`)
          .then((res) => this.setState({ loading: this.state.tab !== 'helmets', helmets: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'cost':
        return api.get(`/trip/${tripId}/cost`)
          .then((res) => this.setState({ loading: this.state.tab !== 'cost', cost: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      default:
        return;
    }
  }

  renderPhoto() {
    const { parkingPhotoUrl } = this.props.trip;
    return (
      <div className="fm-trip-tab-photo">
        {
          parkingPhotoUrl ? (
            <img className="fm-trip-tab-photo-img" alt="Vehicle Parking" src={parkingPhotoUrl} />
          ) : (
            <p className="fm-trip-tab-photo-empty">No parking photo.</p>
          )
        }
      </div>
    );
  }

  renderWaypoint(waypoint, i) {
    return (
      <a className="fm-trip-tab-row" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${waypoint.latitude},${waypoint.longitude}`}>
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-title">{ moment(waypoint.createdAt).format('HH:mm:ss') }</p>
          <p className="fm-trip-tab-row-detail">{ waypoint.zone ? `${waypoint.zone.name} (${waypoint.zone.type}) - ` : '' }{ waypoint.speed } km/h{ waypoint.paused ? ' (Paused)' : '' }</p>
        </div>
      </a>
    );
  }

  renderStatusChange(change, i) {
    const user = this.props.trip.user;
    const reason = change.eventTypeReason === 'USER_PICK_UP' ? (user ? `${user.firstName} began their ride.` : 'Ride began.') : (user ? `${user.firstName} ended their ride.` : 'Ride ended.');
    return (
      <div key={i} className="fm-trip-tab-row">
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-tag" style={{ backgroundColor: colors.status[change.eventType] }}>{ change.eventType }</p>
          <p className="fm-trip-tab-row-title">{ moment(change.eventTime).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-trip-tab-row-detail">{ reason } { parseInt(change.batteryPercent * 100) }%.</p>
      </div>
    );
  }

  renderSupportTicket(ticket, i) {
    const status = ticket.resolvedAt ? 'resolved' : 'unresolved';
    return (
      <Link to={`/support/ticket/${ticket.id}`} key={i} className="fm-trip-tab-row">
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-tag" style={{ backgroundColor: colors.ticket[status] }}>{ status.toUpperCase() }</p>
          <p className="fm-trip-tab-row-title">{ moment(ticket.createdAt).fromNow() }</p>
        </div>
        <p className="fm-trip-tab-row-detail">{ ticket.subject }</p>
      </Link>
    );
  }

  renderCost(line, i) {
    const costTypes = {
      voucher: 'Voucher.',
      subscription: 'Subscription.',
      hireage: 'Trip Fare.',
      unlock: 'Unlock Fee.',
      adjustment: 'Rounding adjustment.',
    };
    const details = line.voucherRedemption ? `Voucher ${line.voucherRedemption.code}. ${line.minutes || 0} minutes.` : (line.userSubscription ? `Subscription. ${line.minutes || 0} minutes.` : costTypes[line.type]);
    return (
      <div key={i} className="fm-trip-tab-row">
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-tag" style={{ backgroundColor: colors.cost[line.type] }}>{ line.type.toUpperCase() }</p>
          <p className="fm-trip-tab-row-title">${ (line.cost / 100).toFixed(2) }</p>
        </div>
        <p className="fm-trip-tab-row-detail">{ details }</p>
      </div>
    );
  }

  renderParkingWarning(warning, i) {
    return (
      <a className="fm-trip-tab-row" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${warning.latitude},${warning.longitude}`}>
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-title">{ warning.zone.name } - { warning.zone.type }</p>
          <p className="fm-trip-tab-row-detail">{ moment(warning.createdAt).format('h:mma D/M/YY') }</p>
        </div>
      </a>
    );
  }

  renderHelmetHire(hire, i) {
    return (
      <Link to={{ pathname: `/helmet-lock/${hire.helmetLock.id}`, state: { helmetLock: hire.helmetLock } }} key={i} className="fm-trip-tab-row">
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-tag" style={{ backgroundColor: colors.helmet[hire.status] }}>{ hire.status.toUpperCase() }</p>
          <p className="fm-trip-tab-row-title">{ moment(hire.startTime).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-trip-tab-row-detail">{ hire.endTime ? `Helmet returned at ${moment(hire.endTime).format('h:mma D/M/YY')}.` : 'Helmet not returned.' }</p>
      </Link>
    );
  }

  renderReport(report, i) {
    return (
      <Link to={{ pathname: `/report/${report.id}`, state: { report } }} key={i} className="fm-trip-tab-row">
        <div className="fm-trip-tab-row-content">
          <p className="fm-trip-tab-row-tag" style={{ backgroundColor: colors.report[report.type.category] }}>{ report.type.category.toUpperCase() }</p>
          <p className="fm-trip-tab-row-title">{ moment(report.createdAt).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-trip-tab-row-detail">{ report.type.title } - { report.notes || 'No notes.' }</p>
      </Link>
    );
  }

  render() {
    const { loading, tab, waypoints, statusChanges, reports, cost, parkingWarnings, supportTickets, helmets } = this.state;
    return (
      <>
        <div className="fm-trip-tabs">
          { tabs.map((t, i) => <div key={i} className={`fm-trip-tab${ tab === t.key ? ' fm-trip-tab-active' : '' }`} onClick={() => this.setTab(t.key)}>{ t.label }</div>) }
        </div>
        <div className="fm-trip-tabs-loading">
          { loading && <LoadingLine /> }
        </div>
        <div className="fm-trip-tab-content">
          { tab === 'photo' && this.renderPhoto() }
          { (tab === 'waypoints') && ((waypoints.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No waypoints found.</p> : waypoints.map(this.renderWaypoint)) }
          { (tab === 'statusChanges') && ((statusChanges.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No status changes found.</p> : statusChanges.map(this.renderStatusChange)) }
          { (tab === 'reports') && ((reports.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No reports found.</p> : reports.map(this.renderReport)) }
          { (tab === 'cost') && ((cost.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No cost lines found.</p> : cost.map(this.renderCost)) }
          { (tab === 'parkingWarnings') && ((parkingWarnings.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No parking warnings found.</p> : parkingWarnings.map(this.renderParkingWarning)) }
          { (tab === 'supportTickets') && ((supportTickets.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No support tickets found.</p> : supportTickets.map(this.renderSupportTicket)) }
          { (tab === 'helmets') && ((helmets.length === 0 && !loading) ? <p className="fm-trip-tab-content-empty">No helmet hires found.</p> : helmets.map(this.renderHelmetHire)) }
          { !tab && <p className="fm-trip-tab-content-empty">Select a tab above.</p> }
        </div>
      </>
    );
  }
}

export default TripTabs;
