import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

class NestTop extends Component {
  constructor() {
    super();
    this.state = { nests: [], loading: true };
    this.loadNests = this.loadNests.bind(this);
  }

  componentDidMount() {
    document.title = 'Top Nests | Flamingo Admin';
    this.loadNests();
  }

  loadNests() {
    return api.get('/nest/top')
      .then((res) => this.setState({ nests: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderNest(nest, i) {
    return (
      <Link to={`/nest/${nest.id}`} key={i} className="fm-nest-top-row">
        <p className="fm-nest-top-address">{ nest.address }</p>
        <p className="fm-nest-top-capacity">{ nest.maxCapacity - nest.currentOccupancy } spaces</p>
      </Link>
    );
  }

  render() {
    const { loading, nests } = this.state;

    return (
      <div className="fm-nest-top">
        <NavigationBar title="High Demand Nests" loading={loading} showBack={true} />
        <div className="fm-nest-top-rows">
          { nests.map(this.renderNest) }
        </div>
      </div>
    );
  }
}

export default NestTop;
