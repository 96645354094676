import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Input from 'components/common/input';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker, Polyline } from 'components/common/map';
import api from 'helpers/api';
import colors from 'helpers/colors';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  'pickup': { url: baseUrl + 'task-pickup.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
  'batterySwap': { url: baseUrl + 'task-battery-swap.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
  'rebalance': { url: baseUrl + 'task-rebalance.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
  'repark': { url: baseUrl + 'task-repark.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
  'charge': { url: baseUrl + 'task-charge.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
  'inspection': { url: baseUrl + 'task-inspection.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
  'helmet': { url: baseUrl + 'task-helmet.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43), anchor: new window.google.maps.Point(21, 21) },
};

class PerformanceOverview extends Component {
  constructor() {
    super();
    const maxDate = moment().format('YYYY-MM-DD');
    this.state = { tasks: [], date: maxDate, maxDate, loading: false };
    this.loadData = this.loadData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderTask = this.renderTask.bind(this);
  }

  componentDidMount() {
    document.title = 'User Performance | Flamingo Admin';
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const period = 'DAY';
    const userId = this.props.match.params.userId;
    const { date } = this.state;
    api.post('/performance/user', { date, userId, period })
      .then((res) => this.setState({ tasks: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  renderTask(task, i) {
    return (
      <div className="fm-performance-user-row" key={i}>
        <Link className="fm-performance-user-row-registration" to={`/vehicle/${task.vehicle.id}`}>{ task.vehicle.registration }</Link>
        <a className="fm-performance-user-row-date" target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${task.collectedLatitude},${task.collectedLongitude}`}>{ moment(task.collectedAt).format('h:mma D/M/YY') } - { moment(task.createdAt).from(task.collectedAt, true) } - {parseInt(task.collectedBatteryPercent * 100)}%</a>
        <Link className="fm-performance-user-row-type" to={`/task/${task.id}`} style={{ backgroundColor: colors.task[task.type] }}>{ task.type.toUpperCase() }</Link>
      </div>
    );
  }

  render() {
    const { tasks, loading } = this.state;
    const path = tasks.map((task) => ({ lat: task.collectedLatitude, lng: task.collectedLongitude }));
    const activityAction = { to: `/performance/${this.props.match.params.userId}/activity`, icon: 'list-ul' };

    return (
      <div className="fm-performance-user">
        <NavigationBar title="User Performance" showBack={true} rightActions={[activityAction]} loading={loading} />
        <div className="fm-performance-user-options">
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} max={this.state.maxDate} />
          <button className="fm-performance-user-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-performance-user-map">
          <Map google={window.google} isGlobal>
            <Polyline path={path} strokeColor={'#ff206e'} strokeWeight={'4'} />
            { tasks.map((task, i) => <Marker key={i} position={{ lat: task.collectedLatitude, lng: task.collectedLongitude }} icon={icons[task.type]} title={`${task.type} - ${moment(task.collectedAt).format('h:mma D/M/YY')}`} />) }
          </Map>
        </div>
        <div className="fm-performance-user-list">
          { tasks.map(this.renderTask) }
        </div>
      </div>
    );
  }
}

export default PerformanceOverview;
