import React, { Component } from 'react';
import { QrReader } from '@flamingo-scooters/react-qr-reader';
import moment from 'moment';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import history from 'helpers/history';
import location from 'helpers/location';
import distance from 'helpers/distance';
import colors from 'helpers/colors';

const scannerActions = [
  { label: 'Make Available', value: 'available' },
  { label: 'Pick Up', value: 'transit' },
  { label: 'Power On', value: 'power' },
  { label: 'Unlock Vehicle', value: 'unlock' },
  { label: 'Lock Vehicle', value: 'lock' },
  { label: 'Battery Cover', value: 'batteryCover' },
  { label: 'Mark as Sanitised', value: 'sanitised' },
  { label: 'Demo Mode', value: 'demo' },
];

class VehicleScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: 'available',
      mode: 'single',
      multi: [],
      multiView: false,
      status: false,
      lastScan: false,
      nearbyHub: false,
      torchOn: true,
      torchAvailable: false,
    };

    this.handleScan = this.handleScan.bind(this);
    this.findResponse = this.findResponse.bind(this);
    this.scanResponse = this.scanResponse.bind(this);

    this.apiVehicle = this.apiVehicle.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.handleLocation = this.handleLocation.bind(this);

    this.toggleTorch = this.toggleTorch.bind(this);
    this.handleControls = this.handleControls.bind(this);
    this.handleSingle = this.handleSingle.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.handleVehicleAction = this.handleVehicleAction.bind(this);
    this.handleMultiRun = this.handleMultiRun.bind(this);
    this.multiVehicleSuccess = this.multiVehicleSuccess.bind(this);
    this.multiVehicleFail = this.multiVehicleFail.bind(this);

    this.renderMultiVehicle = this.renderMultiVehicle.bind(this);

    this.scannerControls = null;
  }

  componentDidMount() {
    this.getLocation();
  }

  handleScan(result, error) {
    if (result) {
      const qrCode = result.text.slice(-6);
      if (!isNaN(qrCode) && this.state.lastScan !== qrCode) {
        this.setState({ status: `Scanned ${qrCode}`, lastScan: qrCode });
        return api.post('/vehicle/search', { query: qrCode, limit: 1 })
          .then(this.findResponse)
          .catch(() => this.setState({ status: 'Search error' }));
      }
    }
  }

  findResponse(res) {
    if (res.data.data[0]) {
      return this.scanResponse(res.data.data[0]);
    } else {
      this.setState({ status: 'Could not find vehicle' });
    }
  }

  scanResponse(vehicle) {
    const { mode, multi } = this.state;

    if (mode === 'single') {
      return history.push({ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } });
    }

    if (!multi.some(v => v.id === vehicle.id)) {
      multi.push(vehicle);
      this.setState({ multi });
    }
  }

  getLocation() {
    const lastLocation = location.getLastLocation();
    if (lastLocation) {
      this.handleLocation(lastLocation);
    }
    return location.getLocation(true)
      .then(this.handleLocation)
      .catch(console.log);
  }

  handleLocation(user) {
    const { hubs } = this.props || [];
    if (!hubs[0] || !user || !user.latitude) {
      return;
    }

    const distanceFromUser = (hub) => {
      hub.distance = distance(hub.latitude, hub.longitude, user.latitude, user.longitude);
      return hub;
    };

    const nearestHub = hubs.slice(1).reduce((nearest, current) => {
      const hub = distanceFromUser(current);
      if (hub.distance < nearest.distance) {
        return hub;
      }
      return nearest;
    }, distanceFromUser(hubs[0]));

    let nearbyHub = false;
    if (nearestHub.distance < 1) {
      nearbyHub = nearestHub;
    }
    this.setState({ nearbyHub });
  }

  handleActionChange(e) {
    localStorage.setItem('fm-scanner', e.target.value);
    this.setState({ action: e.target.value, lastScan: false });
  }

  toggleTorch() {
    const { torchOn } = this.state;
    this.setState({ torchOn: !torchOn });
    this.scannerControls.switchTorch(torchOn);
  }

  handleControls(controls) {
    this.scannerControls = controls;
    this.setState({ torchAvailable: !!controls });
  }

  handleSingle() {
    this.setState({ mode: 'single', lastScan: false, multiView: false, status: false });
  }

  handleMulti() {
    const { mode, multi, multiView } = this.state;
    this.setState({ mode: 'multi', lastScan: false, status: false, multiView: mode === 'multi' && multi.length > 0 && !multiView });
  }

  handleVehicleAction(index) {
    const { multi } = this.state;
    multi.splice(index, 1);
    this.setState({ multi, multiView: multi.length > 0 });
  }

  handleMultiRun() {
    const { action, nearbyHub } = this.state;
    const multi = this.state.multi.map((v) => { v.multiStatus = 'loading'; return v; });
    this.setState({ multi });

    for (var i = 0; i < multi.length; i++) {
      const vehicle = multi[i];

      switch (action) {
        case 'available':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/status`, { status: 'AVAILABLE' });
          break;

        case 'hub':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/status`, { status: 'AVAILABLE', forceUserLocation: true }, nearbyHub);
          break;

        case 'transit':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/status`, { status: vehicle.status === 'MAINTENANCE' ? 'REPAIR' : 'TRANSIT' });
          break;

        case 'unlock':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/unlock`);
          break;

        case 'power':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/power`, { controlType: true });
          break;

        case 'lock':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/lock`);
          break;

        case 'batteryCover':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/battery-cover`);
          break;

        case 'sanitised':
          this.apiVehicle(vehicle, `/sanitisation/${vehicle.id}`);
          break;

        case 'demo':
          this.apiVehicle(vehicle, `/vehicle/${vehicle.id}/status`, { status: 'DEMO' });
          break;

        default:
          break;
      }
    }
  }

  apiVehicle(vehicle, endpoint, data, hub) {
    if (data && hub) {
      data.userLatitude = hub.latitude;
      data.userLongitude = hub.longitude;
      data.userLocationAccuracy = 1;
      data.userLocationTime = moment().format();
    } else if (data) {
      const { latitude, longitude, timestamp, accuracy } = location.getLastLocation();
      if (latitude && longitude && timestamp) {
        data.userLatitude = latitude;
        data.userLongitude = longitude;
        data.userLocationTime = timestamp;
        data.userLocationAccuracy = accuracy;
      }
    }

    return api.post(endpoint, data)
      .then((res) => this.multiVehicleSuccess(res.data.data))
      .catch((e) => this.multiVehicleFail(e, vehicle));
  }

  multiVehicleSuccess(vehicle) {
    const { multi } = this.state;

    const index = multi.findIndex((v) => v.id === vehicle.id);
    if (index > -1) {
      vehicle.multiStatus = 'succeeded';
      multi[index] = vehicle;

      this.setState({ multi });
    }
  }

  multiVehicleFail(e, vehicle) {
    const { multi } = this.state;
    const index = multi.findIndex((v) => v.id === vehicle.id);

    if (index > -1) {
      vehicle.multiStatus = 'failed';
      vehicle.multiError = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
      multi[index] = vehicle;

      this.setState({ multi });
    }
  }

  renderMultiVehicle(vehicle, i) {
    let description = `${ parseInt(vehicle.batteryPercent * 100) }%.`;

    if (moment().diff(vehicle.lastInspection, 'days') > 15) {
      description = `${ parseInt(vehicle.batteryPercent * 100) }%. Insp due ${ moment(vehicle.lastInspection).add(28, 'days').fromNow() }.`;
    }

    if (vehicle.multiError) {
      description = vehicle.multiError;
    }

    const multiStatuses = {
      pending: { class: 'fm-vehicle-scanner-multi-item fm-vehicle-scanner-multi-item-pending', icon: 'times' },
      failed: { class: 'fm-vehicle-scanner-multi-item fm-vehicle-scanner-multi-item-failed', icon: 'times' },
      succeeded: { class: 'fm-vehicle-scanner-multi-item fm-vehicle-scanner-multi-item-succeeded', icon: 'check' },
      loading: { class: 'fm-vehicle-scanner-multi-item fm-vehicle-scanner-multi-item-loading', icon: 'spinner' },
    };
    const multiStatus = multiStatuses[vehicle.multiStatus || 'pending'];

    return (
      <div className={ multiStatus.class } key={ i }>
        <p className="fm-vehicle-scanner-multi-item-registration">{ vehicle.registration }</p>
        <div className="fm-vehicle-scanner-multi-item-details">
          <div className="fm-vehicle-scanner-multi-item-status">
            <div className="fm-vehicle-scanner-multi-item-status-circle" style={{ backgroundColor: colors.status[vehicle.status] }}></div>
            <p className="fm-vehicle-scanner-multi-item-status-text">{ vehicle.status }</p>
          </div>
          <p className="fm-vehicle-scanner-multi-item-description">{ description }</p>
        </div>
        <div className="fm-vehicle-scanner-multi-item-action" onClick={() => this.handleVehicleAction(i)}>
          <Icon icon={ multiStatus.icon } />
        </div>
      </div>
    );
  }

  render() {
    const { action, status, mode, multi, multiView, nearbyHub, torchOn, torchAvailable } = this.state;
    return (
      <div className={ `fm-vehicle-scanner${multiView ? ' fm-vehicle-scanner-multi-active' : ''}` }>
        { status && <div className="fm-vehicle-scanner-status">{ status }</div> }
        <QrReader
          scanDelay={300}
          onResult={this.handleScan}
          onControls={this.handleControls}
          constraints={{ facingMode: 'environment' }}
          videoStyle={{ objectFit: 'cover' }}
          className="fm-vehicle-scanner-reader"
          innerRef={this.scanner}
        />
        {
          multiView &&
          <div className="fm-vehicle-scanner-multi">
            <div className="fm-vehicle-scanner-multi-list">
              { multi.map(this.renderMultiVehicle) }
            </div>
            <div className="fm-vehicle-scanner-multi-controls">
              <select className="fm-input fm-input-select fm-vehicle-scanner-multi-select" value={action} onChange={this.handleActionChange}>
                { scannerActions.map((option, i) => <option key={i} value={ option.value }>{ option.label }</option>)}
                { nearbyHub && <option value="hub">Hub Deploy ({ nearbyHub.name } - { nearbyHub.distance.toFixed(2) } km)</option> }
              </select>
              <div className="fm-vehicle-scanner-multi-button" onClick={this.handleMultiRun}>
                <Icon icon="angle-double-right" />
              </div>
            </div>
          </div>
        }
        <div className="fm-vehicle-scanner-bar">
          <div className={`fm-vehicle-scanner-bar-modes fm-vehicle-scanner-bar-modes-${mode}`}>
            <div className="fm-vehicle-scanner-bar-mode fm-vehicle-scanner-bar-mode-single" onClick={this.handleSingle}>
              <p className="fm-vehicle-scanner-bar-mode-title">SINGLE</p>
              <p className="fm-vehicle-scanner-bar-mode-subtitle">View a single vehicle</p>
            </div>
            <div className="fm-vehicle-scanner-bar-mode fm-vehicle-scanner-bar-mode-multi" onClick={this.handleMulti}>
              {
                multi.length > 0 ? (
                  <p className="fm-vehicle-scanner-bar-mode-title">MULTI ({ multi.length })</p>
                ) : (
                  <p className="fm-vehicle-scanner-bar-mode-title">MULTI</p>
                )
              }
              {
                mode === 'multi' ? (
                  multi.length > 0 ? (
                    multiView ? (
                      <p className="fm-vehicle-scanner-bar-mode-subtitle">Tap to minimise</p>
                    ) : (
                      <p className="fm-vehicle-scanner-bar-mode-subtitle">Tap to select an action</p>
                    )
                  ) : (
                    <p className="fm-vehicle-scanner-bar-mode-subtitle">Scan a vehicle to begin</p>
                  )
                ) : (
                  <p className="fm-vehicle-scanner-bar-mode-subtitle">For bulk actions</p>
                )
              }
            </div>
          </div>
          {
            torchAvailable &&
            <div className={ torchOn ? 'fm-vehicle-scanner-torch fm-vehicle-scanner-torch-on' : 'fm-vehicle-scanner-torch' } onClick={this.toggleTorch}>
              <Icon icon="lightbulb" />
            </div>
          }
        </div>
      </div>
    );
  }
}


export default VehicleScanner;
