import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';

const taskTypes = [
  { value: 'all', label: 'All Types' },
  { value: 'charge', label: 'Charge' },
  { value: 'batterySwap', label: 'Battery Swap' },
  { value: 'pickup', label: 'Pickup' },
  { value: 'deploy', label: 'Deploy' },
  { value: 'rebalance', label: 'Rebalance' },
  { value: 'repark', label: 'Repark' },
  { value: 'inspection', label: 'Inspection' },
  { value: 'helmet', label: 'Helmet' },
];

const taskStatuses = [
  { value: 'all', label: 'All Statuses' },
  { value: 'available', label: 'Available' },
  { value: 'reserved', label: 'Reserved' },
  { value: 'collected', label: 'Collected' },
];

const trendDescriptions = {
  increasing: 'Increasing',
  decreasing: 'Decreasing',
  constant: 'Constant',
  veryLow: 'Very Low',
  low: 'Low',
  normal: 'Normal',
  high: 'High',
  veryHigh: 'Very High',
};

const trendIcons = {
  increasing: 'arrow-alt-circle-up',
  decreasing: 'arrow-alt-circle-down',
  constant: 'arrow-alt-circle-right',
  veryLow: 'arrow-alt-circle-down',
  low: 'arrow-alt-circle-down',
  normal: 'arrow-alt-circle-right',
  high: 'arrow-alt-circle-up',
  veryHigh: 'arrow-alt-circle-up',
};

const cardKeys = {
  pendingTickets: { color: '#EB3B5A', label: 'Pending Tickets' },
  offlineVehicles: { color: '#e67e22', label: 'Offline Vehicles' },
  deployableVehicles: { color: '#000000', label: 'Deployable Vehicles' },
  transitVehicles: { color: '#7f8c8d', label: 'Street Transit Vehicles' },
  availableBatterySwapTasks: { color: '#4B7BEC', label: 'Battery Swap Tasks' },
  availablePickupTasks: { color: '#EB3B5A', label: 'Available Pickup Tasks' },
  availableChargeTasks: { color: '#1abc9c', label: 'Available Charge Tasks' },
  fallenVehicles: { color: '#f1c40f', label: 'Fallen Over Vehicles' },
  upcomingInspections: { color: '#A55EEA', label: 'Upcoming Inspections' },
  unlockedBikes: { color: '#833471', label: 'Unlocked Bikes' },
  unlockedHelmets: { color: '#A3CB38', label: 'Missing Helmets' },
  hubOccupancy: { color: '#f1c40f', label: 'Current Nest Targets', isPercentage: true },
};

const taskIcons = {
  batterySwap: 'battery-full',
  rebalance: 'arrow-alt-circle-right',
  repark: 'chevron-circle-up',
  pickup: 'shuttle-van',
  inspection: 'tools',
  charge: 'bolt',
  deploy: 'external-link-square-alt',
  helmet: 'hard-hat',
};

const taskNames = {
  batterySwap: 'Battery Swap',
  rebalance: 'Rebalance',
  repark: 'Repark',
  pickup: 'Pick Up',
  inspection: 'Inspection',
  charge: 'Charge',
  deploy: 'Deploy',
  helmet: 'Helmet',
};

class TaskActive extends Component {
  constructor() {
    super();
    this.state = {
      groupedTasks: [],
      sort: 'created',
      stats: [],
      type: 'all',
      status: 'available',
      trends: {},
      loading: true,
      pendingTickets: 0,
      offlineVehicles: 0,
    };

    this.loadData = this.loadData.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.loadStats = this.loadStats.bind(this);
    this.loadTrends = this.loadTrends.bind(this);
    this.parseStats = this.parseStats.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.mapTasks = this.mapTasks.bind(this);
    this.sortTasks = this.sortTasks.bind(this);
    this.renderTasks = this.renderTasks.bind(this);
  }

  componentDidMount() {
    document.title = `Tasks | Flamingo Admin`;
    localStorage.setItem('fm-task', 'active');
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    this.loadTasks();
    this.loadStats();
    this.loadTrends();
  }

  loadTasks() {
    const { type, status } = this.state;
    let endpoint = '/task/active';
    if (type !== 'all' && status !== 'all') {
      endpoint += `?type=${type}&status=${status}`;
    } else if (type !== 'all') {
      endpoint += `?type=${type}`;
    } else if (status !== 'all') {
      endpoint += `?status=${status}`;
    }

    api.get(endpoint)
      .then(this.mapTasks)
      .catch((e) => this.setState({ loading: false }, () => alert(e.toString())));
  }

  mapTasks(res) {
    const taskItems = res.data.data;
    const vehicleTasks = {};
    const vehicleOrder = [];

    for (var i = 0; i < taskItems.length; i++) {
      const taskItem = taskItems[i]
      const vehicleId = taskItem.vehicle.id;
      if (vehicleTasks[vehicleId]) {
        vehicleTasks[vehicleId].push(taskItem);
      } else {
        vehicleTasks[vehicleId] = [taskItem];
        vehicleOrder.push(vehicleId);
      }
    }

    const groupedTasks = vehicleOrder.map((vehicleId) => vehicleTasks[vehicleId]);
    this.setState({ groupedTasks, loading: false });
  }

  loadStats() {
    api.get('/task/statistics')
      .then(this.parseStats)
      .catch((e) => alert(e.toString()));
  }

  parseStats(res) {
    const { data } = res.data;
    const { pendingTickets, offlineVehicles } = data;
    const bannerKeys = ['pendingTickets', 'offlineVehicles'];
    const stats = Object.keys(data).map((key) => ({ key, value: data[key] })).filter((v) => v.value > 0 && !bannerKeys.includes(v.key));
    this.setState({ stats, offlineVehicles, pendingTickets });
  }

  loadTrends() {
    api.get('/task/trend')
      .then((res) => this.setState({ trends: res.data.data }))
      .catch(console.log);
  }

  handleTypeChange(e) {
    const type = e.target.value;
    this.setState({ type, loading: true }, this.loadTasks);
  }

  handleStatusChange(e) {
    const status = e.target.value;
    this.setState({ status, loading: true }, this.loadTasks);
  }

  handleSort() {
    this.setState({ sort: this.state.sort === 'created' ? 'battery' : 'created' });
  }

  sortTasks(a, b) {
    if (this.state.sort === 'created') {
      return new Date(a[0].createdAt) - new Date(b[0].createdAt);
    }
    return a[0].vehicle.batteryPercent - b[0].vehicle.batteryPercent;
  }

  descriptionForTasks(tasks) {
    for (var i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      if (task.status === 'collected') {
        return `Collected by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }.${ task.completeBy ? ` Due ${moment(task.completeBy).fromNow() }.` : '' }`;
      }
      if (task.status === 'reserved') {
        return `Reserved by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }. Expires ${ moment(task.expiresAt).fromNow() }.`;
      }
    }
    return tasks.map((task) => taskNames[task.type]).sort().join(', ').replace(/,(?=[^,]*$)/, ' &') + '. ' + tasks.filter((task) => task.notes).map((task) => task.notes).join(' ');
  }

  handleCardClick(key) {
    switch (key) {
      case 'deployableVehicles':
        return history.push('/task/deployable');
      case 'transitVehicles':
        return history.push('/task/transits');
      case 'pendingTickets':
        return history.push('/ticket');
      case 'offlineVehicles':
        return history.push('/task/offline');
      case 'fallenVehicles':
        return history.push('/task/fallen');
      case 'unlockedBikes':
        return history.push('/task/unlocked');
      case 'unlockedHelmets':
        return this.setState({ status: 'available', type: 'helmet', loading: true }, this.loadTasks);
      case 'upcomingInspections':
        return history.push('/maintenance/schedule');
      case 'hubOccupancy':
        return history.push('/hub');
      case 'availablePickupTasks':
        return this.setState({ status: 'available', type: 'pickup', loading: true }, this.loadTasks);
      case 'availableBatterySwapTasks':
        return this.setState({ status: 'available', type: 'batterySwap', loading: true }, this.loadTasks);
      case 'availableChargeTasks':
        return this.setState({ status: 'available', type: 'charge', loading: true }, this.loadTasks);
      default:
        return;
    }
  }

  renderTasks(tasks, i) {
    const vehicle = tasks[0].vehicle;
    const types = tasks.map((task) => task.type).sort();
    const statuses = tasks.map((task) => task.status).sort();
    const color = statuses.includes('collected') ? colors.taskStatus.collected : (statuses.includes('reserved') ? colors.taskStatus.reserved : colors.taskStatus[statuses[0]]);
    return (
      <Link className="fm-task-active-item" key={i} to={{ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } }}>
        <div className="fm-task-active-item-header">
          <p className="fm-task-active-item-rego">{ vehicle.registration }</p>
          <div className="fm-task-active-item-types">
            { types.map((type, ii) => <p className="fm-task-active-item-type" key={ii} style={{ backgroundColor: colors.task[type] }}><Icon icon={taskIcons[type]} /></p>) }
          </div>
          <p className="fm-task-active-item-created">{ moment(tasks[0].createdAt).fromNow() } - { parseInt(vehicle.batteryPercent * 100) }%</p>
          <div className="fm-task-active-item-status" style={{ backgroundColor: color }}></div>
        </div>
        <p className="fm-task-active-item-details">{ this.descriptionForTasks(tasks) }</p>
      </Link>
    );
  }

  render() {
    const { groupedTasks, loading, status, type, stats, trends, pendingTickets, offlineVehicles } = this.state;
    const sortAction = { onClick: this.handleSort, icon: 'sort-amount-down' };
    const refreshAction = { onClick: this.loadData, icon: 'sync-alt' };
    const mapTaskAction = { to: '/task/map', icon: 'map-marked' };
    const newTaskAction = { to: '/task/new', icon: 'plus-circle' };

    return (
      <div className="fm-task-active">
        <NavigationBar title="Tasks" rightActions={[sortAction, refreshAction, mapTaskAction, newTaskAction]} loading={loading} />
        <div className="fm-task-active-top">
          {
            pendingTickets > 0 &&
            <Link className="fm-task-active-top-card fm-task-active-top-card-ticket" to="/ticket">
              <p className="fm-task-active-top-highlight">{ pendingTickets }</p>
              <p className="fm-task-active-top-label">Pending Tickets</p>
            </Link>
          }
          {
            offlineVehicles > 0 &&
            <Link className="fm-task-active-top-card fm-task-active-top-card-offline" to="/task/offline">
              <p className="fm-task-active-top-highlight">{ offlineVehicles }</p>
              <p className="fm-task-active-top-label">Offline Vehicles</p>
            </Link>
          }
        </div>
        {
          stats.length > 0 &&
          <div className="fm-task-active-cards">
            {
              stats.map((stat, i) => {
                const card = cardKeys[stat.key];
                if (card) {
                  return (
                    <div className="fm-task-active-card" key={i} onClick={() => this.handleCardClick(stat.key)}>
                      <p className="fm-task-active-card-highlight" style={{ backgroundColor: card.color }}>{ stat.value }{ card.isPercentage ? '%' : null }</p>
                      <p className="fm-task-active-card-description">{ card.label }</p>
                    </div>
                  );
                }
                return null;
              })
            }
          </div>
        }
        <div className="fm-task-active-trend">
          <div className="fm-task-active-trend-item">
            <p className="fm-task-active-trend-text">Tasks: { trends.taskLevel ? trendDescriptions[trends.taskLevel] : 'Loading...' }</p>
            { trends.taskLevel && <Icon icon={trendIcons[trends.taskLevel]} /> }
          </div>
          <div className="fm-task-active-trend-item">
            <p className="fm-task-active-trend-text">Trend: { trends.trendLevel ? trendDescriptions[trends.trendLevel] : 'Loading...' }</p>
            { trends.taskLevel && <Icon icon={trendIcons[trends.trendLevel]} /> }
          </div>
        </div>
        <div className="fm-task-active-filters">
          <select value={type} onChange={this.handleTypeChange} className="fm-input fm-input-select">
            { taskTypes.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
          </select>
          <select value={status} onChange={this.handleStatusChange} className="fm-input fm-input-select">
            { taskStatuses.map((status, i) => <option key={i} value={status.value}>{status.label}</option>) }
          </select>
        </div>
        { groupedTasks.sort(this.sortTasks).map(this.renderTasks) }
      </div>
    );
  }
}

export default TaskActive;
