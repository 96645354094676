import React, { Component } from 'react';
import Button from 'components/common/button';
import api from 'helpers/api';

class StripeUnblock extends Component {
  constructor() {
    super();
    this.state = { loading: false, unblocked: false };
    this.handleUnblock = this.handleUnblock.bind(this);
  }

  componentDidMount() {
    document.title = 'Unblock Fingerprint | Flamingo Admin';
  }

  // Networking

  handleUnblock() {
    this.setState({ loading: true });
    const fingerprint = this.props.match.params.fingerprint;
    return api.post('/stripe/unblock-fingerprint', { fingerprint })
      .then((res) => this.setState({ loading: false, unblocked: true }))
      .catch((e) => this.setState({ loading: false }));
  }

  // Render

  render() {
    return (
      <div className="fm-page fm-stripe-unblock">
        <p className="fm-page-title">Unblock Fingerprint</p>
        {
          this.state.unblocked ? (
            <div className="fm-stripe-unblock-content">
              <p>The fingerprint has been successfully unblocked. You still need to re-enable the user's account.</p>
              <br/>
              <p><b>Fingerprint:</b> { this.props.match.params.fingerprint }</p>
            </div>
          ) : (
            <div className="fm-stripe-unblock-content">
              <p>Click the button below to unblock the following card fingerprint from Stripe.</p>
              <br/>
              <p><b>Fingerprint:</b> { this.props.match.params.fingerprint }</p>
              <Button loading={this.state.loading} onClick={this.handleUnblock}>Unblock Fingerprint</Button>
            </div>
          )
        }
      </div>
    );
  }
}

export default StripeUnblock;
