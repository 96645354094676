import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Map, { Marker, Rectangle } from 'components/common/map';
import api from 'helpers/api';

class UserAreaView extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, areaViews: [], areaViewIndex: 0, playing: false };

    this.loadAreaViews = this.loadAreaViews.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.play = this.play.bind(this);
    this.nextView = this.nextView.bind(this);
  }

  componentDidMount() {
    document.title = `User Area Views | Flamingo Admin`;
    this.loadAreaViews();
  }

  /* NETWORKING */

  loadAreaViews() {
    this.setState({ loading: true });
    const { userId, startTime, endTime } = this.props.match.params;
    return api.get(`/user/${userId}/area-view?startTime=${startTime}&endTime=${endTime}`)
      .then(this.handleData)
      .catch(this.handleError);
  }

  handleData(res) {
    const areaViews = res.data.data;
    this.setState({ areaViews, loading: false });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  handlePlay() {
    clearInterval(this.mapInterval);
    this.setState({ areaViewIndex: 0, playing: true }, this.play);
  }

  handlePause() {
    clearInterval(this.mapInterval);
    this.setState({ playing: false });
  }

  play() {
    this.mapInterval = setInterval(this.nextView, 1000);
  }

  nextView() {
    const { areaViews, areaViewIndex } = this.state;
    const newIndex = areaViewIndex + 1;

    if (areaViews[newIndex]) {
      this.setState({ areaViewIndex: newIndex });
    } else {
      clearInterval(this.mapInterval);
      this.setState({ playing: false });
    }
  }

  /* RENDERS */

  render() {
    const { areaViews, areaViewIndex, playing, loading, error } = this.state;
    const { markerLatitude, markerLongitude } = this.props.match.params;

    const playAction = { onClick: this.handlePlay, icon: 'play' };
    const pauseAction = { onClick: this.handlePause, icon: 'pause' };
    const currentAction = playing ? [pauseAction] : [playAction];

    const center = {
      lat: markerLatitude ? parseFloat(markerLatitude) : -41.29249605503776,
      lng: markerLongitude ? parseFloat(markerLongitude) : 174.7784299745886,
    };
    if (!markerLongitude && areaViews[0]) {
      center.lat = areaViews[0].neLatitude;
      center.lng = areaViews[0].neLongitude;
    }

    const areaView = areaViews[areaViewIndex] || false;
    const oneAreaView = areaViews[areaViewIndex - 1] || false;
    const twoAreaView = areaViews[areaViewIndex - 2] || false;
    const viewCount = `${areaViewIndex + 1} / ${areaViews.length}`;

    // Render
    return (
      <div className="fm-user-area">
        <NavigationBar title="App Area View Activity" subtitle={loading ? '' : viewCount} loading={loading} showBack={true} rightActions={loading ? [] : currentAction} />
        <div className="fm-user-area-map">
          <Map google={window.google} options={{ center, zoom: 14 }}>
            { areaView && <Rectangle fillOpacity={0.2} strokeWeight={3} bounds={{ north: areaView.neLatitude, east: areaView.neLongitude, south: areaView.swLatitude, west: areaView.swLongitude }} /> }
            { oneAreaView && <Rectangle fillOpacity={0.1} strokeWeight={0} bounds={{ north: oneAreaView.neLatitude, east: oneAreaView.neLongitude, south: oneAreaView.swLatitude, west: oneAreaView.swLongitude }} /> }
            { twoAreaView && <Rectangle fillOpacity={0.1} strokeWeight={0} bounds={{ north: twoAreaView.neLatitude, east: twoAreaView.neLongitude, south: twoAreaView.swLatitude, west: twoAreaView.swLongitude }} /> }
            { (markerLatitude && markerLongitude) && <Marker position={{ lat: parseFloat(markerLatitude), lng: parseFloat(markerLongitude) }} /> }
          </Map>
        </div>
        <div className="fm-user-area-controls">
          <p className="fm-user-area-date">{ areaView.viewedAt ? moment(areaView.viewedAt).format('YYYY-MM-DD HH:mm:ss ') : 'Loading...' }</p>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default UserAreaView;
