import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Map, { Dots } from 'components/common/map';
import api from 'helpers/api';

class SanitisationStatistics extends Component {
  constructor() {
    super();
    this.state = { users: [], recent: [], daily: [], maxDaily: 0, maxTrips: 0, loading: true };

    this.loadStatistics = this.loadStatistics.bind(this);
    this.parseResponse = this.parseResponse.bind(this);
    this.handleError = this.handleError.bind(this);

    this.renderUser = this.renderUser.bind(this);
    this.renderSanitisation = this.renderSanitisation.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.renderTrips = this.renderTrips.bind(this);
  }

  componentDidMount() {
    document.title = 'Sanitisation Statistics | Flamingo Admin';
    localStorage.setItem('fm-sanitisation', 'statistics');
    this.loadStatistics();
  }

  /* ------ Networking ------ */

  loadStatistics() {
    api.get('/sanitisation/statistics')
      .then(this.parseResponse)
      .catch(this.handleError);
  }

  parseResponse(res) {
    const users = res.data.data.userStatistics;
    const recent = res.data.data.recentSanitisations;

    const dailyTotals = [];
    const dailySanitisations = res.data.data.dailySanitisations.reduce((a, c) => {
      a[c.sanitisedDay] = c.total;
      dailyTotals.push(c.total);
      return a;
    }, {});
    const dailyUnqiueSanitisations = res.data.data.dailyUnqiueSanitisations.reduce((a, c) => {
      a[c.sanitisedDay] = c.total;
      dailyTotals.push(c.total);
      return a;
    }, {});

    const trips = res.data.data.trips.reduce((a, c) => {
      a[c.tripDay] = c.total;
      return a;
    }, {});

    const maxDaily = Math.max.apply(Math, dailyTotals);
    const dailyTrips = [];
    const daily = [];
    const today = moment();
    var day = moment().subtract(7, 'days').startOf('day');

    while (day <= today) {
      const dayDate = day.format('YYYY-MM-DD');
      const data = {
        date: dayDate,
        total: dailySanitisations[dayDate] || 0,
        unique: dailyUnqiueSanitisations[dayDate] || 0,
        trips: (trips[dayDate] || 0) / (dailySanitisations[dayDate] || 0) || 0,
      };
      dailyTrips.push(data.trips);
      daily.push(data);
      day = day.clone().add(1, 'd');
    }

    const maxTrips = Math.max.apply(Math, dailyTrips);
    this.setState({ users, recent, daily, maxDaily, maxTrips, loading: false })
  }

  handleError(e) {
    console.log(e);
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Renders ------ */

  renderUser(row, i) {
    return (
      <tr key={i} className="fm-sanitisation-statistics-row">
        <td className="fm-sanitisation-statistics-row-name">{ row.user.firstName } { row.user.lastName }</td>
        <td className="fm-sanitisation-statistics-row-value">{ row.total || 0 }</td>
      </tr>
    );
  }

  renderSanitisation(row, i) {
    return (
      <div key={i} className="fm-sanitisation-statistics-recent-row">
        <p className="fm-sanitisation-statistics-recent-row-name">{ row.user.firstName } { row.user.lastName }</p>
        <p className="fm-sanitisation-statistics-recent-row-time">{ moment(row.createdAt).fromNow() }</p>
      </div>
    );
  }

  renderDay(day, i) {
    const { maxDaily } = this.state;
    return (
      <div className="fm-sanitisation-statistics-day" key={i}>
        <div className="fm-sanitisation-statistics-day-bar-holder">
          <div className="fm-sanitisation-statistics-day-bar-unique" title={day.unique} style={{ height: (((day.unique / maxDaily) * 100) + '%') }}></div>
          <div className="fm-sanitisation-statistics-day-bar-total" title={day.total} style={{ height: (((day.total / maxDaily) * 100) + '%') }}></div>
        </div>
        <div className="fm-sanitisation-statistics-day-value-holder">
          <p className="fm-sanitisation-statistics-day-value">{ day.unique || 0 }</p>
          <p className="fm-sanitisation-statistics-day-value">{ day.total || 0 }</p>
        </div>
        <p className="fm-sanitisation-statistics-day-date">{ moment(day.date).format('D/M') }</p>
      </div>
    );
  }

  renderTrips(day, i) {
    const { maxTrips } = this.state;
    return (
      <div className="fm-sanitisation-statistics-day" key={i}>
        <div className="fm-sanitisation-statistics-day-bar-holder">
          <div className="fm-sanitisation-statistics-day-bar-trips" title={day.trips} style={{ height: (((day.trips / maxTrips) * 100) + '%') }}></div>
        </div>
        <div className="fm-sanitisation-statistics-day-value-holder">
          <p className="fm-sanitisation-statistics-day-value">{ day.trips.toFixed(2) }</p>
        </div>
        <p className="fm-sanitisation-statistics-day-date">{ moment(day.date).format('D/M') }</p>
      </div>
    );
  }

  render() {
    const { loading, users, recent, daily, error } = this.state;
    const actions = [
      { to: '/sanitisation/schedule', icon: 'list' },
      { to: '/sanitisation/map', icon: 'map-marked' },
      { to: '/sanitisation/scanner', icon: 'qrcode' },
    ];

    return (
      <div className="fm-sanitisation-statistics">
        <NavigationBar title="Sanitisation Statistics" loading={loading} rightActions={actions} />
        <div className="fm-sanitisation-statistics-container">
          <div className="fm-sanitisation-statistics-table-container">
            <table className="fm-sanitisation-statistics-table">
              <tbody>
                <tr>
                  <th className="fm-sanitisation-statistics-column-title">Last 12 Hours</th>
                  <th className="fm-sanitisation-statistics-column-header">Sanitised Scooters</th>
                </tr>
                { users.map(this.renderUser) }
              </tbody>
            </table>
          </div>
          <div className="fm-sanitisation-statistics-map">
            <Map google={window.google} isGlobal hideLocation>
              <Dots dots={recent} />
            </Map>
          </div>
          { daily.length > 0 && <p className="fm-sanitisation-statistics-title">Daily Sanitisations - Total Vehicles Sanitised vs Total Sanitisations</p> }
          <div className="fm-sanitisation-statistics-daily">
            { daily.map(this.renderDay) }
          </div>
          { daily.length > 0 && <p className="fm-sanitisation-statistics-title">Daily Average Trips per Sanitisation</p> }
          <div className="fm-sanitisation-statistics-daily">
            { daily.map(this.renderTrips) }
          </div>
          { recent.length > 0 && <p className="fm-sanitisation-statistics-title">Recent Sanitisations</p> }
          <div className="fm-sanitisation-statistics-recent">
            { recent.slice(0, 10).map(this.renderSanitisation) }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }

}

export default SanitisationStatistics;
