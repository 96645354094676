import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class InspectionView extends Component {
  constructor() {
    super();
    this.state = { inspection: { items: [] }, loading: true };

    this.loadInspection = this.loadInspection.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    document.title = 'View Inspection | Flamingo Admin';

    this.loadInspection();
  }

  /* ----- NETWORKING ----- */

  loadInspection() {
    return api.get(`/inspection/${this.props.match.params.inspectionId}`)
      .then((res) => this.setState({ inspection: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ----- RENDERS ----- */

  renderItem(item, i) {
    return (
      <div className="fm-inspection-view-item" key={i}>
        <div className="fm-inspection-view-item-header">
          <p className="fm-inspection-view-item-name">{ item.name }</p>
          {
            item.type === 'GRADE' &&
            <p className={`fm-inspection-view-item-grade fm-inspection-view-item-grade-${item.value.toLowerCase()}`}>{ item.value }</p>
          }
        </div>
        <p className="fm-inspection-view-item-description">{ item.description }</p>
        { item.directive && <p className="fm-inspection-view-item-directive">{ item.directive }</p> }
        {
          item.type === 'PHOTO' &&
          <img className="fm-inspection-view-item-photo" alt="Vehicle" src={item.value} />
        }
      </div>
    );
  }

  render() {
    const { inspection, loading, error } = this.state;

    const title = inspection.vehicle ? `${inspection.vehicle.registration} Inspection` : 'View Inspection';
    const subtitle = inspection.completedAt ? moment(inspection.completedAt).format('D/M/YY') : undefined;

    return (
      <div className="fm-inspection-view">
        <NavigationBar title={title} subtitle={subtitle} loading={loading} showBack={true} />
        <div className="fm-inspection-view-content">
          <div className="fm-inspection-view-header">
            <div className="fm-inspection-view-header-top">
              { inspection.result && <p className={`fm-inspection-view-result fm-inspection-view-result-${inspection.result.toLowerCase()}`}>{ inspection.result }</p> }
              { inspection.completedAt && <p className="fm-inspection-view-completed">{ moment(inspection.completedAt).format('D/M/YY') }</p> }
            </div>
            { inspection.notes && <p className="fm-inspection-view-notes">{ inspection.notes }</p> }
            { inspection.user && <p className="fm-inspection-view-user">{ inspection.user.firstName } { inspection.user.lastName }</p> }
          </div>
          <div className="fm-inspection-view-items">
            { inspection.items.map(this.renderItem) }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default InspectionView;
