import React from 'react';

class Dots extends React.Component {
  componentDidUpdate(prevProps) {
    if ((this.props.map !== prevProps.map) || (this.props.dots.length !== prevProps.dots.length)) {
      if (this.dots) {
        this.dots.map((dot) => dot.setMap(null));
      }
      this.renderDots();
    }
  }

  componentWillUnmount() {
    if (this.dots) {
      this.dots.map((dot) => dot.setMap(null));
    }
  }

  renderDots() {
    const { map, google, dots } = this.props;
    const url = dots.length < 500 ? 'https://storage.googleapis.com/flamingo-static/images/admin/dot30.png' : 'https://storage.googleapis.com/flamingo-static/images/admin/dot15.png';
    const icon = { url, size: new window.google.maps.Size(5, 5), scaledSize: new window.google.maps.Size(5, 5) };
    this.dots = dots.map((pos) => new google.maps.Marker({ map, icon, position: { lat: pos.latitude || pos[0], lng: pos.longitude || pos[1] } }));
  }

  render() {
    return null;
  }
}

export default Dots;
