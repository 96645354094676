import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Circle } from 'components/common/map';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsLocation extends Component {
  constructor(props) {
    super(props);
    const weekAgo = moment().subtract(1, 'week').format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    this.state = {
      start: weekAgo,
      end: today,
      today: today,
      radius: 50,
      vehicleType: 'ALL',
      hubs: [],
    };

    this.loadData = this.loadData.bind(this);
    this.loadHubs = this.loadHubs.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleRadiusChange = this.handleRadiusChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.title = 'Location Performance | Flamingo Admin';
    this.loadHubs();
  }

  /* NETWORKING */

  loadData() {
    const { radius, latitude, longitude, start, end, vehicleType } = this.state;
    const data = { radius, latitude, longitude, start, end };
    if (!latitude || !longitude) {
      return this.setState({ error: 'Select a location on the map first' });
    }
    if (vehicleType !== 'ALL') {
      data.vehicleType = vehicleType;
    }
    this.setState({ loading: true, error: false });
    return api.post('/analytics/location', data)
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  loadHubs() {
    return api.get('/hub')
      .then((res) => this.setState({ hubs: res.data.data }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* HANDLERS */

  handleRadiusChange(e) {
    this.setState({ radius: parseInt(e.target.value) });
  }

  handleStartChange(e) {
    this.setState({ start: e.target.value });
  }

  handleEndChange(e) {
    this.setState({ end: e.target.value });
  }

  handleTypeChange(e) {
    this.setState({ vehicleType: e.target.value });
  }

  handleClick(latitude, longitude) {
    this.setState({ latitude, longitude });
  }

  /* RENDERS */

  render() {
    const { loading, latitude, longitude, radius, start, end, today, vehicleType, data, hubs } = this.state;

    return (
      <div className="fm-analytics-location">
        <NavigationBar title="Location Performance" loading={loading} />
        <div className="fm-analytics-location-content">
          <Map google={window.google} onClick={this.handleClick} isGlobal hideLocation>
            { hubs.map((hub, i) => <Circle position={{ lat: hub.latitude, lng: hub.longitude }} radius={20} strokeWeight={0} clickable={true} fillColor={'#FBC531'} fillOpacity={0.5} onClick={() => this.handleClick(hub.latitude, hub.longitude)} />) }
            { (latitude && longitude) && <Circle position={{ lat: latitude, lng: longitude }} radius={radius} /> }
          </Map>
          <div className="fm-analytics-location-controls">
            {
              data ? (
                <div className="fm-analytics-location-data">
                  <div className="fm-analytics-location-data-row">
                    <p className="fm-analytics-location-data-label">Average Wait Duration:</p>
                    <p className="fm-analytics-location-data-value">{ (data.duration / 60).toFixed(1) } hours</p>
                  </div>
                  <div className="fm-analytics-location-data-row">
                    <p className="fm-analytics-location-data-label">Trip Starts:</p>
                    <p className="fm-analytics-location-data-value">{ data.tripStartSum }</p>
                  </div>
                  <div className="fm-analytics-location-data-row">
                    <p className="fm-analytics-location-data-label">Team Deployments:</p>
                    <p className="fm-analytics-location-data-value">{ data.dropOffSum }</p>
                  </div>
                  <div className="fm-analytics-location-data-row">
                    <p className="fm-analytics-location-data-label">Total Available:</p>
                    <p className="fm-analytics-location-data-value">{ data.availableSum }</p>
                  </div>
                  <p className="fm-analytics-location-empty">Total Available includes trip ends and team deployments.</p>
                </div>
              ) : (
                <p className="fm-analytics-location-empty">Click on the map to select a location.</p>
              )
            }
            <div className="fm-analytics-location-form">
              <div className="fm-analytics-location-input">
                <p className="fm-input-label">Radius ({ radius }m)</p>
                <input className="fm-input-slider" type="range" min="10" max="750" step="10" value={radius} onChange={this.handleRadiusChange} />
              </div>
              <div className="fm-analytics-location-input">
                <p className="fm-input-label">Start Date</p>
                <input value={start} className="fm-input" type="date" onChange={this.handleStartChange} max={today} />
              </div>
              <div className="fm-analytics-location-input">
                <p className="fm-input-label">End Date</p>
                <input value={end} className="fm-input" type="date" onChange={this.handleEndChange} max={today} />
              </div>
              <div className="fm-analytics-location-input">
                <p className="fm-input-label">Vehicle Types</p>
                <select className="fm-input fm-select" onChange={this.handleTypeChange} value={vehicleType}>
                  <option value="ALL">All</option>
                  <option value="BIKE">Bikes</option>
                  <option value="SCOOTER">Scooters</option>
                </select>
              </div>
              <button className="fm-analytics-location-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
            </div>
          </div>
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsLocation;
