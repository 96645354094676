import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import FileUploader from 'components/common/file-uploader';
import Map, { Marker, Polygon } from 'components/common/map';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import history from 'helpers/history';
import api from 'helpers/api';
import colors from 'helpers/colors';

const hubNotes = [
  `Ensure the hub does not obstruct the footpath.`,
  `If the hub is near a bike rack, ensure the photo and instructions are clear not to deploy in the bike rack. Next to a bike rack is suitable.`,
];

class HubAdd extends Component {
  constructor() {
    super();
    const hubDefaultTargetString = localStorage.getItem('hubDefaultTarget');
    const hubDefaultTarget = hubDefaultTargetString ? parseInt(hubDefaultTargetString) : 4;
    this.state = {
      hub: {
        targets: [
          { day: 'monday', vehicleType: 'scooter', target: hubDefaultTarget },
          { day: 'tuesday', vehicleType: 'scooter', target: hubDefaultTarget },
          { day: 'wednesday', vehicleType: 'scooter', target: hubDefaultTarget },
          { day: 'thursday', vehicleType: 'scooter', target: hubDefaultTarget },
          { day: 'friday', vehicleType: 'scooter', target: hubDefaultTarget },
          { day: 'saturday', vehicleType: 'scooter', target: hubDefaultTarget },
          { day: 'sunday', vehicleType: 'scooter', target: hubDefaultTarget },
        ],
        sort: 10 ,
      },
      regions: [],
      saving: false,
    };

    this.loadRegions = this.loadRegions.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoto = this.handlePhoto.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleGeocode = this.handleGeocode.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.handleTargetRemove = this.handleTargetRemove.bind(this);
    this.handleTargetAdd = this.handleTargetAdd.bind(this);
    this.renderTarget = this.renderTarget.bind(this);
  }

  componentDidMount() {
    document.title = 'Add Hub | Flamingo Admin';
    this.loadRegions();
  }

  loadRegions() {
    return api.get('/region/service-area')
      .then((res) => this.setState({ regions: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleSave() {
    this.setState({ saving: true });
    return api.post('/hub/add', this.state.hub)
      .then((res) => history.push(`/hub/${res.data.data.id}`))
      .catch(this.handleError);
  }

  handleChange(e) {
    const { hub } = this.state;
    const name = e.target.getAttribute('hefi');
    hub[name] = ['target', 'sort'].includes(name) ? parseInt(e.target.value) : e.target.value;
    this.setState({ hub, error: false });
  }

  handleTargetChange(e) {
    const { hub } = this.state;
    const key = e.target.getAttribute('tk');
    const index = parseInt(e.target.getAttribute('ti'));
    if (key === 'target') {
      localStorage.setItem('hubDefaultTarget', parseInt(e.target.value));
    }
    hub.targets[index][key] = key === 'target' ? parseInt(e.target.value) : e.target.value;
    this.setState({ hub, error: false });
  }

  handleTargetRemove(index) {
    const { hub } = this.state;
    hub.targets.splice(index, 1);
    this.setState({ hub, error: false });
  }

  handleTargetAdd() {
    const { hub } = this.state;
    hub.targets.push({ day: 'monday', vehicleType: 'scooter', target: 10 });
    this.setState({ hub, error: false });
  }

  handlePhoto(file) {
    const { hub } = this.state;
    hub.photoUrl = file.url;
    this.setState({ hub });
  }

  handleMapClick(latitude, longitude) {
    const { hub } = this.state;
    hub.latitude = latitude;
    hub.longitude = longitude;
    this.setState({ hub }, () => this.handleGeocode(latitude, longitude));
  }

  handleGeocode(latitude, longitude) {
    const { hub } = this.state;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: new window.google.maps.LatLng(latitude, longitude) }, (results, status) => {
      if (results[0]) {
        hub.address = results[0]['formatted_address'];
        this.setState({ hub });
      }
    });
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? e.response.data.validation[0] : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, saving: false });
  }

  renderTarget(target, i) {
    return (
      <div className="fm-hub-add-input-target" key={i}>
        <select className="fm-input fm-input-select" tk="day" ti={i} value={target.day} onChange={this.handleTargetChange}>
          <option value="monday">Monday</option>
          <option value="tuesday">Tuesday</option>
          <option value="wednesday">Wednesday</option>
          <option value="thursday">Thursday</option>
          <option value="friday">Friday</option>
          <option value="saturday">Saturday</option>
          <option value="sunday">Sunday</option>
        </select>
        <select className="fm-input fm-input-select" tk="vehicleType" ti={i} value={target.vehicleType} onChange={this.handleTargetChange}>
          <option value="scooter">Scooter</option>
          <option value="bike">Bike</option>
        </select>
        <input type="number" value={target.target} className="fm-input" tk="target" ti={i} onChange={this.handleTargetChange} />
        <div className="fm-hub-add-input-target-delete" onClick={() => this.handleTargetRemove(i)}><Icon icon="trash" /></div>
      </div>
    );
  }

  render() {
    const { saving, hub, regions, error } = this.state;

    return (
      <div className="fm-hub-add">
        <NavigationBar title="Add Hub" loading={saving} showBack={true} />
        <div className="fm-hub-add-content">
          <div className="fm-hub-add-left">
            <div className="fm-hub-add-map">
              <Map google={window.google} onClick={this.handleMapClick} isGlobal>
                { (hub.latitude && hub.longitude) && <Marker position={{ lat: hub.latitude, lng: hub.longitude }} title="Hub Location" /> }
                { regions.map((region, i) => <Polygon key={i} paths={ region.serviceArea.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} clickable={false} />) }
              </Map>
            </div>
            <ul className="fm-hub-add-notes">
              { hubNotes.map((note, i) => <li key={i} className="fm-hub-add-note">{ note }</li>)}
            </ul>
          </div>
          <div className="fm-hub-add-form">
            <div className="fm-hub-add-input-row">
              <div className="fm-hub-add-input">
                <p className="fm-input-label">Name</p>
                <input type="text" value={hub.name || ''} disabled={saving} placeholder="Eg. Example Park" className="fm-input" hefi="name" onChange={this.handleChange} />
              </div>
            </div>
            <div className="fm-hub-add-input-row">
              <div className="fm-hub-add-input">
                <p className="fm-input-label">Address</p>
                <input type="text" value={hub.address || ''} disabled={saving} placeholder="Eg. 123 Example Street, Mount Victoria" className="fm-input" hefi="address" onChange={this.handleChange} />
              </div>
            </div>
            <div className="fm-hub-add-input-row">
              <div className="fm-hub-add-input">
                <p className="fm-input-label">Deploy Instructions</p>
                <input type="text" value={hub.description || ''} disabled={saving} placeholder="Eg. Place scooters next to the bench without blocking the path." className="fm-input" hefi="description" onChange={this.handleChange} />
              </div>
            </div>
            <div className="fm-hub-add-input-row">
              <div className="fm-hub-add-input">
                <p className="fm-input-label">Sort</p>
                <input type="number" value={hub.sort || ''} disabled={saving} className="fm-input" hefi="sort" onChange={this.handleChange} />
                <p className="fm-hub-add-input-note">The lower the number the higher up the list. Hubs with the same sort number will be sorted alphabetically.</p>
              </div>
            </div>
            <div className="fm-hub-add-input-row">
              <div className="fm-hub-add-input">
                <p className="fm-input-label">Targets</p>
                { hub.targets.map(this.renderTarget) }
                <button className="fm-hub-add-input-target-add" onClick={this.handleTargetAdd}>Add Target</button>
              </div>
            </div>
            <FileUploader accept="images" type="hub" onFinish={this.handlePhoto} />
            <button className="fm-hub-add-button" onClick={this.handleSave} disabled={saving}>{ saving ? 'Adding...' : 'Add Hub' }</button>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default HubAdd;
