import React from 'react';

class PopUp extends React.Component {
  constructor() {
    super();
    this.background = React.createRef();
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
  }

  handleBackgroundClick(e) {
    if (e.target === this.background.current) {
      if (this.props.onDismiss) {
        this.props.onDismiss();
      }
    }
  }

  render() {
    return (
      <div className={ this.props.active ? 'fm-popup fm-popup-active' : 'fm-popup' } ref={this.background} onClick={this.handleBackgroundClick}>
        <div className="fm-popup-box">
          { this.props.title && <p className="fm-popup-title">{ this.props.title }</p> }
          { this.props.description && <p className="fm-popup-description">{ this.props.description }</p> }
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default PopUp;
