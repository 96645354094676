import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
// import FileUploader from 'components/common/file-uploader';
// import history from 'helpers/history';
// import api from 'helpers/api';

class ReportInterference extends Component {
  constructor(props) {
    super(props);
    this.state = { notes: '', fileProgress: 100, loading: false };

    this.handleReport = this.handleReport.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleFileProgress = this.handleFileProgress.bind(this);
    this.onFile = this.onFile.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Interference | Flamingo Admin';
  }

  loadZones() {
    // return api.get('/zone/area?active=true&type[]=LOWSPEEDNOPARKING&type[]=NOPARKING&type[]=NOHIRE&type[]=NORIDING')
    //   .then((res) => this.setState({ zones: res.data.data, loading: false }))
    //   .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  handleReport() {
    return alert('Feature Unavailable');
    // const tripId = this.props.match.params.tripId;
    // const tripReportTypeId = this.props.match.params.typeId;
    // const { notes, zone, photoUrl, fileProgress, loading } = this.state;
    //
    // if (loading) {
    //   return;
    // }
    //
    // if (fileProgress < 100) {
    //   return window.alert('Please wait, your photo is still uploading.');
    // }
    //
    // this.setState({ loading: true });
    // const data = { tripReportTypeId, photoUrl, tripId };
    // if (notes.length > 0) {
    //   data.notes = notes;
    // }
    // if (zone > 0) {
    //   data.zoneId = zone;
    // }
    // api.post(`/report`, data)
    //   .then((res) => alert(`Report created successfully.`))
    //   .then(() => history.go(-3))
    //   .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  handleNotesChange(e) {
    const notes = e.target.value;
    this.setState({ notes });
  }

  handleZoneChange(e) {
    const zone = e.target.value;
    this.setState({ zone });
  }

  onFile(file) {
    this.setState({ photoUrl: file.url });
  }

  handleFileProgress(fileProgress) {
    this.setState({ fileProgress });
  }

  render() {
    const { loading } = this.state;
    // const { loading, notes } = this.state;
    return (
      <div className="fm-report-interference">
        <NavigationBar title="Report Interference" loading={loading} showBack={true} />
        <div className="fm-report-interference-content">
          <p className="fm-report-interference-info">This feature is unavailable.</p>
          {
            /*
            <p className="fm-report-interference-info">Use this form to report interference with scooters and bikes. This includes graffiti and unauthorised movement of scooters/bikes. Notes and images are required.</p>
            <input type="text" autoFocus={true} className="fm-input" value={notes} onChange={this.handleNotesChange} placeholder="Notes" />
            <FileUploader accept="images" type="report" resize={1800} onFinish={this.onFile} onProgress={this.handleFileProgress} />
            <button className="fm-report-interference-button" onClick={this.handleReport}>{ loading ? 'Loading...' : 'Submit Report' }</button>
            */
          }
        </div>
      </div>
    );
  }
}

export default ReportInterference;
