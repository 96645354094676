import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import api from 'helpers/api';

class TaskAssign extends Component {
  constructor() {
    super();
    this.state = { users: [], assignedTo: 0, loading: true };

    this.loadUsers = this.loadUsers.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
  }

  componentDidMount() {
    document.title = 'Assign Task | Flamingo Admin';
    this.loadUsers();
  }

  loadUsers() {
    return api.get('/user/team')
      .then((res) => this.setState({ loading: false, users: [{ label: 'Unassigned', value: 0 }].concat(res.data.data.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.id }))) }))
      .catch((e) => alert(e.toString()));
  }

  handleUserChange(e) {
    const assignedTo = e.target.value;
    this.setState({ assignedTo });
  }

  handleAssign() {
    const { assignedTo } = this.state;
    this.setState({ loading: true });
    return api.post(`/task/${this.props.match.params.taskId}/assign`, { assignedTo })
      .then(() => history.goBack())
      .catch((e) => {
        this.setState({ loading: false });
        alert(e.toString());
      });
  }

  render() {
    const { loading, users, assignedTo } = this.state;

    return (
      <div className="fm-task">
        <NavigationBar title="Assign Task" loading={loading} showBack={true} />
        <div className="fm-task-assign">
          <select className="fm-input fm-input-select" value={assignedTo} onChange={this.handleUserChange}>
            { users.map((user, i) => <option key={i} value={user.value}>{user.label}</option>) }
          </select>
          <button className="fm-task-new-button" onClick={this.handleAssign}>{ loading ? 'Loading...' : 'Assign Task' }</button>
        </div>
      </div>
    );
  }
}

export default TaskAssign;
