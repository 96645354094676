import React, { Component } from 'react';
import LoadingLine from 'components/common/loading-line';
import Input from 'components/common/input';
import api from 'helpers/api';
import colors from 'helpers/colors';

const filterOptions = ['AVAILABLE', 'UNAVAILABLE', 'MAINTENANCE'];

class TaskNewSearch extends Component {
  constructor() {
    super();
    this.state = { results: [], query: '', searching: false };

    this.renderResult = this.renderResult.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSearchChange(query) {
    this.setState({ searching: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ searching: false, results: [] });
      return;
    }
    api.post('/vehicle/search', { query, limit: 5 })
      .then((res) => this.setState({ results: res.data.data.filter((v) => filterOptions.includes(v.status)), searching: false }))
      .catch(console.log);
  }

  handleSelect(vehicle) {
    this.props.onAdd(vehicle);
      this.setState({ searching: false, query: '' });
  }

  renderResult(vehicle, i) {
    return (
      <div key={i} className="fm-task-new-search-result" onClick={() => this.handleSelect(vehicle)}>
        <p className="fm-task-new-search-result-registration">{ vehicle.registration }</p>
        <p className="fm-task-new-search-result-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status }</p>
      </div>
    );
  }

  render() {
    const { query, results, searching } = this.state;
    return (
      <div className="fm-task-new-search">
        <Input
          placeholder="Search"
          inputMode="numeric"
          value={this.state.query}
          onChange={this.handleSearchChange} />
        <LoadingLine hide={!searching} />
        <div className="">
          { (!searching && results.length === 0 && query.length > 0) && <p className="fm-vehicle-finder-results-empty">No vehicles found</p> }
          { results.map(this.renderResult) }
        </div>
      </div>
    );
  }
}

export default TaskNewSearch;
