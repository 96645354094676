import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import LoadingLine from 'components/common/loading-line';
import Map, { Marker } from 'components/common/map';
import moment from 'moment';
import api from 'helpers/api';
import history from 'helpers/history';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icon = { url: baseUrl + 'task-helmet.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) };

class TaskHelmetMap extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], loading: true };

    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
  }

  componentDidMount() {
    document.title = 'Helmet Map | Flamingo Admin';
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng());
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude) {
    const endpoint = `/helmet/lock/map?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}`;
    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude };
    return api.get(endpoint)
      .then((res) => this.setState({ vehicles: res.data.data, currentBounds, loading: false }))
      .catch(console.log);
  }

  /* ------ Renders ------ */

  render() {
    const { vehicles, loading } = this.state;

    return (
      <div className="fm-helmet-map">
        <NavigationBar title="Helmet Map" showBack={true} />
        <div className="fm-helmet-map-holder">
          <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { vehicles.map((vehicle, i) => <Marker key={i} position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={`${vehicle.registration} - ${moment(vehicle.helmetLock.lastUpdated).fromNow()}`} icon={icon} onClick={() => history.push({ pathname: `/helmet-lock/${vehicle.helmetLock.id}`, state: { helmetLock: vehicle.helmetLock } })} />) }
          </Map>
        </div>
        <LoadingLine hide={!loading} />
      </div>
    );
  }
}

export default TaskHelmetMap;
