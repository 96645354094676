import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';
import colors from 'helpers/colors';

class TaskRoute extends Component {
  constructor() {
    super();
    this.state = { tasks: [], loading: true };

    this.loadTasks = this.loadTasks.bind(this);
    this.handleTaskResponse = this.handleTaskResponse.bind(this);
    this.renderTask = this.renderTask.bind(this);
    this.handleDirections = this.handleDirections.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleBatteryCover = this.handleBatteryCover.bind(this);
  }

  componentDidMount() {
    document.title = `Task Route | Flamingo Admin`;
    this.loadTasks();
  }

  loadTasks() {
    this.setState({ loading: true });
    const taskIds = this.props.match.params.taskIds.split(',');
    return api.post('/task/multiple', { taskIds })
      .then(this.handleTaskResponse)
      .catch(console.log);
  }

  handleTaskResponse(res) {
    const taskObj = res.data.data.reduce((t, c) => {
      t[c.id] = c;
      return t;
    }, {});
    const taskIds = this.props.match.params.taskIds.split(',');
    const tasks = taskIds.map((id) => taskObj[id]).filter((t) => t.status === 'available');
    this.setState({ tasks, loading: false });
  }

  handleDirections() {
    const { tasks, loading } = this.state;
    if (loading || tasks.length < 2) {
      return;
    }
    const finalTask = tasks.pop();
    const waypoints = `&waypoints=${tasks.map((t) => `${t.vehicle.latitude},${t.vehicle.longitude}`).join('|')}`;
    const destination = `&destination=${finalTask.vehicle.latitude},${finalTask.vehicle.longitude}`;
    const url = `https://www.google.com/maps/dir/?api=1&${waypoints + destination}`;
    window.open(url, '_blank');
  }

  handleStatus(task, status) {
    this.setState({ loading: true });
    return api.post('/vehicle/' + task.vehicle.id + '/status', { status })
      .then((res) => this.setState({ loading: false }))
      .catch(console.log);
  }

  handleBatteryCover(task) {
    this.setState({ loading: true });
    return api.post('/vehicle/' + task.vehicle.id + '/battery-cover')
      .then((res) => this.setState({ loading: false }))
      .catch(console.log);
  }

  renderTaskActions(task) {
    if (task.type === 'maintenance') {
      return (
        <div className="fm-task-route-item-actions">
          <button className="fm-task-route-item-actions-button" onClick={() => this.handleStatus(task, 'REPAIR')}>REPAIR</button>
        </div>
      );
    }
    if (task.type === 'batterySwap') {
      return (
        <div className="fm-task-route-item-actions">
          <button className="fm-task-route-item-actions-button" onClick={() => this.handleStatus(task, 'TRANSIT')}>PICKUP</button>
          <button className="fm-task-route-item-actions-button" onClick={() => this.handleBatteryCover(task)}>BATTERY COVER</button>
          <button className="fm-task-route-item-actions-button" onClick={() => this.handleStatus(task, 'AVAILABLE')}>AVAILABLE</button>
        </div>
      );
    }
    return (
      <div className="fm-task-route-item-actions">
        <button className="fm-task-route-item-actions-button" onClick={() => this.handleStatus(task, 'TRANSIT')}>PICKUP</button>
      </div>
    );
  }

  renderTask(task, i) {
    return (
      <div className="fm-task-route-item" key={i}>
        <Link className="fm-task-route-item-header" to={`/vehicle/${task.vehicle.id}`}>
          <p className="fm-task-route-item-rego">{ task.vehicle.registration }</p>
          <p className="fm-task-route-item-type" style={{ backgroundColor: colors.task[task.type] }}>{ task.type.toUpperCase() }</p>
        </Link>
        { i === 0 && this.renderTaskActions(task) }
      </div>
    );
  }

  render() {
    const { tasks, loading } = this.state;
    const refreshAction = { onClick: this.loadTasks, icon: 'arrow-right' };
    const navigationAction = { onClick: this.handleDirections, icon: 'directions' };

    return (
      <div className="fm-task-route">
        <NavigationBar title="Task Route" loading={loading} showBack={true} rightActions={[navigationAction, refreshAction]} />
        <div className="fm-task-route-items">
          { tasks.map(this.renderTask) }
        </div>
      </div>
    );
  }
}

export default TaskRoute;
