import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import ParkingReview from './components/review';
import ParkingOffence from './components/offence';
import ParkingOverview from './components/overview';

class Parking extends Component {
  render() {
    return (
      <Switch>
        <Route path="/parking/review/:tripId?" component={ParkingReview} />
        <Route path="/parking/offence" component={ParkingOffence} />
        <Route path="/parking" component={ParkingOverview} />
      </Switch>
    );
  }
}

export default Parking;
