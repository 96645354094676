import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loading from 'components/common/loading';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';
import history from 'helpers/history';

const icon = { url: 'https://storage.googleapis.com/flamingo-static/images/admin/task-maintenance.png', size: new window.google.maps.Size(31.5, 29.5), scaledSize: new window.google.maps.Size(31.5, 29.5) };

class InspectionMap extends Component {
  constructor() {
    super();
    this.state = { mapVehicles: [], loading: true, currentLocation: { lat: -41.288170, lng: 174.778952 }, loadingLocation: true };
    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
  }

  componentDidMount() {
    document.title = 'Inspection Map | Flamingo Admin';
    this.loadLocation();
  }

  loadLocation() {
    if (navigator.geolocation) {
      setTimeout(() => this.setState({ loadingLocation: false }), 3000);
      navigator.geolocation.getCurrentPosition(this.handleLocation);
    } else {
      this.setState({ loadingLocation: false });
    }
  }

  handleLocation(location) {
    if (location.coords) {
      const currentLocation = { lat: location.coords.latitude, lng: location.coords.longitude };
      this.setState({ currentLocation, loadingLocation: false });
    } else {
      this.setState({ loadingLocation: false });
    }
  }

  handleMapBoundsChange(ne, sw, zoom) {
    const endpoint = `/inspection/area?neLatitude=${ne.lat()}&neLongitude=${ne.lng()}&swLatitude=${sw.lat()}&swLongitude=${sw.lng()}&zoom=${zoom}`;
    api.get(endpoint)
      .then((res) => this.setState({ mapVehicles: res.data.data }))
      .catch(console.log);
  }

  render() {
    const mapOptions = { center: this.state.currentLocation, zoom: 16 };
    return (
      <div className="fm-page fm-inspection-schedule">
        <p className="fm-page-title">Inspection Map</p>
        <div className="fm-page-actions">
          <Link className="fm-page-action" to="/maintenance">Maintenance</Link>
          <Link className="fm-page-action" to="/maintenance/schedule">Inspection Schedule</Link>
        </div>
        <div className="fm-tasks-map">
          {
            this.state.loadingLocation ? (
              <Loading />
            ) : (
              <Map options={mapOptions} google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
                { this.state.mapVehicles.map((vehicle, i) => <Marker key={i} position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={`${ vehicle.registration } - ${ moment().diff(vehicle.lastInspection, 'days') + ' days ago' }`} icon={icon} onClick={() => history.push('/vehicle/' + vehicle.id)} opacity={ vehicle.overdue ? 1 : 0.3 } />) }
              </Map>
            )
          }
        </div>
      </div>
    );
  }
}

export default InspectionMap;
