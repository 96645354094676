import React from 'react';
import Icon from '../icon';
import api from 'helpers/api';

const acceptTypes = {
  images: 'image/x-png,image/png,image/jpeg,image/gif',
  pdfs: 'application/pdf',
  documents: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf',
  imagesdocuments: 'image/x-png,image/png,image/jpeg,image/gif,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf',
};

class FileUploader extends React.Component {
  constructor() {
    super();
    const isAndroid = window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
    this.state = { uploaded: false, uploading: false, percent: 0, isAndroid };

    this.handleChange = this.handleChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleChange(e) {
    if (!e.target.files[0]) return;
    this.setState({ uploaded: false, uploading: true, percent: 0 });
    // Check if it needs to be resized
    if (this.props.resize) {
      return this.handleResize(e.target.files[0], this.props.resize, this.props.resize);
    }
    return this.handleUpload(e.target.files[0]);
  }

  handleResize(file, maxWidth = 1800, maxHeight = 1800) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = (event) => {

        var width = img.width;
        var height = img.height;

        // Change the resizing logic
        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        // Add to canvas
        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Convert to blob
        canvas.toBlob((blob) => {
          // Only use the resize if its smaller
          if (blob.size < file.size) {
            return this.handleUpload(blob);
          }
          return this.handleUpload(file);
        });
      }

      img.src = e.target.result;
    }

    reader.readAsDataURL(file);
  }

  handleUpload(file) {
    if (this.props.onStart) {
      this.props.onStart();
    }
    // Create the form data
    const data = new FormData();
    data.append('file', file);
    data.append('type', this.props.type);
    // Start the upload
    return api.post('/file/upload', data, { onUploadProgress: this.handleProgress }, true, true)
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  handleProgress(e) {
    const percent = (e.loaded / e.total * 100);
    this.setState({ percent });
    if (this.props.onProgress) {
      this.props.onProgress(percent);
    }
  }

  handleSuccess(res) {
    this.setState({ uploaded: true, uploading: false, percent: 0, previewUrl: (this.props.accept === 'images' ? res.data.data.url : undefined) });
    if (this.props.onFinish) {
      this.props.onFinish(res.data.data);
    }
  }

  handleError(e) {
    this.setState({ uploaded: false, uploading: false, percent: 0 });
    if (this.props.onError) {
      this.props.onError(e);
    }
  }

  render() {
    const { isAndroid, uploaded, previewUrl, percent, uploading } = this.state;
    const { accept, label, disabled } = this.state;
    const className = isAndroid ? 'ff-file ff-file-isandroid' : 'ff-file';
    return (
      <div className={className}>
        { label && <label className="ff-file-label">{ label }</label> }
        <div className={ 'ff-file-container' + (uploaded ? ' ff-file-container-uploaded' : '') }>
          <div className="ff-file-preview" style={{ backgroundImage: previewUrl ? `url(${previewUrl})` : undefined }}></div>
          <p className="ff-file-text">{ uploaded ? (accept === 'images' ? 'Image Uploaded' : 'File Uploaded') : (uploading ? 'Uploading...' : (accept === 'images' ? 'Select Image' : 'Select File')) }</p>
          <div className="ff-file-progress" style={{ width: (percent + '%') }}></div>
          <input className="ff-file-input" type="file" accept={acceptTypes[accept]} onChange={this.handleChange} disabled={uploading || disabled} />
          {
            isAndroid &&
            <div className="ff-file-android">
              <Icon icon="camera" />
              <input className="ff-file-android-input" type="file" capture="capture" accept={acceptTypes[accept]} onChange={this.handleChange} disabled={uploading || disabled} />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default FileUploader;
