import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Input from 'components/common/input';
import PopUp from 'components/common/pop-up';
import Loading from 'components/common/loading';
import api from 'helpers/api';
import user from 'helpers/user';
import history from 'helpers/history';
import colors from 'helpers/colors';
import getTemplates from 'helpers/templates';

class Ticket extends Component {
  constructor() {
    super();
    this.state = { loading: true, assignPopUp: false, ticket: {}, user: {}, staff: [], templates: [], response: '' };

    this.loadTicket = this.loadTicket.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleResolveAndSend = this.handleResolveAndSend.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleAssignUser = this.handleAssignUser.bind(this);
    this.handleResponseChange = this.handleResponseChange.bind(this);
    this.handleTemplate = this.handleTemplate.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleAdjustVoucher = this.handleAdjustVoucher.bind(this);
    this.adjustVoucher = this.adjustVoucher.bind(this);
  }

  componentDidMount() {
    document.title = 'Support Ticket | Flamingo Admin';
    this.loadTemplates();
    this.loadTicket(this.props.match.params.ticketId);
    user.getCurrentUser().then((user) => this.setState({ user }));
  }

  loadTicket(ticketId) {
    return api.get(`/support/ticket/${ ticketId }`)
      .then((res) => this.setState({ ticket: res.data.data, loading: false }))
      .catch(console.log);
  }

  loadTemplates() {
    return getTemplates()
      .then((templates) => this.setState({ templates }))
      .catch(console.log);
  }

  handleTemplate(e) {
    const template = this.state.templates[e.target.value];
    const recipient = this.state.ticket.name.split(' ')[0];
    const agent = this.state.user.firstName || 'Flamingo';
    const cost = this.state.ticket.associatedRide ? (this.state.ticket.associatedRide.actualCost / 100).toFixed(2) : '0.00';
    const vehicle = this.state.ticket.associatedVehicle ? this.state.ticket.associatedVehicle.type.toLowerCase() : 'scooter';
    const startDate = moment(this.state.ticket.associatedRide ? this.state.ticket.associatedRide.startTime : undefined);
    const startTime = moment(this.state.ticket.associatedRide ? this.state.ticket.associatedRide.startTime : undefined).format('h:mma');
    const endTime = moment(this.state.ticket.associatedRide ? this.state.ticket.associatedRide.endTime : undefined).format('h:mma');
    const daysAgo = moment().endOf('day').diff(startDate, 'days');
    const date = daysAgo === 0 ? 'today' : (daysAgo === 1 ? 'yesterday' : `on the ${startDate.format('Do of MMMM')}`);
    const response = template.content.replace('{{recipient}}', recipient).replace('{{agent}}', agent).replace('{{cost}}', cost).replace('{{date}}', date).replace('{{startTime}}', startTime).replace('{{endTime}}', endTime).replace(/\{\{vehicle\}\}/g, vehicle);
    this.setState({ response })
  }

  handleResolve(e) {
    const autoLoadNextTicket = e.shiftKey;
    this.setState({ loading: true });
    api.post(`/support/ticket/${ this.state.ticket.id }/resolve`)
      .then((res) => (autoLoadNextTicket ? api.get(`/support/tickets/unassigned/next?currentId=${ this.state.ticket.id }`) : Promise.resolve(res)))
      .then((res) => this.setState({ ticket: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleSend() {
    this.setState({ loading: true });
    const response = this.state.response;
    api.post(`/support/ticket/${ this.state.ticket.id }/respond`, { response, resolve: false })
      .then((res) => this.setState({ ticket: res.data.data, loading: false, response: '' }))
      .catch(console.log);
  }

  handleNote() {
    this.setState({ loading: true });
    const note = this.state.response;
    api.post(`/support/ticket/${ this.state.ticket.id }/note`, { note })
      .then((res) => this.setState({ ticket: res.data.data, loading: false, response: '' }))
      .catch(console.log);
  }

  handleResolveAndSend(e) {
    const autoLoadNextTicket = e.shiftKey;
    this.setState({ loading: true });
    const response = this.state.response;
    api.post(`/support/ticket/${ this.state.ticket.id }/respond`, { response, resolve: true })
      .then((res) => (autoLoadNextTicket ? api.get(`/support/tickets/unassigned/next?currentId=${ this.state.ticket.id }`) : Promise.resolve(res)))
      .then((res) => this.setState({ ticket: res.data.data, loading: false, response: '' }))
      .catch(console.log);
  }

  handleAssign() {
    this.setState({ assignPopUp: true, saving: this.state.staff.length === 0 });
    if (this.state.staff.length === 0) {
      api.get('/support/staff')
        .then((res) => this.setState({ saving: false, staff: res.data.data.map((s) => ({ label: `${s.firstName} ${s.lastName}`, value: s.id })) }))
        .catch(console.log);
    }
  }

  handleAssignUser(assignedTo) {
    this.setState({ saving: true });
    api.post(`/support/ticket/${ this.state.ticket.id }/assign`, { assignedTo })
      .then((res) => this.setState({ ticket: res.data.data, saving: false, assignPopUp: false }))
      .catch(console.log);
  }

  handleDelete() {
    if (window.confirm('Are you sure you want to delete this ticket? This action is irreversible.')) {
      this.setState({ loading: true });
      api.post(`/support/ticket/${ this.state.ticket.id }/delete`)
        .then(() => history.goBack())
        .catch(console.log);
    }
  }

  handleAdjustVoucher() {
    const adjustmentString = window.prompt(`Enter the amount to adjust the user's voucher credit by. Use a number followed by the letter C for credit, M for minutes or U for unlocks. For example to add $5 credit type 5C. To subtract 10 minutes type -10M.`, '');
    if (adjustmentString != null) {
      let adjustmentType = adjustmentString.substr(adjustmentString.length - 1).toLowerCase();
      let adjustmentAmount = parseFloat(adjustmentString.slice(0, -1));

      if (!adjustmentAmount) {
        return alert('Invalid adjustment amount.')
      }

      if (!['c', 'm', 'u'].includes(adjustmentType)) {
        return alert('Invalid adjustment type. Please use the letter C for credit, M for minutes or U for unlocks.')
      }

      let adjustmentSummary = '';
      switch (adjustmentType) {
        case 'm':
          adjustmentSummary = adjustmentAmount > 0 ? `add ${adjustmentAmount} minutes to` : `remove ${adjustmentAmount * -1} minutes from`;
          adjustmentType = 'MINUTES';
          break;
        case 'c':
          adjustmentSummary = adjustmentAmount > 0 ? `add $${adjustmentAmount} credit to` : `remove $${adjustmentAmount * -1} credit from`;
          adjustmentAmount = parseInt((adjustmentAmount * 100).toFixed());
          adjustmentType = 'CREDIT';
          break;
        case 'u':
          adjustmentSummary = adjustmentAmount > 0 ? `add ${adjustmentAmount} unlocks to` : `remove ${adjustmentAmount * -1} unlocks from`;
          adjustmentType = 'UNLOCKS';
          break;
        default:
          break;
      }

      let adjustmentConfirm = `Are you sure you want to ${adjustmentSummary} this user?`;
      if (window.confirm(adjustmentConfirm)) {
        return this.adjustVoucher(adjustmentType, adjustmentAmount);
      }
    }
  }

  adjustVoucher(type, value) {
    return api.post(`/user/${this.state.ticket.associatedUserId}/adjust-vouchers`, { type, value })
      .then(() => alert('Voucher added!'))
      .catch(() => alert('Error adding voucher!'));
  }

  handleResponseChange(response) {
    this.setState({ response });
  }

  renderItem(item, key) {
    if (item.type === 'TEXT') {
      return (
        <div className={`fm-ticket-item ${ item.source === 'EXTERNAL' ? 'fm-ticket-item-external' : 'fm-ticket-item-internal' }`} key={key}>
          <p className="fm-ticket-item-content">{ item.content }</p>
          <p className="fm-ticket-item-date">{ (item.user ? item.user.firstName : 'User') } - { moment(item.createdAt).fromNow() }</p>
        </div>
      );
    } else if (item.type === 'NOTE') {
      return (
        <div className={`fm-ticket-item fm-ticket-item-note`} key={key}>
          <p className="fm-ticket-item-content">{ item.content }</p>
          <p className="fm-ticket-item-date">{ 'Note - ' + (item.user ? item.user.firstName : 'User') + ' - ' + moment(item.createdAt).fromNow() }</p>
        </div>
      );
    } else if (item.type === 'IMAGE') {
      return (
        <div className={`fm-ticket-item ${ item.source === 'EXTERNAL' ? 'fm-ticket-item-external' : 'fm-ticket-item-internal' }`} key={key}>
          <p className="fm-ticket-item-content"><img alt="Reported content" src={ item.content } /></p>
          <p className="fm-ticket-item-date">{ item.user.firstName } - { moment(item.createdAt).fromNow() }</p>
        </div>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const ticket = this.state.ticket;
    const status = ticket.assignedTo ? (ticket.resolvedAt ? 'resolved' : 'open') : 'unassigned';
    return (
      <div className="fm-page fm-ticket">
        <p className="fm-page-title">{ ticket.subject }</p>
        <div className="fm-page-actions">
          <span className="fm-page-action" onClick={() => history.goBack()}>Back</span>
          { ticket.associatedUserId && <Link className="fm-page-action" to={ '/user/' + ticket.associatedUserId }>View User</Link> }
          { ticket.associatedRideId && <Link className="fm-page-action" to={{ pathname: `/trip/${ticket.associatedRideId}`, state: { trip: ticket.associatedRide } }}>View Trip</Link> }
          { ticket.associatedVehicleId && <Link className="fm-page-action" to={ '/vehicle/' + ticket.associatedVehicleId }>View Vehicle</Link> }
          { !ticket.assignedTo && <span className="fm-page-action" onClick={this.handleAssign}>Assign User</span> }
          { ticket.assignedUser && <span className="fm-page-action" onClick={this.handleAssign}>{ `Assigned to ${ticket.assignedUser.firstName} ${ticket.assignedUser.lastName}` }</span> }
          { ticket.resolvedBy && <Link className="fm-page-action" to={ '/user/' + ticket.resolvedBy }>Resolved at { moment(ticket.resolvedAt).format('D/M/YY') }</Link> }
          { ticket.associatedRideId && <Link className="fm-page-action" to={ '/incident/new/trip/' + ticket.associatedRideId }>Create Incident</Link> }
          { ticket.associatedUserId && <span className="fm-page-action" onClick={this.handleAdjustVoucher}>Add Voucher</span> }
        </div>
        <div className="fm-ticket-content">
          <div className="fm-ticket-top">
            <p className="fm-ticket-top-status" style={{ backgroundColor: colors.ticket[status] }}>{ status.toUpperCase() }</p>
            <p className="fm-ticket-top-type" style={{ backgroundColor: colors.support[ticket.source] }}>{ ticket.source }</p>
            { (ticket.otherTickets && ticket.otherTickets > 0) ? <p className="fm-ticket-top-open">{ ticket.otherTickets + (ticket.resolvedAt ? 0 : 1) } OPEN TICKETS</p> : null }
            <p className="fm-ticket-top-created">{ moment(ticket.createdAt).format('h:mma D/M/YY') }</p>
          </div>
          <div className="fm-ticket-boxes">
            <div className="fm-ticket-box">
              <p className="fm-ticket-box-title">{ ticket.name || 'Unknown Name' }</p>
              { ticket.phone && <Link className="fm-ticket-box-detail" to={`/call/${ticket.phone}`}>{ ticket.phone }</Link> }
              { ticket.email && <a className="fm-ticket-box-detail" href={`mailto:${ticket.email}`}>{ ticket.email }</a> }
            </div>
            { ticket.associatedVehicle &&
              <div className="fm-ticket-box">
                <p className="fm-ticket-box-title">Scooter { ticket.associatedVehicle.registration }</p>
                <p className="fm-ticket-box-detail">Status: { ticket.associatedVehicle.status }</p>
                <p className="fm-ticket-box-detail">Last Activity: { moment(ticket.associatedVehicle.lastActivity).fromNow() }</p>
              </div>
            }
            { ticket.associatedRide &&
              <div className="fm-ticket-box">
                <p className="fm-ticket-box-title">Trip at { moment(ticket.associatedRide.startTime).format('h:mma D/M/YY') }</p>
                <p className="fm-ticket-box-detail">Duration: { (ticket.associatedRide.duration / 60).toFixed() } mins</p>
                <p className="fm-ticket-box-detail">Cost: ${ (ticket.associatedRide.actualCost ? (ticket.associatedRide.actualCost / 100).toFixed(2) : '0.00') }</p>
              </div>
            }
          </div>
          { (ticket.associatedUser && ticket.associatedUser.notes) && <p className="fm-ticket-notes">Notes: { ticket.associatedUser.notes }</p> }
        </div>
        <div className="fm-ticket-items">
          { this.state.ticket.items.map(this.renderItem) }
        </div>
        <div className="fm-ticket-reply">
          <Input type="textarea" value={this.state.response} onChange={this.handleResponseChange} />
          <div className="fm-ticket-reply-actions">
            { !ticket.resolvedAt && <span onClick={this.handleResolve} className="fm-ticket-reply-action fm-ticket-reply-action-r" title="Hold Shift to load the next ticket.">Resolve</span> }
            <span onClick={this.handleNote} className="fm-ticket-reply-action fm-ticket-reply-action-n">Post as Note</span>
            <span className="fm-ticket-reply-actions-spacer"></span>
            { this.state.ticket.email &&
              <>
                <span className="fm-ticket-reply-actions-input">
                  <div className={'fm-input-container'}>
                    <select onChange={this.handleTemplate} defaultValue="" className="fm-input fm-input-select">
                      <option value="" disabled hidden>Select Template</option>
                      { this.state.templates.map((template, i) => <option key={i} value={i}>{template.name}</option>) }
                    </select>
                  </div>
                </span>
                { !ticket.resolvedAt && <span onClick={this.handleResolveAndSend} className="fm-ticket-reply-action fm-ticket-reply-action-rs" title="Hold Shift to load the next ticket.">Resolve & Send</span> }
                <span onClick={this.handleSend} className="fm-ticket-reply-action fm-ticket-reply-action-s">Send</span>
              </>
            }
          </div>
        </div>
        <PopUp active={this.state.assignPopUp} title="Assign User" description="Select the staff member to be assigned to this ticket." onDismiss={() => this.setState({ assignPopUp: false })}>
          <div className="fm-support-on-call-popup">
            <Input type="select" value={this.state.ticket.assignedTo} onChange={this.handleAssignUser} options={this.state.staff} disabled={this.state.saving} defaultOption="Select User" />
          </div>
          <span className="fm-vehicle-popup-cancel-button" onClick={() => this.setState({ assignPopUp: false })}>Cancel</span>
        </PopUp>
      </div>
    );
  }
}

export default Ticket;
