class DeleteMenu extends window.google.maps.OverlayView {
  constructor() {
    super();
    this.div_ = document.createElement('div');
    this.div_.className = 'fm-map-delete-menu';
    this.div_.innerHTML = 'Delete';

    const menu = this;

    this.div_.addEventListener('click', () => {
      menu.removeVertex()
    });
  }

  onAdd() {
    const map = this.getMap();
    const deleteMenu = this;

    this.getPanes().floatPane.appendChild(this.div_);

    this.divListener_ = (e) => {
      if (e.target !== deleteMenu.div_) {
        deleteMenu.close();
      }
    };

    map.getDiv().addEventListener('mousedown', this.divListener_, true);
  }

  onRemove() {
    if (this.divListener_) {
      this.div_.removeEventListener('mousedown', this.divListener_);
    }

    (this.div_.parentNode).removeChild(this.div_);

    this.set('position', null);
    this.set('path', null);
    this.set('vertex', null);
  }

  close() {
    this.setMap(null);
  }

  draw() {
    const position = this.get('position');
    const projection = this.getProjection();

    if (!position || !projection) {
      return;
    }

    const point = projection.fromLatLngToDivPixel(position);

    this.div_.style.top = point.y + 'px';
    this.div_.style.left = point.x + 'px';
  }

  open(map, path, vertex) {
    this.set('position', path.getAt(vertex));
    this.set('path', path);
    this.set('vertex', vertex);
    this.setMap(map);
    this.draw();
  }

  removeVertex() {
    const path = this.get('path');
    const vertex = this.get('vertex');

    if (!path || vertex === undefined) {
      this.close();
      return;
    }

    path.removeAt(vertex);
    this.close();
  }
}

export default DeleteMenu;
