import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const shades = [
  '#E8E7EA',
  '#2ecc71',
  '#8acc2e',
  '#a1cc2e',
  '#e0e73c',
  '#e7a63c',
  '#e74c3c',
];

const shadeSwitch = (duration) => {
  if (duration === 0) { return shades[0]; }
  if (duration < 2) { return shades[1]; }
  if (duration < 6) { return shades[2]; }
  if (duration < 10) { return shades[3]; }
  if (duration < 14) { return shades[4]; }
  if (duration < 18) { return shades[5]; }
  return shades[6];
};

class AnalyticsHubs extends Component {
  constructor(props) {
    super(props);
    const today = moment().format('YYYY-MM-DD')
    this.state = { weekEnding: today, weeks: 1, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.parseData = this.parseData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleWeeksChange = this.handleWeeksChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Hub Analytics | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const { weeks, weekEnding } = this.state;
    return api.post('/analytics/hubs', { weeks, weekEnding })
      .then(this.parseData)
      .catch(this.handleError);
  }

  parseData(res) {
    const { data } = res.data;
    const statistics = data.reduce((statistics, hub) => {
      statistics.totalAvailable += hub.totalAvailable;
      statistics.totalDeployed += hub.totalDeployed;
      statistics.totalTripEnds += hub.totalTripEnds;
      statistics.totalTripStarts += hub.totalTripStarts;
      return statistics;
    }, { totalAvailable: 0, totalDeployed: 0, totalTripEnds: 0, totalTripStarts: 0 });
    this.setState({ data, statistics, loading: false });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleWeeksChange(e) {
    this.setState({ weeks: e.target.value });
  }

  handleDateChange(e) {
    this.setState({ weekEnding: e.target.value });
  }

  renderHub(analytics, i) {
    const averageWaitDuration = analytics.averageWaitDuration || 0;
    return (
      <div className="fm-analytics-hubs-item" key={i}>
        <div className="fm-analytics-hubs-item-header">
          <Link className="fm-analytics-hubs-item-name" to={{ pathname: `/hub/${analytics.hub.id}`, state: { hub: analytics.hub } }}>{ analytics.hub.name }</Link>
          <div className="fm-analytics-hubs-item-result">
            <p className="fm-analytics-hubs-item-ratio">{ analytics.totalAvailable === 0 ? 0 : (analytics.totalTripStarts / analytics.totalAvailable * 100).toFixed(0) }%</p>
            <p className="fm-analytics-hubs-item-hours" style={{ backgroundColor: shadeSwitch(averageWaitDuration) }}>{ averageWaitDuration === 0 ? 'N/A' : `${averageWaitDuration.toFixed(1)}H` }</p>
          </div>
        </div>
        <div className="fm-analytics-hubs-item-content">
          <p className="fm-analytics-hubs-item-address">{ analytics.hub.address } ({ analytics.hub.deployRadius }m)</p>
          <div className="fm-analytics-hubs-item-stats">
            <p className="fm-analytics-hubs-item-stat">Trip Starts: { analytics.totalTripStarts }</p>
            <span className="fm-analytics-hubs-item-stat-spacer"></span>
            <p className="fm-analytics-hubs-item-stat">Trip Ends: { analytics.totalTripEnds }</p>
            <span className="fm-analytics-hubs-item-stat-spacer"></span>
            <p className="fm-analytics-hubs-item-stat">Deployed: { analytics.totalDeployed }</p>
            <span className="fm-analytics-hubs-item-stat-spacer"></span>
            <p className="fm-analytics-hubs-item-stat">Total Available: { analytics.totalAvailable }</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, data, statistics, weeks, weekEnding, today } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="Hub Analytics" loading={loading} />
        <div className="fm-analytics-hubs">
          <div className="fm-analytics-hubs-form">
            <select value={weeks} className="fm-input fm-input-select" onChange={this.handleWeeksChange}>
              <option value="1">Week Ending</option>
              <option value="2">Fortnight Ending</option>
            </select>
            <input value={weekEnding} className="fm-input" type="date" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-hubs-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            statistics &&
            <div className="fm-analytics-hubs-statistics">
              <p className="fm-analytics-hubs-statistics-item">Total Trip Starts: { statistics.totalTripStarts || 0 }</p>
              <p className="fm-analytics-hubs-statistics-item">Total Trip Ends: { statistics.totalTripEnds || 0 }</p>
              <p className="fm-analytics-hubs-statistics-item">Total Deployed: { statistics.totalDeployed || 0 }</p>
            </div>
          }
          {
            data ? (
              <div className="fm-analytics-hubs-list">
                { data.map(this.renderHub) }
              </div>
            ) : (
              <p className="fm-analytics-hubs-empty">Select a period above.</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsHubs;
