import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import colors from 'helpers/colors';

class SanitisationSchedule extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], loading: true };

    this.loadSchedule = this.loadSchedule.bind(this);
    this.renderVehicle = this.renderVehicle.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Sanitisation Schedule | Flamingo Admin';
    localStorage.setItem('fm-sanitisation', 'schedule');
    this.loadSchedule();
  }

  loadSchedule() {
    this.setState({ loading: true });
    api.get('/sanitisation')
      .then((res) => this.setState({ vehicles: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Renders ------ */

  renderVehicle(vehicle, i) {
    return (
      <Link className="fm-sanitisation-schedule-item" key={i} to={{ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } }}>
        <p className="fm-sanitisation-schedule-item-rego">{ vehicle.registration }</p>
        <p className="fm-sanitisation-schedule-item-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status.toUpperCase() }</p>
        <p className="fm-sanitisation-schedule-item-time">{ vehicle.count } { vehicle.count === 1 ? 'trip' : 'trips' } ({ moment(vehicle.lastSanitisation).fromNow(true) })</p>
      </Link>
    );
  }

  render() {
    const { loading, vehicles, error } = this.state;
    const actions = [
      { onClick: this.loadSchedule, icon: 'sync-alt' },
      { to: '/sanitisation/statistics', icon: 'chart-bar' },
      { to: '/sanitisation/map', icon: 'map-marked' },
      { to: '/sanitisation/scanner', icon: 'qrcode' },
    ];

    return (
      <div className="fm-sanitisation-schedule">
        <NavigationBar title="Sanitisation Schedule" loading={loading} rightActions={actions} />
        { vehicles.map(this.renderVehicle) }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SanitisationSchedule;
