import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker } from 'components/common/map';
import moment from 'moment';
import api from 'helpers/api';
import history from 'helpers/history';

class TaskAlerts extends Component {
  constructor() {
    super();
    this.state = { alerts: [], minutes: 10, refreshSeconds: 20, loading: true };
    this.refreshTimeout = null;

    this.loadAlerts = this.loadAlerts.bind(this);
    this.setTimer = this.setTimer.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    document.title = `Vehicle Alerts | Flamingo Admin`;
    this.loadAlerts();
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
  }

  /* ------ NETWORKING ------ */

  loadAlerts() {
    const { minutes } = this.state;
    this.setState({ loading: true });
    return api.get(`/task/alerts?minutes=${minutes}`)
      .then((res) => this.setState({ alerts: res.data.data, loading: false }, this.setTimer))
      .catch(this.handleError);
  }

  setTimer() {
    const { refreshSeconds } = this.state;
    this.refreshTimeout = setTimeout(this.loadAlerts, refreshSeconds * 1000);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Renders ------ */

  renderMarker(alert, i) {
    const { minutes } = this.state;
    const position = { lat: alert.latitude, lng: alert.longitude };
    const title = `${alert.vehicle.registration} - ${alert.alert} - ${moment(alert.occurredAt).fromNow()}`;
    const onClick = () => history.push({ pathname: `/vehicle/${alert.vehicle.id}`, state: { vehicle: alert.vehicle } });
    const opacity = (minutes - moment().diff(alert.occurredAt, 'minutes')) / minutes;
    return <Marker key={i} position={position} opacity={opacity} title={title} onClick={onClick} />;
  }

  render() {
    const { alerts, error, loading } = this.state;

    return (
      <div className="fm-task-alerts">
        <NavigationBar title="Vehicle Alerts" subtitle={ loading ? 'Refreshing...' : 'Last 10 mins' } loading={loading} showBack={true} />
        <div className="fm-task-alerts-map">
          {
            error ? (
              <p className="fm-task-alerts-error">Error: { error }</p>
            ) : (
              <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
                { alerts.map(this.renderMarker) }
              </Map>
            )
          }
        </div>
      </div>
    );
  }
}

export default TaskAlerts;
