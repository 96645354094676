import React from 'react';

class Circle extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.renderCircle();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map || this.props.position.lat !== prevProps.position.lat || this.props.position.lng !== prevProps.position.lng || this.props.radius !== prevProps.radius) {
      if (this.circle) {
        this.circle.setMap(null);
      }
      this.renderCircle();
    }
  }

  componentWillUnmount() {
    if (this.circle) {
      this.circle.setMap(null);
    }
  }

  renderCircle() {
    const { map, google, position, strokeColor, strokeOpacity, strokeWeight, fillColor, fillOpacity, radius, clickable } = this.props;
    const circleData = {
      center: position,
      radius: radius || 50,
      strokeColor: strokeColor || '#ff206e',
      strokeOpacity,
      strokeWeight,
      fillColor: fillColor || '#ff206e',
      fillOpacity,
      clickable: clickable || false,
      map,
    };
    this.circle = new google.maps.Circle(circleData);
    this.circle.addListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e.domEvent);
    }
  }

  render() {
    return null;
  }
}

export default Circle;
