import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class OnCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: [],
      schedule: [],
      selectedUser: null,
      onCall: false,
      loading: true,
      scheduledChange: moment().format('YYYY-MM-DDTHH:mm'),
    };

    this.loadOnCall = this.loadOnCall.bind(this);
    this.loadOnCallSchedule = this.loadOnCallSchedule.bind(this);
    this.loadStaff = this.loadStaff.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSchedule = this.handleSchedule.bind(this);
    this.handleNow = this.handleNow.bind(this);
    this.selectUser = this.selectUser.bind(this);

    this.renderUser = this.renderUser.bind(this);
    this.renderChange = this.renderChange.bind(this);
  }

  componentDidMount() {
    document.title = 'On Call | Flamingo Admin';
    this.loadOnCall();
    this.loadStaff();
    this.loadOnCallSchedule();
  }

  /* Networking */

  loadOnCall() {
    api.get('/support/on-call')
      .then((res) => this.setState({ onCall: res.data.data }))
      .catch(this.handleError);
  }

  loadOnCallSchedule() {
    api.get('/support/on-call/schedule')
      .then((res) => this.setState({ schedule: res.data.data }))
      .catch(this.handleError);
  }

  loadStaff() {
    api.get('/support/staff')
      .then((res) => this.setState({ staff: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* Controls */

  handleDateChange(e) {
    const scheduledChange = e.target.value;
    this.setState({ scheduledChange });
  }

  handleSchedule() {
    const { selectedUser, scheduledChange } = this.state;

    this.setState({ loading: true });
    api.post('/support/on-call', { userId: selectedUser, schedule: moment(scheduledChange).toISOString() })
      .then(this.loadOnCallSchedule)
      .then((res) => this.setState({ loading: false }))
      .catch(this.handleError);
  }

  handleNow() {
    const { selectedUser } = this.state;

    this.setState({ loading: true });
    api.post('/support/on-call', { userId: selectedUser })
      .then(this.loadOnCall)
      .then((res) => this.setState({ loading: false }))
      .catch(this.handleError);
  }

  selectUser(userId) {
    const { selectedUser, loading } = this.state;
    if (!loading) {
      this.setState({ selectedUser: selectedUser === userId ? null : userId })
    }
  }

  /* Renders */

  renderUser(user, i) {
    const { selectedUser } = this.state;
    return (
      <div className="fm-oncall-staff-result" key={i} onClick={() => this.selectUser(user.id)}>
        <p className="fm-oncall-staff-result-name">{ `${user.firstName} ${user.lastName}` }</p>
        <p className="fm-oncall-staff-result-phone">{ user.phone }</p>
        <p className="fm-oncall-staff-result-selected"><Icon icon={ selectedUser === user.id ? 'check-square' : 'square' } regular={true} /></p>
      </div>
    );
  }

  renderChange(change, i) {
    return (
      <div className="fm-oncall-schedule-change" key={i}>
        {
          change.changeUser ? (
            <>
              <p className="fm-oncall-schedule-change-name">{ `${change.changeUser.firstName} ${change.changeUser.lastName}` }</p>
              <p className="fm-oncall-schedule-change-phone">{ change.changeUser.phone }</p>
            </>
          ) : (
            <>
              <p className="fm-oncall-schedule-change-name">Voicemail</p>
            </>
          )
        }
        <p className="fm-oncall-schedule-change-date">{ moment(change.changeAt).format('h:mma D/M/YYYY') }</p>
      </div>
    );
  }

  render() {
    const { loading, staff, schedule, onCall, scheduledChange, error } = this.state;
    const onCallText = onCall ? (onCall.user ? `Now: ${onCall.user.firstName} ${onCall.user.lastName}` : 'Now: Voicemail') : 'Now: Loading...';

    return (
      <div className="fm-oncall">
        <NavigationBar title="On Call" subtitle={onCallText} showBack={true} loading={loading} />
        <div className="fm-oncall-content">
          <div className="fm-oncall-staff">
            { staff.map(this.renderUser) }
          </div>
          <div className="fm-oncall-date">
            <input className="fm-oncall-input" type="datetime-local" disabled={loading} value={scheduledChange} onChange={this.handleDateChange} />
          </div>
          <div className="fm-oncall-actions">
            <button className={`fm-oncall-action-now ${ loading ? 'fm-oncall-action-loading' : '' }`} disabled={loading} onClick={this.handleNow}>Change Now</button>
            <button className={`fm-oncall-action-schedule ${ loading ? 'fm-oncall-action-loading' : '' }`} disabled={loading} onClick={this.handleSchedule}>Schedule Change</button>
          </div>
          <div className="fm-vehicle-section-header">
            <p className="fm-vehicle-section-title">Scheduled Changes</p>
          </div>
          <div className="fm-oncall-schedule">
            { (schedule.length === 0 && !loading) && <p className="fm-oncall-schedule-empty">No scheduled changes.</p> }
            { schedule.map(this.renderChange) }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default OnCall;
