function parseCoordinates(coordinates) {
  const firstCoordinate = coordinates[0];
  for (var i = coordinates.length - 1; i >= 0; i--) {
    const coordinate = coordinates[i];
    if (firstCoordinate.latitude === coordinate.latitude && firstCoordinate.longitude === coordinate.longitude) {
      coordinates.pop();
    } else {
      break;
    }
  }
  return JSON.parse(JSON.stringify(coordinates));
}

export default parseCoordinates;
