import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import InspectionSchedule from './components/inspection-schedule/';
import InspectionMap from './components/inspection-map';
import MaintenanceSolution from './components/maintenance-solution/';
import MaintenanceGuide from './components/maintenance-guide/';
import MaintenanceGuides from './components/maintenance-guides/';

class Maintenance extends Component {
  render() {
    return (
      <Switch>
        <Route path="/maintenance/map" component={InspectionMap} />
        <Route path="/maintenance/schedule" component={InspectionSchedule} />
        <Route path="/maintenance/solution/:solutionId" component={MaintenanceSolution} />
        <Route path="/maintenance/guide/:guideId" component={MaintenanceGuide} />
        <Route path="/maintenance/guides/:model" component={MaintenanceGuides} />
      </Switch>
    );
  }
}

export default Maintenance;
