import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import UserApp from './components/user-app/';
import UserAccess from './components/user-access/';
import UserAreaView from './components/user-area-view/';
import UserEdit from './components/user-edit/';
import UserDetails from './components/user-details/';

class User extends Component {
  render() {
    return (
      <Switch>
        <Route path="/user/:userId/access" component={UserAccess} />
        <Route path="/user/:userId/app" component={UserApp} />
        <Route path="/user/:userId/area-view/:startTime/:endTime/:markerLatitude?/:markerLongitude?" component={UserAreaView} />
        <Route path="/user/:userId/edit" component={UserEdit} />
        <Route path="/user/:userId" component={UserDetails} />
      </Switch>
    );
  }
}

export default User;
