import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import RegionServiceAreaEdit from './components/region-service-area-edit/';

class Hub extends Component {
  render() {
    return (
      <Switch>
        <Route path="/region/:regionId/service-area/edit" component={RegionServiceAreaEdit} />
      </Switch>
    );
  }
}

export default Hub;
