import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import colors from 'helpers/colors';
import api from 'helpers/api';

class ReportPending extends Component {
  constructor(props) {
    super(props);
    this.state = { reports: [], loading: true };
    this.loadReports = this.loadReports.bind(this);
    this.renderReport = this.renderReport.bind(this);
  }

  componentDidMount() {
    document.title = 'Reports | Flamingo Admin';
    this.loadReports();
  }

  loadReports() {
    this.setState({ loading: true });
    let endpoint = `/report/pending`;
    if (this.props.match.params.category) {
      endpoint += `?category=${this.props.match.params.category}`;
    }
    return api.get(endpoint)
      .then((res) => this.setState({ reports: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderReport(report, i) {
    return (
      <Link to={{ pathname: `/report/${report.id}`, state: { report } }} key={i} className="fm-report-pending-item">
        <div className="fm-report-pending-item-header">
          <p className="fm-report-pending-item-title">{ report.type.title }</p>
          <p className="fm-report-pending-item-tag" style={{ backgroundColor: colors.report[report.type.category] }}>{ report.type.category.toUpperCase() }</p>
        </div>
        <div className="fm-report-pending-item-details">
          <p className="fm-report-pending-item-user">{ report.user.firstName } { report.user.lastName }</p>
          <p className="fm-report-pending-item-description">{ report.notes || report.type.description }</p>
          <p className="fm-report-pending-item-time">{ moment(report.trip.startTime).format('h:mma D/M/YY') }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, reports } = this.state;
    const refreshAction = { onClick: this.loadReports, icon: 'sync-alt' };

    return (
      <div className="fm-report-pending">
        <NavigationBar title="Reports" subtitle={ reports.length > 0 ? `${reports.length} Pending` : null } rightActions={[refreshAction]} loading={loading} />
        <div className="fm-report-pending-content">
          { reports.map(this.renderReport) }
          { (reports.length === 0 && !loading) && <p className="fm-report-pending-empty">No pending reports.</p> }
        </div>
      </div>
    );
  }
}

export default ReportPending;
