import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import colors from 'helpers/colors';

class SubscriptionUser extends Component {
  constructor(props) {
    super(props);
    const preloadedSubscription = props.location.state ? props.location.state.subscription : false;
    this.state = { subscription: preloadedSubscription || {}, loading: true, error: false };
    this.loadSubscription = this.loadSubscription.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAdjust = this.handleAdjust.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleReceipt = this.handleReceipt.bind(this);
    this.renderPayment = this.renderPayment.bind(this);
  }

  componentDidMount() {
    document.title = 'Subscription | Flamingo Admin';
    this.loadSubscription();
  }

  loadSubscription() {
    return api.get('/subscription/user/' + this.props.match.params.userSubscriptionId)
      .then((res) => this.setState({ subscription: { ...this.state.subscription, ...res.data.data }, loading: false }))
      .catch(this.handleError);
  }

  handleCancel() {
    if (window.confirm('Are you sure you want to cancel this subscription?')) {
      this.setState({ loading: true, error: false });
      return api.post('/subscription/' + this.props.match.params.userSubscriptionId + '/cancel')
        .then((res) => this.setState({ subscription: { ...this.state.subscription, ...res.data.data }, loading: false }))
        .catch(this.handleError);
    }
  }

  handleAdjust() {
    const adjustmentString = window.prompt(`Enter the days to adjust the user's subscription by. For example to add 1 day type 1. To subtract 1 day type -1.`, '');
    if (adjustmentString != null) {
      this.setState({ loading: true, error: false });
      const value = parseInt(adjustmentString);
      return api.post('/subscription/' + this.props.match.params.userSubscriptionId + '/adjust', { value })
        .then((res) => this.setState({ subscription: { ...this.state.subscription, ...res.data.data }, loading: false }))
        .catch(this.handleError);
    }
  }

  handleReceipt(payment) {
    const receipt = window.confirm('Would you like to send an email receipt? Press cancel to view this payment on Stripe.');
    if (receipt) {
      const email = prompt('Please enter an email to send the receipt to:', '');
      if (email !== null) {
        this.setState({ loading: true });
        return api.post(`/subscription/payment/${payment.id}/receipt`, { email })
          .then((res) => this.setState({ loading: false }, () => alert('Receipt sent!')))
          .catch(this.handleError);
      }
    } else {
      window.open(`https://dashboard.stripe.com/payments/${payment.stripePaymentId}`);
    }
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  renderPayment(payment, i) {
    return (
      <div key={i} onClick={() => this.handleReceipt(payment)} className="fm-user-tab-row fm-user-tab-row-pointer">
        <div className="fm-user-tab-row-content">
          <p className="fm-user-tab-row-tag" style={{ backgroundColor: colors.payment[payment.status] }}>{ payment.status.toUpperCase() }</p>
          <p className="fm-user-tab-row-title">{ moment(payment.createdAt).format('D/M/YY') }</p>
        </div>
        <p className="fm-user-tab-row-detail">${ (payment.cost / 100).toFixed(2) } { payment.currency } - { moment(payment.startDate).format('D/M/YY') } to { moment(payment.endDate).format('D/M/YY') }</p>
      </div>
    );
  }

  render() {
    const { loading, error, subscription } = this.state;

    const actions = [];
    if (['active', 'scheduled'].includes(subscription.status)) {
      actions.push({ onClick: this.handleAdjust, icon: 'calendar-plus' });
    }
    if (('active' === subscription.status && subscription.autoRenew) || 'scheduled' === subscription.status) {
      actions.push({ onClick: this.handleCancel, icon: 'stop-circle' });
    }

    const totalTerm = { daily: 'Daily', weekly: 'Weekly' };
    const remainingTerm = { daily: 'Today', weekly: 'This Week' };

    return (
      <div className="fm-subscription">
        <NavigationBar title={`Subscription ${subscription.id}`} loading={loading} showBack={true} rightActions={actions} />
        <div className="fm-subscription-user-content">
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-name">Go Pass { subscription.planName }</p>
            { subscription.status && <p className="fm-subscription-user-status" style={{ backgroundColor: colors.subscription[subscription.status] }}>{ subscription.status.toUpperCase() }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Start Date:</p>
            { subscription.startDate && <p className="fm-subscription-user-row-value">{ moment(subscription.startDate).format('h:mma D/M/YY') }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Expires:</p>
            { subscription.expires && <p className="fm-subscription-user-row-value">{ moment(subscription.expires).format('h:mma D/M/YY') }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Auto Renew:</p>
            { subscription.expires && <p className="fm-subscription-user-row-value">{ subscription.autoRenew ? 'On' : 'Off' }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Minutes Term:</p>
            { subscription.minutesTerm && <p className="fm-subscription-user-row-value">{ totalTerm[subscription.minutesTerm] }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">{ totalTerm[subscription.minutesTerm || 'daily'] } Minutes:</p>
            { subscription.minutes && <p className="fm-subscription-user-row-value">{ subscription.minutes === 1440 ? `Unlimited` : `${subscription.minutes} mins` }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Remaining Minutes { remainingTerm[subscription.minutesTerm || 'daily'] }:</p>
            { subscription.remainingMinutes && <p className="fm-subscription-user-row-value">{ subscription.remainingMinutes === 1440 ? `Unlimited` : `${subscription.remainingMinutes} mins` }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Days:</p>
            { subscription.plan && <p className="fm-subscription-user-row-value">{ subscription.days } days</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Cost:</p>
            { subscription.plan && <p className="fm-subscription-user-row-value">${ (subscription.cost / 100).toFixed(2) } { subscription.currency }</p> }
          </div>
          <div className="fm-subscription-user-row">
            <p className="fm-subscription-user-row-key">Max Ride Duration:</p>
            { subscription.maxDuration && <p className="fm-subscription-user-row-value">{ subscription.maxDuration } mins</p> }
          </div>
          <div className="fm-subscription-user-row">
            { subscription.terms && <p className="fm-subscription-user-row-terms">Terms: { subscription.terms }</p> }
          </div>
          <div className="fm-subscription-user-payments">
            <p className="fm-subscription-user-payments-title">Payments</p>
            <div className="fm-subscription-user-payments-container">
              { (subscription.payments || []).map(this.renderPayment) }
            </div>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SubscriptionUser;
