import React, { Component } from 'react';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  'pickup': { url: baseUrl + 'task-pickup.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
  'batterySwap': { url: baseUrl + 'task-battery-swap.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
  'rebalance': { url: baseUrl + 'task-rebalance.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
  'repark': { url: baseUrl + 'task-repark.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
  'charge': { url: baseUrl + 'task-charge.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
  'inspection': { url: baseUrl + 'task-inspection.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
  'helmet': { url: baseUrl + 'task-helmet.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) },
};

const filterTypes = ['pickup', 'charge', 'batterySwap', 'rebalance', 'repark', 'helmet'];

class TaskRouteNewMap extends Component {
  constructor() {
    super();
    this.state = { tasks: [], type: 'pickup', mode: 'search', types: [] };

    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    this.loadFilters();
  }

  /* ------ Filters ------ */

  loadFilters() {
    const filtersString = localStorage.getItem('taskFilters');
    if (filtersString) {
      const types = JSON.parse(filtersString);
      this.setState({ types });
    }
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), this.state.types);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, types) {
    let endpoint = `/task/map?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}`;

    if (types && types.length > 0 && types.length !== filterTypes.length) {
      endpoint += types.map((type) => `&type[]=${type}`).join('');
    }

    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude };
    return api.get(endpoint)
      .then((res) => this.setState({ tasks: res.data.data, currentBounds }))
      .catch(console.log);
  }

  handleSelect(vehicle) {
    this.props.onAdd(vehicle);
  }

  /* ------ Renders ------ */

  renderMarker(task, i) {
    const position = { lat: task.vehicle.latitude, lng: task.vehicle.longitude };
    const icon = icons[task.type];
    return (
      <Marker
        key={i}
        position={position}
        title={task.type.toUpperCase()}
        icon={icon}
        onClick={() => this.handleSelect(task)} />
    );
  }

  render() {
    const { selectedTasks } = this.props;
    const tasks = this.state.tasks.filter((task) => selectedTasks.findIndex((t) => t.id === task.id) < 0);
    return (
      <div className="fm-task-new-map">
        <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
          { tasks.map(this.renderMarker) }
        </Map>
      </div>
    );
  }
}

export default TaskRouteNewMap;
