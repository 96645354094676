import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class VoucherView extends Component {
  constructor(props) {
    super(props);
    const preloadedVoucher = props.location.state ? props.location.state.voucher : false;
    this.state = { voucher: preloadedVoucher || {}, loading: true, error: false };
    this.loadVoucher = this.loadVoucher.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Voucher | Flamingo Admin';
    this.loadVoucher();
  }

  loadVoucher() {
    return api.get(`/voucher/${this.props.match.params.voucherId}`)
      .then((res) => this.setState({ voucher: { ...this.state.voucher, ...res.data.data }, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  render() {
    const { loading, error, voucher } = this.state;

    const title = voucher.code || 'Voucher';
    const valueString = voucher.type === 'CREDIT' ? `$${ (voucher.value / 100).toFixed(2) }` : `${ voucher.value } ${ voucher.type === 'MINUTES' ? 'mins' : 'unlocks' }`;
    const hasExpired = moment().isAfter(voucher.expires);
    const maxedRedemptions = voucher.redemptions >= voucher.maxRedemptions;
    const status = voucher.id ? (maxedRedemptions ? 'Max Redemptions' : (hasExpired ? 'Expired' : 'Active')) : false;

    return (
      <div className="fm-voucher-view">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-voucher-view-content">
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Code:</p>
            { voucher.code && <p className="fm-voucher-view-row-value">{ voucher.code }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Status:</p>
            { status && <p className="fm-voucher-view-row-value" style={{ color: status === 'Active' ? '#4cd137' : '#e84118', fontWeight: 600 }}>{ status }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Value:</p>
            { voucher.value && <p className="fm-voucher-view-row-value">{ valueString }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Expires:</p>
            { voucher.expires && <p className="fm-voucher-view-row-value">{ moment(voucher.expires).format('h:mma D/M/YY') }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Redemption Duration:</p>
            { voucher.duration && <p className="fm-voucher-view-row-value">{ voucher.duration > 86400 ? `${parseInt(voucher.duration / 86400)} days` : `${parseInt(voucher.duration / 3600)} hours` }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Redemptions:</p>
            { voucher.duration && <p className="fm-voucher-view-row-value">{ voucher.redemptions }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Maximum Redemptions:</p>
            { voucher.duration && <p className="fm-voucher-view-row-value">{ voucher.maxRedemptions }</p> }
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Region:</p>
            <p className="fm-voucher-view-row-value">{ voucher.region ? voucher.region.name : 'All' }</p>
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Vehicle Type:</p>
            <p className="fm-voucher-view-row-value">{ voucher.vehicleType || 'All' }</p>
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">New Users Only:</p>
            <p className="fm-voucher-view-row-value">{ voucher.newUsersOnly ? 'Yes' : 'No' }</p>
          </div>
          <div className="fm-voucher-view-row">
            <p className="fm-voucher-view-row-key">Bypass Payment Method:</p>
            <p className="fm-voucher-view-row-value">{ voucher.bypassPaymentMethod ? 'Yes' : 'No' }</p>
          </div>
          <div className="fm-voucher-view-row">
            { voucher.notes && <p className="fm-voucher-view-row-terms">Notes: { voucher.notes }</p> }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default VoucherView;
