import React, { Component } from 'react';
import Toast from 'components/common/toast';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Radio from 'components/common/radio';
import RadioGroup from 'components/common/radio-group';
import FileUploader from 'components/common/file-uploader';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import api from 'helpers/api';

const statusOptions = [
  'AVAILABLE',
  'UNAVAILABLE',
];

class NestEdit extends Component {
  constructor() {
    super();
    this.state = { nest: {}, loading: true, submitting: false };

    this.loadNest = this.loadNest.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoto = this.handlePhoto.bind(this);
  }

  componentDidMount() {
    document.title = 'Edit Nest | Flamingo Admin';
    this.loadNest();
  }

  loadNest() {
    return api.get('/nest/' + this.props.match.params.nestId)
      .then((res) => this.setState({ nest: res.data.data, loading: false }))
      .catch((e) => this.setState({ error: this.handleError(e) }));
  }

  handleSave(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ submitting: true });
    const { nest } = this.state;
    api.post('/nest/' + this.props.match.params.nestId, nest)
      .then((res) => history.goBack())
      .catch((e) => this.setState({ submitting: false, error: this.handleError(e) }));
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    return window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
  }

  handleChange(key, value) {
    const nest = this.state.nest;
    nest[key] = value;
    this.setState({ nest });
  }

  handlePhoto(file) {
    const nest = this.state.nest;
    nest.photoUrl = file.url;
    this.setState({ nest });
  }

  render() {
    const { loading, nest } = this.state;

    return (
      <div className="fm-nest-edit">
        <NavigationBar title="Edit Nest" loading={loading} showBack={true} />
        {
          !loading &&
          <form onSubmit={this.handleSave} className="fm-nest-edit-form">
            <Input label="Address" placeholder="Eg. 226 Victoria Street, Te Aro" value={nest.address} onChange={(address) => this.handleChange('address', address)} disabled={this.state.submitting} />
            <Input label="Description" placeholder="Eg. Line up next to the fence." value={nest.description} onChange={(description) => this.handleChange('description', description)} disabled={this.state.submitting} />
            <RadioGroup label="Max Vehicles">
              <Radio label="2" onSelect={() => this.handleChange('maxCapacity', 2)} checked={ nest.maxCapacity === 2 } disabled={this.state.submitting} />
              <Radio label="4" onSelect={() => this.handleChange('maxCapacity', 4)} checked={ nest.maxCapacity === 4 } disabled={this.state.submitting} />
              <Radio label="6" onSelect={() => this.handleChange('maxCapacity', 6)} checked={ nest.maxCapacity === 6 } disabled={this.state.submitting} />
            </RadioGroup>
            <Input label="Status" type="select" defaultOption="Select Status" value={nest.status} options={statusOptions} onChange={(status) => this.handleChange('status', status)} disabled={this.state.submitting} />
            <FileUploader accept="images" type="nest" label="Change Photo (Optional)" onFinish={this.handlePhoto} />
            <Button loading={this.state.submitting}>Save Nest</Button>
          </form>
        }
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default NestEdit;
