import React, { Component } from 'react';
import moment from 'moment';
import Loading from 'components/common/loading';
import Toast from 'components/common/toast';
import PopUp from 'components/common/pop-up';
import Icon from 'components/common/icon';
import history from 'helpers/history';
import api from 'helpers/api';

class ParkingReview extends Component {
  constructor(props) {
    super(props);
    this.state = { rides: [], tripId: props.match.params.tripId, loading: true, badParkingOptions: false };

    this.loadRides = this.loadRides.bind(this);
    this.loadNextRide = this.loadNextRide.bind(this);

    this.handleAPI = this.handleAPI.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleBad = this.handleBad.bind(this);
    this.handleUnsure = this.handleUnsure.bind(this);
    this.handleGood = this.handleGood.bind(this);

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.cycleToGrade = this.cycleToGrade.bind(this);
  }

  componentDidMount() {
    document.title = 'Parking Review | Flamingo Admin';
    this.loadRides();
  }

  loadRides() {
    if (this.state.tripId) {
      return api.get(`/trip/${this.state.tripId}`)
        .then((res) => this.setState({ rides: [res.data.data], loading: false, updating: false }))
        .catch(this.handleError);
    }
    return api.get('/parking/review')
      .then((res) => this.setState({ rides: this.state.rides.concat(res.data.data), loading: false, updating: false }))
      .catch(this.handleError);
  }

  /* API Helpers */

  handleAPI(endpoint, data) {
    return api.post(endpoint, data)
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  handleResponse() {
    if (this.state.tripId) {
      console.log('aaa');
      history.goBack();
    }
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    const empty = window.access(() => err.response.status) && err.response.status === 404;
    this.setState({ error, empty, updating: false });
  }

  /* Controls */

  loadNextRide() {
    let rides = this.state.rides;
    const currentRide = rides.shift();
    this.setState({ rides, badParkingOptions: false });
    if (rides.length === 2 && !this.state.tripId) {
      this.loadRides();
    }
    return currentRide;
  }

  onKeyPressed(e) {
    e.preventDefault();
    // Check what key
    if (e.keyCode === 38 || e.keyCode === 40) {
      // Unsure
      this.handleUnsure();
    } else if (e.keyCode === 37) {
      // Bad
      this.setState({ badParkingOptions: true });
    } else if (e.keyCode === 39) {
      // Good
      this.handleGood();
    } else if (e.keyCode === 49 && this.state.badParkingOptions) {
      // Bad 1
      this.handleBadReason('BLOCKING_PATHWAY');
    } else if (e.keyCode === 50 && this.state.badParkingOptions) {
      // Bad 2
      this.handleBadReason('BLOCKING_ENTRANCE');
    } else if (e.keyCode === 51 && this.state.badParkingOptions) {
      // Bad 3
      this.handleBadReason('BLOCKING_EMERGENCY');
    } else if (e.keyCode === 52 && this.state.badParkingOptions) {
      // Bad 4
      this.handleBadReason('NOT_IN_PHOTO');
    } else if (e.keyCode === 53 && this.state.badParkingOptions) {
      // Bad 5
      this.handleBadReason('BAD_PARKING');
    } else if (e.keyCode === 54 && this.state.badParkingOptions) {
      // Bad
      this.handleBadReason('DUAL_KICKSTAND');
    }
  }

  handleBad() {
    this.setState({ badParkingOptions: true });
  }

  handleBadReason(reason) {
    const currentRide = this.loadNextRide();
    this.handleAPI('/parking/' + currentRide.id + '/review', { parkingReview: reason });
  }

  handleUnsure() {
    const currentRide = this.loadNextRide();
    this.handleAPI('/parking/' + currentRide.id + '/review', { parkingReview: 'INDETERMINABLE' });
  }

  handleGood() {
    const currentRide = this.loadNextRide();
    this.handleAPI('/parking/' + currentRide.id + '/review', { parkingReview: 'GOOD' });
  }

  /* Renders */

  cycleToGrade(cycle) {
    if (cycle === 0) {
      return 'First';
    } else if (cycle < 7) {
      return 'Poor';
    } else if (cycle < 30) {
      return 'Average';
    } else if (cycle < 60) {
      return 'Good';
    } else {
      return 'Great';
    }
  }

  render() {
    if (this.state.rides.length === 0 && !this.state.loading) {
      // Nothing to review
      return (
        <div className="fm-page fm-parking-offence">
          <p className="fm-page-title">Parking Review</p>
          <p className="fm-parking-offence-empty">No rides to review.</p>
        </div>
      );
    } else if (this.state.loading) {
      // Loading
      return (
        <div className="fm-page fm-parking-offence">
          <p className="fm-page-title">Parking Review</p>
          <Loading />
        </div>
      );
    }
    // Parking to review
    const ride = this.state.rides[0];
    return (
      <div className="fm-page fm-parking-offence" onKeyDown={this.onKeyPressed} tabIndex="0">
        <p className="fm-page-title">Parking Review</p>
        <div className="fm-parking-review-image" style={{ backgroundImage: `url('${ ride.parkingPhotoUrl }')`}}>
          {
            this.state.badParkingOptions && (
              <div className="fm-parking-review-options">
                <button className="fm-parking-review-option" onClick={() => this.handleBadReason('BLOCKING_PATHWAY')}><span className="fm-parking-review-option-number">1</span>Blocking pathway</button>
                <button className="fm-parking-review-option" onClick={() => this.handleBadReason('BLOCKING_ENTRANCE')}><span className="fm-parking-review-option-number">2</span>Blocking entrance</button>
                <button className="fm-parking-review-option" onClick={() => this.handleBadReason('BLOCKING_EMERGENCY')}><span className="fm-parking-review-option-number">3</span>Blocking emergency utilities</button>
                <button className="fm-parking-review-option" onClick={() => this.handleBadReason('NOT_IN_PHOTO')}><span className="fm-parking-review-option-number">4</span>Scooter not in photo</button>
                <button className="fm-parking-review-option" onClick={() => this.handleBadReason('BAD_PARKING')}><span className="fm-parking-review-option-number">5</span>Bad Parking</button>
                <button className="fm-parking-review-option" onClick={() => this.handleBadReason('DUAL_KICKSTAND')}><span className="fm-parking-review-option-number">6</span>Dual Kickstand</button>
              </div>
            )
          }
        </div>
        <div className="fm-parking-review-controls">
          <button className="fm-parking-review-control fm-parking-offence-control-bad" onClick={this.handleBad} title="Bad parking"><Icon icon="times" /></button>
          <button className="fm-parking-review-control fm-parking-offence-control-unsure" onClick={this.handleUnsure} title="Unable to determine"><Icon icon="question" /></button>
          <button className="fm-parking-review-control fm-parking-offence-control-good" onClick={this.handleGood} title="Good parking"><Icon icon="check" /></button>
        </div>
        <a className="fm-parking-review-open" href={`/trip/${ride.id}`} rel="noopener noreferrer" target="_blank">Open Trip in New Tab</a>
        <div className="fm-parking-review">
          <p>{ moment(ride.startTime).fromNow() }</p>
          <p>Grade: { this.cycleToGrade(ride.parkingReviewCycle) }</p>
        </div>
        { /* ------ Loading Pop Up ------ */ }
        <PopUp active={this.state.updating} title="Updating..." description="Please wait while the offence is updated." onDismiss={() => this.setState({ updating: false })}>
          <Loading />
        </PopUp>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default ParkingReview;
