import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import ZoneMap from './components/zone-map/';
import ZoneNew from './components/zone-new/';
import ZoneEdit from './components/zone-edit/';

class Zone extends Component {
  render() {
    return (
      <Switch>
        <Route path="/zone/:zoneId/edit" component={ZoneEdit} />
        <Route path="/zone/new" component={ZoneNew} />
        <Route path="/zone" component={ZoneMap} />
      </Switch>
    );
  }
}

export default Zone;
