import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AllStaff from './components/all-staff';
import AddStaff from './components/add-staff';
import StaffMember from './components/staff-member';
import StaffMap from './components/staff-map';

class Staff extends Component {
  render() {
    return (
      <Switch>
        <Route path="/staff/add" component={AddStaff} />
        <Route path="/staff/map" component={StaffMap} />
        <Route path="/staff/:id" component={StaffMember} />
        <Route path="/staff" component={AllStaff} />
      </Switch>
    );
  }
}

export default Staff;
