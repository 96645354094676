import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';
import colors from 'helpers/colors';

class TaskTransits extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], loading: true };

    this.loadVehicles = this.loadVehicles.bind(this);
    this.renderVehicle = this.renderVehicle.bind(this);
  }

  componentDidMount() {
    document.title = 'Transit Vehicles | Flamingo Admin';
    this.loadVehicles();
  }

  loadVehicles() {
    this.setState({ loading: true });
    return api.get('/task/transits')
      .then((res) => this.setState({ vehicles: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderVehicle(vehicle, i) {
    return (
      <Link className="fm-task-transits-item" key={i} to={`/vehicle/${vehicle.id}`}>
        <p className="fm-task-transits-item-rego">{ vehicle.registration }</p>
        <p className="fm-task-transits-item-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status.toUpperCase() }</p>
        <p className="fm-task-transits-item-time">{ moment(vehicle.lastActivity).fromNow() }</p>
      </Link>
    );
  }

  render() {
    const { loading, vehicles } = this.state;
    const refreshAction = { onClick: this.loadVehicles, icon: 'sync-alt' };

    return (
      <div className="fm-task">
        <NavigationBar title="Transit Street Vehicles" loading={loading} rightActions={[refreshAction]} showBack={true} />
        { vehicles.map(this.renderVehicle) }
      </div>
    );
  }
}

export default TaskTransits;
