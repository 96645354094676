import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Map, { Marker, Rectangle } from 'components/common/map';
import api from 'helpers/api';

class UserApp extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, appOpens: [], areaViews: [] };

    this.loadApp = this.loadApp.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
    this.renderArea = this.renderArea.bind(this);
  }

  componentDidMount() {
    document.title = `User App Activiy | Flamingo Admin`;
    this.loadApp();
  }

  /* NETWORKING */

  loadApp() {
    this.setState({ loading: true });
    return api.get(`/user/${this.props.match.params.userId}/app`)
      .then(this.handleData)
      .catch(this.handleError);
  }

  handleData(res) {
    const { appOpens, areaViews } = res.data.data;
    this.setState({ appOpens, areaViews, loading: false });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  renderLocation(location, i) {
    return <Marker key={i} label={{ text: `${i + 1}`, color: 'white' }} position={{ lat: location.latitude, lng: location.longitude }} title={location.createdAt} />;
  }

  renderArea(area, i) {
    const bounds = {
      north: area.neLatitude,
      east: area.neLongitude,
      south: area.swLatitude,
      west: area.swLongitude,
    };
    const fillOpacity = i === 0 ? 0.2 : (0.2 * (this.state.areaViews.length - i) / this.state.areaViews.length);
    const strokeWeight = i === 0 ? 3 : 0;
    return <Rectangle key={i} fillOpacity={fillOpacity} strokeWeight={strokeWeight} bounds={bounds} />;
  }

  renderLocationLabel(location, i) {
    return (
      <a className="fm-user-app-item" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${location.latitude},${location.longitude}`}>
        <p className="fm-user-app-item-label">{ i + 1 } - { moment(location.createdAt).format('h:mma D/MM/YY') }</p>
      </a>
    );
  }

  renderAreaLabel(area, i) {
    return (
      <div className="fm-user-app-item" key={i}>
        <p className="fm-user-app-item-label">{ i + 1 } - { moment(area.viewedAt).format('h:mma D/MM/YY') }</p>
      </div>
    );
  }

  /* RENDERS */

  render() {
    const { appOpens, areaViews, loading, error } = this.state;
    const refreshAction = { onClick: this.loadApp, icon: 'sync-alt' };
    const center = { lat: -41.29249605503776, lng: 174.7784299745886 };
    if (appOpens[0]) {
      center.lat = appOpens[0].latitude;
      center.lng = appOpens[0].longitude;
    }

    // Render
    return (
      <div className="fm-user-app">
        <NavigationBar title="App Activity" loading={loading} showBack={true} rightActions={[refreshAction]} />
        <div className="fm-user-app-map">
          <Map google={window.google} options={{ center, zoom: 14 }}>
            { appOpens.map(this.renderLocation) }
            { areaViews.map(this.renderArea) }
          </Map>
        </div>
        <div className="fm-user-app-items">
          <p className="fm-user-app-items-title">App Opens</p>
          { appOpens.map(this.renderLocationLabel) }
        </div>
        <div className="fm-user-app-items">
          <p className="fm-user-app-items-title">Area Views</p>
          { areaViews.map(this.renderAreaLabel) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default UserApp;
