import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import PerformanceActivity from './components/performance-activity/';
import PerformanceOverview from './components/performance-overview/';
import PerformanceOutOfHours from './components/performance-out-of-hours/';
import PerformanceTimeliness from './components/performance-timeliness/';
import PerformanceUser from './components/performance-user/';

class Performance extends Component {
  render() {
    return (
      <Switch>
        <Route path="/performance/timeliness" component={PerformanceTimeliness} />
        <Route path="/performance/out-of-hours" component={PerformanceOutOfHours} />
        <Route path="/performance/:userId/activity" component={PerformanceActivity} />
        <Route path="/performance/:userId" component={PerformanceUser} />
        <Route path="/performance" component={PerformanceOverview} />
      </Switch>
    );
  }
}

export default Performance;
