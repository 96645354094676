import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import FileUploader from 'components/common/file-uploader';
import Map, { Marker } from 'components/common/map';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class HubAdd extends Component {
  constructor() {
    super();
    this.state = { hub: { targets: [] }, loading: true, saving: false };

    this.loadHub = this.loadHub.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.handleTargetRemove = this.handleTargetRemove.bind(this);
    this.handleTargetAdd = this.handleTargetAdd.bind(this);
    this.handlePhoto = this.handlePhoto.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleGeocode = this.handleGeocode.bind(this);
    this.renderTarget = this.renderTarget.bind(this);
  }

  componentDidMount() {
    document.title = 'Edit Hub | Flamingo Admin';
    this.loadHub();
  }

  loadHub() {
    return api.get('/hub/' + this.props.match.params.hubId)
      .then((res) => this.setState({ hub: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleSave() {
    this.setState({ saving: true });
    return api.post('/hub/' + this.props.match.params.hubId, this.state.hub)
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  handleChange(e) {
    const { hub } = this.state;
    const name = e.target.getAttribute('hefi');
    hub[name] = ['target', 'sort'].includes(name) ? parseInt(e.target.value) : e.target.value;
    this.setState({ hub, error: false });
  }

  handleTargetChange(e) {
    const { hub } = this.state;
    const key = e.target.getAttribute('tk');
    const index = parseInt(e.target.getAttribute('ti'));
    hub.targets[index][key] = key === 'target' ? parseInt(e.target.value) : e.target.value;
    this.setState({ hub, error: false });
  }

  handleTargetRemove(index) {
    const { hub } = this.state;
    hub.targets.splice(index, 1);
    this.setState({ hub, error: false });
  }

  handleTargetAdd() {
    const { hub } = this.state;
    hub.targets.push({ day: 'monday', vehicleType: 'scooter', target: 10 });
    this.setState({ hub, error: false });
  }

  handlePhoto(file) {
    const { hub } = this.state;
    hub.photoUrl = file.url;
    this.setState({ hub });
  }

  handleMapClick(latitude, longitude) {
    const { hub } = this.state;
    hub.latitude = latitude;
    hub.longitude = longitude;
    this.setState({ hub }, () => this.handleGeocode(latitude, longitude));
  }

  handleGeocode(latitude, longitude) {
    const { hub } = this.state;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: new window.google.maps.LatLng(latitude, longitude) }, (results, status) => {
      if (results[0]) {
        hub.address = results[0]['formatted_address'];
        this.setState({ hub });
      }
    });
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? e.response.data.validation[0] : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, saving: false, loading: false });
  }

  renderTarget(target, i) {
    return (
      <div className="fm-hub-edit-input-target" key={i}>
        <select className="fm-input fm-input-select" tk="day" ti={i} value={target.day} onChange={this.handleTargetChange}>
          <option value="monday">Monday</option>
          <option value="tuesday">Tuesday</option>
          <option value="wednesday">Wednesday</option>
          <option value="thursday">Thursday</option>
          <option value="friday">Friday</option>
          <option value="saturday">Saturday</option>
          <option value="sunday">Sunday</option>
        </select>
        <select className="fm-input fm-input-select" tk="vehicleType" ti={i} value={target.vehicleType} onChange={this.handleTargetChange}>
          <option value="scooter">Scooter</option>
          <option value="bike">Bike</option>
        </select>
        <input type="number" value={target.target} className="fm-input" tk="target" ti={i} onChange={this.handleTargetChange} />
        <div className="fm-hub-edit-input-target-delete" onClick={() => this.handleTargetRemove(i)}><Icon icon="trash" /></div>
      </div>
    );
  }

  render() {
    const { loading, saving, hub, error } = this.state;
    const title = `Edit ${ hub.name || 'Hub' }`;
    const mapOptions = { center: { lat: hub.latitude, lng: hub.longitude }, zoom: 16 };

    return (
      <div className="fm-hub-edit">
        <NavigationBar title={title} loading={loading || saving} showBack={true} />
        {
          !loading &&
          <div className="fm-hub-edit-content">
            <div className="fm-hub-edit-map">
              <Map google={window.google} options={mapOptions} onClick={this.handleMapClick}>
                { (hub.latitude && hub.longitude) && <Marker position={{ lat: hub.latitude, lng: hub.longitude }} title="Hub Location" /> }
              </Map>
            </div>
            <div className="fm-hub-edit-form">
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Name</p>
                  <input type="text" value={hub.name || ''} disabled={saving} placeholder="Eg. Example Park" className="fm-input" hefi="name" onChange={this.handleChange} />
                </div>
              </div>
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Address</p>
                  <input type="text" value={hub.address || ''} disabled={saving} placeholder="Eg. 123 Example Street, Mount Victoria" className="fm-input" hefi="address" onChange={this.handleChange} />
                </div>
              </div>
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Deploy Instructions</p>
                  <input type="text" value={hub.description || ''} disabled={saving} placeholder="Eg. Place scooters next to the bench without blocking the path." className="fm-input" hefi="description" onChange={this.handleChange} />
                </div>
              </div>
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Sort</p>
                  <input type="number" value={hub.sort || ''} className="fm-input" hefi="sort" onChange={this.handleChange} />
                  <p className="fm-hub-edit-input-note">The lower the number the higher up the list. Hubs with the same sort number will be sorted alphabetically.</p>
                </div>
              </div>
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Radius</p>
                  <input type="number" value={hub.radius || ''} className="fm-input" hefi="radius" onChange={this.handleChange} />
                  <p className="fm-hub-edit-input-note">The radius from the hub for vehicles that should be counted in the occupancy. Measured in meters.</p>
                </div>
              </div>
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Deploy Radius</p>
                  <input type="number" value={hub.deployRadius || ''} className="fm-input" hefi="deployRadius" onChange={this.handleChange} />
                  <p className="fm-hub-edit-input-note">The radius from the hub for vehicles that should be counted as Hub Deploys. Measured in meters.</p>
                </div>
              </div>
              <div className="fm-hub-edit-input-row">
                <div className="fm-hub-edit-input">
                  <p className="fm-input-label">Targets</p>
                  { hub.targets.map(this.renderTarget) }
                  <button className="fm-hub-edit-input-target-add" onClick={this.handleTargetAdd}>Add Target</button>
                </div>
              </div>
              <FileUploader accept="images" type="hub" onFinish={this.handlePhoto} />
              <button className="fm-hub-edit-button" onClick={this.handleSave} disabled={saving}>{ saving ? 'Saving...' : 'Save Hub' }</button>
            </div>
          </div>
        }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default HubAdd;
