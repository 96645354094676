import React from 'react';

class PopUpButton extends React.Component {
  constructor() {
    super();
    this.background = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <div className="fm-popup-button" onClick={this.handleClick}>
        { this.props.children }
      </div>
    );
  }
}

export default PopUpButton;
