import React, { Component } from 'react';

class Radio extends Component {
  render() {
    return (
      <div className={'fm-radio' + (this.props.disabled ? ' fm-radio-disabled' : '')}>
        <label className="fm-radio-label">
          <input
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={(e) => this.props.onSelect(e.target.value)}
            className="fm-radio-input"
            type="radio"
            name={this.props.name}
            checked={this.props.checked || false}
          />
          <span className="fm-radio-circle">
            <span className="fm-radio-circle-inner"></span>
          </span>
          <span className="fm-radio-text">{ this.props.label || '' }</span>
        </label>
      </div>
    );
  }
}

export default Radio;
