import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import Input from 'components/common/input';
import history from 'helpers/history';
import api from 'helpers/api';

import UserTabs from '../user-tabs/';

class UserDetails extends Component {
  constructor(props) {
    super(props);
    const preloadedUser = props.location.state ? props.location.state.user : false;
    this.state = { user: preloadedUser || {}, editingNotes: false, notes: '', loading: true };

    this.tabs = React.createRef();

    this.loadUser = this.loadUser.bind(this);
    this.handleUser = this.handleUser.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleAdjustVoucher = this.handleAdjustVoucher.bind(this);
    this.adjustVoucher = this.adjustVoucher.bind(this);
    this.handleChangePlan = this.handleChangePlan.bind(this);
    this.viewStripe = this.viewStripe.bind(this);
    this.editNotes = this.editNotes.bind(this);
    this.notesChange = this.notesChange.bind(this);
    this.handleTicket = this.handleTicket.bind(this);
  }

  componentDidMount() {
    document.title = `User | Flamingo Admin`;
    this.loadUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.userId !== this.props.match.params.userId) {
      this.setState({ user: this.props.location.state ? (this.props.location.state.user || {}) : {} }, this.loadUser);
    }
  }

  /* NETWORKING */

  loadUser() {
    this.setState({ loading: true });
    return api.get(`/user/${this.props.match.params.userId}`)
      .then(this.handleUser)
      .catch(this.handleError);
  }

  handleUser(res) {
    const user = res.data.data;
    document.title = `${user.firstName} ${user.lastName} | Flamingo Admin`;
    this.setState({ user, loading: false });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  viewStripe() {
    const { stripe } = this.state.user;
    if (stripe) {
      window.open(`https://dashboard.stripe.com/customers/${stripe.customer}`);
    }
  }

  handleAdjustVoucher() {
    const adjustmentString = window.prompt(`Enter the amount to adjust the user's voucher credit by. Use a number followed by the letter C for credit, M for minutes or U for unlocks. For example to add $5 credit type 5C. To subtract 10 minutes type -10M.`, '');
    if (adjustmentString != null) {
      let adjustmentType = adjustmentString.substr(adjustmentString.length - 1).toLowerCase();
      let adjustmentAmount = parseFloat(adjustmentString.slice(0, -1));

      if (!adjustmentAmount) {
        return alert('Invalid adjustment amount.')
      }

      if (!['c', 'm', 'u'].includes(adjustmentType)) {
        return alert('Invalid adjustment type. Please use the letter C for credit, M for minutes or U for unlocks.')
      }

      let adjustmentSummary = '';
      switch (adjustmentType) {
        case 'm':
          adjustmentSummary = adjustmentAmount > 0 ? `add ${adjustmentAmount} minutes to` : `remove ${adjustmentAmount * -1} minutes from`;
          adjustmentType = 'MINUTES';
          break;
        case 'c':
          adjustmentSummary = adjustmentAmount > 0 ? `add $${adjustmentAmount} credit to` : `remove $${adjustmentAmount * -1} credit from`;
          adjustmentAmount = parseInt((adjustmentAmount * 100).toFixed());
          adjustmentType = 'CREDIT';
          break;
        case 'u':
          adjustmentSummary = adjustmentAmount > 0 ? `add ${adjustmentAmount} unlocks to` : `remove ${adjustmentAmount * -1} unlocks from`;
          adjustmentType = 'UNLOCKS';
          break;
        default:
          break;
      }

      let adjustmentConfirm = `Are you sure you want to ${adjustmentSummary} this user?`;
      if (window.confirm(adjustmentConfirm)) {
        return this.adjustVoucher(adjustmentType, adjustmentAmount);
      }
    }
  }

  adjustVoucher(type, value) {
    this.setState({ loading: true });
    return api.post(`/user/${this.props.match.params.userId}/adjust-vouchers`, { type, value })
      .then(() => this.setState({ loading: false }, this.tabs.current.loadTab))
      .catch(this.handleError);
  }

  handleChangePlan(e) {
    e.preventDefault();
    const planString = window.prompt(`Enter the plan to change the user to. Valid plans include BLACK, STAFF and STANDARD.`, '');
    if (planString != null) {
      const plan = planString.toUpperCase();
      if (['STAFF', 'BLACK', 'STANDARD', 'COUNCIL', 'STUDENT', 'COMMUNITY', 'SUPERGOLD', 'LEISURE', 'HAPAI', 'TOTALMOBILITY'].includes(plan)) {
        this.setState({ loading: true });
        return api.post(`/user/${this.props.match.params.userId}/change-plan`, { plan })
          .then((res) => this.setState({ user: res.data.data, loading: false }))
          .catch(this.handleError);
      } else {
        return alert('Invalid plan. Valid plans include BLACK, STAFF and STANDARD.')
      }
    }
  }

  editNotes() {
    let { editingNotes, notes, user } = this.state;
    if (editingNotes) {
      user.notes = notes;
      this.setState({ editingNotes: false, loading: true, user });
      return api.post(`/user/${this.props.match.params.userId}/notes`, { notes })
        .then((res) => this.setState({ user: res.data.data, loading: false }))
        .catch(this.handleError);
    } else {
      this.setState({ editingNotes: true, notes: user.notes || '' });
    }
  }

  notesChange(notes) {
    this.setState({ notes });
  }

  handleTicket() {
    const subject = prompt('Please enter a subject line. This will be visible to the rider. Eg "Parking Review".');
    if (subject !== null) {
      const note = prompt('Please enter information for the support team to:');
      if (note !== null) {
        this.setState({ loading: true });
        return api.post('/support/ticket', { userId: this.props.match.params.userId, subject, note })
          .then((res) => history.push(`/support/ticket/${res.data.data.id}`))
          .catch(this.handleError);
      }
    }
  }

  /* RENDERS */

  render() {
    const { user, loading, error, editingNotes, notes } = this.state;

    // Navigation
    const title = user.firstName ? `${user.firstName} ${user.lastName}` : `User`;

    const actions = [{ onClick: this.handleTicket, icon: 'life-ring' }];

    if (window.innerWidth > 450) {
      actions.push({ to: `/incident/new/user/${user.id}`, icon: 'exclamation-triangle' });
    }

    if (user.stripe) {
      actions.push({ onClick: this.viewStripe, icon: 'cc-stripe', title: 'View on Stripe' });
    }

    actions.push({ onClick: this.handleAdjustVoucher, icon: 'money-bill-alt', title: 'Adjust Vouchers' });

    if (['active', 'disabled', 'suspended'].includes(user.status)) {
      actions.push({ to: { pathname: `/user/${user.id}/edit`, state: { user } }, icon: 'edit' });
    }

    const statusIcon = { deleted: 'trash', active: 'check-circle', disabled: 'times-circle', suspended: 'pause-circle' };

    // Render
    return (
      <div className="fm-user">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={actions} />
        <div className="fm-user-content">
          <div className="fm-user-section-header">
            <p className="fm-user-section-title">Details</p>
          </div>
          <div className="fm-user-status">
            <Link className="fm-user-status-item" to={`/call/${user.phone || '#'}`}>
              <div className="fm-user-status-item-icon">
                <Icon icon="phone-alt" />
              </div>
              <p className="fm-user-status-item-text">{ user.phone || '---' }</p>
            </Link>
            <a className="fm-user-status-item" href={`mailto:${user.email || '#'}`} target="_blank" rel="noopener noreferrer">
              <div className="fm-user-status-item-icon">
                <Icon icon="at" />
              </div>
              <p className="fm-user-status-item-text">{ user.email || '---' }</p>
            </a>
            <a className="fm-user-status-item" href="#change-plan" title="Change Plan" onClick={this.handleChangePlan}>
              <div className="fm-user-status-item-icon">
                <Icon icon="wallet" />
              </div>
              <p className="fm-user-status-item-text">{ user.plan || '---' }</p>
            </a>
            <div className="fm-user-status-item">
              <div className={ user.hasSubscription ? 'fm-user-status-item-icon fm-user-status-item-icon-pink' : 'fm-user-status-item-icon' }>
                <Icon icon={ user.hasSubscription ? 'infinity' : 'money-bill-wave' } />
              </div>
              <p className="fm-user-status-item-text">{ user.hasSubscription ? 'Active Go Pass' : 'Pay as You Go' }</p>
            </div>
            <div className="fm-user-status-item">
              <div className="fm-user-status-item-icon">
                <Icon icon="user-friends" />
              </div>
              <p className="fm-user-status-item-text">{ user.referralCode || '---' }</p>
            </div>
            <div className="fm-user-status-item">
              <div className="fm-user-status-item-icon">
                <Icon icon={ statusIcon[user.status] || 'question-circle' } />
              </div>
              <p className="fm-user-status-item-text" style={{ textTransform: 'capitalize' }}>{ user.status }</p>
            </div>
          </div>
          <div className="fm-user-section-header">
            <p className="fm-user-section-title">Notes</p>
            {
              loading ? null : (
                <div className="fm-user-section-button" onClick={this.editNotes}>
                  <Icon icon={ editingNotes ? 'check' : 'pen' } />
                </div>
              )
            }
          </div>
          <div className="fm-user-notes">
            {
              editingNotes ? (
                <Input type="textarea" value={notes} onChange={this.notesChange} />
              ) : (
                <p className="fm-user-notes-text" onClick={this.editNotes}>{ user.notes ? user.notes : 'No notes.' }</p>
              )
            }
          </div>
          { user.id && <UserTabs key={this.props.match.params.userId} user={user} ref={this.tabs} /> }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default UserDetails;
