import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import api from 'helpers/api';

class ChangeRegion extends Component {
  constructor() {
    super();
    this.state = { regions: [], loading: true };

    this.loadRegions = this.loadRegions.bind(this);
    this.changeRegion = this.changeRegion.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.renderRegion = this.renderRegion.bind(this);
  }

  componentDidMount() {
    document.title = 'Change Region | Flamingo Admin';
    this.loadRegions();
  }

  loadRegions() {
    this.setState({ loading: true });
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data, loading: false }))
      .catch(console.log);
  }

  changeRegion(regionId) {
    this.setState({ loading: true });
    window.localStorage.removeItem('mapLocation');
    return api.post('/user/change-region', { regionId })
      .then(this.handleRedirect)
      .catch(console.log);
  }

  handleRedirect() {
    history.push('/change-region/done');
    this.setState({ loading: false }, () => history.go(-2));
  }

  renderRegion(region, i) {
    const buttonClass = this.state.loading ? 'fm-regions-button fm-regions-button-loading' : 'fm-regions-button';
    return <button key={i} disabled={this.state.loading} className={buttonClass} onClick={() => this.changeRegion(region.id)}>{ region.name }</button>;
  }

  render() {
    const { loading, regions } = this.state;

    return (
      <div className="fm-regions">
        <NavigationBar title="Change Region" loading={loading} />
        { regions.map(this.renderRegion) }
        <button disabled={loading} className={`fm-regions-button${loading ? ` fm-regions-button-loading` : ''}`} onClick={() => this.changeRegion(null)}>No Region</button>
      </div>
    );
  }
}

export default ChangeRegion;
