import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class Forecast extends Component {
  constructor() {
    super();
    this.state = {
      regions: [],
      loading: true,
    };

    this.loadForecast = this.loadForecast.bind(this);
    this.parseForecast = this.parseForecast.bind(this);
    this.renderRegion = this.renderRegion.bind(this);
    this.renderHour = this.renderHour.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Trip Forecast | Flamingo Admin';
    this.loadForecast();
  }

  loadForecast() {
    this.setState({ loading: true });
    return api.get('/analytics/trip-forecast')
      .then(this.parseForecast)
      .catch(this.handleError);
  }

  parseForecast(res) {
    this.setState({ regions: res.data.data, loading: false });
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  renderHour(hour, i, maxHour) {
    console.log(maxHour);
    return (
      <div className="fm-forecast-region-chart-item" key={i}>
        <div className="fm-forecast-region-chart-item-bars">
          <div className="fm-forecast-region-chart-item-bar fm-forecast-region-chart-item-bar-fortnight" title={ hour.fortnightAverage } style={{ height: `${ hour.fortnightAverage / maxHour * 100 }%` }}></div>
          <div className="fm-forecast-region-chart-item-bar fm-forecast-region-chart-item-bar-forecast" title={ hour.forecastTotal } style={{ height: `${ hour.forecastTotal / maxHour * 100 }%` }}></div>
          <div className="fm-forecast-region-chart-item-bar fm-forecast-region-chart-item-bar-current" title={ hour.currentTotal } style={{ height: `${ hour.currentTotal / maxHour * 100 }%` }}></div>
        </div>
        <p className="fm-forecast-region-chart-item-value">{ (hour.currentTotal).toFixed() }</p>
        <p className="fm-forecast-region-chart-item-hour">{ hour.hour }</p>
      </div>
    );
  }

  renderRegion(region, i) {
    const maxHour = Math.max(...region.chart.map((h) => Math.max(...[h.forecastTotal, h.currentTotal, h.fortnightAverage])));
    const positiveTrend = region.statistics.forecastTrend > 1;
    const trendClass = positiveTrend ? 'fm-forecast-region-statistics-item-value fm-forecast-region-statistics-item-value-positive' : 'fm-forecast-region-statistics-item-value fm-forecast-region-statistics-item-value-negative';
    return (
      <div className="fm-forecast-region" key={i}>
        <p className="fm-forecast-region-title">{ region.name }</p>
        <div className="fm-forecast-region-statistics">
          <div className="fm-forecast-region-statistics-item">
            <p className="fm-forecast-region-statistics-item-value">{ region.statistics.currentTotal }</p>
            <p className="fm-forecast-region-statistics-item-title">Current Total</p>
          </div>
          <div className="fm-forecast-region-statistics-item">
            <p className="fm-forecast-region-statistics-item-value">{ parseInt(region.statistics.forecastTotal) }</p>
            <p className="fm-forecast-region-statistics-item-title">Forecasted Total</p>
          </div>
          <div className="fm-forecast-region-statistics-item">
            <p className="fm-forecast-region-statistics-item-value">{ parseInt(region.statistics.fornightTotal) }</p>
            <p className="fm-forecast-region-statistics-item-title">Fortnight Average</p>
          </div>
          <div className="fm-forecast-region-statistics-item">
            <p className={trendClass}>{ positiveTrend ? '+' : '' }{ (region.statistics.forecastTrend * 100 - 100).toFixed(1) }%</p>
            <p className="fm-forecast-region-statistics-item-title">Day Trend</p>
          </div>
        </div>
        <div className="fm-forecast-region-chart">
          { region.chart.map((h, i) => this.renderHour(h, i, maxHour)) }
        </div>
      </div>
    );
  }

  render() {
    const { loading, regions, error } = this.state;

    return (
      <div className="fm-forecast">
        <NavigationBar title="Forecast" loading={loading} />
        { regions.map(this.renderRegion) }
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default Forecast;
