import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import InstructionGroup from './components/instruction-group/';
import InstructionGroups from './components/instruction-groups/';

class Instruction extends Component {
  render() {
    return (
      <Switch>
        <Route path="/instruction/group/:instructionGroupId" component={InstructionGroup} />
        <Route path="/instruction" component={InstructionGroups} />
      </Switch>
    );
  }
}

export default Instruction;
