import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class Battery extends Component {
  constructor(props) {
    super(props);
    this.state = { recent: [], loading: true, serialNumber: '' };

    this.loadRecent = this.loadRecent.bind(this);
    this.recordRepair = this.recordRepair.bind(this);
    this.repairResult = this.repairResult.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSerialNumberChange = this.handleSerialNumberChange.bind(this);
    this.renderRepair = this.renderRepair.bind(this);
  }

  componentDidMount() {
    document.title = 'Battery Repair | Flamingo Admin';
    this.loadRecent();
  }

  // Networking

  loadRecent() {
    return api.get('/battery')
      .then((res) => this.setState({ recent: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  recordRepair(e) {
    e.preventDefault();
    const { loading, serialNumber } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true, error: false });
    return api.post('/battery', { serialNumber })
      .then((res) => this.repairResult(res.data.data))
      .catch(this.handleError);
  }

  repairResult(result) {
    const { recent } = this.state;
    recent.unshift(result);
    this.setState({ recent, serialNumber: '', loading: false, error: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Handlers

  handleSerialNumberChange(e) {
    this.setState({ serialNumber: e.target.value });
  }

  // Renders

  renderRepair(repair, i) {
    return (
      <div className="fm-battery-repair" key={i}>
        <p className="fm-battery-repair-title">{ repair.serialNumber }</p>
        <p className="fm-battery-repair-details">{ repair.user.firstName } { repair.user.lastName } - { moment(repair.repairedAt).format('h:mma D/MM/YY') }</p>
      </div>
    );
  }

  render() {
    const { loading, serialNumber, recent, error } = this.state;

    return (
      <div className="fm-battery">
        <NavigationBar title="Battery Repair" loading={loading} />
        <div className="fm-battery-content">
          <form onSubmit={this.recordRepair} className="fm-battery-form">
            <input className="fm-input" type="text" placeholder="Battery Serial Number" onChange={this.handleSerialNumberChange} value={serialNumber} disabled={loading} />
            <input className="fm-battery-submit" type="submit" value="Record Repair" disabled={loading} />
          </form>
          <div className="fm-battery-recent">
            { recent.map(this.renderRepair) }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Battery;
