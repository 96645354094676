import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import TicketActive from './components/ticket-active/';
import TicketNew from './components/ticket-new/';
import TicketView from './components/ticket-view/';

class Ticket extends Component {
  render() {
    return (
      <Switch>
        <Route path="/ticket/new/:vehicleId" component={TicketNew} />
        <Route path="/ticket/:ticketId" component={TicketView} />
        <Route path="/ticket" component={TicketActive} />
      </Switch>
    );
  }
}

export default Ticket;
