import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AddNest from './components/add-nest';
import NestTop from './components/nest-top/';
import NestView from './components/nest-view/';
import NestMap from './components/nest-map/';
import NestEdit from './components/nest-edit/';

class Nest extends Component {
  render() {
    return (
      <Switch>
        <Route path="/nest/add" component={AddNest} />
        <Route path="/nest/top" component={NestTop} />
        <Route path="/nest/:nestId/edit" component={NestEdit} />
        <Route path="/nest/:nestId" component={NestView} />
        <Route path="/nest" component={NestMap} />
      </Switch>
    );
  }
}

export default Nest;
