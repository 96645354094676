import React, { Component } from 'react';
import Loading from 'components/common/loading';
import Toast from 'components/common/toast';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Radio from 'components/common/radio';
import RadioGroup from 'components/common/radio-group';
import FileUploader from 'components/common/file-uploader';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';
import history from 'helpers/history';

const addSteps = {
  MAP: 'MAP',
  DETAILS: 'DETAILS',
  PHOTO: 'PHOTO',
  COMPLETE: 'PHOTO',
};

class AddNest extends Component {
  constructor() {
    super();
    this.state = { step: addSteps.MAP, loading: true, loadingStep: false, currentLocation: { lat: -41.288170, lng: 174.778952 }, location: {}, address: '', description: '', maxCapacity: 2, tags: '' };
    this.loadLocation = this.loadLocation.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handlePhoto = this.handlePhoto.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDetailsNext = this.handleDetailsNext.bind(this);
    this.handleMapNext = this.handleMapNext.bind(this);
    this.handlePhotoNext = this.handlePhotoNext.bind(this);
    this.locationMarker = React.createRef();
  }

  componentDidMount() {
    document.title = 'Add Nest | Flamingo Admin';
    this.loadLocation();
  }

  loadLocation() {
    if (navigator.geolocation) {
      setTimeout(() => this.setState({ loading: false }), 3000);
      navigator.geolocation.getCurrentPosition(this.handleLocation);
    } else {
      this.setState({ loading: false });
    }
  }

  handleLocation(location) {
    if (location.coords && this.state.loading) {
      const currentLocation = { lat: location.coords.latitude, lng: location.coords.longitude };
      this.setState({ currentLocation, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handlePhoto(file) {
    this.setState({ photoUrl: file.url });
  }

  handleBack() {
    if (this.state.step === addSteps.DETAILS) {
      this.setState({ step: addSteps.MAP, loadingStep: true });
      return;
    }
    if (this.state.step === addSteps.PHOTO) {
      this.setState({ step: addSteps.DETAILS, loadingStep: true });
      return;
    }
  }

  handleMapNext() {
    if (this.locationMarker.current && this.locationMarker.current.marker) {
      this.setState({ loadingStep: true });
      const markerLocation = this.locationMarker.current.marker.getPosition();
      const location = { latitude: markerLocation.lat(), longitude: markerLocation.lng() };
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: markerLocation }, (results, status) => {
        if (results[0]) {
          const address = results[0]['formatted_address'];
          this.setState({ loadingStep: false, step: addSteps.DETAILS, location, address });
        } else {
          this.setState({ loadingStep: false, step: addSteps.DETAILS, location });
        }
      });
    } else {
      this.setState({ error: 'Unable to locate marker'});
    }
  }

  handleDetailsNext() {
    if (this.state.address.length < 5) {
      this.setState({ error: 'Invalid address'});
      return;
    }
    if (this.state.description.length < 5) {
      this.setState({ error: 'Invalid description'});
      return;
    }
    this.setState({ step: addSteps.PHOTO });
  }

  handlePhotoNext() {
    if (!this.state.photoUrl) {
      this.setState({ error: 'Invalid photo'});
      return;
    }

    this.setState({ loadingStep: true });

    // Format the data
    const { address, description, maxCapacity, photoUrl, tags, location } = this.state;
    const data = { address, description, maxCapacity, photoUrl };
    data.latitude = location.latitude;
    data.longitude = location.longitude;
    if (tags.length > 0) {
      data.tags = tags;
    }

    api.post('/nest/add', data)
      .then((res) => history.push('/nest/' + res.data.data.id))
      .catch(() => this.setState({ error: 'Server Error'}));
  }

  renderMapStep() {
    const mapOptions = { center: this.state.currentLocation, zoom: 18, mapTypeId: 'satellite' };
    const markerLocation = this.state.currentLocation;
    return (
      <div className="fm-page fm-nest-add">
        <p className="fm-page-title">Nest Location</p>
        <div className="fm-nest-add-content">
          <div className="fm-nest-add-map">
            <Map options={mapOptions} google={window.google}>
              <Marker position={markerLocation} draggable={true} ref={this.locationMarker} />
            </Map>
          </div>
          <Button onClick={this.handleMapNext} loading={this.state.loadingStep}>Next</Button>
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }

  renderDetailsStep() {
    return (
      <div className="fm-page fm-nest-add">
        <p className="fm-page-title">Nest Details</p>
        <div className="fm-nest-add-content">
          <Input label="Address" placeholder="Eg. 226 Victoria Street, Te Aro" value={this.state.address} onChange={(address) => this.setState({ address })} />
          <Input label="Description" placeholder="Eg. Line up next to the fence." value={this.state.description} onChange={(description) => this.setState({ description })} />
          <Input label="Tags (Optional)" placeholder="Eg. tier1, transit, cbd" value={this.state.tags} onChange={(tags) => this.setState({ tags })} />
          <RadioGroup label="Max Vehicles">
            <Radio label="2" onSelect={() => this.setState({ maxCapacity: 2 })} checked={ this.state.maxCapacity === 2 } />
            <Radio label="4" onSelect={() => this.setState({ maxCapacity: 4 })} checked={ this.state.maxCapacity === 4 } />
            <Radio label="6" onSelect={() => this.setState({ maxCapacity: 6 })} checked={ this.state.maxCapacity === 6 } />
          </RadioGroup>
          <Button onClick={this.handleDetailsNext} loading={this.state.loadingStep}>Next</Button>
          <span className="fm-nest-add-back" onClick={this.handleBack}>Back</span>
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }

  renderPhotoStep() {
    return (
      <div className="fm-page fm-nest-add">
        <p className="fm-page-title">Nest Photo</p>
        <div className="fm-nest-add-content">
          <FileUploader accept="images" type="nest" onFinish={this.handlePhoto} />
          <Button onClick={this.handlePhotoNext} loading={this.state.loadingStep}>Next</Button>
          <span className="fm-nest-add-back" onClick={this.handleBack}>Back</span>
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.step === addSteps.DETAILS) {
      return this.renderDetailsStep();
    }

    if (this.state.step === addSteps.PHOTO) {
      return this.renderPhotoStep();
    }

    return this.renderMapStep();
  }

}

export default AddNest;
