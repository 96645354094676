import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import colors from 'helpers/colors';

const ticketTypeColors = {
  'note': '#f0932b',
  'maintenance': '#eb4d4b',
  'missing': '#f1c40f',
  'accident': '#eb4d4b',
  'movement': '#f0932b',
  'theft': '#e056fd',
  'noParking': '#f1c40f',
  'parking': '#f1c40f',
};

class TicketView extends Component {
  constructor() {
    super();
    this.state = { ticket: {}, loading: true };
    this.loadTicket = this.loadTicket.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
  }

  componentDidMount() {
    document.title = 'Ticket Details | Flamingo Admin';
    this.loadTicket();
  }

  loadTicket() {
    return api.get('/ticket/' + this.props.match.params.ticketId)
      .then((res) => this.setState({ ticket: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleResolve() {
    this.setState({ loading: true });
    return api.post('/ticket/' + this.props.match.params.ticketId + '/resolve')
      .then((res) => this.setState({ ticket: res.data.data, loading: false }))
      .catch(console.log);
  }

  render() {
    const { loading, ticket } = this.state;
    const resolveTicketAction = { onClick: this.handleResolve, icon: 'check-square' };

    return (
      <div className="fm-task">
        <NavigationBar title="Ticket Details" loading={loading} showBack={true} rightActions={ (loading || ticket.resolvedAt) ? [] : [resolveTicketAction] } />
        {
          !loading && (
            <div className="fm-task-view-content">
              <div className="fm-ticket-active-item-vehicle">
                <Link className="fm-ticket-active-item-vehicle-label" to={`/vehicle/${ticket.vehicle.id}`} title={`${ticket.vehicle.status} - ${ticket.vehicle.batteryPercent * 100}%`}>
                  <div className="fm-ticket-active-item-vehicle-status" style={{ backgroundColor: colors.status[ticket.vehicle.status] }}></div>
                  <p className="fm-ticket-active-item-vehicle-rego">{ ticket.vehicle.registration }</p>
                </Link>
                {
                  ticket.task ? (
                    <Link to={`/task/${ticket.task.id}`} className="fm-ticket-active-item-vehicle-button">View Task<Icon icon="chevron-right" /></Link>
                  ) : (
                    <Link to={`/task/new/${ticket.vehicle.id}`} className="fm-ticket-active-item-vehicle-button">Create Task<Icon icon="chevron-right" /></Link>
                  )
                }
              </div>
              <div className="fm-ticket-active-item-ticket">
                <div className="fm-ticket-active-item-ticket-header">
                  <p className="fm-ticket-active-item-ticket-type" style={{ backgroundColor: ticketTypeColors[ticket.type] }}>{ ticket.type.toUpperCase() }</p>
                  <p className="fm-ticket-active-item-ticket-source">{ ticket.source.toUpperCase() }</p>
                  <p className="fm-ticket-active-item-ticket-created">{ moment(ticket.createdAt).fromNow() }</p>
                </div>
                <div className="fm-ticket-active-item-ticket-content">
                  <p className="fm-ticket-active-item-ticket-details">{ ticket.description }</p>
                </div>
                {
                  ticket.user && (
                    <div className="fm-ticket-active-item-ticket-content">
                      <p className="fm-ticket-active-item-ticket-details">Created by { ticket.user.firstName } { ticket.user.lastName }.</p>
                    </div>
                  )
                }
                {
                  ticket.photoUrl && (
                    <img className="fm-ticket-active-item-ticket-img" alt="Report" src={ticket.photoUrl} />
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default TicketView;
