import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class Call extends Component {
  constructor() {
    super();
    this.state = { loading: false };
    this.requestCall = this.requestCall.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Call | Flamingo Admin';
  }

  requestCall() {
    const { phone } = this.props.match.params;
    this.setState({ loading: true });
    return api.post('/support/call', { phone })
      .then((res) => history.goBack())
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  render() {
    const { loading, error } = this.state;
    const { phone } = this.props.match.params;

    return (
      <div className="fm-call">
        <NavigationBar title={`Call ${phone || ''}`} loading={loading} showBack={true} />
        <div className="fm-call-content">
          <p className="fm-call-description">Select the method for calling { phone }. If you select a Proxy Call, Flamingo will call you, then add the recipient to the call. The call to the recipient will be made from Flamingo's phone number.</p>
          { !loading && <a className="fm-call-link" href={`tel:${phone || '#'}`} target="_blank" rel="noopener noreferrer">Direct Call</a> }
          { !loading && <button className="fm-call-button" onClick={this.requestCall}>Proxy Call</button> }
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default Call;
