import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

class MaintenanceGuide extends Component {
  constructor() {
    super();
    this.state = { guide: {}, loading: true };

    this.loadGuide = this.loadGuide.bind(this);
  }

  componentDidMount() {
    document.title = `Repair Guide | Flamingo Admin`;
    this.loadGuide();
  }

  loadGuide() {
    this.setState({ loading: true });
    api.get(`/maintenance/guide/${this.props.match.params.guideId}`)
      .then((res) => this.setState({ guide: res.data.data, loading: false }))
      .catch((res) => this.setState({ loading: false }));
  }

  /* RENDERS */

  render() {
    const { guide, loading } = this.state;
    const title = guide.title || 'Repair Guide';
    const url = guide.youtubeId ? `https://www.youtube.com/embed/${guide.youtubeId}` : '';
    return (
      <div className="fm-maintenance-guide">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-maintenance-guide-video-container">
          {
            !loading && (
              <iframe className="fm-maintenance-guide-video" width="100%" src={url} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            )
          }
        </div>
        { guide.instructions && <p className="fm-maintenance-guide-instruction">{ guide.instructions }</p> }
      </div>
    );
  }
}

export default MaintenanceGuide;
