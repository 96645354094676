import React, { Component } from 'react';

class TaskNewMap extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], type: 'pickup', mode: 'search' };

    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  handleModeChange(e) {
    const mode = e.target.value;
    this.setState({ mode });
  }

  handleTypeChange(e) {
    const type = e.target.value;
    this.setState({ type });
  }

  render() {
    return (
      <div className="fm-task-new-map">
        
      </div>
    );
  }
}

export default TaskNewMap;
