import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

class InstructionGroups extends Component {
  constructor(props) {
    super(props);
    this.state = { groups: [], loading: true, error: false };

    this.loadGroups = this.loadGroups.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
  }

  componentDidMount() {
    document.title = `Instructions | Flamingo Admin`;
    this.loadGroups();
  }

  /* NETWORKING */

  loadGroups() {
    this.setState({ loading: true });
    api.get(`/instruction/group`)
      .then((res) => this.setState({ groups: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderGroup(group, i) {
    return (
      <Link className="fm-instruction-groups-item" key={i} to={{ pathname: `/instruction/group/${group.id}`, state: { group } }}>
        <p className="fm-instruction-groups-item-title">{ group.name }</p>
      </Link>
    );
  }

  render() {
    const { groups, loading, error } = this.state;

    return (
      <div className="fm-instruction-groups">
        <NavigationBar title="Instructions" loading={loading} />
        <div className="fm-instruction-groups-items">
          { groups.map(this.renderGroup) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default InstructionGroups;
