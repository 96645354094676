import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const helmetColors = {
  'unlocked': '#4834D4',
  'locked': '#6AB04C',
  'missing': '#EB3B5A',
  'added': '#F0932B',
};

class HelmetHires extends Component {
  constructor() {
    super();
    this.state = { loading: true };

    this.loadHire = this.loadHire.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleFine = this.handleFine.bind(this);
    this.handleWarning = this.handleWarning.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
  }

  componentDidMount() {
    document.title = 'Unreturned Helmets | Flamingo Admin';
    this.loadHire();
  }

  loadHire() {
    this.setState({ loading: true });
    return api.get('/helmet/hire')
      .then((res) => this.setState({ occurrence: res.data.data.hire ? res.data.data : undefined, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleFine() {
    return this.handleStatus('fine');
  }

  handleWarning() {
    return this.handleStatus('warning');
  }

  handleDismiss() {
    return this.handleStatus('dismiss');
  }

  handleStatus(status) {
    const { hire } = this.state.occurrence;

    this.setState({ loading: true });
    return api.post('/helmet/hire/' + hire.id, { status })
      .then(this.loadHire)
      .catch(this.handleError);
  }

  render() {
    const { loading, occurrence } = this.state;

    return (
      <div className="fm-hires">
        <NavigationBar title="Unreturned Helmets" loading={loading}  />
        {
          occurrence ? (
            <div className="fm-hires-content">
              <div className="fm-hires-main">
                <div className="fm-hires-main-photo" style={{ backgroundImage: `url(${occurrence.hire.trip.parkingPhotoUrl})` }}>
                  {
                    occurrence.hire.trip.parkingPhotoUrl ? (
                      <p className="fm-hires-main-photo-title">Parking Photo ({ moment(occurrence.hire.trip.endTime).format('H:mm D/M/YY') })</p>
                    ) : (
                      <p className="fm-hires-main-photo-title">No Parking Photo ({ moment(occurrence.hire.trip.endTime).format('H:mm D/M/YY') })</p>
                    )
                  }
                </div>
                <div className="fm-hires-main-photos">
                  <div className="fm-hires-main-photo" style={{ backgroundImage: occurrence.previousTrip ? `url(${occurrence.previousTrip.parkingPhotoUrl})` : undefined }}>
                    {
                      (occurrence.previousTrip && occurrence.previousTrip.parkingPhotoUrl) ? (
                        <p className="fm-hires-main-photo-title">Previous Photo ({ moment(occurrence.previousTrip.endTime).format('H:mm D/M/YY') })</p>
                      ) : (
                        <p className="fm-hires-main-photo-title">No Previous Photo</p>
                      )
                    }
                  </div>
                  <div className="fm-hires-main-photo" style={{ backgroundImage: occurrence.nextTrip ? `url(${occurrence.nextTrip.parkingPhotoUrl})` : undefined }}>
                    {
                      (occurrence.nextTrip && occurrence.nextTrip.parkingPhotoUrl) ? (
                        <p className="fm-hires-main-photo-title">Next Photo ({ moment(occurrence.nextTrip.endTime).format('H:mm D/M/YY') })</p>
                      ) : (
                        <p className="fm-hires-main-photo-title">No Next Photo</p>
                      )
                    }
                  </div>
                </div>
                <div className="fm-hires-statuses">
                  {
                    occurrence.statuses.map((status, i) => {
                      const sameUser = status.user ? occurrence.hire.user.id === status.user.id : false;
                      return (
                        <div className="fm-hires-status" key={i}>
                          <div className="fm-hires-status-top">
                            <p className="fm-hires-status-badge-type" style={{ backgroundColor: helmetColors[status.eventType] }}>{ status.eventType.toUpperCase() }</p>
                            <p className="fm-hires-status-badge-source">{ (status.source || 'scan').toUpperCase() }</p>
                            <p className="fm-hires-status-date">{ moment(status.eventTime).format('H:mm D/M/YY') }</p>
                          </div>
                          <p className="fm-hires-status-details" style={{ fontWeight: sameUser ? 600 : 400 }}>{ status.user ? `${status.user.firstName} ${status.user.lastName}` : '' }</p>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
              <div className="fm-hires-details">
                <div className="fm-hires-details-items">
                  <p className="fm-hires-details-name">{ occurrence.hire.user.firstName } { occurrence.hire.user.lastName }</p>
                  <p className="fm-hires-details-time">Trip { moment(occurrence.hire.trip.startTime).format('H:mm') } to { moment(occurrence.hire.trip.endTime).format('H:mm D/M/YY') } ({ parseInt(occurrence.hire.trip.duration / 60) } mins)</p>
                  <p className="fm-hires-details-time">Helmet Unlocked at { moment(occurrence.hire.startTime).format('H:mm D/M/YY') }</p>
                  <div className="fm-hires-details-previous">
                    { occurrence.previousHires.length === 0 && <p className="fm-hires-details-previous-item">No Previous Hires</p> }
                    { occurrence.previousHires.map((previous) => <p className="fm-hires-details-previous-item">{ previous.status.toUpperCase() }: { previous.total }</p>) }
                  </div>
                </div>
                <div className="fm-hires-details-actions">
                  <button className="fm-hires-details-action fm-hires-details-action-fine" onClick={this.handleFine} disabled={loading}>{ loading ? '--' : '$10 Fine' }</button>
                  <button className="fm-hires-details-action fm-hires-details-action-warning" onClick={this.handleWarning} disabled={loading}>{ loading ? '--' : 'Warning' }</button>
                  <button className="fm-hires-details-action fm-hires-details-action-dismiss" onClick={this.handleDismiss} disabled={loading}>{ loading ? '--' : 'Dimiss' }</button>
                </div>
              </div>
            </div>
          ) : (
            <div className="fm-hires-content">
              <p className="fm-hires-empty">{ loading ? `Loading helmet hires...` : `No helmet hires outstanding!` }</p>
            </div>
          )
        }
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default HelmetHires;
