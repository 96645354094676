import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import LoadingLine from 'components/common/loading-line';
import Map, { Marker } from 'components/common/map';
import moment from 'moment';
import api from 'helpers/api';
import history from 'helpers/history';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icon = { url: baseUrl + 'task-pickup.png', size: new window.google.maps.Size(42, 43), scaledSize: new window.google.maps.Size(42, 43) };

class TaskOfflineMap extends Component {
  constructor() {
    super();
    this.state = { vehicles: [], loading: true };

    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
  }

  componentDidMount() {
    document.title = 'Offline Vehicles | Flamingo Admin';
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng());
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude) {
    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude };
    return api.get('/vehicle/offline')
      .then((res) => this.setState({ vehicles: res.data.data, currentBounds, loading: false }))
      .catch(console.log);
  }

  /* ------ Renders ------ */

  render() {
    const { vehicles, loading } = this.state;

    return (
      <div className="fm-offline-map">
        <NavigationBar title="Offline Vehicles Map" showBack={true} />
        <div className="fm-offline-map-holder">
          <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { vehicles.map((vehicle, i) => <Marker key={i} position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={`${vehicle.registration} - ${moment(vehicle.statusTime).fromNow()}`} icon={icon} onClick={() => history.push({ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } })} />) }
          </Map>
        </div>
        <LoadingLine hide={!loading} />
      </div>
    );
  }
}

export default TaskOfflineMap;
