import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class UserEdit extends Component {
  constructor(props) {
    super(props);
    const preloadedUser = props.location.state ? props.location.state.user : false;
    this.state = { user: preloadedUser || {} };

    this.loadUser = this.loadUser.bind(this);
    this.handleUser = this.handleUser.bind(this);
    this.handleError = this.handleError.bind(this);

    this.onChange = this.onChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRevoke = this.handleRevoke.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleEnable = this.handleEnable.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    document.title = `Edit User | Flamingo Admin`;
    if (!this.state.user.id) {
      this.loadUser();
    }
  }

  /* NETWORKING */

  loadUser() {
    this.setState({ loading: true });
    return api.get(`/user/${this.props.match.params.userId}`)
      .then(this.handleUser)
      .catch(this.handleError);
  }

  handleUser(res) {
    this.setState({ user: res.data.data, loading: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  onChange(e) {
    const user = this.state.user;
    user[e.target.getAttribute('okey')] = e.target.value;
    this.setState({ user, error: false })
  }

  handleSave() {
    this.setState({ loading: true, error: false });
    const { firstName, lastName, email } = this.state.user;
    const userId = this.props.match.params.userId;
    return api.post(`/user/${userId}`, { firstName, lastName, email })
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  handleDisable() {
    this.setState({ loading: true, error: false });
    return api.post(`/user/${this.props.match.params.userId}/status`, { status: 'disabled' })
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  handleRevoke() {
    this.setState({ loading: true, error: false });
    return api.post(`/user/${this.props.match.params.userId}/revoke`)
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  handleEnable() {
    this.setState({ loading: true, error: false });
    return api.post(`/user/${this.props.match.params.userId}/status`, { status: 'active' })
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  handleDelete() {
    const deletePrompt = window.prompt(`Are you sure you want to delete this account? The user will never be able to access this account again but will be able to create a new one. Please type the word "delete" below to confirm.`, '');
    if (deletePrompt === 'delete') {
      this.setState({ loading: true, error: false });
      return api.post(`/user/${this.props.match.params.userId}/status`, { status: 'deleted' })
        .then(() => history.goBack())
        .catch(this.handleError);
    }
  }

  /* RENDERS */

  render() {
    const { user, error, loading } = this.state;

    return (
      <div className="fm-user-edit">
        <NavigationBar title="Edit User" loading={loading} showBack={true} />
        <div className="fm-user-edit-content">
          <div className="fm-user-edit-input">
            <p className="fm-input-label">First Name</p>
            <input value={user.firstName || ''} onChange={this.onChange} okey="firstName" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-user-edit-input">
            <p className="fm-input-label">Last Name</p>
            <input value={user.lastName || ''} onChange={this.onChange} okey="lastName" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-user-edit-input">
            <p className="fm-input-label">Email</p>
            <input value={user.email || ''} onChange={this.onChange} okey="email" className="fm-input" disabled={loading} />
          </div>
          <button className="fm-user-edit-button" onClick={this.handleSave} disabled={loading}>Save</button>
          <button className="fm-user-edit-status" onClick={this.handleRevoke} disabled={loading}>Force Logout (1H)</button>
          {
            user.status === 'active' ? (
              <>
                <button className="fm-user-edit-status" onClick={this.handleDisable} disabled={loading}>Disable Account</button>
                <button className="fm-user-edit-status" onClick={this.handleDelete} disabled={loading}>Delete Account</button>
              </>
            ) : (
              <>
                <button className="fm-user-edit-status" onClick={this.handleEnable} disabled={loading}>Enable Account</button>
                <button className="fm-user-edit-status" onClick={this.handleDelete} disabled={loading}>Delete Account</button>
              </>
            )
          }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default UserEdit;
