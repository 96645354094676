import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const colours = ['#2ecc71', '#e67e22', '#3498db', '#e74c3c', '#9b59b6', '#f1c40f', '#1abc9c', '#7f8c8d', '#e84393', '#34495e'];

class UserAccess extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, events: [], uuidMap: {} };

    this.loadAcess = this.loadAcess.bind(this);
    this.handleData = this.handleData.bind(this);
    this.colorEvent = this.colorEvent.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderEvent = this.renderEvent.bind(this);
  }

  componentDidMount() {
    document.title = `User Access Logs | Flamingo Admin`;
    this.loadAcess();
  }

  /* NETWORKING */

  loadAcess() {
    this.setState({ loading: true });
    return api.get(`/user/${this.props.match.params.userId}/access`)
      .then(this.handleData)
      .catch(this.handleError);
  }

  handleData(res) {
    this.setState({ events: res.data.data.map(this.colorEvent), loading: false });
  }

  colorEvent(e) {
    const { uuidMap } = this.state;
    if (!e.uuid) {
      e.uuid = 'UNKN';
    }
    if (!uuidMap[e.uuid]) {
      uuidMap[e.uuid] = colours[Object.keys(uuidMap).length];
      this.setState({ uuidMap })
    }
    e.color = uuidMap[e.uuid];
    return e;
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  /* ACTIONS */

  renderEvent(e, i) {
    return (
      <div className="fm-user-access-event" key={i}>
        <p className="fm-user-access-event-uuid" style={{ backgroundColor: e.color }}>{ e.uuid.slice(0, 4) }</p>
        <p className="fm-user-access-event-detail">{ e.ipAddress }</p>
        <p className="fm-user-access-event-detail">{ e.userAgent }</p>
        <p className="fm-user-access-event-created">{ moment(e.createdAt).format('h:mma D/MM/YY') }</p>
      </div>
    );
  }

  /* RENDERS */

  render() {
    const { events, loading, error } = this.state;
    return (
      <div className="fm-user-access">
        <NavigationBar title="User Access Logs" loading={loading} showBack={true} />
        <div className="fm-user-access-events">
          { events.map(this.renderEvent) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default UserAccess;
