import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import Loading from 'components/common/loading';
import Input from 'components/common/input';
import api from 'helpers/api';
import colors from 'helpers/colors';

class VehicleSearch extends Component {
  constructor(props) {
    super(props);
    const query = props.match.params.query;
    this.state = { results: [], loading: true, query };
    this.renderResult = this.renderResult.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = throttle(500, this.search);
  }

  componentDidMount() {
    document.title = 'Search Results | Flamingo Admin';
    this.search(this.state.query);
  }

  handleSearchChange(query) {
    this.setState({ loading: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ loading: false });
      return;
    }
    api.post('/vehicle/search', { query, limit: 100 })
      .then((res) => this.setState({ results: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderResult(vehicle, i) {
    return (
      <Link to={{ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } }} key={i}>
        <div className="fm-vehicles-result">
          <p className="fm-vehicles-result-registration">{ vehicle.registration }</p>
          <p className="fm-vehicles-result-qr">{ vehicle.qrCode }</p>
          <div className="fm-vehicles-result-right">
            <p className="fm-vehicles-result-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status }</p>
            <p className="fm-vehicles-result-imei">{ vehicle.iotCode }</p>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className="fm-page fm-vehicles">
        <p className="fm-page-title">Search Results</p>
        <div className="fm-vehicles-search">
          <div className="fm-vehicles-search-bar">
            <Input placeholder="Search for Vehicle" value={this.state.query} onChange={this.handleSearchChange} />
          </div>
          <div className="fm-vehicles-results">
            { this.state.loading && <Loading /> }
            { this.state.results.map(this.renderResult) }
            { (!this.state.loading && this.state.results.length === 0 && this.state.query.length > 0) && <p className="fm-vehicles-results-empty">No vehicles found</p> }
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleSearch;
