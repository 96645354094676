import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Polygon } from 'components/common/map';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';

class ZoneNewMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zones: [],
      regions: [],
      showAll: false,
    };

    this.loadAllZones = this.loadAllZones.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.toggleAllZones = this.toggleAllZones.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    this.loadAllZones();
    this.loadRegions();
  }

  /* ------ Networking ------ */

  loadAllZones() {
    return api.get('/zone/area?active=true')
      .then((res) => this.setState({ zones: res.data.data }))
      .catch(this.props.onError);
  }

  loadRegions() {
    return api.get('/region/service-area')
      .then((res) => this.setState({ regions: res.data.data }))
      .catch(this.props.onError);
  }

  /* ------ Controls ------ */

  toggleAllZones() {
    this.setState({ showAll: !this.state.showAll });
  }

  handleZoneChange(coordinates) {
    this.setState({ coordinates });
  }

  handleClick(latitude, longitude) {
    if (this.props.coordinates.length > 0) {
      return;
    }
    const coordinates = [
      { latitude: latitude + 0.0005, longitude: longitude + 0.0005 },
      { latitude: latitude - 0.0005, longitude: longitude + 0.0005 },
      { latitude: latitude - 0.0005, longitude: longitude - 0.0005 },
      { latitude: latitude + 0.0005, longitude: longitude - 0.0005 },
    ];
    this.props.onCoordinates(coordinates);
  }

  handleNext() {
    history.push('/zone/new/details')
  }

  /* ------ Renders ------ */

  render() {
    const { coordinates } = this.props;
    const { zones, regions, showAll } = this.state;

    const actions = [
      { onClick: this.toggleAllZones, icon: showAll ? 'eye-slash' : 'eye', title: 'Show/Hide Other Zones' },
      { onClick: this.handleNext, icon: 'check', title: 'Next' },
    ];

    return (
      <div className="fm-zone-new">
        <NavigationBar title="New Zone" showBack={true} rightActions={actions} />
        <div className="fm-zone-new-main">
          <div className="fm-zone-new-map">
            <Map google={window.google} onClick={this.handleClick} isGlobal>
              { coordinates.length > 0 && <Polygon paths={ coordinates.map((c) => ({ lat: c.latitude, lng: c.longitude })) } onChange={this.props.onCoordinates} strokeWeight={2} fillOpacity={0.2} editable={true} /> }
              { showAll && zones.map((z, i) => <Polygon key={i} paths={ z.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeWeight={0} fillColor={colors.zone[z.type]} fillOpacity={0.2} />) }
              { showAll && regions.map((region, i) => <Polygon key={i} paths={ region.serviceArea.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} clickable={false} />) }
            </Map>
          </div>
        </div>
      </div>
    );
  }
}

export default ZoneNewMap;
