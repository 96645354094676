import React, { Component } from 'react';
import moment from 'moment';
import LoadingLine from 'components/common/loading-line';
import api from 'helpers/api';

const tabs = [
  { label: 'Status Changes', key: 'statusChanges' },
];

const helmetColors = {
  'unlocked': '#4834D4',
  'locked': '#6AB04C',
  'missing': '#EB3B5A',
  'added': '#F0932B',
};

class HelmetLockTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tab: false,
      statusChanges: [],
      hires: [],
    };

    this.setTab = this.setTab.bind(this);
    this.loadTab = this.loadTab.bind(this);

    this.renderStatusChange = this.renderStatusChange.bind(this);
    this.renderHire = this.renderHire.bind(this);
  }

  setTab(tab) {
    this.setState({ tab, loading: this.state[tab].length === 0 }, this.loadTab);
  }

  loadTab() {
    const helmetLockId = this.props.helmetLockId;
    switch (this.state.tab) {
      case 'statusChanges':
        return api.get(`/helmet/lock/${helmetLockId}/status-changes`)
          .then((res) => this.setState({ loading: this.state.tab !== 'statusChanges', statusChanges: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      case 'hires':
        return api.get(`/helmet/lock/${helmetLockId}/hires`)
          .then((res) => this.setState({ loading: this.state.tab !== 'hires', hires: res.data.data }))
          .catch(() => this.setState({ loading: false }));
      default:
        return;
    }
  }

  renderStatusChange(change, i) {
    return (
      <div key={i} className="fm-helmet-lock-tab-row">
        <div className="fm-vehicle-tab-row-content">
          <p className="fm-vehicle-tab-row-tag" style={{ backgroundColor: helmetColors[change.eventType] }}>{ change.eventType.toUpperCase() }</p>
          <p className="fm-vehicle-tab-row-title">{ moment(change.eventTime).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-vehicle-tab-row-detail">{ change.eventType.charAt(0).toUpperCase() + change.eventType.slice(1) } { change.user ? `by ${change.user.firstName} ${change.user.lastName}` : '' } - { parseInt(change.batteryPercent * 100) }%.</p>
      </div>
    );
  }

  renderHire(hire, i) {
    return (
      <div key={i} className="fm-helmet-lock-tab-row">
        <div className="fm-helmet-lock-tab-row-content">
          <p className="fm-helmet-lock-tab-row-tag" style={{ backgroundColor: helmetColors[hire.status] }}>{ hire.status.toUpperCase() }</p>
          <p className="fm-helmet-lock-tab-row-title">{ moment(hire.startTime).format('h:mma D/M/YY') }</p>
        </div>
        <p className="fm-helmet-lock-tab-row-detail">{ hire.endTime ? `Helmet returned at ${moment(hire.endTime).format('h:mma D/M/YY')}.` : 'Helmet not returned.' }</p>
      </div>
    );
  }

  render() {
    const { loading, tab, statusChanges, hires } = this.state;
    return (
      <>
        <div className="fm-helmet-lock-tabs">
          { tabs.map((t, i) => <div key={i} className={`fm-helmet-lock-tab${ tab === t.key ? ' fm-helmet-lock-tab-active' : '' }`} onClick={() => this.setTab(t.key)}>{ t.label }</div>) }
        </div>
        <div className="fm-helmet-lock-tabs-loading">
          { loading && <LoadingLine /> }
        </div>
        <div className="fm-helmet-lock-tab-content">
          { (tab === 'statusChanges') && ((statusChanges.length === 0 && !loading) ? <p className="fm-helmet-lock-tab-content-empty">No status changes found.</p> : statusChanges.map(this.renderStatusChange)) }
          { (tab === 'hires') && ((hires.length === 0 && !loading) ? <p className="fm-helmet-lock-tab-content-empty">No helmet hires found.</p> : hires.map(this.renderHire)) }
          { !tab && <p className="fm-helmet-lock-tab-content-empty">Select a tab above.</p> }
        </div>
      </>
    );
  }
}

export default HelmetLockTabs;
