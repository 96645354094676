import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Polygon } from 'components/common/map';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';
import parseCoordinates from 'helpers/coordinates';

class ZoneEdit extends Component {
  constructor(props) {
    super(props);
    const zone = props.location.state ? props.location.state.zone : {};
    const coordinates = zone.polygon ? parseCoordinates(zone.polygon) : [];

    this.state = {
      zone,
      coordinates,
      zones: [],
      regions: [],
      showAll: false,
      error: false,
      loading: !zone.id,
    };

    this.loadZone = this.loadZone.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.parseZone = this.parseZone.bind(this);
    this.loadAllZones = this.loadAllZones.bind(this);
    this.toggleAllZones = this.toggleAllZones.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Zone Details | Flamingo Admin';

    if (!this.state.zone.id) {
      this.loadZone();
    }
    this.loadAllZones();
    this.loadRegions();
  }

  /* ------ Networking ------ */

  loadAllZones() {
    return api.get('/zone/area?active=true')
      .then((res) => this.setState({ zones: res.data.data.filter((z) => parseInt(this.props.match.params.zoneId) !== z.id) }))
      .catch(this.handleError);
  }

  loadRegions() {
    return api.get('/region/service-area')
      .then((res) => this.setState({ regions: res.data.data }))
      .catch(this.handleError);
  }

  loadZone() {
    return api.get('/zone/' + this.props.match.params.zoneId)
      .then((res) => this.parseZone(res.data.data))
      .catch(this.handleError);
  }

  parseZone(zone) {
    const coordinates = parseCoordinates(zone.polygon);
    this.setState({ zone, coordinates, loading: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Controls ------ */

  toggleAllZones() {
    this.setState({ showAll: !this.state.showAll });
  }

  handleReset() {
    this.setState({ coordinates: this.state.zone.polygon || [] });
  }

  handleSave() {
    if (window.confirm('Are you sure you want to adjust this zone?')) {
      this.setState({ loading: true });
      const { coordinates } = this.state;
      coordinates.push(coordinates[0]);
      return api.post(`/zone/${this.props.match.params.zoneId}`, { coordinates })
        .then(() => history.goBack())
        .catch(this.handleError);
    }
  }

  handleZoneChange(coordinates) {
    this.setState({ coordinates });
  }

  /* ------ Renders ------ */

  render() {
    const { loading, zone, zones, regions, coordinates, showAll, error } = this.state;

    const actions = [
      { onClick: this.toggleAllZones, icon: showAll ? 'eye-slash' : 'eye', title: 'Show/Hide Other Zones' },
      { onClick: this.handleReset, icon: 'times', title: 'Reset' },
      { onClick: this.handleSave, icon: 'check', title: 'Save' },
    ];

    return (
      <div className="fm-zone-edit">
        <NavigationBar title={ zone.name || 'Zone Details' } loading={loading} showBack={true} rightActions={ loading ? [] : actions } />
        <div className="fm-zone-edit-main">
          <div className="fm-zone-edit-map">
            <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
              { coordinates.length > 0 && <Polygon paths={ coordinates.map((c) => ({ lat: c.latitude, lng: c.longitude })) } onChange={this.handleZoneChange} strokeColor={colors.zone[zone.type]} strokeWeight={2} fillColor={colors.zone[zone.type]} fillOpacity={0.2} editable={true} /> }
              { showAll && zones.map((z, i) => <Polygon key={i} paths={ z.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeWeight={0} fillColor={colors.zone[z.type]} fillOpacity={0.2} />) }
              { showAll && regions.map((region, i) => <Polygon key={i} paths={ region.serviceArea.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} clickable={false} />) }
            </Map>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ZoneEdit;
