import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import colors from 'helpers/colors';

import TaskRouteNewMap from './task-route-new-map';

class TaskRouteNew extends Component {
  constructor() {
    super();
    this.state = { tasks: [], creating: false };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.renderTask = this.renderTask.bind(this);
  }

  componentDidMount() {
    document.title = `New Route | Flamingo Admin`;
  }

  handleAdd(task) {
    const tasks = this.state.tasks;
    if (tasks.findIndex((t) => t.id === task.id) === -1) {
      tasks.push(task);
      this.setState({ tasks });
    }
  }

  handleRemove(index) {
    const tasks = this.state.tasks;
    tasks.splice(index, 1);
    this.setState({ tasks });
  }

  handleCreate() {
    const { tasks, creating } = this.state;
    if (creating || tasks.length < 2) {
      return;
    }
    const appUrl = `/task/route/${tasks.map((t) => t.id).join(',')}`;
    const finalTask = tasks.pop();
    const waypoints = `&waypoints=${tasks.map((t) => `${t.vehicle.latitude},${t.vehicle.longitude}`).join('|')}`;
    const destination = `&destination=${finalTask.vehicle.latitude},${finalTask.vehicle.longitude}`;
    const url = `https://www.google.com/maps/dir/?api=1&${waypoints + destination}`;
    window.open(url, '_blank');
    history.push(appUrl);
  }

  renderTask(task, i) {
    return (
      <div className="fm-task-route-new-selected-vehicle" key={i} onClick={() => this.handleRemove(i)}>
        <div className="fm-task-route-new-selected-vehicle-status" style={{ backgroundColor: colors.task[task.type] }}></div>
        <p className="fm-task-route-new-selected-vehicle-rego">{ task.vehicle.registration }</p>
      </div>
    );
  }

  render() {
    const { tasks, creating } = this.state;

    return (
      <div className="fm-task-route-new">
        <NavigationBar title="New Task Route" loading={creating} showBack={true} />
        <div className="fm-task-route-new-box">
          <TaskRouteNewMap selectedTasks={tasks} onAdd={this.handleAdd} />
        </div>
        <div className="fm-task-route-new-selected">
          { tasks.length === 0 && <p className="fm-task-route-new-note">Select tasks in pickup order.</p> }
          { tasks.map(this.renderTask) }
        </div>
        <div className="fm-task-route-new-options">
          <button className="fm-task-route-new-button" onClick={this.handleCreate}>{ creating ? 'Loading...' : 'Create Route' }</button>
          <br />
        </div>
      </div>
    );
  }
}

export default TaskRouteNew;
