import React, { Component } from 'react';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import api from 'helpers/api';

class SubscriptionNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: {
        regionId: 'all',
        name: '',
        minutes: '20',
        minutesTerm: 'weekly',
        unlock: '0',
        perMin: '30',
        includesGroups: true,
        timezone: 'Pacific/Auckland',
        terms: `This XXX Go Pass is valid for approved XXX staff only. This Pass includes XXX minutes of ride time per Week. A Week is defined as starting at 00:00 on Sunday. A rate of XXXc per minute applies after XXX minutes of ride time per Week. Flamingo's standard Terms of Service apply.`,
        planName: '',
        planCost: '0',
        planCurrency: 'NZD',
        planTagline: 'FREE',
        planDays: '7',
        planAutoRenews: true,
        domain: '',
        facadeName: '',
        facadeSlug: '',
      },
      regions: [],
      loading: true,
      error: false,
    };

    this.createSubscription = this.createSubscription.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = `New Subscription | Flamingo Admin`;
    this.loadRegions();
  }

  /* NETWORKING */

  loadRegions() {
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  createSubscription() {
    this.setState({ loading: true });
    const { subscription } = this.state;
    const subscriptionData = {
      name: subscription.name,
      minutes: parseInt(subscription.minutes),
      minutesTerm: subscription.minutesTerm,
      unlock: parseInt(subscription.unlock),
      perMin: parseInt(subscription.perMin),
      includesGroups: (subscription.includesGroups === 'true' || subscription.includesGroups === true),
      timezone: subscription.timezone,
      terms: subscription.terms,
      planName: subscription.planName,
      planCost: parseInt(subscription.planCost),
      planCurrency: subscription.planCurrency,
      planTagline: subscription.planTagline,
      planDays: parseInt(subscription.planDays),
      planAutoRenews: (subscription.planAutoRenews === 'true' || subscription.planAutoRenews === true),
      domain: subscription.domain,
      facadeName: subscription.facadeName,
      facadeSlug: subscription.facadeSlug,
    };
    if (subscription.regionId === 'all') {
      subscriptionData.includesRegions = true;
    } else {
      subscriptionData.regionId = subscription.regionId;
      subscriptionData.includesRegions = false;
    }
    return api.post('/subscription', subscriptionData)
      .then((res) => history.push(`/subscription/${res.data.data.id}`))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  onChange(e) {
    const subscription = this.state.subscription;
    subscription[e.target.getAttribute('okey')] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ subscription, error: false })
  }

  /* RENDERS */

  render() {
    const { subscription, regions, loading, error } = this.state;

    const terms = [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
    ];
    const timezones = [
      { value: 'Pacific/Auckland', label: 'New Zealand' },
    ];
    const currencies = [
      { value: 'NZD', label: 'NZD' },
    ];
    const toggles = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ];

    return (
      <div className="fm-subscription-new">
        <NavigationBar title="Create Subscription" showBack={true} loading={loading} />
        <div className="fm-subscription-new-form">
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Subscription Name</p>
            <input value={subscription.name || ''} onChange={this.onChange} okey="name" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Minutes</p>
            <input value={subscription.minutes || ''} onChange={this.onChange} okey="minutes" className="fm-input" type="number" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Minutes Term</p>
            <select value={subscription.minutesTerm || ''} disabled={loading} onChange={this.onChange} okey="minutesTerm" className="fm-input fm-input-select">
              { terms.map((term, i) => <option value={term.value} key={i}>{ term.label }</option>) }
            </select>
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Region</p>
            <select value={subscription.regionId || ''} disabled={loading} onChange={this.onChange} okey="regionId" className="fm-input fm-input-select">
              <option value="all">All Regions</option>
              { regions.map((region, i) => <option value={region.id} key={i}>{ region.name }</option>) }
            </select>
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Unlock Fee</p>
            <input value={subscription.unlock || ''} onChange={this.onChange} okey="unlock" className="fm-input" type="number" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Per Minute</p>
            <input value={subscription.perMin || ''} onChange={this.onChange} okey="perMin" className="fm-input" type="number" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Include Group Rides</p>
            <select value={subscription.includesGroups || ''} disabled={loading} onChange={this.onChange} okey="includesGroups" className="fm-input fm-input-select">
              { toggles.map((item, i) => <option value={item.value} key={i}>{ item.label }</option>) }
            </select>
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Timezone</p>
            <select value={subscription.timezone || ''} disabled={loading} onChange={this.onChange} okey="timezone" className="fm-input fm-input-select">
              { timezones.map((timezone, i) => <option value={timezone.value} key={i}>{ timezone.label }</option>) }
            </select>
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Terms</p>
            <input value={subscription.terms || ''} onChange={this.onChange} okey="terms" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Plan Name</p>
            <input value={subscription.planName || ''} onChange={this.onChange} okey="planName" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Plan Cost</p>
            <input value={subscription.planCost || ''} onChange={this.onChange} okey="planCost" className="fm-input" type="number" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Currency</p>
            <select value={subscription.planCurrency || ''} disabled={loading} onChange={this.onChange} okey="planCurrency" className="fm-input fm-input-select">
              { currencies.map((currency, i) => <option value={currency.value} key={i}>{ currency.label }</option>) }
            </select>
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Plan Tagline</p>
            <input value={subscription.planTagline || ''} onChange={this.onChange} okey="planTagline" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Plan Days</p>
            <input value={subscription.planDays || ''} onChange={this.onChange} okey="planDays" className="fm-input" type="number" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Plan Auto Renews</p>
            <select value={subscription.planAutoRenews || ''} disabled={loading} onChange={this.onChange} okey="planAutoRenews" className="fm-input fm-input-select">
              { toggles.map((item, i) => <option value={item.value} key={i}>{ item.label }</option>) }
            </select>
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Email Domain</p>
            <input value={subscription.domain || ''} onChange={this.onChange} okey="domain" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Facade Name</p>
            <input value={subscription.facadeName || ''} onChange={this.onChange} okey="facadeName" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-subscription-new-input">
            <p className="fm-input-label">Facade Slug</p>
            <input value={subscription.facadeSlug || ''} onChange={this.onChange} okey="facadeSlug" className="fm-input" disabled={loading} />
          </div>
          <button className="fm-subscription-new-button" onClick={this.createSubscription} disabled={loading}>{ loading ? 'Loading...' : 'Create Subscription' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SubscriptionNew;
