import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';

class StaffMap extends Component {
  constructor() {
    super();
    this.state = { users: [], loading: true };
    this.loadUsers = this.loadUsers.bind(this);
    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    document.title = 'Staff Map | Flamingo Admin';
    this.loadUsers();
  }

  loadUsers() {
    this.setState({ loading: true });
    return api.get('/user/staff-map')
      .then((res) => this.setState({ users: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderMarker(user, i) {
    const position = { lat: user.admin.latitude, lng: user.admin.longitude };
    const title = `${ user.firstName } ${ user.lastName } - ${ moment(user.admin.updatedAt).fromNow() }`;
    const label = { text: `${ user.firstName.charAt(0) + user.lastName.charAt(0) }`, color: 'white' };
    return <Marker key={i} position={position} title={title} label={label} onClick={() => window.alert(title)} />;
  }

  render() {
    const { users, loading } = this.state;
    const refresh = { onClick: this.loadUsers, icon: 'sync-alt' };

    return (
      <div className="fm-staff-map">
        <NavigationBar title="Staff Map" loading={loading} rightActions={[refresh]} />
        <div className="fm-staff-map-container">
          <Map google={window.google} isGlobal>
            { users.map(this.renderMarker) }
          </Map>
        </div>
      </div>
    );
  }
}

export default StaffMap;
