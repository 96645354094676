import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import Input from 'components/common/input';
import api from 'helpers/api';

class SubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      allSubscriptions: [],
      statistics: {},
      query: '',
      loading: true,
      error: false,
    };

    this.loadSubscription = this.loadSubscription.bind(this);
    this.loadSubscriptionStatistics = this.loadSubscriptionStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.renderSubscription = this.renderSubscription.bind(this);
    this.renderPlan = this.renderPlan.bind(this);
  }

  componentDidMount() {
    document.title = `Subscriptions | Flamingo Admin`;
    this.loadSubscription();
    this.loadSubscriptionStatistics();
  }

  /* NETWORKING */

  loadSubscription() {
    this.setState({ loading: true });
    return api.get(`/subscription`)
      .then((res) => this.setState({ subscriptions: res.data.data, allSubscriptions: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  loadSubscriptionStatistics() {
    return api.get(`/subscription/statistics`)
      .then((res) => this.setState({ statistics: res.data.data.activeSubscriptions }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  handleSearchChange(query) {
    const { allSubscriptions } = this.state;
    if (query.length < 2) {
      return this.setState({ subscriptions: allSubscriptions, query });
    }
    const lowerQuery = query.toLowerCase();
    const subscriptions = allSubscriptions.filter((subscription) => {
      if (subscription.name.toLowerCase().includes(lowerQuery)) {
        return true;
      }
      if (subscription.region) {
        if (subscription.region.name.toLowerCase().includes(lowerQuery)) {
          return true;
        }
      }
      return false;
    });
    this.setState({ subscriptions, query });
  }

  /* RENDERS */

  renderPlan(plan, i) {
    const { statistics } = this.state;
    return (
      <div className="fm-subscription-list-item-plan" key={i}>
        <p className="fm-subscription-list-item-plan-name">{ plan.name }</p>
        <div className="fm-subscription-list-item-plan-container">
          <div className="fm-subscription-list-item-plan-details">
            <p className="fm-subscription-list-item-plan-label">${ plan.cost / 100 } per { plan.days } days</p>
            { plan.domains.length > 0 && <p className="fm-subscription-list-item-plan-label">{ plan.domains.map((domain) => `@${domain.domain}`).join(', ') }</p> }
          </div>
          <p className="fm-subscription-list-item-plan-label">{ statistics[plan.id] || 0 } active user{ statistics[plan.id] === 1 ? '' : 's' }</p>
        </div>
      </div>
    );
  }

  renderSubscription(subscription, i) {
    return (
      <Link className="fm-subscription-list-item" key={i} to={{ pathname: `/subscription/${subscription.id}`, state: { subscription } }}>
        <p className="fm-subscription-list-item-title">{ subscription.name }</p>
        <div className="fm-subscription-list-item-details">
          { subscription.region && <p className="fm-subscription-list-item-details-label">{ subscription.region.name }</p> }
          { subscription.region && <span className="fm-subscription-list-item-details-spacer"></span> }
          <p className="fm-subscription-list-item-details-label">{ subscription.minutes } mins { subscription.minutesTerm }</p>
          <span className="fm-subscription-list-item-details-spacer"></span>
          <p className="fm-subscription-list-item-details-label">${ subscription.unlock / 100 } to unlock, { subscription.perMin }c per min</p>
          { subscription.facade && <span className="fm-subscription-list-item-details-spacer"></span> }
          { subscription.facade && <p className="fm-subscription-list-item-details-label">/join-plan/{ subscription.facade.slug }</p> }
        </div>
        <div className="fm-subscription-list-item-plans">
          { subscription.plans.map(this.renderPlan) }
        </div>
      </Link>
    );
  }

  render() {
    const { subscriptions, query, loading, error } = this.state;

    return (
      <div className="fm-subscription-list">
        <NavigationBar title="Subscriptions" loading={loading} />
        <div className="fm-subscription-list-search">
          <Input placeholder="Search for a subscription" value={query} autoFocus={true} onChange={this.handleSearchChange} />
        </div>
        <div className="fm-subscription-list-items">
          { subscriptions.map(this.renderSubscription) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SubscriptionList;
