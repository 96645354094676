import Promise from 'bluebird';

import api from './api';

let userCache = null;

const getCurrentUser = (forceRefresh = false) => {
  if (userCache && !forceRefresh) {
    return Promise.resolve(userCache);
  }

  return api.get('/user')
    .then((res) => {
      userCache = res.data.data;
      return userCache;
    });
};

const refreshUser = () => {
  return api.get('/user')
    .then((res) => updateUser(res.data.data));
};

const updateUser = (newUser) => {
  userCache = newUser;
  return userCache;
};

const clearUser = () => {
  userCache = null;
};

const isManager = () => {
  if (userCache && userCache.admin) {
    return userCache.admin.isManager;
  }
  return false;
};

const user = { getCurrentUser, refreshUser, updateUser, clearUser, isManager };

export default user;
