import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const years = [
  moment().format('YYYY'),
  moment().add(1, 'year').format('YYYY'),
  moment().add(2, 'year').format('YYYY'),
  moment().add(3, 'year').format('YYYY'),
  moment().add(4, 'year').format('YYYY'),
];
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

class PlanApplications extends Component {
  constructor() {
    super();
    this.state = {
      applications: [],
      expiryMonth: false,
      expiryYear: false,
      expiryOption: false,
      lastResultClass: 'fm-plans-image-default',
      loading: true,
    };

    this.loadApplications = this.loadApplications.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleMismatch = this.handleMismatch.bind(this);
    this.handleExpired = this.handleExpired.bind(this);
    this.handleInvalid = this.handleInvalid.bind(this);
    this.handleIgnore = this.handleIgnore.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.onMonth = this.onMonth.bind(this);
    this.onYear = this.onYear.bind(this);
    this.onExpiryDate = this.onExpiryDate.bind(this);
    this.renderMonth = this.renderMonth.bind(this);
    this.renderYear = this.renderYear.bind(this);
  }

  componentDidMount() {
    document.title = 'Plan Applications | Flamingo Admin';
    this.loadApplications();
  }

  loadApplications() {
    this.setState({ loading: true });
    return api.get('/plan/applications')
      .then((res) => this.setState({ applications: res.data.data, lastResultClass: 'fm-plans-image-default', loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleMismatch() {
    return this.handleStatus('declined', 'detailsMismatch');
  }

  handleExpired() {
    return this.handleStatus('declined', 'expired');
  }

  handleInvalid() {
    return this.handleStatus('declined', 'invalid');
  }

  handleIgnore() {
    return this.handleStatus('declined', 'ignore');
  }

  handleApprove() {
    const application = this.state.applications[0];
    if (['STUDENT', 'COMMUNITY', 'HAPAI', 'TOTALMOBILITY'].includes(application.plan)) {
      return this.setState({ expiryOption: true, expiryYear: false, expiryMonth: false });
    }
    return this.handleStatus('approved');
  }

  handleStatus(status, reason = false, expiryDate = false) {
    const applications = this.state.applications;
    const application = applications[0];
    if (!application) {
      return this.loadApplications();
    }

    applications.shift();
    this.setState({ applications, lastResultClass: status === 'declined' ? 'fm-plans-image-invalid' : 'fm-plans-image-approved' });
    setTimeout(() => this.setState({ lastResultClass: 'fm-plans-image-default' }), 500);

    const data = {
      planApplicationId: application.id,
      status,
    };
    if (reason) {
      data.reason = reason;
    }
    if (expiryDate) {
      data.expiryDate = expiryDate;
    }

    return api.post('/plan/application', data)
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  handleSuccess() {
    if (this.state.applications.length === 0) {
      this.loadApplications();
    }
  }

  onMonth(month) {
    this.setState({ expiryMonth: month }, this.onExpiryDate);
  }

  onYear(year) {
    this.setState({ expiryYear: year }, this.onExpiryDate);
  }

  onExpiryDate() {
    const { expiryMonth, expiryYear } = this.state;
    if (expiryMonth && expiryYear) {
      const month = months.indexOf(expiryMonth) + 1;
      const expiryDate = moment(`${expiryYear}-${month}-01`, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DDTHH:mm');
      return this.setState({ expiryOption: false, expiryYear: false, expiryMonth: false }, () => this.handleStatus('approved', false, expiryDate));
    }
  }

  renderMonth(month, i) {
    const { expiryMonth } = this.state;
    const buttonClass = expiryMonth === month ? 'fm-plans-controls-expiry-month fm-plans-controls-expiry-month-active' : 'fm-plans-controls-expiry-month';
    return <button key={i} className={buttonClass} onClick={() => this.onMonth(month)}>{ month }</button>;
  }

  renderYear(year, i) {
    const { expiryYear } = this.state;
    const buttonClass = expiryYear === year ? 'fm-plans-controls-expiry-year fm-plans-controls-expiry-year-active' : 'fm-plans-controls-expiry-year';
    return <button key={i} className={buttonClass} onClick={() => this.onYear(year)}>{ year }</button>;
  }

  render() {
    const { loading, applications, expiryOption, lastResultClass } = this.state;
    const refreshAction = { onClick: this.loadApplications, icon: 'sync-alt' };
    const title = applications.length > 100 ? `Plan Applications (100+)` : (applications.length === 0 ? `Plan Applications` : `Plan Applications (${applications.length})`);

    const application = applications[0];

    return (
      <div className="fm-plans" onKeyDown={this.onKeyPressed} tabIndex="0">
        <NavigationBar title={title} loading={loading} rightActions={[refreshAction]} />
        {
          application ? (
            <div className="fm-plans-content">
              <div className={`fm-plans-image ${lastResultClass}`} style={{ backgroundImage: `url('${application.identificationPhotoUrl}')` }}></div>
              <div className="fm-plans-details">
                <div className="fm-plans-user">
                  <p className="fm-plans-user-name">{application.user.firstName} {application.user.lastName}</p>
                  <p className="fm-plans-user-email">{application.user.email}</p>
                  <p className="fm-plans-user-plan">Plan: {application.plan}</p>
                  <p className="fm-plans-user-time">{moment(application.createdAt).fromNow()}</p>
                </div>
                <div className="fm-plans-controls">
                  {
                    expiryOption ? (
                      <div className="fm-plans-controls-expiry">
                        <p className="fm-plans-controls-expiry-title">Select Expiry</p>
                        <p className="fm-plans-controls-expiry-note">If there is no expiry on the card, select a period one year from now.</p>
                        <div className="fm-plans-controls-expiry-months">
                          { months.map(this.renderMonth) }
                        </div>
                        <div className="fm-plans-controls-expiry-years">
                          { years.map(this.renderYear) }
                        </div>
                      </div>
                    ) : (
                      <>
                        <button className="fm-plans-controls-approved" onClick={this.handleApprove}>Approve</button>
                        <div className="fm-plans-controls-invalids">
                          <button className="fm-plans-controls-invalid" onClick={this.handleMismatch}>Name</button>
                          <button className="fm-plans-controls-invalid" onClick={this.handleExpired}>Expired</button>
                          <button className="fm-plans-controls-invalid" onClick={this.handleInvalid}>Invalid</button>
                          <button className="fm-plans-controls-invalid" onClick={this.handleIgnore}>Ignore</button>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="fm-plans-content">
              <p className="fm-plans-empty">{ loading ? `Loading applications...` : `No applications outstanding!` }</p>
            </div>
          )
        }
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default PlanApplications;
