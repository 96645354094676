import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Polygon } from 'components/common/map';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import colors from 'helpers/colors';
import parseCoordinates from 'helpers/coordinates';

class RegionServiceAreaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: {},
      serviceArea: [],
      error: false,
      loading: true,
    };

    this.loadRegion = this.loadRegion.bind(this);
    this.parseServiceArea = this.parseServiceArea.bind(this);
    this.handleServiceAreaChange = this.handleServiceAreaChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Edit Service Area | Flamingo Admin';
    this.loadRegion();
  }

  /* ------ Networking ------ */

  loadRegion() {
    return api.get(`/region/${this.props.match.params.regionId}/service-area`)
      .then((res) => this.parseServiceArea(res.data.data))
      .catch(this.handleError);
  }

  parseServiceArea(region) {
    const serviceArea = parseCoordinates(region.serviceArea);
    this.setState({ region, serviceArea, loading: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Controls ------ */

  handleReset() {
    this.setState({ serviceArea: this.state.region.serviceArea || [] });
  }

  handleSave() {
    if (window.confirm('Are you sure you want to adjust this region?')) {
      this.setState({ loading: true });
      const { serviceArea } = this.state;
      serviceArea.push(serviceArea[0]);
      return api.post(`/region/${this.props.match.params.regionId}/service-area`, { coordinates: serviceArea })
        .then(() => this.setState({ loading: false }, () => alert('Region updated.')))
        .catch(this.handleError);
    }
  }

  handleServiceAreaChange(serviceArea) {
    this.setState({ serviceArea });
  }

  /* ------ Renders ------ */

  render() {
    const { loading, region, serviceArea, error } = this.state;

    const actions = [
      { onClick: this.handleReset, icon: 'times', title: 'Reset' },
      { onClick: this.handleSave, icon: 'check', title: 'Save' },
    ];

    return (
      <div className="fm-region-service-area-edit">
        <NavigationBar title={ region.name || 'Edit Service Area' } loading={loading} rightActions={ loading ? [] : actions } />
        <div className="fm-region-service-area-edit-main">
          <div className="fm-region-service-area-edit-map">
            <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
              { serviceArea.length > 0 && <Polygon paths={ serviceArea.map((c) => ({ lat: c.latitude, lng: c.longitude })) } onChange={this.handleServiceAreaChange}  strokeColor={colors.zone.serviceArea} strokeWeight={2} fillOpacity={0} editable={true} /> }
            </Map>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default RegionServiceAreaEdit;
