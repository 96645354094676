import React, { Component } from 'react';

class StaffMember extends Component {
  componentDidMount() {
    document.title = 'Staff | Flamingo Admin';
  }

  render() {
    return (
      <div className="fm-page fm-staff">
        <p className="fm-page-title">Jacksen Leve</p>
        <p className="fm-text">Staff Member</p>
      </div>
    );
  }
}

export default StaffMember;
