import React, { Component } from 'react';
import moment from 'moment';
import Input from 'components/common/input';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

const performanceKeys = ['inspections', 'repairPoints', 'batteryOpens', 'rebalanceCompleted', 'reparkCompleted', 'pickupCompleted', 'chargeCompleted', 'tasksCompleted', 'hubDeploys', 'reportsCreated', 'hours', 'points', 'pointsPerHour'];
const performanceLabels = ['Inspections', 'Repairs', 'Battery Swaps', 'Rebalance', 'Repark', 'Pickup', 'Charge', 'Tasks', 'Hub Deploys', 'Reports', 'Hours', 'Points', 'Points/Hour'];

class PerformanceOverview extends Component {
  constructor() {
    super();
    const maxDate = moment().format('YYYY-MM-DD');
    this.state = { users: [], data: {}, totals: {}, period: 'day', date: maxDate, maxDate, loading: false };
    this.loadData = this.loadData.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.renderUser = this.renderUser.bind(this);
  }

  componentDidMount() {
    document.title = 'Team Performance | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const { period, date } = this.state;
    api.post('/performance', { period, date })
      .then(this.handleData)
      .catch(this.handleError);
  }

  handleData(res) {
    const users = res.data.data.users;

    const data = performanceKeys.reduce((d, c) => {
      d[c] = res.data.data[c];
      return d;
    }, {});

    const totals = performanceKeys.reduce((t, c) => {
      t[c] = Object.values(res.data.data[c]).reduce((a, b) => a + b, 0);
      return t;
    }, {});

    this.setState({ users, totals, data, loading: false })
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  handlePeriodChange(period) {
    this.setState({ period });
  }

  renderUser(user, i) {
    const data = this.state.data;
    return (
      <tr key={i} className="fm-performance-overview-row" onClick={() => history.push(`/performance/${user.id}`)}>
        <td className="fm-performance-overview-row-name">{ user.firstName } { user.lastName }</td>
        { performanceKeys.map((key, ii) => <td className="fm-performance-overview-row-value" key={ii}>{ Math.round((data[key][user.id] || 0) * 100) / 100 }</td>) }
      </tr>
    );
  }

  pointsHelp() {
    alert('Points\n\nReports: 1\nHub Deploys: 3\nInspections: (Varies depending on repair)\nBattery Swap Tasks: 4\nBattery Swap (without task): 2\nRebalance: 2\nPick Up: 7\nCharge: 7\nRepark: 2');
  }

  render() {
    const { loading, error, users, totals } = this.state;

    const helpAction = { onClick: this.pointsHelp, icon: 'info-circle' };
    const periodOptions = [{ label: 'On day', value: 'day' }, { label: 'For week ending', value: 'week' }, { label: 'For fortnight ending', value: 'fortnight' }];

    return (
      <div className="fm-performance-overview">
        <NavigationBar title="Team Performance" loading={loading} rightActions={[helpAction]} />
        <div className="fm-performance-overview-options">
          <Input type="select" value={this.state.period} onChange={this.handlePeriodChange} options={periodOptions} />
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} max={this.state.maxDate} />
          <button className="fm-performance-overview-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-performance-overview-list">
          <table className="fm-performance-overview-table">
            <thead>
              <tr>
                <th></th>
                { performanceLabels.map((label, i) => <th className="fm-performance-overview-column-header" key={i}>{ label }</th>) }
              </tr>
            </thead>
            <tbody>
              { users.map(this.renderUser) }
              <tr className="fm-performance-overview-row">
                <td className="fm-performance-overview-row-total-label">Total</td>
                { performanceKeys.map((key, i) => <td className="fm-performance-overview-row-total" key={i}>{ Math.round((totals[key] || 0) * 100) / 100 }</td>) }
              </tr>
            </tbody>
          </table>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default PerformanceOverview;
