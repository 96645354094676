import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import SubscriptionUser from './components/subscription-user/';
import SubscriptionPlan from './components/subscription-plan/';
import SubscriptionList from './components/subscription-list/';
import SubscriptionNew from './components/subscription-new/';

class Subscription extends Component {
  render() {
    return (
      <Switch>
        <Route path="/subscription/user/:userSubscriptionId" component={SubscriptionUser} />
        <Route path="/subscription/new" component={SubscriptionNew} />
        <Route path="/subscription/:subscriptionId" component={SubscriptionPlan} />
        <Route path="/subscription" component={SubscriptionList} />
      </Switch>
    );
  }
}

export default Subscription;
