import React, { Component } from 'react';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

class SubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = { subscription: {}, usage: {}, loading: true, error: false };

    this.loadSubscription = this.loadSubscription.bind(this);
    this.loadSubscriptionUsage = this.loadSubscriptionUsage.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleFacade = this.handleFacade.bind(this);
    this.renderPlan = this.renderPlan.bind(this);
  }

  componentDidMount() {
    document.title = `Subscriptions | Flamingo Admin`;
    this.loadSubscription(this.props.match.params.subscriptionId);
    this.loadSubscriptionUsage(this.props.match.params.subscriptionId);
  }

  /* NETWORKING */

  loadSubscription(subscriptionId) {
    this.setState({ loading: true });
    return api.get(`/subscription/${subscriptionId}`)
      .then((res) => this.setState({ subscription: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  loadSubscriptionUsage(subscriptionId) {
    this.setState({ loading: true });
    return api.get(`/subscription/${subscriptionId}/usage`)
      .then((res) => this.setState({ usage: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  handleFacade() {
    const { subscription } = this.state;
    window.open('https://flamingo.co.nz/join-plan/' + subscription.facade.slug);
  }

  /* RENDERS */

  renderPlan(plan, i) {
    return (
      <div className="fm-subscription-list-item-plan" key={i}>
        <p className="fm-subscription-list-item-plan-name">{ plan.name }</p>
        <div className="fm-subscription-list-item-plan-container">
          <div className="fm-subscription-list-item-plan-details">
            <p className="fm-subscription-list-item-plan-label">${ plan.cost / 100 } per { plan.days } days</p>
            { plan.domains.length > 0 && <p className="fm-subscription-list-item-plan-label">Emails: { plan.domains.map((domain) => `@${domain.domain}`).join(', ') }</p> }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { subscription, usage, loading, error } = this.state;

    const totalTerm = { daily: 'Daily', weekly: 'Weekly' };

    let actions = [];
    if (subscription.facade) {
      actions.push({ onClick: this.handleFacade, icon: 'external-link-square-alt' });
    }

    return (
      <div className="fm-subscription-plan">
        <NavigationBar title={ subscription.name || 'Subscription' } subtitle={ subscription.region ? subscription.region.name : null } showBack={true} rightActions={actions} loading={loading} />
        <div className="fm-subscription-plan-content">
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Public</p>
            <p className="fm-subscription-plan-row-value">{ subscription.visible ? 'Yes' : 'No' }</p>
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Minutes Term:</p>
            { subscription.minutesTerm && <p className="fm-subscription-plan-row-value">{ totalTerm[subscription.minutesTerm] }</p> }
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">{ totalTerm[subscription.minutesTerm || 'daily'] } Minutes:</p>
            { subscription.minutes && <p className="fm-subscription-plan-row-value">{ subscription.minutes } mins</p> }
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Additional Usage Rate:</p>
            { subscription.perMin && <p className="fm-subscription-plan-row-value">${ (subscription.unlock / 100) } to unlock, { subscription.perMin }c per min</p> }
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Region:</p>
            <p className="fm-subscription-plan-row-value">{ subscription.region ? subscription.region.name : 'National' }</p>
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Includes Group Rides:</p>
            <p className="fm-subscription-plan-row-value">{ subscription.includesGroups ? 'Yes' : 'No' }</p>
          </div>
          {
            subscription.facade &&
            <div className="fm-subscription-plan-row">
              <p className="fm-subscription-plan-row-key">Joining Page:</p>
              <p className="fm-subscription-plan-row-value">flamingo.co.nz/join-plan/{ subscription.facade.slug }</p>
            </div>
          }
          <div className="fm-subscription-plan-row">
            { subscription.terms && <p className="fm-subscription-plan-row-terms">Terms: { subscription.terms }</p> }
          </div>
          <p className="fm-subscription-plan-title">Plans</p>
          <div className="fm-subscription-plan-plans">
            { subscription.plans && subscription.plans.map(this.renderPlan) }
          </div>
          <br />
          <p className="fm-subscription-plan-title">28 Day Usage</p>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Active Subscriptions (Now):</p>
            <p className="fm-subscription-plan-row-value">{ usage.activeSubscriptions ? usage.activeSubscriptions.toLocaleString() : 0 }</p>
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Total Subscriptions:</p>
            <p className="fm-subscription-plan-row-value">{ usage.totalSubscriptions ? usage.totalSubscriptions.toLocaleString() : 0 }</p>
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Total Subscribers Taken Trip:</p>
            <p className="fm-subscription-plan-row-value">{ usage.totalUsers ? usage.totalUsers.toLocaleString() : 0 }</p>
          </div>
          <div className="fm-subscription-plan-row">
            <p className="fm-subscription-plan-row-key">Total Trips:</p>
            <p className="fm-subscription-plan-row-value">{ usage.totalTrips ? usage.totalTrips.toLocaleString() : 0 }</p>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SubscriptionList;
