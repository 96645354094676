import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import NavigationBar from 'components/common/navigation-bar';
import Input from 'components/common/input';
import history from 'helpers/history';
import api from 'helpers/api';

class Users extends Component {
  constructor(props) {
    super(props);
    const query = props.match.params.query ? decodeURIComponent(props.match.params.query) : '';
    this.state = { results: [], query, loading: query.length > 0 };
    this.renderResult = this.renderResult.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = throttle(500, this.search);
  }

  componentDidMount() {
    document.title = 'Users | Flamingo Admin';
    if (this.state.query.length > 0) {
      this.search(this.state.query);
    }
  }

  handleSearchChange(query) {
    this.setState({ loading: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ loading: false });
      return;
    }
    history.replace(`/users/${encodeURIComponent(query)}`)
    api.post('/user/search', { query })
      .then((res) => this.setState({ results: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderResult(user, i) {
    return (
      <Link to={{ pathname: `/user/${user.id}`, state: { user } }} key={i}>
        <div className={`fm-users-result fm-users-result-${user.status}`}>
          <p className="fm-users-result-name">{ `${user.firstName} ${user.lastName}` }</p>
          <p className="fm-users-result-phone">{ user.phone }</p>
          <p className="fm-users-result-email">{ user.email }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, query } = this.state;

    return (
      <div className="fm-users">
        <NavigationBar title="Users" loading={loading} />
        <div className="fm-users-search">
          <div className="fm-users-search-bar">
            <Input placeholder="Search for a user" value={query} autoFocus={true} onChange={this.handleSearchChange} />
          </div>
          <div className="fm-users-results">
            { this.state.results.map(this.renderResult) }
            { (!this.state.loading && this.state.results.length === 0 && this.state.query.length > 0) && <p className="fm-users-results-empty">No users found</p> }
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
