import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import ManualList from './components/manual-list/';
import ManualView from './components/manual-view/';

class Maintenance extends Component {
  render() {
    return (
      <Switch>
        <Route path="/manual/:itemId" component={ManualView} />
        <Route path="/manual" component={ManualList} />
      </Switch>
    );
  }
}

export default Maintenance;
