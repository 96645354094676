import Promise from 'bluebird';

import api from './api';

let templateCache = null;

function getTemplates() {
  if (templateCache) {
    return Promise.resolve(templateCache);
  }

  return api.get('/support/templates')
    .then((res) => {
      templateCache = res.data.data;
      return templateCache;
    });
}

export default getTemplates;
