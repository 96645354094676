import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class Calls extends Component {
  constructor() {
    super();
    this.state = { calls: [], loading: true };
    this.renderCall = this.renderCall.bind(this);
  }

  componentDidMount() {
    document.title = 'Recent Calls | Flamingo Admin';
    api.get('/support/recent-calls')
      .then((res) => this.setState({ calls: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderCall(call, i) {
    const icon = (call.resolvedAt && call.resolvedBy) ? 'check-circle' : (call.resolvedAt ? 'times-circle' : 'pause-circle');
    const color = (call.resolvedAt && call.resolvedBy) ? '#4cd137' : (call.resolvedAt ? '#e84118' : '#7f8fa6');

    if (call.associatedUser) {
      return (
        <Link key={i} to={`/user/${call.associatedUser.id}`} className="fm-support-calls-call">
          <p className="fm-support-calls-call-phone">{ call.phone }</p>
          <p className="fm-support-calls-call-user">{ `${call.associatedUser.firstName} ${call.associatedUser.lastName}` }</p>
          <p className="fm-support-calls-call-date">{ moment(call.createdAt).fromNow() }</p>
          <p className="fm-support-calls-call-icon" style={{ color }}><Icon icon={icon} /></p>
        </Link>
      );
    }
    return (
      <Link key={i} to={`/call/${call.phone || '#'}`} className="fm-support-calls-call">
        <p className="fm-support-calls-call-phone">{ call.phone || 'Private' }</p>
        <p className="fm-support-calls-call-user"></p>
        <p className="fm-support-calls-call-date">{ moment(call.createdAt).fromNow() }</p>
        <p className="fm-support-calls-call-icon" style={{ color }}><Icon icon={icon} /></p>
      </Link>
    );
  }

  render() {
    const { loading, calls } = this.state;
    return (
      <div className="fm-support-calls">
        <NavigationBar title="Recent Calls" loading={loading} showBack={true} />
        <div className="fm-support-calls-list">
          { calls.map(this.renderCall) }
        </div>
      </div>
    );
  }
}

export default Calls;
