import React, { Component } from 'react';
import moment from 'moment';
import Input from 'components/common/input';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const eventColors = {
  tripReportCreated: '#4B7BEC',
  tripReportActioned: '#f1c40f',
  supportTicketResolved: '#20BF6B',
  supportTicketItem: '#7f8c8d',
  pricingPlanApplicationReviewed: '#1abc9c',
};

class PerformanceActivity extends Component {
  constructor() {
    super();
    const maxDate = moment().format('YYYY-MM-DD');
    this.state = { activity: [], date: maxDate, maxDate, loading: false };
    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    document.title = 'Support Activity | Flamingo Admin';
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const { date } = this.state;
    api.post('/support/activity', { date })
      .then((res) => this.setState({ activity: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    let error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    if (error === 'validation' && window.access(() => e.response.data.validation[0])) {
      error = `Invalid ${e.response.data.validation[0]}`;
    }
    this.setState({ error, loading: false });
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  renderItem(item, i) {
    return (
      <div className="fm-support-activity-item" key={i}>
        <p className="fm-support-activity-item-event" style={{ backgroundColor: eventColors[item.eventType] }}>{ item.eventType }</p>
        <p className="fm-support-activity-item-user">{ item.user.firstName } { item.user.lastName }</p>
        <p className="fm-support-activity-item-date">{ moment(item.eventDate).format('h:mma D/M/YY') }</p>
      </div>
    );
  }

  render() {
    const { activity, loading, error } = this.state;

    return (
      <div className="fm-support-activity">
        <NavigationBar title="Support Activity" loading={loading} />
        <div className="fm-support-activity-options">
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} max={this.state.maxDate} />
          <button className="fm-support-activity-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-support-activity-timeline">
          { activity.map(this.renderItem) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default PerformanceActivity;
