import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import moment from 'moment';
import user from 'helpers/user';
import api from 'helpers/api';

const targetIcons = {
  scooter: `https://storage.googleapis.com/flamingo-static/images/admin/icon-scooter-white.svg`,
  bike: `https://storage.googleapis.com/flamingo-static/images/admin/icon-bike-white.svg`,
};

class HubCurrent extends Component {
  constructor() {
    super();
    this.state = { hubs: [], loading: true, targetKey: moment().add(3, 'hours').format('dddd').toLowerCase() };

    this.loadHubs = this.loadHubs.bind(this);
    this.parseHubs = this.parseHubs.bind(this);
    this.refreshHubs = this.refreshHubs.bind(this);
    this.totalTarget = this.totalTarget.bind(this);
    this.zeroTarget = this.zeroTarget.bind(this);
    this.renderHub = this.renderHub.bind(this);
  }

  componentDidMount() {
    document.title = 'Hub Targets | Flamingo Admin';
    localStorage.setItem('fm-hub', 'list');
    this.loadHubs();
  }

  loadHubs() {
    return api.get('/hub')
      .then((res) => this.parseHubs(res.data.data))
      .catch(console.log);
  }

  parseHubs(allHubs) {
    const hubs = allHubs.map(this.totalTarget).filter(this.zeroTarget).sort((a, b) => !!b.totalTarget - !!a.totalTarget || a.sort - b.sort);
    this.setState({ hubs, loading: false });
  }

  refreshHubs() {
    if (this.state.loading) {
      return;
    }
    // Check when it was last updated
    const difference = moment().diff(Math.min.apply(null,this.state.hubs.map(a => new Date(a.occupancyUpdatedAt))), 'minutes');
    if (difference >= 5 || window.confirm(`Data was last updated ${difference} minutes ago. Are you sure you want to update?`)) {
      // Refresh the hubs
      this.setState({ loading: true });
      return api.post('/hub')
        .then((res) => this.parseHubs(res.data.data))
        .catch(console.log);
    }
  }

  totalTarget(hub) {
    hub.totalTarget = hub.targets.filter((target) => target.day === this.state.targetKey).reduce((a, c) => c.target + a, 0);
    return hub;
  }

  zeroTarget(hub) {
    return hub.totalTarget > 0 || user.isManager();
  }

  renderHubTarget(target, i, hub) {
    if (target.target === 0) {
      return null;
    }
    return (
      <div className="fm-hub-current-target" key={i}>
        <p className="fm-hub-current-target-label">{ hub[`${target.vehicleType}Occupancy`] } / { target.target }</p>
        <img className="fm-hub-current-target-icon" src={targetIcons[target.vehicleType]} alt={target.vehicleType} />
      </div>
    );
  }

  renderHub(hub, i) {
    const targets = hub.targets.filter((target) => target.day === this.state.targetKey);
    const percentage = Math.min(targets.map((t) => t.target === 0 ? 1 : hub.occupancy / t.target));
    const backgroundColor = hub.totalTarget === 0 ? '#111' : (percentage > 0.8 ? '#20BF6B' : (percentage > 0.5 ? '#f39c12' : '#EB3B5A'));
    return (
      <Link to={`/hub/${hub.id}`} key={i} className="fm-hub-current-item" style={{ backgroundImage: `url(${hub.photoUrl})` }}>
        <div className="fm-hub-current-overlay">
          <div className="fm-hub-current-status" style={{ backgroundColor }}></div>
          <div className="fm-hub-current-details">
            { targets.map((t, i) => this.renderHubTarget(t, i, hub)) }
            <p className="fm-hub-current-name">{ hub.name }</p>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, hubs } = this.state;
    const subtitle = moment().add(3, 'hours').format('dddd');
    const addAction = { to: '/hub/add', icon: 'plus-circle' };
    const refreshAction = { onClick: this.refreshHubs, icon: 'sync-alt' };
    const mapAction = { to: '/hub/map', icon: 'map-marked' };

    return (
      <div className="fm-hub-current">
        <NavigationBar title="Hub Targets" subtitle={subtitle} loading={loading} rightActions={[addAction, mapAction, refreshAction]} />
        <div className="fm-hub-current-items">
          { hubs.map(this.renderHub) }
        </div>
      </div>
    );
  }
}

export default HubCurrent;
