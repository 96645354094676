import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Input from 'components/common/input';
import NavigationBar from 'components/common/navigation-bar';
import colors from 'helpers/colors';
import api from 'helpers/api';

class PerformanceOutOfHours extends Component {
  constructor() {
    super();
    const maxDate = moment().format('YYYY-MM-DD');
    this.state = { users: [], date: maxDate, maxDate, loading: false };
    this.loadData = this.loadData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.renderEvent = this.renderEvent.bind(this);
  }

  componentDidMount() {
    document.title = 'Out of Hours Activity | Flamingo Admin';
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    const { date } = this.state;
    api.post('/performance/out-of-hours', { date })
      .then((res) => this.setState({ users: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  renderUser(user, i) {
    return (
      <div className="fm-performance-hours-row" key={i}>
        <p className="fm-performance-hours-row-name">{ user.firstName } { user.lastName }</p>
        <div className="fm-performance-hours-events">
          { user.statusChanges.map(this.renderEvent) }
        </div>
      </div>
    );
  }

  renderEvent(item, i) {
    return (
      <div className="fm-performance-hours-event-row" key={i}>
        <Link className="fm-performance-hours-event-row-type" style={{ backgroundColor: colors.performanceActivity[item.vehicleState] }} to={`/vehicle/${item.vehicleId}`}>{ item.vehicleState.replace(/_/g, ' ') }</Link>
        <p className="fm-performance-hours-event-row-description">{ item.eventType.replace(/_/g, ' ') }</p>
        <a className="fm-performance-hours-event-row-time" target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${item.eventLatitude},${item.eventLongitude}`}>{ moment(item.eventTime).format('h:mma') }</a>
      </div>
    );
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div className="fm-performance-hours">
        <NavigationBar title="Out of Hours Activity" loading={loading} />
        <div className="fm-performance-hours-options">
          <Input type="date" value={this.state.date} onChange={this.handleDateChange} max={this.state.maxDate} />
          <button className="fm-performance-hours-options-go" onClick={this.loadData}>Go</button>
        </div>
        <div className="fm-performance-hours-list">
          { users.map(this.renderUser) }
        </div>
      </div>
    );
  }
}

export default PerformanceOutOfHours;
