import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsOperations extends Component {
  constructor(props) {
    super(props);
    const query = props.location.search.substring(1).split('&').reduce((a, c) => { const x = c.split('='); a[x[0]] = x[1]; return a }, {});
    const today = moment().format('YYYY-MM-DD')
    this.state = { weekEnding: today, today, weeks: query.weeks || 1, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Operations Analytics | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const { weeks, weekEnding } = this.state;
    return api.post('/analytics/operations', { weeks, weekEnding })
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleDateChange(e) {
    this.setState({ weekEnding: e.target.value });
  }

  render() {
    const { loading, data, weeks, weekEnding, today } = this.state;
    const taskTitles = { batterySwap: 'Battery Swap', charge: 'Charge', rebalance: 'Rebalance', repark: 'Repark', pickup: 'Pickup', inspection: 'Inspection', helmet: 'Helmet', deploy: 'Deploy' };

    return (
      <div className="fm-analytics">
        <NavigationBar title="Operations" loading={loading} />
        <div className="fm-analytics-operations">
          <div className="fm-analytics-operations-form">
            <input value={weekEnding} className="fm-input" type="date" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-operations-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            data ? (
              <table className="fm-analytics-operations-table">
                {
                  Object.values(data.regions).map((region, i) => {
                    const averageActive = region.dailyActive.reduce((t, c) => (t += c.maxActive), 0) / (7 * weeks) / region.cap * 100;
                    const [taskHours, totalTasks] = region.taskDurations.reduce((acc, cur) => {
                      acc[0] = acc[0] + cur.averageDuration * cur.total;
                      acc[1] = acc[1] + cur.total;
                      return acc;
                    }, [0, 0]);
                    return (
                      <tbody key={i}>
                        <tr>
                          <td className="fm-analytics-operations-table-label"><b>{ region.name }</b></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Active Scooters</td>
                          <td className="fm-analytics-operations-table-value">{ averageActive.toFixed(2) }%</td>
                        </tr>
                        {
                          region.dailyActive.map((day, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">{ moment(day.activeDate, 'YYYY/MM/DD').format('dddd, D/M/YY') }</td>
                                <td className="fm-analytics-operations-table-value">{ day.maxActive }</td>
                              </tr>
                            );
                          })
                        }
                        {
                          region.hubOccupancy.length === 0 ? null : (
                            <>
                              <tr>
                                <td className="fm-analytics-operations-table-label"></td>
                                <td className="fm-analytics-operations-table-value"></td>
                              </tr>
                              <tr>
                                <td className="fm-analytics-operations-table-label">Hub Occupancy ({ region.hubOccupancyHour })</td>
                                <td className="fm-analytics-operations-table-value"></td>
                              </tr>
                            </>
                          )
                        }
                        {
                          region.hubOccupancy.map((day, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">{ moment(day.validAt).format('dddd, D/M/YY') }</td>
                                <td className="fm-analytics-operations-table-value">{ day.targetPercent }%</td>
                              </tr>
                            );
                          })
                        }
                        {
                          region.hubsOccupancy.length > 0 &&
                          <tr>
                            <td className="fm-analytics-operations-table-label"></td>
                            <td className="fm-analytics-operations-table-value"></td>
                          </tr>
                        }
                        {
                          region.hubsOccupancy.map((hub, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">{ hub.name }</td>
                                <td className="fm-analytics-operations-table-value">{ hub.targetPercent }%</td>
                              </tr>
                            );
                          })
                        }
                        {
                          region.hubDeploys.length === 0 ? null : (
                            <>
                              <tr>
                                <td className="fm-analytics-operations-table-label"></td>
                                <td className="fm-analytics-operations-table-value"></td>
                              </tr>
                              <tr>
                                <td className="fm-analytics-operations-table-label">Hub Deploys</td>
                                <td className="fm-analytics-operations-table-value"></td>
                              </tr>
                            </>
                          )
                        }
                        {
                          region.hubDeploys.map((day, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">{ moment(day.deployDate).format('dddd, D/M/YY') }</td>
                                <td className="fm-analytics-operations-table-value">{ day.total }</td>
                              </tr>
                            );
                          })
                        }
                        <tr>
                          <td className="fm-analytics-operations-table-label"></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Week Offline Vehicles</td>
                          <td className="fm-analytics-operations-table-value">{ region.offline }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label"></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Week Maintenance Inspections</td>
                          <td className="fm-analytics-operations-table-value">{ region.inspectionsTotal }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Week Vehicles Inspected</td>
                          <td className="fm-analytics-operations-table-value">{ region.inspectionsVehicleTotal }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Week Overdue Inspections</td>
                          <td className="fm-analytics-operations-table-value">{ region.inspectionsOverdue }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Week Average Rating</td>
                          <td className="fm-analytics-operations-table-value">{ region.averageReview.toFixed(2) }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Week Scan Fails</td>
                          <td className="fm-analytics-operations-table-value">{ (region.failedScans * 100).toFixed(2) }%</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label">Total Hours</td>
                          <td className="fm-analytics-operations-table-value">{ (region.totalHours).toFixed(2) }</td>
                        </tr>
                        <tr>
                          <td className="fm-analytics-operations-table-label"></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                        {
                          region.taskDurations.map((task, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">Average { taskTitles[task.type] } Duration</td>
                                <td className="fm-analytics-operations-table-value">{ task.averageDuration.toFixed(2) } hours</td>
                              </tr>
                            );
                          })
                        }
                        {
                          <tr>
                            <td className="fm-analytics-operations-table-label">Overall Average Duration</td>
                            <td className="fm-analytics-operations-table-value">{ (taskHours / totalTasks).toFixed(2) } hours</td>
                          </tr>
                        }
                        <tr>
                          <td className="fm-analytics-operations-table-label"></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                        {
                          region.taskDurations.map((task, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">Total { taskTitles[task.type] } Tasks</td>
                                <td className="fm-analytics-operations-table-value">{ task.total }</td>
                              </tr>
                            );
                          })
                        }
                        {
                          <tr>
                            <td className="fm-analytics-operations-table-label">Total Tasks</td>
                            <td className="fm-analytics-operations-table-value">{ totalTasks }</td>
                          </tr>
                        }
                        {
                          region.tasksLeftover.length === 0 ? null : (
                            <>
                              <tr>
                                <td className="fm-analytics-operations-table-label"></td>
                                <td className="fm-analytics-operations-table-value"></td>
                              </tr>
                              <tr>
                                <td className="fm-analytics-operations-table-label">Leftover Tasks</td>
                                <td className="fm-analytics-operations-table-value"></td>
                              </tr>
                            </>
                          )
                        }
                        {
                          region.tasksLeftover.map((day, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">{ moment(day.taskDay).format('dddd, D/M/YY') }</td>
                                <td className="fm-analytics-operations-table-value">{ day.totalExpired + day.totalOverlap }</td>
                              </tr>
                            );
                          })
                        }
                        <tr>
                          <td className="fm-analytics-operations-table-label"></td>
                          <td className="fm-analytics-operations-table-value"></td>
                        </tr>
                        {
                          region.items.map((item, ii) =>{
                            return (
                              <tr key={ii}>
                                <td className="fm-analytics-operations-table-label">{ item.label }</td>
                                <td className="fm-analytics-operations-table-value">{ item.value }</td>
                              </tr>
                            );
                          })
                        }
                        {
                          region.items.length === 0 ? null : (
                            <tr>
                              <td className="fm-analytics-operations-table-label"></td>
                              <td className="fm-analytics-operations-table-value"></td>
                            </tr>
                          )
                        }
                      </tbody>
                    );
                  })
                }
              </table>
            ) : (
              <p className="fm-analytics-operations-empty">Select the week ending date above.</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsOperations;
