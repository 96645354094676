import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import FirmwareUpgrade from './components/firmware-upgrade/';
import FirmwareView from './components/firmware-view/';

class Firmware extends Component {
  render() {
    return (
      <Switch>
        <Route path="/vehicle/:vehicleId/firmware/upgrade" component={FirmwareUpgrade} />
        <Route path="/vehicle/:vehicleId/firmware" component={FirmwareView} />
      </Switch>
    );
  }
}

export default Firmware;
