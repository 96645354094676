import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import history from 'helpers/history';
import api from 'helpers/api';
import user from 'helpers/user';
import colors from 'helpers/colors';

import TaskNewMap from './task-new-map';
import TaskNewScanner from './task-new-scanner';
import TaskNewSearch from './task-new-search';

const taskTypes = [
  { value: 'charge', label: 'Charge' },
  { value: 'pickup', label: 'Pickup (Return to warehouse)' },
  { value: 'deploy', label: 'Deploy (Pickup from warehouse)' },
  { value: 'rebalance', label: 'Rebalance' },
  { value: 'repark', label: 'Repark' },
  { value: 'batterySwap', label: 'Battery Swap' },
  { value: 'inspection', label: 'Inspection' },
  { value: 'helmet', label: 'Helmet' },
];

const modes = [
  { value: 'map', label: 'Map Mode' },
  { value: 'search', label: 'Search Mode' },
];

const priorities = [
  { value: 1, label: 'Normal Priority' },
  { value: 2, label: 'Medium Priority' },
  { value: 3, label: 'High Priority' },
];

const autoFill = [
  'Check battery terminal, sticker and swap battery',
  'Check brakes and tighten if possible',
];

class TaskNew extends Component {
  constructor(props) {
    super(props);
    const type = props.match.params.type || 'pickup';
    this.state = { vehicles: [], users: [], type, priority: 1, notes: '', mode: 'search', creating: false, assignedTo: 0, user: {} };

    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.renderVehicle = this.renderVehicle.bind(this);
  }

  componentDidMount() {
    document.title = `New Task | Flamingo Admin`;

    user.getCurrentUser(false).then((user) => this.setState({ user }));

    api.get('/user/team')
      .then((res) => this.setState({ users: [{ label: 'Unassigned', value: 0 }].concat(res.data.data.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.id }))) }))
      .catch((e) => alert(e.toString()));

    if (this.props.match.params.vehicleId) {
      api.get(`/vehicle/${this.props.match.params.vehicleId}`)
        .then((res) => this.setState({ vehicles: [res.data.data] }))
        .catch((e) => alert(e.toString()));
    }
  }

  handleModeChange(e) {
    const mode = e.target.value;
    this.setState({ mode });
  }

  handleUserChange(e) {
    const assignedTo = e.target.value;
    this.setState({ assignedTo });
  }

  handleTypeChange(e) {
    const type = e.target.value;
    this.setState({ type });
  }

  handlePriorityChange(e) {
    const priority = parseInt(e.target.value);
    this.setState({ priority });
  }

  handleNotesChange(e) {
    const notes = e.target.value;
    this.setState({ notes });
  }

  handleAdd(vehicle) {
    const vehicles = this.state.vehicles;
    if (vehicles.findIndex((v) => v.id === vehicle.id) === -1) {
      vehicles.push(vehicle);
      this.setState({ vehicles });
    }
  }

  handleRemove(index) {
    const vehicles = this.state.vehicles;
    vehicles.splice(index, 1);
    this.setState({ vehicles });
  }

  handleCreate() {
    const { type, vehicles, creating, notes, priority, assignedTo } = this.state;
    if (creating || vehicles.length === 0) {
      return;
    }
    const vehicleIds = vehicles.map((vehicle) => vehicle.id);
    const data = { vehicleIds, type, priority };
    if (assignedTo > 0) {
      data.assignedTo = assignedTo;
    }
    if (notes.length > 0) {
      data.notes = notes;
    } else if (priority > 1) {
      return alert('A note is required for medium-high priority tasks.');
    }
    this.setState({ creating: true });
    api.post('/task', data)
      .then((res) => alert(`${res.data.data.length}/${vehicleIds.length} tasks created successfully.`))
      .then(() => history.goBack())
      .catch((e) => this.setState({ creating: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderVehicle(vehicle, i) {
    return (
      <div className="fm-task-new-selected-vehicle" key={i} onClick={() => this.handleRemove(i)}>
        <div className="fm-task-new-selected-vehicle-status" style={{ backgroundColor: colors.status[vehicle.status] }}></div>
        <p className="fm-task-new-selected-vehicle-rego">{ vehicle.registration }</p>
      </div>
    );
  }

  render() {
    const { type, mode, vehicles, creating, users, assignedTo, priority, notes, user } = this.state;

    return (
      <div className="fm-task-new">
        <NavigationBar title="New Task" loading={creating} showBack={true} />
        <div className="fm-task-new-mode">
          <select className="fm-input fm-input-select fm-task-new-mode-select" value={mode} onChange={this.handleModeChange}>
            { modes.map((mode, i) => <option key={i} value={mode.value}>{mode.label}</option>) }
          </select>
        </div>
        <div className="fm-task-new-box">
          { mode === 'map' && <TaskNewMap selectedVehicles={vehicles} onAdd={this.handleAdd} /> }
          { mode === 'scanner' && <TaskNewScanner /> }
          { mode === 'search' && <TaskNewSearch onAdd={this.handleAdd} /> }
        </div>
        <div className="fm-task-new-selected">
          { (vehicles.length === 0 && mode === 'map') && <p className="fm-task-new-note">Adjust map filters from Vehicles page.</p> }
          { vehicles.map(this.renderVehicle) }
        </div>
        <div className="fm-task-new-options">
          <select className="fm-input fm-input-select" value={type} onChange={this.handleTypeChange}>
            { taskTypes.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
          </select>
          <div className="fm-task-new-options-group">
            <select className="fm-input fm-input-select" value={assignedTo} onChange={this.handleUserChange}>
              { users.map((user, i) => <option key={i} value={user.value}>{user.label}</option>) }
            </select>
            <select className="fm-input fm-input-select" value={priority} onChange={this.handlePriorityChange}>
              { priorities.map((priority, i) => <option key={i} value={priority.value}>{priority.label}</option>) }
            </select>
          </div>
          <input type="text" className="fm-input" value={notes} onChange={this.handleNotesChange} placeholder={ priority > 1 ? 'Notes (Required)' : 'Notes (Optional)' } />
          {
            user.id === 21 &&
            <div className="fm-task-new-auto">
              { autoFill.map((notes, i) => <button className="fm-task-new-auto-item" key={i} onClick={() => this.setState({ notes })}>{ notes }</button>) }
            </div>
          }
          <button className="fm-task-new-button" onClick={this.handleCreate}>{ creating ? 'Loading...' : (vehicles.length > 1 ? 'Create Tasks' : 'Create Task') }</button>
          <br />
        </div>
      </div>
    );
  }
}

export default TaskNew;
