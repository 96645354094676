import React, { Component } from 'react';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class AddStaff extends Component {
  constructor() {
    super();
    this.state = { email: '', isManager: false, canChangeRegion: false, submitting: false, regions: [], error: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadRegions = this.loadRegions.bind(this);
    this.handleManagerChange = this.handleManagerChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCanChangeRegionChange = this.handleCanChangeRegionChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Add Staff | Flamingo Admin';
    this.loadRegions();
  }

  loadRegions() {
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data }))
      .catch(console.log);
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const { email, regionId, canChangeRegion, isManager } = this.state;
    this.setState({ submitting: true });

    return api.post('/user/invite', { email, regionId, canChangeRegion, isManager })
      .then(() => history.push('/staff'))
      .catch((e) => this.setState({ submitting: false, error: this.handleError(e) }));
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    return window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
  }

  handleEmailChange(email) {
    this.setState({ email, error: false });
  }

  handleManagerChange(isManager) {
    this.setState({ isManager, error: false });
  }

  handleCanChangeRegionChange(canChangeRegion) {
    this.setState({ canChangeRegion, error: false });
  }

  handleRegionChange(regionId) {
    this.setState({ regionId, error: false });
  }

  render() {
    const regionOptions = this.state.regions.map((region) => ({ label: region.name, value: region.id }));
    return (
      <div className="fm-page fm-staff">
        <p className="fm-page-title">Add Staff</p>
        <p className="fm-text">Enter the user's details below to invite them to the Flamingo Admin Platform.</p>
        <form onSubmit={this.handleSubmit}>
          <Input label="Email" value={this.state.email} onChange={this.handleEmailChange} type="email" placeholder="example@flamingoscooters.com" disabled={this.state.submitting} />
          <Input label="Manager" type="checkbox" checked={this.state.isManager} onChange={this.handleManagerChange} disabled={this.state.submitting} />
          <Input label="Can Change Region" type="checkbox" checked={this.state.canChangeRegion} onChange={this.handleCanChangeRegionChange} disabled={this.state.submitting} />
          <Input label="Region" type="select" defaultOption="Select Region" value={this.state.regionId} options={regionOptions} onChange={this.handleRegionChange} disabled={this.state.submitting} />
          <Button loading={this.state.submitting}>Add</Button>
        </form>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AddStaff;
