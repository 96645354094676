import React, { Component } from 'react';
import Input from 'components/common/input';
import Toast from 'components/common/toast';
import Button from 'components/common/button';
import auth from 'helpers/auth';
import history from 'helpers/history';

const firebaseErrors = {
  'auth/invalid-email': 'Invalid Email',
  'auth/user-not-found': 'Invalid Login',
  'auth/wrong-password': 'Invalid Password',
  'auth/too-many-requests': 'Too many unsuccessful login attempts, try again later',
  'auth/not-authorized': 'You are not authorized to use this platform',
};

class Login extends Component {
  constructor() {
    super();
    this.state = { email: '', password: '', error: false, loading: false };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginError = this.handleLoginError.bind(this);
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'Login | Flamingo Admin';
  }

  handleEmailChange(email) {
    this.setState({ email, error: false });
  }

  handlePasswordChange(password) {
    this.setState({ password, error: false });
  }

  handleLogin(e) {
    if (e) {
      e.preventDefault();
    }
    const { email, password } = this.state;
    if (!email) {
      this.setState({ error: 'Invalid Email' });
      return;
    }
    if (!password) {
      this.setState({ error: 'Invalid Password' });
      return;
    }
    this.setState({ loading: true });
    auth.login(email, password)
      .then(this.handleLoginSuccess)
      .catch(this.handleLoginError);
  }

  handleLoginError(error) {
    this.setState({ error: firebaseErrors[error.code] || error.message, loading: false })
  }

  handleLoginSuccess(user) {
    history.push('/');
  }

  render() {
    return (
      <div className="fm-login">
        <div className="fm-login-container">
          <div className="fm-login-logo"></div>
          <p className="fm-login-title">Admin Platform</p>
          <div className="fm-login-form">
            <form onSubmit={this.handleLogin}>
              <Input label="Email" onChange={this.handleEmailChange} value={this.state.email} disabled={this.state.loading} placeholder="example@flamingoscooters.com" autoFocus />
              <Input label="Password" onChange={this.handlePasswordChange} value={this.state.password} disabled={this.state.loading} type="password" />
              <Button loading={this.state.loading}>Login</Button>
            </form>
          </div>
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default Login;
