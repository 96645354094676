import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';
import Map, { Marker, Polyline } from 'components/common/map';

const longDate = 'HH:mm:ss dddd, Do MMMM YYYY';
const tagColours = {
  'FALLENDOWN': '#0fb9b1',
  'LIFTED': '#0fb9b1',
  'ILLEGALMOVE': '#f0932b',
  'IOTREMOVED': '#eb4d4b',
};
const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  'FALLENDOWN': { url: baseUrl + 'theft-fallen.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'LIFTED': { url: baseUrl + 'theft-lifted.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'ILLEGALMOVE': { url: baseUrl + 'theft-movement.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'IOTREMOVED': { url: baseUrl + 'theft-iot.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'SCAN': { url: baseUrl + 'theft-scan.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'VIEW': { url: baseUrl + 'theft-view.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'OPEN': { url: baseUrl + 'theft-open.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
  'AREA': { url: baseUrl + 'theft-area.png', size: new window.google.maps.Size(28, 28), scaledSize: new window.google.maps.Size(28, 28), anchor: new window.google.maps.Point(14, 14) },
};

class VehicleTheft extends Component {
  constructor() {
    super();
    this.state = { report: {}, loading: true };

    this.loadReport = this.loadReport.bind(this);
    this.handleError = this.handleError.bind(this);

    this.setSelected = this.setSelected.bind(this);

    this.renderAreaView = this.renderAreaView.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
    this.renderScan = this.renderScan.bind(this);
  }

  componentDidMount() {
    document.title = 'Theft Report | Flamingo Admin';
    this.loadReport();
  }

  /* NETWORKING */

  loadReport() {
    this.setState({ loading: true });
    let url = `/vehicle/${this.props.match.params.vehicleId}/theft-report`;
    if (this.props.match.params.statusChangeId) {
      url += `?statusChangeId=${this.props.match.params.statusChangeId}`;
    }
    return api.get(url)
      .then((res) => this.setState({ report: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, updating: false, isRefreshing: false });
  }

  /* ACTIONS */

  setSelected(type, i) {
    const item = this.state.report[type][i];
    switch (type) {
      case 'alerts':
        alert(`${item.alert} ${moment(item.occurredAt).fromNow()}.`);
        break;
      case 'scans':
        alert(`Vehicle scanned by ${item.user.firstName} ${item.user.lastName} ${moment(item.scannedAt).fromNow()}.`);
        break;
      case 'nearbyOrigin':
        alert(`App opened by ${item.user.firstName} ${item.user.lastName} ${(item.distance * 1000).toFixed()} meters away from last status change ${moment(item.createdAt).fromNow()}.`);
        break;
      case 'nearbyLocation':
        alert(`App opened by ${item.user.firstName} ${item.user.lastName} ${(item.distance * 1000).toFixed()} meters away from current location ${moment(item.createdAt).fromNow()}.`);
        break;
      default:
        return
    }
  }

  /* RENDERS */

  renderAreaView(item, i) {
    return (
      <Link className="fm-vehicle-theft-item" key={i} to={`/user/${item.user.id}`}>
        <div className="fm-vehicle-theft-item-header">
          <p className="fm-vehicle-theft-item-tag" style={{ backgroundColor: '#A3CB38' }}>AREA VIEW</p>
          <p className="fm-vehicle-theft-item-date">{ moment(item.viewedAt).format(longDate) } ({ moment(item.viewedAt).fromNow() }).</p>
        </div>
        <p className="fm-vehicle-theft-item-text">Area viewed by { item.user.firstName } { item.user.lastName }.</p>
      </Link>
    );
  }

  renderAlert(item, i) {
    return (
      <a className="fm-vehicle-theft-item" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${item.latitude},${item.longitude}`}>
        <div className="fm-vehicle-theft-item-header">
          <p className="fm-vehicle-theft-item-tag" style={{ backgroundColor: tagColours[item.alert] }}>{ item.alert }</p>
          <p className="fm-vehicle-theft-item-date">{ moment(item.occurredAt).format(longDate) } ({ moment(item.occurredAt).fromNow() })</p>
        </div>
      </a>
    );
  }

  renderView(item, i) {
    return (
      <Link className="fm-vehicle-theft-item" key={i} to={`/user/${item.user.id}`}>
        <div className="fm-vehicle-theft-item-header">
          <p className="fm-vehicle-theft-item-tag" style={{ backgroundColor: '#009432' }}>VIEW</p>
          <p className="fm-vehicle-theft-item-date">{ moment(item.occuredAt).format(longDate) } ({ moment(item.occuredAt).fromNow() }).</p>
        </div>
        <p className="fm-vehicle-theft-item-text">Vehicle viewed by { item.user.firstName } { item.user.lastName }.</p>
      </Link>
    );
  }

  renderScan(item, i) {
    return (
      <Link className="fm-vehicle-theft-item" key={i} to={`/user/${item.user.id}`}>
        <div className="fm-vehicle-theft-item-header">
          <p className="fm-vehicle-theft-item-tag" style={{ backgroundColor: '#0652DD' }}>SCAN</p>
          <p className="fm-vehicle-theft-item-date">{ moment(item.scannedAt).format(longDate) } ({ moment(item.scannedAt).fromNow() })</p>
        </div>
        <p className="fm-vehicle-theft-item-text">Vehicle scanned by { item.user.firstName } { item.user.lastName }.</p>
      </Link>
    );
  }

  renderLocation(item, i) {
    return (
      <Link className="fm-vehicle-theft-item" key={i} to={`/user/${item.user.id}`}>
        <div className="fm-vehicle-theft-item-header">
          <p className="fm-vehicle-theft-item-tag" style={{ backgroundColor: '#9980FA' }}>APP OPEN</p>
          <p className="fm-vehicle-theft-item-date">{ moment(item.createdAt).format(longDate) } ({ moment(item.createdAt).fromNow() })</p>
        </div>
        <p className="fm-vehicle-theft-item-text">App opened by { item.user.firstName } { item.user.lastName } { (item.distance * 1000).toFixed() } meters away.</p>
      </Link>
    );
  }

  render() {
    const { loading, report } = this.state;
    const refreshAction = { onClick: this.loadReport, icon: 'sync-alt' };

    const path = [];
    if (report.lastStatusChange) {
      path.push({ lat: report.lastStatusChange.eventLatitude, lng: report.lastStatusChange.eventLongitude });
    }
    if (report.alerts) {
      path.push(...[...report.alerts].reverse().map((alert) => ({ lat: alert.latitude, lng: alert.longitude })));
    }
    if (report.vehicle) {
      path.push({ lat: report.vehicle.latitude, lng: report.vehicle.longitude });
    }
    const locations = [];
    if (report.locations) {
      locations.push(...report.locations.map((loc) => ({ lat: loc.latitude, lng: loc.longitude })));
    }

    return (
      <div className="fm-vehicle-theft">
        <NavigationBar title="Theft Report" loading={loading} showBack={true} rightActions={[refreshAction]} />
        {
          !loading &&
          <div className="fm-vehicle-theft-content">
            <div className="fm-vehicle-theft-map">
              <Map google={window.google} options={{ center: { lat: report.vehicle.latitude, lng: report.vehicle.longitude }, zoom: 14 }}>
                { locations.length > 1 && <Polyline path={locations} strokeColor={'#000'} strokeWeight={'4'} strokeOpacity={0.4} /> }
                { path.length > 0 && <Polyline path={path} strokeColor={'#ff206e'} strokeWeight={'4'} /> }
                <Marker position={{ lat: report.vehicle.latitude, lng: report.vehicle.longitude }} title={report.vehicle.registration} />
                { report.alerts.map((alert, i) => <Marker key={i} position={{ lat: alert.latitude, lng: alert.longitude }} icon={icons[alert.alert]} title={`${alert.alert} - ${moment(alert.occurredAt).fromNow()}`} onClick={() => this.setSelected('alerts', i)} />) }
                { report.scans.map((scan, i) => <Marker key={i} position={{ lat: scan.latitude, lng: scan.longitude }} icon={icons['SCAN']} title={`${scan.user.firstName } ${scan.user.lastName} - ${moment(scan.scannedAt).fromNow()}`} onClick={() => this.setSelected('scans', i)} />) }
                { report.nearbyOrigin.map((location, i) => <Marker key={i} position={{ lat: location.latitude, lng: location.longitude }} icon={icons['OPEN']} title={`${location.user.firstName } ${location.user.lastName} - ${moment(location.createdAt).fromNow()}`} onClick={() => this.setSelected('nearbyOrigin', i)} />) }
                { report.nearbyLocation.map((location, i) => <Marker key={i} position={{ lat: location.latitude, lng: location.longitude }} icon={icons['OPEN']} title={`${location.user.firstName } ${location.user.lastName} - ${moment(location.createdAt).fromNow()}`} onClick={() => this.setSelected('nearbyLocation', i)} />) }
              </Map>
            </div>
            <div className="fm-vehicle-theft-summary">
              <div className="fm-vehicle-theft-summary-row">
                <p className="fm-vehicle-theft-summary-text">Last cellular signal</p>
                <p className="fm-vehicle-theft-summary-date">{ moment(report.vehicle.statusTime).format(longDate) } ({ moment(report.vehicle.statusTime).fromNow() })</p>
              </div>
              <div className="fm-vehicle-theft-summary-row">
                <p className="fm-vehicle-theft-summary-text">Last GPS signal</p>
                <p className="fm-vehicle-theft-summary-date">{ moment(report.vehicle.gpsTime).format(longDate) } ({ moment(report.vehicle.gpsTime).fromNow() })</p>
              </div>
              <div className="fm-vehicle-theft-summary-row">
                {
                  report.lastStatusChange.user ? (
                    <Link className="fm-vehicle-theft-summary-text" to={`/user/${report.lastStatusChange.user.id}`}>Last status change { report.lastStatusChange.eventTypeReason } by { report.lastStatusChange.user.firstName } { report.lastStatusChange.user.lastName }.</Link>
                  ) : (
                    <p className="fm-vehicle-theft-summary-text">Last status change { report.lastStatusChange.eventTypeReason }.</p>
                  )
                }
                <p className="fm-vehicle-theft-summary-date">{ moment(report.lastStatusChange.eventTime).format(longDate) } ({ moment(report.lastStatusChange.eventTime).fromNow() })</p>
              </div>
            </div>
            {
              report.alerts.length > 0 &&
              <div className="fm-vehicle-theft-section">
                <p className="fm-vehicle-theft-title">Alerts</p>
                <div className="fm-vehicle-theft-items">
                  { report.alerts.map(this.renderAlert) }
                </div>
              </div>
            }
            {
              report.scans.length > 0 &&
              <div className="fm-vehicle-theft-section">
                <p className="fm-vehicle-theft-title">Scans</p>
                <div className="fm-vehicle-theft-items">
                  { report.scans.map(this.renderScan) }
                </div>
              </div>
            }
            {
              report.nearbyOrigin.length > 0 &&
              <div className="fm-vehicle-theft-section">
                <p className="fm-vehicle-theft-title">App Opens Near Last Status Change</p>
                <div className="fm-vehicle-theft-items">
                  { report.nearbyOrigin.map(this.renderLocation) }
                </div>
              </div>
            }
            {
              report.nearbyLocation.length > 0 &&
              <div className="fm-vehicle-theft-section">
                <p className="fm-vehicle-theft-title">App Opens Near Current Location</p>
                <div className="fm-vehicle-theft-items">
                  { report.nearbyLocation.map(this.renderLocation) }
                </div>
              </div>
            }
            {
              report.views.length > 0 &&
              <div className="fm-vehicle-theft-section">
                <p className="fm-vehicle-theft-title">Views</p>
                <div className="fm-vehicle-theft-items">
                  { report.views.map(this.renderView) }
                </div>
              </div>
            }
            {
              report.areaViews.length > 0 &&
              <div className="fm-vehicle-theft-section">
                <p className="fm-vehicle-theft-title">Area Views</p>
                <div className="fm-vehicle-theft-items">
                  { report.areaViews.map(this.renderAreaView) }
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default VehicleTheft;
