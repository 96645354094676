import React from 'react';

class RadioGroup extends React.Component {
  render() {
    return (
      <div className={'fm-radio-group'}>
        { this.props.label && <label className="fm-radio-group-label">{this.props.label}</label> }
        <div className="fm-radio-group-inputs">
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default RadioGroup;
