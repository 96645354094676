import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class InstructionGroup extends Component {
  constructor(props) {
    super(props);
    const preloadedGroup = props.location.state ? props.location.state.group : false;
    this.state = { group: preloadedGroup || {}, editingItems: [], loading: true, error: false };

    this.loadGroup = this.loadGroup.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderInstruction = this.renderInstruction.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  /* NETWORKING */

  loadGroup() {
    this.setState({ loading: true });
    api.get(`/instruction/group/${this.props.match.params.instructionGroupId}`)
      .then((res) => this.setState({ group: res.data.data, loading: false }, () => { document.title = `${res.data.data.name} | Flamingo Admin` }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  toggleEdit(e) {
    const { group, editingItems } = this.state;
    const groupIndex = parseInt(e.target.getAttribute('groupindex'));
    const itemId = parseInt(e.target.getAttribute('iid'));
    if (editingItems.includes(itemId)) {
      const items = editingItems.filter((i) => i !== itemId);
      this.setState({ editingItems: items, loading: true });

      const itemIndex = group.instructions[groupIndex].items.findIndex(item => item.id === itemId);
      const itemData = {
        title: group.instructions[groupIndex].items[itemIndex].title,
        description: group.instructions[groupIndex].items[itemIndex].description,
      };

      api.post(`/instruction/item/${itemId}`, itemData)
        .then((res) => this.setState({ loading: false }))
        .catch(this.handleError);

    } else {
      editingItems.push(itemId);
      this.setState({ editingItems });
    }
  }

  onChange(e) {
    const { group } = this.state;
    const groupIndex = parseInt(e.target.getAttribute('groupindex'));
    const itemId = parseInt(e.target.getAttribute('iid'));
    const itemType = e.target.getAttribute('iitype');
    const itemIndex = group.instructions[groupIndex].items.findIndex(item => item.id === itemId);
    if (group.instructions[groupIndex].items[itemIndex]) {
      group.instructions[groupIndex].items[itemIndex][itemType] = e.target.value;
      this.setState({ group });
    }
  }

  /* RENDERS */

  renderInstruction(instruction, i) {
    return (
      <div className="fm-instruction-group-instruction" key={i}>
        <div className="fm-instruction-group-instruction-header">
          <p className="fm-instruction-group-instruction-name">{instruction.name}</p>
          <p className="fm-instruction-group-instruction-lang">{instruction.lang}</p>
          <p className="fm-instruction-group-instruction-id">{instruction.id}</p>
        </div>
        <div className="fm-instruction-group-items">
          { instruction.items && instruction.items.map((item, ii) => this.renderItem(item, ii, i)) }
        </div>
      </div>
    );
  }

  renderItem(item, i, groupIndex) {
    const isEditing = this.state.editingItems.includes(item.id);
    return (
      <div className="fm-instruction-group-item" key={i}>
        <div className="fm-instruction-group-item-header">
          <div className="fm-instruction-group-item-photo" style={{ backgroundImage: `url('${item.photoUrl}')` }} />
          <div className="fm-instruction-group-item-header-right">
            <p className="fm-instruction-group-item-id">{item.id}</p>
            <button className="fm-instruction-group-item-edit" onClick={this.toggleEdit} groupindex={groupIndex} iid={item.id}><Icon icon={ isEditing ? 'check-square' : 'edit' } /></button>
          </div>
        </div>
        {
          isEditing ? (
            <>
              <input className="fm-instruction-group-item-input" groupindex={groupIndex} iid={item.id} iitype="title" value={item.title} onChange={this.onChange} />
              <textarea className="fm-instruction-group-item-textarea" groupindex={groupIndex} iid={item.id} iitype="description" value={item.description} onChange={this.onChange}></textarea>
            </>
          ) : (
            <>
              <p className="fm-instruction-group-item-title">{item.title}</p>
              <p className="fm-instruction-group-item-description">{item.description}</p>
            </>
          )
        }
      </div>
    );
  }

  render() {
    const { group, loading, error } = this.state;

    return (
      <div className="fm-instruction-group">
        <NavigationBar title={group.name || 'Instruction'} subtitle={group.id} loading={loading} showBack={true} />
        <div className="fm-instruction-group-container">
          { group.instructions && group.instructions.map(this.renderInstruction) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default InstructionGroup;
