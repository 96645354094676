import Promise from 'bluebird';

import api from './api';

let timer = null;
let callback = null;
let refreshRate = 60000;

function set(tickets) {
  if (callback) {
    callback(tickets);
    setRefresh();
  }
}

function parseTickets(res) {
  if (callback) {
    callback(res.data.data);
    setRefresh();
  }
  return Promise.resolve(res.data.data);
}

function retryTickets() {
  if (callback) {
    setRefresh();
  }
  return Promise.resolve([]);
}

function refresh(newCallback = null) {
  if (newCallback) {
    callback = newCallback;
  }
  return api.get('/ticket/active')
    .then(parseTickets)
    .catch(retryTickets);
}

function clearRefresh() {
  callback = null;
  if (timer) {
    clearTimeout(timer);
  }
}

function setRefresh() {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(refresh, refreshRate);
}

const tickets = {
  set,
  refresh,
  clearRefresh,
};

export default tickets;
