import axios from 'axios';
import auth from './auth';

const apiUrl = 'https://admin.api.flamingoscooters.com';
const fileApiUrl = 'https://files.flamingoscooters.com';
// const apiUrl = 'https://staging.api.flamingoscooters.com';
// const apiUrl = 'http://localhost:3002';

const request = (method, endpoint, data = null, config = {}, needsAuthorization = true, fileApi = false) => {
  if (needsAuthorization) {
    return auth.getIdToken().then((idToken) => {
      const initialConfig = { method, url: (fileApi ? fileApiUrl : apiUrl) + endpoint, headers: { Authorization: idToken }, data };
      return axios({ ...initialConfig, ...config });
    });
  }
  const initialConfig = { method: method, url: apiUrl + endpoint, data };
  return axios({ ...initialConfig, ...config });
};

const get = (endpoint, data = null, config = {}, needsAuthorization = true) => {
  return request('GET', endpoint, data, config, needsAuthorization);
};

const post = (endpoint, data = null, config = {}, needsAuthorization = true, fileApi = false) => {
  return request('POST', endpoint, data, config, needsAuthorization, fileApi);
};

const errorStrings = {
  does_not_exist: 'The requested item does not exist.',
  not_found: 'The requested item was not found.',
  something_went_wrong: 'Something went wrong.',
  already_setup_stripe: 'You have already setup Stripe.',
};

const errorHandler = (e) => {
  if (window.access(() => e.response.data.code)) {
    if (e.response.data.validation) {
      return `Invalid ${e.response.data.validation.join(', ')}.`;
    }
    return errorStrings[e.response.data.code] || e.response.data.code;
  }
  console.log(e);
  return e.toString() || 'Something went wrong.';
};

const api = { get, post, errorHandler };

export default api;
