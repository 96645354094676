import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class ReplaceIot extends Component {
  constructor() {
    super();
    this.state = { vehicle: { iotCode: '' }, imei: '', type: 'spare', loading: true };

    this.changeImei = this.changeImei.bind(this);
    this.keyDown = this.keyDown.bind(this);

    this.loadVehicle = this.loadVehicle.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSpare = this.handleSpare.bind(this);
    this.handleExisting = this.handleExisting.bind(this);

    this.inputImei = React.createRef();
  }

  componentDidMount() {
    document.title = 'Replace IoT | Flamingo Admin';
    this.inputImei.current.focus();
    this.loadVehicle();
  }

  loadVehicle() {
    return api.get('/vehicle/' + this.props.match.params.vehicleId)
      .then((res) => this.setState({ vehicle: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleAdd() {
    this.setState({ loading: true, error: false });
    const { imei, type } = this.state;

    if (imei.charAt(0) !== '8' && imei.length !== 15) {
      return this.setState({ loading: false, error: 'Invalid IMEI' });
    }

    return api.post(`/vehicle/${this.props.match.params.vehicleId}/replace-iot`, { imei })
      .then(() => alert(type === 'spare' ? 'IoT replaced and awaiting activation.' : 'IoT change completed.'))
      .then(() => history.push(`/vehicle/${this.props.match.params.vehicleId}`))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  changeImei(e) {
    const imei = e.target.value;
    this.setState({ imei });
  }

  handleSpare() {
    this.setState({ type: 'spare' });
  }

  handleExisting() {
    this.setState({ type: 'existing' });
  }

  keyDown(key, e) {
    if (e.key === 'Enter') {
      this.handleAdd();
    }
  }

  render() {
    const { loading, type, vehicle, imei, error } = this.state;
    const title = vehicle.registration ? `Replace IoT - ${vehicle.registration}` : 'Replace IoT';
    const sliderClass = `fm-replace-slider ${ type === 'spare' ? 'fm-replace-slider-spare' : 'fm-replace-slider-existing' }`;

    return (
      <div className="fm-replace">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-replace-content">
          <div className="fm-replace-input">
            <p className="fm-input-label">Existing IoT IMEI</p>
            <input value={vehicle.iotCode} className="fm-input" disabled={true} />
          </div>
          <p className="fm-replace-slider-title">Are you replacing this IoT with a <b>new/spare IoT</b> or an <b>IoT currently connected to another vehicle</b>?</p>
          <div className={sliderClass}>
            <div className="fm-replace-slider-background"></div>
            <div className="fm-replace-slider-options">
              <button className="fm-replace-slider-option fm-replace-slider-option-spare" onClick={this.handleSpare} disabled={loading}>New/Spare IoT</button>
              <button className="fm-replace-slider-option fm-replace-slider-option-existing" onClick={this.handleExisting} disabled={loading}>IoT from Vehicle</button>
            </div>
          </div>
          {
            type === 'spare' ? (
              <p className="fm-replace-body">This form will replace the existing IoT with a new/spare IoT. The existing IoT will be deactivated. If the existing IoT is non functional, it can be discarded. If the existing IoT can be reused, ensure it is correctly labeled and used as a "Spare IoT" in the future. It takes approximately 15 minutes for an IoT to activate.</p>
            ) : (
              <p className="fm-replace-body">This form will swap the IoTs between two vehicles. Both IoTs will remain activate. If the existing IoT is non functional, ensure it is placed in "Offline" mode afterwards, not "Repair", so that it is deactivated. Remember to check the QR codes are correct after replacing the IoT.</p>
            )
          }
          <div className="fm-replace-input">
            <p className="fm-input-label">{ type === 'spare' ? 'New/Spare IoT IMEI' : 'Other Vehicle\'s IoT IMEI' }</p>
            <input ref={this.inputImei} value={imei} className="fm-input" onChange={this.changeImei} onKeyDown={(e) => this.keyDown('imei', e)} type="number" />
          </div>
          <button className="fm-task-new-button" onClick={this.handleAdd}>{ loading ? 'Loading...' : 'Replace IoT' }</button>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default ReplaceIot;
