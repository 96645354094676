import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import Input from 'components/common/input';
import api from 'helpers/api';

class VoucherList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vouchers: [],
      queriedVouchers: [],
      query: '',
      loading: true,
      error: false,
    };

    this.loadVouchers = this.loadVouchers.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderVoucher = this.renderVoucher.bind(this);
  }

  componentDidMount() {
    document.title = `Vouchers | Flamingo Admin`;
    this.loadVouchers();
  }

  /* NETWORKING */

  loadVouchers() {
    this.setState({ loading: true });
    return api.get(`/voucher`)
      .then((res) => this.setState({ vouchers: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  handleSearchChange(query) {
    this.setState({ loading: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ loading: false });
      return;
    }
    api.get(`/voucher?query=${encodeURIComponent(query)}`)
      .then((res) => this.setState({ queriedVouchers: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* RENDERS */

  renderVoucher(voucher, i) {
    const statusClass = (voucher.redemptions < voucher.maxRedemptions && moment().isBefore(voucher.expires)) ? 'fm-voucher-list-item-status-dot fm-voucher-list-item-status-dot-active' : 'fm-voucher-list-item-status-dot fm-voucher-list-item-status-dot-inactive';
    const valueString = voucher.type === 'CREDIT' ? `$${ (voucher.value / 100).toFixed(2) }` : `${ voucher.value } ${ voucher.type === 'MINUTES' ? 'mins' : 'unlocks' }`;
    return (
      <Link className="fm-voucher-list-item" key={i} to={{ pathname: `/voucher/${voucher.id}`, state: { voucher } }}>
        <div className="fm-voucher-list-item-status">
          <div className={statusClass}></div>
        </div>
        <div className="fm-voucher-list-item-main">
          <p className="fm-voucher-list-item-code">{ voucher.code }</p>
          <p className="fm-voucher-list-item-notes">{ valueString } - { voucher.notes }</p>
        </div>
        <div className="fm-voucher-list-item-details">
          <p className="fm-voucher-list-item-redemptions">{ voucher.redemptions } / { voucher.maxRedemptions }</p>
          <p className="fm-voucher-list-item-expires">{ moment(voucher.expires).format('HH:mm DD-MM-YYYY') }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { vouchers, queriedVouchers, query, loading, error } = this.state;

    return (
      <div className="fm-voucher-list">
        <NavigationBar title="Vouchers" loading={loading} />
        <div className="fm-voucher-list-search">
          <Input placeholder="Search for a voucher" value={query} autoFocus={true} onChange={this.handleSearchChange} />
        </div>
        <div className="fm-voucher-list-items">
          {
            query.length < 2 ? (
              vouchers.map(this.renderVoucher)
            ) : (
              !loading && queriedVouchers.length === 0 ? (
                <p className="fm-voucher-list-empty">No vouchers found.</p>
              ) : (
                queriedVouchers.map(this.renderVoucher)
              )
            )
          }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default VoucherList;
