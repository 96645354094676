import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class Warehouses extends Component {
  constructor(props) {
    super(props);
    this.state = { warehouses: [], loading: true, actioning: false };

    this.loadWarehouses = this.loadWarehouses.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderWarehouse = this.renderWarehouse.bind(this);
  }

  componentDidMount() {
    document.title = 'Warehouses | Flamingo Admin';
    this.loadWarehouses();
  }

  // Networking

  loadWarehouses() {
    return api.get('/warehouse')
      .then((res) => this.setState({ warehouses: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, actioning: false, loading: false });
  }

  // Handlers

  handleAction(action, label) {
    const promptResult = window.confirm(`Are you sure you want to action "${label}"?`);
    if (promptResult === true) {
      this.setState({ actioning: true });
      return api.post(`/warehouse/${action}`)
        .then((res) => this.setState({ actioning: false }, () => alert('Action sent successfully! Please wait at least 10 seconds before trying again.')))
        .catch(this.handleError);
    }
  }

  // Renders

  renderWarehouse(warehouse, i) {
    const { actioning } = this.state;
    return (
      <div className="fm-warehouses-item" key={i}>
        <p className="fm-warehouses-item-title">{ warehouse.name }</p>
        <p className="fm-warehouses-item-address">{ warehouse.address }</p>
        { warehouse.notes && <p className="fm-warehouses-item-notes">{ warehouse.notes }</p> }
        <div className="fm-warehouses-item-buttons">
          { warehouse.action && <button className="fm-warehouses-item-button" disabled={actioning} onClick={() => this.handleAction(warehouse.action, warehouse.actionLabel)}>{ warehouse.actionLabel || 'Trigger Action' }</button> }
          { warehouse.secondaryAction && <button className="fm-warehouses-item-button" disabled={actioning} onClick={() => this.handleAction(warehouse.secondaryAction, warehouse.secondaryActionLabel)}>{ warehouse.secondaryActionLabel || 'Trigger Action' }</button> }
        </div>
      </div>
    );
  }

  render() {
    const { loading, actioning, warehouses, error } = this.state;

    return (
      <div className="fm-warehouses">
        <NavigationBar title="Warehouses" loading={loading || actioning} />
        <div className="fm-warehouses-content">
          { warehouses.map(this.renderWarehouse) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Warehouses;
