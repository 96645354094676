import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Credentials from './components/credentials';
import AddCredential from './components/add-credential';

class Insights extends Component {
  render() {
    return (
      <Switch>
        <Route path="/data-sharing/add-credential" component={AddCredential} />
        <Route path="/data-sharing" component={Credentials} />
      </Switch>
    );
  }
}

export default Insights;
