import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import InspectionNew from './components/inspection-new/';
import InspectionView from './components/inspection-view/';

class Inspection extends Component {
  render() {
    return (
      <Switch>
        <Route path="/inspection/new/:vehicleId" component={InspectionNew} />
        <Route path="/inspection/:inspectionId" component={InspectionView} />
      </Switch>
    );
  }
}

export default Inspection;
