import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

class ReportParking extends Component {
  constructor(props) {
    super(props);
    this.state = { regions: [], loading: true };

    this.loadStatistics = this.loadStatistics.bind(this);
    this.renderRegion = this.renderRegion.bind(this);
  }

  componentDidMount() {
    document.title = 'Parking | Flamingo Admin';
    this.loadStatistics();
  }

  loadStatistics() {
    return api.get(`/report/parking/statistics`)
      .then((res) => this.setState({ regions: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderRegion(region, i) {
    const totalReviewed = region.goodTrips + region.badTrips;
    const percentReviewed = totalReviewed / region.totalTrips;
    const targetPercent = percentReviewed / region.target;
    const targetColor = targetPercent >= 1 ? '#4cd137' : (targetPercent >= 0.75 ? '#ffbe13' : '#e84118');
    const statistics = { totalReviewed, totalTrips: region.totalTrips, target: region.target };
    return (
      <Link className="fm-report-parking-stats-card" key={i} to={{ pathname: `/report/parking/review/${region.id}`, state: { statistics } }}>
        <div className="fm-report-parking-stats-card-donut" style={{ '--p': Math.min(parseInt(targetPercent * 100), 100), '--b': '10px', '--c': targetColor }}>
          { parseInt(targetPercent * 100) }%
        </div>
        <div className="fm-report-parking-stats-card-text">
          <p className="fm-report-parking-stats-card-title">{ region.name }</p>
          <p className="fm-report-parking-stats-card-description">{ parseInt(percentReviewed * 100) }% of trips reviewed<span className="fm-report-parking-stats-desktop"> ({ totalReviewed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } / { region.totalTrips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') })</span>. Target { parseInt(region.target * 100) }%.</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, regions } = this.state;
    const actions = [{ to: `/report/parking/statistics`, icon: 'chart-pie' }];
    return (
      <div className="fm-report-parking-stats">
        <NavigationBar title="Parking Review" loading={loading} rightActions={actions} />
        <div className="fm-report-parking-stats-content">
          <Link className="fm-report-parking-stats-card" to="/report/parking/review">
            <div className="fm-report-parking-stats-card-filler"></div>
            <div className="fm-report-parking-stats-card-text">
              <p className="fm-report-parking-stats-card-title">All Regions</p>
              <p className="fm-report-parking-stats-card-description">Only use this option if all regions have reached their target.</p>
            </div>
          </Link>
          { regions.map(this.renderRegion) }
        </div>
      </div>
    );
  }
}

export default ReportParking;
