import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const eventColor = {
  inspection: '#A55EEA',
  trip: '#00a8ff',
};

class VehicleBurnouts extends Component {
  constructor() {
    super();
    this.state = { events: [], notDetected: false, loading: true };

    this.loadEvents = this.loadEvents.bind(this);
    this.handleEvents = this.handleEvents.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderEvent = this.renderEvent.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Burnout Analysis | Flamingo Admin';
    this.loadEvents();
  }

  /* NETWORKING */

  loadEvents() {
    this.setState({ loading: true });
    const url = `/vehicle/${this.props.match.params.vehicleId}/burnouts`;
    return api.get(url)
      .then(this.handleEvents)
      .catch(this.handleError);
  }

  handleEvents(res) {
    const events = res.data.data;
    let notDetected = true;
    if (events.filter((e) => (e.totalOccurrences > 10 || e.significantOccurrences > 3)).length > 0) {
      notDetected = false;
    }
    this.setState({ events, notDetected, loading: false });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderEvent(item, i) {
    const path = item.tripId ? `/trip/${item.tripId}` : (item.inspectionId ? `/inspection/${item.inspectionId}` : '#');
    return (
      <Link className="fm-vehicle-burnouts-item" key={i} to={path}>
        <div className="fm-vehicle-burnouts-item-header">
          <div className="fm-vehicle-burnouts-item-tag">
            <p className="fm-vehicle-burnouts-item-tag-type" style={{ backgroundColor: eventColor[item.type] }}>{ item.type }</p>
            { item.type === 'inspection' && <p className="fm-vehicle-burnouts-item-tag-flag">{ item.result }</p> }
            { item.type === 'trip' && <p className="fm-vehicle-burnouts-item-tag-flag">{ (item.duration / 60).toFixed() } MINS</p> }
          </div>
          <p className="fm-vehicle-burnouts-item-date">{ moment(item.eventTime).format('h:mma D/MM/YY') }</p>
        </div>
        { item.type === 'inspection' && <p className="fm-vehicle-burnouts-item-text">{ item.user.firstName } { item.user.lastName }<span className="fm-vehicle-burnouts-item-text-notes"> - { item.notes }</span></p> }
        { item.type === 'trip' && <p className="fm-vehicle-burnouts-item-text">{ item.user.firstName } { item.user.lastName }<span className="fm-vehicle-burnouts-item-text-notes">{ item.user.notes ? ` - ${item.user.notes}` : '' }</span></p> }
        { (item.type === 'trip' && item.totalOccurrences > 0) && <p className="fm-vehicle-burnouts-item-occurrences">{ item.totalOccurrences } occurrences { item.significantOccurrences > 0 && <span className="fm-vehicle-burnouts-item-occurrences-sig">({ item.significantOccurrences } significant) </span> }detected</p> }
      </Link>
    );
  }

  render() {
    const { loading, notDetected, error, events } = this.state;

    return (
      <div className="fm-vehicle-burnouts">
        <NavigationBar title="Burnout Analysis" loading={loading} showBack={true} />
        <div className="fm-vehicle-burnouts-content">
          <p className="fm-vehicle-burnouts-text">Check the list of recent trips below. <b>Only if a recent trip detected multiple <b className="fm-vehicle-burnouts-item-occurrences-sig">significant</b> occurrences</b> or 10+ normal occurrences and the tyre has been damaged, then please create a Trip Report for "Burnouts". Please include a photo of the damaged tyre in the report. If a report already exists please create a support ticket.</p>
          <p className="fm-vehicle-burnouts-text">Burnout Analysis can only detect burnouts, not skidding and other forms of bad riding. <b>If the tyre has a single spot of damage it is most likely from skidding, not burnouts.</b></p>
          { notDetected && <p className="fm-vehicle-burnouts-warning">WARNING: Not enough burnout data was detected. Please do not create a trip report.</p> }
          { events.map(this.renderEvent) }
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default VehicleBurnouts;
