import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from 'components/common/loading';
import Table from 'components/common/table';
import api from 'helpers/api';

class AllMembers extends Component {
  constructor() {
    super();
    this.state = { loading: false, members: [] };

    this.loadMembers = this.loadMembers.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    document.title = 'Insights Platform | Flamingo Admin';
    this.loadMembers();
  }

  loadMembers() {
    return api.get('/insights/members')
      .then((res) => this.setState({ members: res.data.data, loading: false }))
      .catch(console.log);
  }

  renderTable() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <Table>
        <tbody>
          { this.state.members.map(this.renderRow) }
        </tbody>
      </Table>
    );
  }

  renderRow(row, i) {
    return (
      <tr key={i}>
        <td>{ row.firstName + ' ' + row.lastName }</td>
        <td className="fm-staff-row-light fm-staff-row-no-mobile">{ row.email }</td>
        <td className="fm-staff-row-light">{ row.insight.region.name }</td>
      </tr>
    );
  }

  render() {
    return (
      <div className="fm-page fm-staff">
        <p className="fm-page-title">Insights Users</p>
        <div className="fm-page-actions">
          <Link className="fm-page-action" to="/insights/add">Add User</Link>
        </div>
        { this.renderTable() }
      </div>
    );
  }
}

export default AllMembers;
