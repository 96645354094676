import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../icon';
import user from 'helpers/user';
import auth from 'helpers/auth';
import tickets from 'helpers/tickets';

const nestRegions = [];

class TabBar extends React.Component {
  constructor() {
    super();
    this.state = { user: { admin: {} }, badges: {} };
    this.handleLogout = this.handleLogout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.ticketCallback = this.ticketCallback.bind(this);
  }

  componentDidMount() {
    this.loadUser(false);
    tickets.refresh(this.ticketCallback);
  }

  componentWillUnmount() {
    tickets.clearRefresh();
  }

  componentDidUpdate() {
    if (this.props.path === '/change-region/done') {
      this.loadUser(true);
    }
  }

  loadUser(forceRefresh) {
    user.getCurrentUser(forceRefresh).then((user) => this.setState({ user }));
  }

  handleLogout() {
    auth.logout();
    user.clearUser();
  }

  ticketCallback(tickets) {
    this.setState({ badges: { tickets: tickets.length } });
  }

  render() {
    const { user, badges } = this.state;
    const isNester = nestRegions.includes(user.admin.region ? user.admin.region.id : 0);

    let links = [
      { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
      { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
      { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
    ];

    if (user.admin) {
      if (user.admin.isSuper) {
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'tools', label: 'Maintenance', path: '/maintenance/schedule' },
          { icon: 'virus', label: 'Sanitisation', path: '/sanitisation' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
          { icon: 'life-ring', label: 'Support', path: '/support' },
          { icon: 'flag', label: 'Reports', path: '/report' },
          { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident' },
          { icon: 'user-friends', label: 'Users', path: '/users' },
          { icon: 'user', label: 'Staff', path: '/staff' },
          { icon: 'poll-h', label: 'Team Performance', path: '/performance' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'project-diagram', label: 'Analytics', path: '/analytics' },
          { icon: 'search-location', label: 'Trip Search', path: '/trip/search' },
          { icon: 'parking', label: 'Parking', path: '/report/parking' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'hard-hat', label: 'Helmet Selfies', path: '/helmet-selfies' },
          { icon: 'id-card', label: 'Plan Applications', path: '/plan-applications' },
          { icon: 'infinity', label: 'Subscriptions', path: '/subscription' },
          { icon: 'ticket-alt', label: 'Vouchers', path: '/voucher' },
          { icon: 'building', label: 'Insights', path: '/insights' },
          { icon: 'database', label: 'Data Sharing', path: '/data-sharing' },
          { icon: 'battery-full', label: 'Battery Repair', path: '/battery' },
          { icon: 'plus-circle', label: 'Add Vehicle', path: '/vehicle/add' },
        ];
      } else if (user.admin.isManager) {
        // Manager Staff
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'tools', label: 'Maintenance', path: '/maintenance/schedule' },
          { icon: 'virus', label: 'Sanitisation', path: '/sanitisation' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
          { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident' },
          { icon: 'parking', label: 'Parking', path: '/report/parking' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'user-friends', label: 'Users', path: '/users' },
          { icon: 'user', label: 'Staff', path: '/staff' },
          { icon: 'poll-h', label: 'Team Performance', path: '/performance' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'project-diagram', label: 'Analytics', path: '/analytics' },
          { icon: 'search-location', label: 'Trip Search', path: '/trip/search' },
          { icon: 'battery-full', label: 'Battery Repair', path: '/battery' },
          { icon: 'plus-circle', label: 'Add Vehicle', path: '/vehicle/add' },
        ];
      } else if (user.admin.isSupport) {
        // Support Staff
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'life-ring', label: 'Support', path: '/support' },
          { icon: 'flag', label: 'Reports', path: '/report' },
          { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident' },
          { icon: 'parking', label: 'Parking', path: '/report/parking' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'search-location', label: 'Trip Search', path: '/trip/search' },
          { icon: 'hard-hat', label: 'Helmet Selfies', path: '/helmet-selfies' },
          { icon: 'id-card', label: 'Plan Applications', path: '/plan-applications' },
          { icon: 'infinity', label: 'Subscriptions', path: '/subscription' },
          { icon: 'ticket-alt', label: 'Vouchers', path: '/voucher' },
          { icon: 'user-friends', label: 'Users', path: '/users' },
          { icon: 'user', label: 'Staff', path: '/staff' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
        ];
      } else {
        // Everyone else
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'tools', label: 'Maintenance', path: '/maintenance/schedule' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'virus', label: 'Sanitisation', path: '/sanitisation' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
          { icon: 'poll-h', label: 'Team Performance', path: '/performance' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'battery-full', label: 'Battery Repair', path: '/battery' },
          { icon: 'plus-circle', label: 'Add Vehicle', path: '/vehicle/add' },
        ];
      }
    }

    if (user.admin.showDashboard) {
      links.unshift({ icon: 'tachometer-alt', label: 'Dashboard', path: '/dashboard', mobile: true });
    }

    return (
      <div className="fm-tab-bar">
        <div className="fm-tab-bar-header">
          <Link className="fm-tab-bar-logo" to="/"></Link>
        </div>
        <div className="fm-tab-bar-items">
          { links.map((link, i) => <Link key={i} className={`fm-tab-bar-item${link.mobile ? '' : ' fm-tab-bar-item-desktop'}`} to={link.path}>{ badges[link.badgeKey] ? <span className="fm-tab-bar-item-badge">{ badges[link.badgeKey] }</span> : undefined }<Icon icon={link.icon} />{link.label}</Link>) }
          <Link className="fm-tab-bar-item fm-tab-bar-item-mobile" to="/more"><Icon icon="bars" />More</Link>
          { this.state.user.admin.canChangeRegion ? <Link className="fm-tab-bar-item fm-tab-bar-item-desktop" to={{ pathname: '/change-region', state: { prevPath: 'location.pathname' }}}><Icon icon="location-arrow" />Change Region</Link> : null }
          <Link className="fm-tab-bar-item fm-tab-bar-item-desktop" to="/login" onClick={this.handleLogout}><Icon icon="sign-out-alt" />Logout</Link>
        </div>
        <div className="fm-tab-bar-profile">
          <div className="fm-tab-bar-profile-avatar"></div>
          <div className="fm-tab-bar-profile-details">
            <p className="fm-tab-bar-profile-name">{ this.state.user.firstName } { this.state.user.lastName }</p>
            <p className="fm-tab-bar-profile-region">{ this.state.user.admin.region ? this.state.user.admin.region.name : 'Flamingo' }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default TabBar;
