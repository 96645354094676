import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown'
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class ManualView extends Component {
  constructor(props) {
    super(props);
    const preloadedItem = props.location.state ? props.location.state.item : false;
    this.state = { item: preloadedItem || {}, loading: true, error: false };

    this.loadItem = this.loadItem.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.loadItem();
  }

  /* NETWORKING */

  loadItem() {
    this.setState({ loading: true });
    api.get(`/operation/manual/${this.props.match.params.itemId}`)
      .then((res) => this.setState({ item: res.data.data, loading: false }, () => { document.title = `${res.data.data.title} | Flamingo Admin` }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  render() {
    const { item, loading, error } = this.state;

    return (
      <div className="fm-manual-view">
        <NavigationBar title={item.title || 'Operational Manual'} loading={loading} showBack={true} />
        <div className="fm-manual-view-container">
          { item.content && <ReactMarkdown children={item.content} /> }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ManualView;
