import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Loading from 'components/common/loading';
import api from 'helpers/api';
import Map, { Marker, Polyline, Circle } from 'components/common/map';

const longDate = 'HH:mm:ss D MMMM YYYY';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  tripStart: { url: baseUrl + 'trip-start.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  tripEnd: { url: baseUrl + 'trip-end.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  scan: { url: baseUrl + 'scan.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  movement: { url: baseUrl + 'movement.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  available: { url: baseUrl + 'available.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  user: { url: baseUrl + 'user.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
};

const hdopConvertor = (hdop) => {
  if (hdop <= 2) return { title: 'Excellent', color: '#4cd137' };
  if (hdop <= 5) return { title: 'Good', color: '#fbc531' };
  if (hdop <= 10) return { title: 'Moderate', color: '#fbc531' };
  if (hdop <= 20) return { title: 'Fair', color: '#fbc531' };
  return { title: 'Poor', color: '#e84118' };
};

class VehicleLocator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleId: props.match.params.vehicleId,
      vehicle: {},
      alerts: [],
      activity: [],
      lastStatusChange: {},
      lastTrip: {},
      path: [],
      timeline: [],
      loading: true,
    };

    this.loadVehicle = this.loadVehicle.bind(this);
    this.getVehicle = this.getVehicle.bind(this);
    this.parseVehicle = this.parseVehicle.bind(this);
    this.loadStatusChanges = this.loadStatusChanges.bind(this);
    this.parseStatusChanges = this.parseStatusChanges.bind(this);
    this.loadAlerts = this.loadAlerts.bind(this);
    this.parseAlerts = this.parseAlerts.bind(this);
    this.loadActivity = this.loadActivity.bind(this);
    this.parseActivity = this.parseActivity.bind(this);
    this.loadTrips = this.loadTrips.bind(this);
    this.parseTrips = this.parseTrips.bind(this);
    this.formatData = this.formatData.bind(this);

    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Locator | Flamingo Admin';
    this.loadVehicle();
  }

  /* NETWORKING */

  loadVehicle() {
    this.setState({ loading: true });
    return api.post('/vehicle/' + this.state.vehicleId)
      .then(this.parseVehicle)
      .catch(this.getVehicle);
  }

  getVehicle() {
    return api.get('/vehicle/' + this.state.vehicleId)
      .then(this.parseVehicle)
      .catch(this.handleError);
  }

  parseVehicle(res) {
    this.setState({ vehicle: res.data.data }, this.loadStatusChanges);
  }

  loadStatusChanges() {
    return api.get(`/vehicle/${ this.state.vehicleId }/status-changes`)
      .then(this.parseStatusChanges)
      .catch(this.handleError);
  }

  parseStatusChanges(res) {
    const lastStatusChange = res.data.data.filter((change) => ['trip_end', 'provider_drop_off', 'trip_cancel'].includes(change.eventType))[0];
    this.setState({ lastStatusChange }, this.loadAlerts);
  }

  loadAlerts() {
    return api.get(`/vehicle/${ this.state.vehicleId }/alerts`)
      .then(this.parseAlerts)
      .catch(this.handleError);
  }

  parseAlerts(res) {
    const { lastStatusChange } = this.state;
    const alerts = res.data.data.filter((a) => moment(a.occurredAt).isAfter(lastStatusChange.eventTime));
    return this.setState({ alerts }, this.loadActivity);
  }

  loadActivity() {
    return api.get(`/vehicle/${ this.state.vehicleId }/activity`)
      .then(this.parseActivity)
      .catch(this.handleError);
  }

  parseActivity(res) {
    const { lastStatusChange } = this.state;
    const activity = res.data.data.filter((a) => a.type === 'SCAN' && moment(a.eventTime).isAfter(lastStatusChange.eventTime));
    if (['trip_end', 'trip_cancel'].includes(lastStatusChange.eventType)) {
      return this.setState({ activity }, this.loadTrips);
    }
    return this.setState({ activity }, this.formatData);
  }

  loadTrips() {
    return api.get(`/vehicle/${ this.state.vehicleId }/trips`)
      .then(this.parseTrips)
      .catch(this.handleError);
  }

  parseTrips(res) {
    const lastTrip = res.data.data[0];
    return this.setState({ lastTrip }, this.formatData);
  }

  formatData() {
    const {
      lastStatusChange,
      alerts,
      activity,
      vehicle,
    } = this.state;

    const path = [
      { lat: lastStatusChange.eventLatitude, lng: lastStatusChange.eventLongitude },
    ];
    if (alerts) {
      path.push(...[...alerts].reverse().map((alert) => ({ lat: alert.latitude, lng: alert.longitude })));
    }
    path.push({ lat: vehicle.latitude, lng: vehicle.longitude });

    const timeline = [
      {
        icon: icons.available.url,
        label: (lastStatusChange.eventType === 'provider_drop_off' ? 'Deployed' : 'Trip Ended'),
        lat: lastStatusChange.eventLatitude,
        lng: lastStatusChange.eventLongitude,
        eventTime: lastStatusChange.eventTime,
      },
    ];
    timeline.push(...alerts.map((alert) => ({ icon: icons.movement.url, label: 'Movement', lat: alert.latitude, lng: alert.longitude, eventTime: alert.occurredAt })));
    timeline.push(...activity.reverse().map((activity) => ({ icon: icons.scan.url, label: 'Scan', lat: activity.latitude, lng: activity.longitude, eventTime: activity.eventTime })));

    timeline.sort((a, b) => {
      const keyA = new Date(a.eventTime);
      const keyB = new Date(b.eventTime);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return this.setState({ loading: false, path, timeline });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderItem(item, i) {
    return (
      <a className="fm-vehicle-locator-timeline-item" key={i} target="_blank" rel="noopener noreferrer" href={`https://www.google.co.nz/maps?q=${item.lat},${item.lng}`}>
        <img className="fm-vehicle-locator-timeline-item-image" alt="Key" src={item.icon} />
        <div className="fm-vehicle-locator-timeline-item-details">
          <p className="fm-vehicle-locator-timeline-item-title">{ item.label }</p>
          <p className="fm-vehicle-locator-timeline-item-date">{ moment(item.eventTime).format(longDate) } ({ moment(item.eventTime).fromNow() })</p>
        </div>
      </a>
    );
  }

  render() {
    const {
      loading,
      vehicle,
      lastStatusChange,
      lastTrip,
      activity,
      alerts,
      path,
      timeline,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    const title = vehicle.registration ? `${vehicle.registration} - Vehicle Locator` : 'Vehicle Locator';
    const refreshAction = { onClick: this.loadVehicle, icon: 'sync-alt' };

    const hdop = hdopConvertor(vehicle.hdop);

    return (
      <div className="fm-vehicle-locator">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={[refreshAction]} />
        <div className="fm-vehicle-locator-map">
          <Map google={window.google} options={{ center: { lat: vehicle.latitude, lng: vehicle.longitude }, zoom: 14 }}>
            <Marker position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={vehicle.registration} />
            { path.length > 0 && <Polyline path={path} strokeColor={'#000'} strokeWeight={'4'} strokeOpacity={0.4} /> }
            { vehicle.hdop && <Circle position={{ lat: vehicle.latitude, lng: vehicle.longitude }} radius={vehicle.hdop * 10} strokeWeight={0} fillColor={'#ff206e'} fillOpacity={0.2} /> }
            { lastTrip.startLatitude && <Marker position={{ lat: lastTrip.startLatitude, lng: lastTrip.startLongitude }} title="Trip Start" icon={icons.tripStart} onClick={() => window.open(`https://www.google.co.nz/maps?q=${lastTrip.startLatitude},${lastTrip.startLongitude}`)} /> }
            { lastStatusChange.eventLatitude && <Marker position={{ lat: lastStatusChange.eventLatitude, lng: lastStatusChange.eventLongitude }} title="Last Status Change" icon={icons.available} onClick={() => window.open(`https://www.google.co.nz/maps?q=${lastStatusChange.eventLatitude},${lastStatusChange.eventLongitude}`)} /> }
            { lastTrip.endUserLatitude && <Marker position={{ lat: lastTrip.endUserLatitude, lng: lastTrip.endUserLongitude }} title="User Location" icon={icons.user} onClick={() => window.open(`https://www.google.co.nz/maps?q=${lastTrip.endUserLatitude},${lastTrip.endUserLongitude}`)} /> }
            { lastTrip.endLatitude && <Marker position={{ lat: lastTrip.endLatitude, lng: lastTrip.endLongitude }} title="Trip End" icon={icons.tripEnd} onClick={() => window.open(`https://www.google.co.nz/maps?q=${lastTrip.endLatitude},${lastTrip.endLongitude}`)} /> }
            { lastTrip.encodedPolyline && <Polyline encodedPath={lastTrip.encodedPolyline} strokeColor={'#ff206e'} strokeWeight={'4'} /> }
            { activity.map((scan, i) => <Marker key={i} position={{ lat: scan.latitude, lng: scan.longitude }} icon={icons.scan} onClick={() => window.open(`https://www.google.co.nz/maps?q=${scan.latitude},${scan.longitude}`)} />) }
            { alerts.map((alert, i) => <Marker key={i} position={{ lat: alert.latitude, lng: alert.longitude }} icon={icons.movement} onClick={() => window.open(`https://www.google.co.nz/maps?q=${alert.latitude},${alert.longitude}`)} />) }
          </Map>
        </div>
        <div className="fm-vehicle-locator-details">
          <div className="fm-vehicle-locator-header">
            <div className="fm-vehicle-locator-header-row">
              <div className="fm-vehicle-locator-header-row-top">
                <p className="fm-vehicle-locator-header-row-label">Last Event</p>
                <p className="fm-vehicle-locator-header-row-value">{ lastStatusChange.eventType === 'provider_drop_off' ? 'Deployed' : 'Trip Ended' }{ lastStatusChange.user ? ` by ${lastStatusChange.user.firstName} ${lastStatusChange.user.lastName}` : '' }</p>
              </div>
              <p className="fm-vehicle-locator-header-row-date">{ moment(lastStatusChange.eventTime).format(longDate) } ({ moment(lastStatusChange.eventTime).fromNow() })</p>
            </div>
            <div className="fm-vehicle-locator-header-row">
              <div className="fm-vehicle-locator-header-row-top">
                <p className="fm-vehicle-locator-header-row-label">Cellular Signal</p>
                <p className="fm-vehicle-locator-header-row-value"><span className="fm-vehicle-locator-header-row-dot" style={{ backgroundColor: vehicle.online ? '#4cd137' : '#e84118' }}></span>{ vehicle.online ? 'Connected' : 'Disconnected' }</p>
              </div>
              <p className="fm-vehicle-locator-header-row-date">{ moment(vehicle.statusTime).format(longDate) } ({ moment(vehicle.statusTime).fromNow() })</p>
            </div>
            <div className="fm-vehicle-locator-header-row">
              <div className="fm-vehicle-locator-header-row-top">
                <p className="fm-vehicle-locator-header-row-label">GPS Signal</p>
                <p className="fm-vehicle-locator-header-row-value"><span className="fm-vehicle-locator-header-row-dot" style={{ backgroundColor: hdop.color }}></span>{ hdop.title }</p>
              </div>
              <p className="fm-vehicle-locator-header-row-date">{ moment(vehicle.gpsTime).format(longDate) } ({ moment(vehicle.gpsTime).fromNow() })</p>
            </div>
          </div>
          <div className="fm-vehicle-locator-key">
            <p className="fm-vehicle-locator-key-title">Map Key</p>
            <div className="fm-vehicle-locator-key-row">
              <img className="fm-vehicle-locator-key-row-image" alt="Map Key" src={icons.tripStart.url} />
              <p className="fm-vehicle-locator-key-row-label">Trip Start Location</p>
            </div>
            <div className="fm-vehicle-locator-key-row">
              <img className="fm-vehicle-locator-key-row-image" alt="Map Key" src={icons.tripEnd.url} />
              <p className="fm-vehicle-locator-key-row-label">Trip End Location</p>
            </div>
            <div className="fm-vehicle-locator-key-row">
              <img className="fm-vehicle-locator-key-row-image" alt="Map Key" src={icons.available.url} />
              <p className="fm-vehicle-locator-key-row-label">Available (Locked) Location</p>
            </div>
            <div className="fm-vehicle-locator-key-row">
              <img className="fm-vehicle-locator-key-row-image" alt="Map Key" src={icons.user.url} />
              <p className="fm-vehicle-locator-key-row-label">User Phone Location</p>
            </div>
            <div className="fm-vehicle-locator-key-row">
              <img className="fm-vehicle-locator-key-row-image" alt="Map Key" src={icons.scan.url} />
              <p className="fm-vehicle-locator-key-row-label">Scan Location</p>
            </div>
            <div className="fm-vehicle-locator-key-row">
              <img className="fm-vehicle-locator-key-row-image" alt="Map Key" src={icons.movement.url} />
              <p className="fm-vehicle-locator-key-row-label">Movement Location</p>
            </div>
          </div>
          <div className="fm-vehicle-locator-timeline">
            <p className="fm-vehicle-locator-timeline-title">Timeline</p>
            <div className="fm-vehicle-locator-timeline-items">
              { timeline.map(this.renderItem) }
            </div>
          </div>
          {
            lastTrip.parkingPhotoUrl &&
            <div className="fm-vehicle-locator-parking">
              <p className="fm-vehicle-locator-parking-title">Parking Photo</p>
              <div className="fm-vehicle-locator-parking-container">
                <img className="fm-vehicle-locator-parking-photo" alt="Last Parking" src={lastTrip.parkingPhotoUrl}  />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default VehicleLocator;
