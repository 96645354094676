import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Calls from './components/calls';
import Tickets from './components/tickets';
import Ticket from './components/ticket';
import OnCall from './components/on-call';

import SupportTicket from './components/support-ticket/';
import SupportActivity from './components/support-activity/';

class Support extends Component {
  render() {
    return (
      <Switch>
        <Route path="/support/calls" component={Calls} />
        <Route path="/support/activity" component={SupportActivity} />
        <Route path="/support/on-call" component={OnCall} />
        <Route path="/support/ticket-old/:ticketId" component={Ticket} />
        <Route path="/support/ticket/:ticketId" component={SupportTicket} />
        <Route path="/support/:tab?" component={Tickets} />
      </Switch>
    );
  }
}

export default Support;
