import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import colors from 'helpers/colors';
import ticketHelpers from 'helpers/tickets';

const ticketTypeColors = {
  'note': '#f0932b',
  'maintenance': '#eb4d4b',
  'missing': '#f1c40f',
  'accident': '#eb4d4b',
  'movement': '#f0932b',
  'theft': '#e056fd',
  'noParking': '#f1c40f',
  'parking': '#f1c40f',
};

const taskTypes = {
  rebalance: { label: 'Rebalance', icon: 'arrow-alt-circle-right' },
  pickup: { label: 'Pick Up', icon: 'shuttle-van' },
  inspection: { label: 'Inspection', icon: 'tools' },
};

class TicketActive extends Component {
  constructor() {
    super();
    this.state = { tickets: [], source: 'all', loadingVehicles: [], loading: true };

    this.loadTickets = this.loadTickets.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
    this.mapTickets = this.mapTickets.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.onActionSuccess = this.onActionSuccess.bind(this);
    this.onActionFail = this.onActionFail.bind(this);
    this.resolveAll = this.resolveAll.bind(this);
    this.renderVehicle = this.renderVehicle.bind(this);
    this.renderTicket = this.renderTicket.bind(this);
  }

  componentDidMount() {
    document.title = `Tickets | Flamingo Admin`;

    this.loadTickets();
  }

  loadTickets() {
    this.setState({ loading: true });

    const { source } = this.state;

    let endpoint = '/ticket/active';
    if (source !== 'all') {
      endpoint += `?source=${source}`;
    }
    return api.get(endpoint)
      .then(this.mapTickets)
      .catch((e) => this.setState({ loading: false }, () => alert(e.toString())));
  }

  handleResolve(ticket) {
    const vehicleId = ticket.vehicle.id;
    const { loadingVehicles } = this.state;
    if (loadingVehicles.includes(vehicleId)) { return }
    loadingVehicles.push(vehicleId);
    this.setState({ loadingVehicles });

    return this.resolveAll([ticket])
      .then(() => this.onActionSuccess(vehicleId))
      .catch((e) => this.onActionFail(vehicleId, e));
  }

  mapTickets(res) {
    ticketHelpers.set(res.data.data);
    const ticketItems = res.data.data;
    const vehicleTickets = {};
    const vehicleOrder = [];

    for (var i = 0; i < ticketItems.length; i++) {
      const ticketItem = ticketItems[i]
      const vehicleId = ticketItem.vehicle.id;
      if (vehicleTickets[vehicleId]) {
        vehicleTickets[vehicleId].push(ticketItem);
      } else {
        vehicleTickets[vehicleId] = [ticketItem];
        vehicleOrder.push(vehicleId);
      }
    }

    const tickets = vehicleOrder.map((vehicleId) => vehicleTickets[vehicleId]);
    this.setState({ tickets, loading: false });
  }

  handleSourceChange(e) {
    const source = e.target.value;
    this.setState({ source, loading: true }, this.loadTickets);
  }

  handleAction(action, vehicleId, tickets = []) {
    const { loadingVehicles } = this.state;
    if (loadingVehicles.includes(vehicleId)) { return }
    loadingVehicles.push(vehicleId);
    this.setState({ loadingVehicles });

    if (action === 'resolve') {
      return this.resolveAll(tickets)
        .then(() => this.onActionSuccess(vehicleId))
        .catch((e) => this.onActionFail(vehicleId, e));
    }

    const data = {
      vehicleIds: [vehicleId],
      type: action,
    };

    return api.post(`/task`, data)
      .then(() => this.onActionSuccess(vehicleId))
      .catch((e) => this.onActionFail(vehicleId, e));
  }

  onActionSuccess(vehicleId) {
    const loadingVehicles = this.state.loadingVehicles.filter((v) => v !== vehicleId);
    return this.loadTickets().then(() => this.setState({ loadingVehicles }));
  }

  onActionFail(vehicleId, e) {
    const loadingVehicles = this.state.loadingVehicles.filter((v) => v !== vehicleId);
    this.setState({ loadingVehicles }, () => alert(e.toString()));
  }

  resolveAll(tickets) {
    const requests = tickets.map((ticket) => api.post(`/ticket/${ticket.id}/resolve`))
    return Promise.all(requests);
  }

  renderVehicle(tickets, i) {
    const vehicle = tickets[0].vehicle;
    const types = tickets.map((ticket) => ticket.type);
    const actions = (types.includes('maintenance') || types.includes('accident')) ? ['pickup', 'inspection'] : ['rebalance'];
    const isLoading = this.state.loadingVehicles.includes(vehicle.id);
    return (
      <div className="fm-ticket-active-item" key={i}>
        <div className="fm-ticket-active-item-vehicle">
          <Link className="fm-ticket-active-item-vehicle-label" to={`/vehicle/${vehicle.id}`} title={`${vehicle.status} - ${vehicle.batteryPercent * 100}%`}>
            <div className="fm-ticket-active-item-vehicle-status" style={{ backgroundColor: colors.status[vehicle.status] }}></div>
            <p className="fm-ticket-active-item-vehicle-rego">{ vehicle.registration }</p>
          </Link>
        </div>
        <div className="fm-ticket-active-item-tickets">
          { tickets.map(this.renderTicket) }
        </div>
        <div className="fm-ticket-active-item-actions">
          {
            isLoading ? (
              <div className="fm-ticket-active-item-action">
                <p className="fm-ticket-active-item-action-label">Loading...</p>
              </div>
            ) : (
              <>
              { actions.map((action, i) => this.renderAction(action, i, vehicle.id)) }
              <div className="fm-ticket-active-item-action" onClick={() => this.handleAction('resolve', vehicle.id, tickets)}>
                <Icon icon="check-square" />
                <p className="fm-ticket-active-item-action-label">Resolve</p>
              </div>
              <Link to={`/task/new/${vehicle.id}`} className="fm-ticket-active-item-action fm-ticket-active-item-action-more">
                <p className="fm-ticket-active-item-action-label fm-ticket-active-item-action-large">More</p>
                <Icon icon="chevron-right" />
              </Link>
              </>
            )
          }
        </div>
      </div>
    );
  }

  renderTicket(ticket, i) {
    return (
      <div className="fm-ticket-active-item-ticket" key={i}>
        <Link className="fm-ticket-active-item-ticket-header" to={`/ticket/${ticket.id}`}>
          <p className="fm-ticket-active-item-ticket-type" style={{ backgroundColor: ticketTypeColors[ticket.type] }}>{ ticket.type.toUpperCase() }</p>
          <p className="fm-ticket-active-item-ticket-source">{ ticket.source.toUpperCase() }</p>
          <p className="fm-ticket-active-item-ticket-created">{ moment(ticket.createdAt).fromNow() } - { parseInt(ticket.vehicle.batteryPercent * 100) }%</p>
        </Link>
        <div className="fm-ticket-active-item-ticket-content">
          <Link className="fm-ticket-active-item-ticket-details" to={`/ticket/${ticket.id}`}>{ ticket.description }{ ticket.photoUrl && <Icon icon="images" /> }</Link>
          {
            !this.state.loading && (
              <button className="fm-ticket-active-item-ticket-resolve" onClick={() => this.handleResolve(ticket)}>
                <Icon icon="check-square" />
              </button>
            )
          }
        </div>
      </div>
    );
  }

  renderAction(action, i, vehicleId) {
    return (
      <div className="fm-ticket-active-item-action" onClick={() => this.handleAction(action, vehicleId)} key={i}>
        <Icon icon={taskTypes[action].icon} />
        <p className="fm-ticket-active-item-action-label">{ taskTypes[action].label }</p>
      </div>
    );
  }

  render() {
    const { tickets, loading } = this.state;
    const refreshTicketsAction = { onClick: this.loadTickets, icon: 'sync-alt' };

    return (
      <div className="fm-task-active">
        <NavigationBar title="Pending Tickets" showBack={true} rightActions={[refreshTicketsAction]} loading={loading} />
        <div className="fm-task-active-heading">Create a task for each vehicle based on the active tickets. If the ticket doesn't require a task, resolve it.</div>
        { tickets.map(this.renderVehicle) }
        { (tickets.length === 0 && !loading) && <p className="fm-task-active-empty">All tickets have been reviewed. Thank you.</p> }
      </div>
    );
  }
}

export default TicketActive;
