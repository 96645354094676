import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import TripSearch from './components/trip-search/';
import TripDetails from './components/trip-details/';

class Trip extends Component {
  render() {
    return (
      <Switch>
        <Route path="/trip/search" component={TripSearch} />
        <Route path="/trip/:tripId" component={TripDetails} />
      </Switch>
    );
  }
}

export default Trip;
