import React, { Component } from 'react';
import Map, { Marker } from 'components/common/map';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';
import colors from 'helpers/colors';

class NestView extends Component {
  constructor() {
    super();
    this.state = { nest: {}, loading: true };
    this.loadNest = this.loadNest.bind(this);
    this.resetOccupancy = this.resetOccupancy.bind(this);
    this.handleDirections = this.handleDirections.bind(this);
  }

  componentDidMount() {
    document.title = 'Nest Details | Flamingo Admin';
    this.loadNest();
  }

  loadNest() {
    return api.get('/nest/' + this.props.match.params.nestId)
      .then((res) => this.setState({ nest: res.data.data, loading: false }))
      .catch(console.log);
  }

  resetOccupancy() {
    this.setState({ loading: true });
    api.post('/nest/' + this.props.match.params.nestId + '/reset-occupancy')
      .then((res) => this.setState({ nest: res.data.data, loading: false }))
      .catch(console.log);
  }

  handleDirections() {
    if (this.state.loading) {
      return;
    }
    const { latitude, longitude } = this.state.nest;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  render() {
    const { loading, nest } = this.state;
    const navigationAction = { onClick: this.handleDirections, icon: 'directions' };
    const resetAction = { onClick: this.resetOccupancy, icon: 'redo' };
    const editAction = { to: `/nest/${nest.id}/edit`, icon: 'edit' };
    const mapOptions = { center: { lat: nest.latitude, lng: nest.longitude }, zoom: 15 };

    return (
      <div className="fm-nest-view">
        <NavigationBar title="Nest Details" loading={loading} showBack={true} rightActions={[navigationAction, resetAction, editAction]} />
        {
          !loading &&
          <>
            { nest.photoUrl && <div className="fm-nest-view-photo-container"><div className="fm-nest-view-photo" style={{ backgroundImage: `url('${nest.photoUrl}')` }}></div></div> }
            <div className="fm-nest-view-content">
              <div className="fm-nest-view-tags">
                <p className="fm-nest-view-status" style={{ backgroundColor: colors.status[nest.status] }}>{ nest.status }</p>
                <div className="fm-nest-view-spacer"></div>
                <p className="fm-nest-view-type">{ nest.type.toUpperCase() }</p>
                <p className="fm-nest-view-capacity">{ nest.currentOccupancy } / { nest.maxCapacity }</p>
              </div>
              <p className="fm-nest-view-address">{ nest.address }</p>
              <p className="fm-nest-view-description">{ nest.description }</p>
            </div>
            <div className="fm-nest-view-map">
              <Map options={mapOptions} google={window.google}>
                <Marker position={{ lat: nest.latitude, lng: nest.longitude }} />
              </Map>
            </div>
          </>
        }
      </div>
    );
  }
}

export default NestView;
