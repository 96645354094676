import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class HelmetSelfies extends Component {
  constructor() {
    super();
    this.state = { selfies: [], lastResultClass: 'fm-selfies-image-default', loading: true };

    this.loadSelfies = this.loadSelfies.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.handleInvalid = this.handleInvalid.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'Helmet Selfies | Flamingo Admin';
    this.loadSelfies();
  }

  loadSelfies() {
    this.setState({ loading: true });
    return api.get('/helmet/selfie')
      .then((res) => this.setState({ selfies: res.data.data, lastResultClass: 'fm-selfies-image-default', loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  onKeyPressed(e) {
    e.preventDefault();
    // Check what key
    if (e.keyCode === 37) {
      return this.handleStatus('invalid');
    } else if (e.keyCode === 39) {
      return this.handleStatus('approved');
    }
  }

  handleInvalid() {
    return this.handleStatus('invalid');
  }

  handleApprove() {
    return this.handleStatus('approved');
  }

  handleStatus(status) {
    const selfies = this.state.selfies;
    const selfie = selfies[0];
    if (!selfie) {
      return this.loadSelfies();
    }

    selfies.shift();
    this.setState({ selfies, lastResultClass: status === 'invalid' ? 'fm-selfies-image-invalid' : 'fm-selfies-image-approved' });
    setTimeout(() => this.setState({ lastResultClass: 'fm-selfies-image-default' }), 500);

    return api.post('/helmet/selfie', { helmetSelfieId: selfie.id, status })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  handleSuccess() {
    if (this.state.selfies.length === 0) {
      this.loadSelfies();
    }
  }

  render() {
    const { loading, selfies, lastResultClass } = this.state;
    const refreshAction = { onClick: this.loadSelfies, icon: 'sync-alt' };
    const title = selfies.length > 100 ? `Helmet Selfies (100+)` : (selfies.length === 0 ? `Helmet Selfies` : `Helmet Selfies (${selfies.length})`);

    const selfie = selfies[0];

    return (
      <div className="fm-selfies" onKeyDown={this.onKeyPressed} tabIndex="0">
        <NavigationBar title={title} loading={loading} rightActions={[refreshAction]} />
        {
          selfie ? (
            <div className="fm-selfies-content">
              <div className={`fm-selfies-image ${lastResultClass}`} style={{ backgroundImage: `url('${selfie.photoUrl}')` }}></div>
              <div className="fm-selfies-controls">
                <button className="fm-selfies-controls-invalid" onClick={this.handleInvalid}>Invalid</button>
                <a className="fm-selfies-controls-ride" href={`/trip/${selfie.trip.id}`} rel="noopener noreferrer" target="_blank">{ moment(selfie.createdAt).fromNow() }</a>
                <button className="fm-selfies-controls-approved" onClick={this.handleApprove}>Approve</button>
              </div>
            </div>
          ) : (
            <div className="fm-selfies-content">
              <p className="fm-selfies-empty">{ loading ? `Loading selfies...` : `No selfies outstanding!` }</p>
            </div>
          )
        }
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default HelmetSelfies;
